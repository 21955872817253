.Calendar_root{
    position: relative;
    width: 100%;
}
.Calendar_close{
    position: absolute;
    color: #eb5d7b;
    padding: 5px;
    z-index: 1;
    right: 0px;
    background-color: transparent;
    border: none;
    outline: none;
    height: 100%;
    width: 40px;

    place-content: center;
    display: flex;
    align-items: center;
}
.Calendar_datepicker{
    color:var(--maindark);
    border-radius: 5px;
    width:100%;
    height: 40px;
}