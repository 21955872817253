.play1_root{
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    place-content: flex-start;
}
.play1_msg{
    height: fit-content;
    align-items: center;
    text-align: center;
}
.play1_msgsub{
    width:100%;
    height: 100%;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 5px;
    position: relative;
}
.play1_msgsub:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 6px 6px 6px;
}
.play1_name{
    display: flex;
    place-content: center;
    align-items: center;
    font-weight: bold;
}
.play1_txt{
    display: flex;
    place-content: center;
    align-items: center;
    padding: 5px;
}
.play1_img{
    object-fit: contain;
    max-width: 100%;
    width:100%;
    background-color: #49494912;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px;
}
.play1_audioiconon{
    width: 25px;
    height: 25px;
    top: 0px;
    display: flex;
    align-items: center;
    place-content: center;
    position: absolute;
    background-color: var(--donegreen);
    border-radius: 50px;
    color: white;
}
.play1_audioiconoff{
    width: 25px;
    height: 25px;
    top: 0px;
    display: flex;
    align-items: center;
    place-content: center;
    position: absolute;
    background-color: white;
    border-radius: 50px;
}