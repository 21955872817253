.Nohori_root{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    place-content: center;
}
.Nohori_txt{
    font-weight: bold;
    font-family: Lato;
    font-size: 16px;
}
.Nohori_icon{
    padding:15px;
    transform: rotate(45deg);
}