.Gbguest_root{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width:100%;
    place-content: flex-start;
    align-items: center;
    color: #707070;
    font-family: Lato;
}
.Gbguest_col{
    display: flex;
    flex-direction: column;
    height: 700px;
    min-height: 700px;
    max-width: 310px;
    width:310px;
}
.Gbguest_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}
.Gbguest_num{
    color: #b9b9b9;
    font-weight: bold;
    letter-spacing: 0.5px;
}
.Gbcast_title{
    display: flex;
    font-weight: bold;
    margin:15px 0px;
}
.Gbcast_quotatitle{
    display: flex;
    font-weight: bold;
    margin:15px 10px;
    place-content: center;
    text-align: center;
}
.Gbcast_label{
    display:flex;
    padding:5px 0px 3px 0px;
}
.Gbguest_input{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #707070;
}
.Gbguest_txtarea{
    padding:5px;
    height: 45vh;
    min-height: 150px;
    border-radius: 5px;
    border: 1px solid #707070;
}
.Gbguest_err{
    height: 30px;
    margin: 0px;
    color: rgb(255, 115, 115);
}
.Gbguest_sendbtn{
    padding:5px;
    margin: 25px 0px 15px 0px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: white;
    min-height: 40px;
    font-weight: bold;
}
.Gbguest_imgbtn{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: white;
    height: 40px;
    font-weight: bold;
    width: 100%;
}
.Gbguest_imginput{
    width: 0px;
    height: 0px;
    visibility: hidden;
}
.Gbguest_img{
    display: flex;
    width: 100px;
    height: 100px;
    border: 1px solid #707070;
    margin: 5px 0px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.Gbguest_imgroot{
    display: flex;
    flex-direction: column;
}
.Gbguest_imgrow{
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: flex-end;
}
.Gbguest_img1{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 70px;
    height: 70px;
    border: 1px solid #707070;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.Gbguest_img2{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 70px;
    height: 70px;
    border: 1px solid #707070;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.Gbguest_heart{
    align-items: center;
    display: flex;
    color: #f77f7f;
}

.Gbguest_audiorecordbtnsrow{
    margin: 0px 0px 10px 0px;
}

.Gbguest_audio{
    margin: 0px 0px 10px 0px;
    width: 100%;
}

.Gbguest_audiostartrecord{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: rgb(149, 226, 149);
    color: white;
    height: 40px;
    font-weight: bold;
    width:50%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: thin solid transparent;
}
.Gbguest_audiostart{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: white;
    height: 40px;
    font-weight: bold;
    width:100%;
}
.Gbguest_audiostop{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: white;
    height: 40px;
    font-weight: bold;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width:50%;

}
.Gbguest_audioclear{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: white;
    height: 40px;
    font-weight: bold;
    width:100%;

}
.Gbguest_selectff{
    margin-bottom:5px;
    padding: 5px;
    border-radius: 5px;
}
.Gbguest_wishquestionmark{
    color: white;
    border-radius: 10px;
    text-align: center;
    display: flex;
    background-color: #dd3653;
    height: 20px;
    width: 20px;
    align-items: center;
    place-content: center;
    cursor: pointer;
}
.Gbguest_emojirow{
    display:flex;
    flex-direction:row;
    padding:5px;
    flex-wrap:wrap;
}
.Gbguest_clear{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 5px;
}
.Gbguest_emojitxt{
    width:100%;
    height: 32px;
    border-radius: 5px;
    border: 1px solid #707070;
}
.Gbguest_emojiplus{
    height: 32px;
    width: 32px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    place-content: center;
    margin-left: 5px;
    align-items: center;
    display: flex;
    cursor: pointer;
    background-color: #eb5d7b;
    color: white;
}
.Gb_text{
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    font-size: 16px;
}
.Gb_emoji{
    font-size: 21px;
}
#gb_submitcomplete{
    overflow: hidden;
    overscroll-behavior: contain;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    touch-action: manipulation;
    user-select: none;
    place-content: center;
    height: 85vh;
    width: 100%;
    font-size: 24px;
    flex-direction: column;
}