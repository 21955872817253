:root {
    --t1borderpx: 2.5px;
    --t1dimpx: 40px;
    --t1mobilecolor: #c8c09e;
}

/* 0 */
.T1border_mobile_0 {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    border-radius: 10px;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}

/* 1 */
.T1border_mobile_1 {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}
/* 2 */
.T1border_mobile_2 {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}
/* 3 */
.T1border_mobile_3 {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}
/* 4 */
.T1border_mobile_4 {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}
/* 5 */
.T1border_mobile_5 {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}
/* 6 */
.T1border_mobile_6{
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: absolute;
    margin: 5px;
    top: 0px;
    left: 0px;
    overflow: hidden;

}