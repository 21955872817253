#Rsvpqnexplorer_root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    align-items: center;
    overflow-x: hidden;
}

#Rsvpqnexplorer_root:nth-child(2) {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    background-color: #f5f5f5;
    flex-direction: column;
    align-items: center;
    place-content: flex-start;
}

#Rsvpqnexplorer_top {
    padding: 10px;
    align-items: center;
    height: 60px;
    display: flex;
    place-content: space-between;
    border: 1px solid #dddddd;
}

#Rsvqnexplorer_backbtn {
    border-radius: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    height: max-content;
    top: 0;
    padding: 0px;
    margin: 0px;
    height: 34px;
    width: 34px;
}

#Rsvpexplorer_drag {
    padding: 10px 10px 0px 10px;
    margin: 10px 0px;
    display: flex;
    border: 1px solid  var(--maindark);
    background-color: white;
    display: flex;
    flex-direction: column;
}

.Rsvpexplorer_catindiconrootnumber {
    display: flex;
    align-items: center;
    width: 25px;
    place-content: center;
}

.Rsvpexplorer_catindiconroot {
    border: 1px solid #dddddd;
    border-radius: 20px;
    padding: 0px 5px;
    font-size: 12px;
    height: max-content;
    margin-right: 5px;
    width: max-content;
    display: flex;
    flex-direction: row;
    letter-spacing: 0.5px;
    min-height: 24px;
    align-items: center;
}

.Rsvpexplorer_tag {
    border: 1px solid  var(--maindark);
    border-radius: 20px;
    padding: 0px 8px;
    font-size: 12px;
    margin-right: 5px;
    width: max-content;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
}

.Rsvpexplorer_tag0 {
    border: 1px solid #f5f0dc;
    background-color: beige;
}

.Rsvpexplorer_tag1 {
    border: 1px solid #cbeaf2;
    background-color: #dcf0f5;
}

.Rsvpexplorer_tagfollowup {
    border: 1px solid #fbcbcb;
    background-color: #f5dcdc;
}

.Rsvpexplorer_qntop {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Rsvpexplorer_qntopindex {
    width: 40px;
    font-size: 15px;
}

.Rsvpexplorer_qnbtm {
    padding: 10px 0px;
}

.Rsvpexplorer_qntxt {
    width: 24px;
    height: 24px;
    display: flex;
    text-align: center;
    align-items: center;
    place-content: center;
    font-size: 16px;
}

.Rsvpexplorer_portal {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    overflow-x:hidden;
    /* padding-bottom: 45px; */
}

.Rsvpexplorer_portaltitle {
    font-size: 24px;
    margin-left: 10px;
    height: 60px;
    min-height: 60px;
    align-items: center;
    display: flex;
    font-family: Lato;
    user-select: none;
    pointer-events: none;
}

.Rsvpexplorer_portalcontent {
    height: calc(100% - 125px);
    width: 100%;
    font-family: Lato;
    overflow-y: auto;
    overflow-x: hidden;
    /* border-bottom: 1px solid #ebebeb !important; */
}

.Rsvpexplorer_portalrow {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
}

.Rsvpexplorer_portalbtntxt{
    touch-action: manipulation;
    pointer-events: none;
    height: 50%;
    display: flex;
    font-size: 14px;
}

.Rsvpexplorer_portalselectbtn {
    width: 50%;
    height: 45px;
    font-size: 14px;
    border: 1px solid var(--maindark);
    background-color: white;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
}

.Rsvpexplorer_portalleftbtn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0.5px solid #707070;
}

.Rsvpexplorer_portalmidbtn {
    border-left: 0.5px solid #707070;
    border-right: 0.5px solid #707070;
}

.Rsvpexplorer_portalrightbtn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 0.5px solid #707070;
}

.Rsvpexplorer_btnselected {
    background-color: var(--mainpink);
    color: white;
}

.Rsvpexplorer_portalqntypecontent {
    display: flex;
    width: calc(100% - 20px);
    flex-direction: column;
    overflow-y: auto;
    padding: 10px 0px;
    margin: 30px 10px 60px 10px;

}

.Rsvpexplorer_input {
    height: 40px;
    min-height: 40px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.Rsvpexplorer_emptytextarea {
    display: flex;
    width: 100%;
    height: 180px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    color: #9b9b9b;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
}

.Rsvpexplorer_portalsave {
    width: calc( 100% - 20px );
    height: 45px;
    background-color: var(--mainpink);
    color: white;
    font-size: 18px;
    position: absolute;
    bottom: 0px;
    border: 0px solid transparent;
    border-top: 1px solid var(--mainpink);
    border-radius: 10px;
    margin: 10px;
}

.Rsvpexplorer_addnewchoicebtn {
    height: 40px;
    width: max-content;
    min-height: 40px;
    min-width: 40px;
    padding: 10px;
    background-color: white;
    border-right: none;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px dashed #707070;
    margin: 5px 0px 15px 0px;
    place-self: center;
    width: 150px;
}

.Rsvpexplorer_addnewchoicebtn:hover {
    background-color: #eb5d7ba8;
    color: white;
}

.Rsvpexplorer_portalmultiplechoiceoption {
    height: 40px;
    padding: 5px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    border-radius: 5px;
}

.Rsvpexplorer_optionrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.Rsvpexplorer_optioncol{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.Rsvpexplorer_portalcolname {
    color: white;
    display: flex;
    flex-wrap: wrap;
    place-content: center;
    align-items: center;
    text-align: center;
}

.Rsvpexplorer_portalcolinput {
    width: 100%;
    border: 2px solid var(--maindark);
    border-radius: 5px;
    font-size: 21px;
    padding-left: 5px;
}

.Rsvpexplorer_portalcustomnameroot {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 40px 10px 0px 10px;
    border-radius: 5px;
}

.Rsvpexplorer_portalcustomnamelabel {
    display: flex;
    color:  var(--maindark);
    padding: 0px 10px 0px 3px;
}

.Rsvpexplorer_portalspreadsheetcolrow {
    display: flex;
    flex-direction: row;
    background-color: var(--maindark);
    border-radius: 5px;
    height: 55px;
}

.Rsvpexplorer_portalpermitroot {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.Rsvpexplorer_portaltagbtn {
    width: 50%;
    height: 40px;
    border: 1px solid var(--maindark);
    background-color: white;
    color: var(--maindark);
    border-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Rsvpexplorer_portaltagbtnlbl {
    width: 90%;
    touch-action: manipulation;
    pointer-events: none;
    place-content: flex-start;
    display: flex;
    padding-left: 5px;
}

.Rsvpexplorer_portaltaginvisible {
    width: 30px;
    height: 100%;
    place-content: center;
    align-items: center;
    display: flex !important;
    touch-action: manipulation;
    pointer-events: none;

}

.Rsvpexplorer_portaltagbtn:first-child {
    border-left: 1px solid var(--maindark);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
}

.Rsvpexplorer_portaltagbtn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
}

.Rsvpexplorer_portalpermitlabel {
    color: white;
    background-color:  var(--maindark);
    place-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 10px 0px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 30px;
}

.Rsvpexplorer_portaltagbtnselected {
    background-color: var(--mainpink);
    color: white;
}

.Rsvpexplorer_portalpermittop {
    display: flex;
    flex-direction: row;
}

.Rsvpexplorer_portalpermitbtm {
    width: 100%;
    place-content: center;
    display: flex;
    border: 1px solid var(--maindark);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;
    flex-direction: column;
    font-family: Lato;
}

.Rsvpexplorer_portalpermitexpand {
    height: 40px;
    width: max-content;
    min-height: 40px;
    min-width: 40px;
    padding: 10px;
    background-color: white;
    border-right: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px dashed #707070;
    place-self: center;
    width: calc(100% - 20px);
    margin: 10px;
}

.Rsvpexplorer_portalpermitexpand:hover {
    background-color: #eb5d7ba8;
    color: white;
}

.Rsvpexplorer_portalwhocanviewcontent {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 0px 10px;
}

.Rsvpexplorer_portaltagcol {
    display: flex;
    flex-direction: column;
}

.Rsvpexplorer_portaltagrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Rsvpexplorer_portaltag {
    /* border-radius: 15px;
    margin: 5px 5px 5px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3px 14px; */
    border: 1px solid var(--mainpink);
    background-color: #eb5d7b21;

    /* border: 1px solid #c0c0c0;
    background-color: white; */
    border-radius: 15px;
    margin: 5px 10px 5px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px 14px 5px;
    white-space: pre;

}

.Rsvpexplorer_portaltagslim{
    padding: 0px 10px;
    margin:0px 10px 0px 0px;

}
.Rsvpexplorer_portaltagselected {
    background-color: var(--mainpink);
    color: white;
}

.Rsvpexplorer_portaltagmainlabel {
    padding-bottom: 30px;
}

.Rsvpexplorer_portaladdnewguest {
    border-radius: 5px;
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    align-self: flex-start;
    cursor: pointer;
    background-color: white;
    margin: 0px;
    border:none;
}

.Rsvpexplorer_gl {
    height: 40px;
    width: calc(100% - 45px);
    border-radius: 5px;
    margin-right: 5px;
    background-color: #efeff0;

}

.Rsvpexplorer_radiorow_small {
    display: flex;
    flex-direction: row;
    place-content: flex-start;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Rsvpexplorer_portalmsg {
    display: none;
}

.Rsvpexplorer_portalmsghighlight {
    display: flex;
    color: red;
    width: 100%;
}

.Rsvpqnexplorer_swappedsavedroot {
    position: fixed;
    width: 100%;
    bottom: 0px;
    right: 0px;
    display: flex;
    place-content: center;
    background-color: #00000030;
    padding: 40px;
    font-size: 16px;

}

.Rsvpqnexplorer_swappedsavedbtn {
    padding: 0px 10px;
    height: 40px;
    place-self: center;
    background-color: var(--mainpink);
    color: white;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    width: 100px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid black;
    border-left: none;
}

.Rsvpqnexplorer_swappedcancel {
    padding: 0px 10px;
    height: 40px;
    place-self: center;
    background-color: #ffffff;
    color: var(--maindark);
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    border: 1px solid var(--maindark);
    width: 100px;
    border-radius: 5px 0px 0px 5px;
}

#Rsvpexplorer_draggablemain {
    margin: 0px 0px 30px 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
}

#Rsvpexplorer_draggablemainlast {
    margin: 0px 0px 30px 0px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
}

#Rsvpqnexplorer_singleqnitembtmroot {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
}

#Rsvpqnexplorer_singleqnitemcontentroot {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

#Rsvpqnexplorer_singleqnitemcontent {
    position: relative;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    border-width: 0px 1px 1px;
    border-top-style: initial;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: initial;
    border-right-color:  var(--maindark);
    border-bottom-color:  var(--maindark);
    border-left-color:  var(--maindark);
    border-image: initial;
    background-color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-top: -1px;
}

#Rsvpqnexplorer_qn {
    padding: 10px;
    width: 100%;
}

#Rsvpexplorer_dropdownbtn {
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: none;
    border: none;
    place-content: flex-end;
    margin: 0px;
    padding: 0px;
    width: 100%;
    font-size: 12px;
}

.Rsvpexplorer_btnhidden {
    opacity: 0;
}

.Rsvpexplorer_txtnumb {
    touch-action: manipulation;
}

.Rsvpexplorer_qnindexroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    place-self: flex-end;
    overflow-x: auto;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
}

#Rsvpexplorer_qnindex {
    height: 50px;
    border-top: 1px solid  var(--maindark);
    border-right: none;
    border-bottom: 1px solid  var(--maindark);
    border-left: 1px solid  var(--maindark);
    border-image: initial;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    align-items: center;
    place-content: center;
}

#Rsvpexplorer_qnfunctionsroot {
    height: 50px;
    border-top: 1px solid  var(--maindark);
    border-right: 1px solid  var(--maindark);
    border-bottom: none;
    border-left: none;
    border-image: initial;
    border-radius: 0px 5px 0px 0px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

#Rsvpexplorer_tagroot {
    width: 100%;
    display: flex;
    flex-direction: row;
}

#Rsvpexplorer_iconroot {
    display: flex;
    flex-direction: row;
}

.Rsvpexplorer_icon {
    cursor: pointer;
    /* min-width: 37px;
    height: 37px; */
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    border: none;
    place-self: center;
    border-radius: 20px;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

#Rsvpexplorer_dragicon {
    /* min-width: 37px;
    height: 37px;
    min-height: 37px;
    min-width: 37px; */
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    border: none;
    place-self: center;
    border-radius: 20px;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    cursor: pointer;
}

.Rsvpexplorer_followupplus {
    /* height: 50px;
    width: 50px; */
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    border: none;
    place-self: center;
    border-radius: 20px;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    cursor: pointer;
}

.Rsvpexplorer_followupplus:hover {
    background-color: #f5f5f5;
}

#Rsvpexplorer_dragicon:hover {
    background-color: #f5f5f5;
}

.Rsvpexplorer_icon:hover {
    background-color: #f5f5f5;
}

.Rsvpexplorer_followupqn {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    position: relative;
}

.Rsvpexplorer_templatetitle {
    font-size: 16px;
    font-weight: bold;
    padding: 0px 10px;
}

.Rsvpexplorer_templateqn {
    height: 45px;
    padding: 10px;
    border-radius: 5px;
    margin: 5px 0px;
    box-shadow: var(--portalinnerelemboxshadow);
    border: var(--portalinnerelemborder);
    background-color: #ffffff;
    display: flex;
    align-items: center;
}

.Rsvpexplorer_portalinnerelemtxt{
    display: flex;
    place-self: flex-start;
    width: 100%;
}
/* .Rsvpexplorer_temp{
    width: 100%;
    display: flex;
    overflow-y: auto;
    place-content: center;
    align-items: flex-end;
    height: 100%;
    margin-top: 60px;
    min-height: max-content;
} */

#Rsvpexplorer_dragroot {
    display: flex;
    flex-direction: column;
    height: max-content;
    margin: 45px 10px 60px 10px;
    place-content: flex-start;
    place-self: center;
}

@media (min-width:280px) {
    .Rsvpexplorer_portaltitle {
        font-size: 20px;
    }

    #Rsvpexplorer_dragroot {
        width: calc(100% - 10px);
    }

    .Rsvpexplorer_tag {
        font-size: 12px;
    }
}

@media (min-width:320px) {
    .Rsvpexplorer_portaltitle {
        font-size: 20px;
    }

    #Rsvpexplorer_dragroot {
        width: calc(100% - 10px);
    }

    .Rsvpexplorer_tag {
        font-size: 12px;
    }
}

@media (min-width:481px) {
    .Rsvpexplorer_portaltitle {
        font-size: 24px;
    }

    #Rsvpexplorer_dragroot {
        width: 450px;
    }

    .Rsvpexplorer_tag {
        font-size: 12px;
    }
}

@media (min-width:641px) {
    .Rsvpexplorer_portaltitle {
        font-size: 24px;
    }

    #Rsvpexplorer_dragroot {
        width: 600px;
    }

    .Rsvpexplorer_tag {
        letter-spacing: 0.5px;
    }
}

@media (min-width:961px) {
    .Rsvpexplorer_portaltitle {
        font-size: 24px;
    }

    #Rsvpexplorer_dragroot {
        width: 600px;
    }

    .Rsvpexplorer_tag {
        letter-spacing: 0.5px;
    }
}

@media (min-width:1025px) {
    .Rsvpexplorer_portaltitle {
        font-size: 24px;
    }

    #Rsvpexplorer_dragroot {
        width: 600px;
    }

    .Rsvpexplorer_tag {
        letter-spacing: 0.5px;
    }
}

@media (min-width:1281px) {
    .Rsvpexplorer_portaltitle {
        font-size: 24px;
    }

    #Rsvpexplorer_dragroot {
        width: 600px;
    }

    .Rsvpexplorer_tag {
        letter-spacing: 0.5px;
    }
}

#testblack {
    width: 100%;
    background-color:  var(--maindark);
    height: 10px;
}

.Rsvpqnexplorer_portalmodalleftbtn{
    height: 45px;
    width: 50%;
    border-bottom: none;
    border-left: none;
    border-image: initial;
    background-color: white;
    border: 1px solid #333333;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
}

.Rsvpqnexplorer_portalmodalrightbtn{
    height: 45px;
    width: 50%;
    border-top: 1px solid #eb5d7b;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-image: initial;
    background-color: #eb5d7b;
    color: white;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.Rsvpqnexplorer_portalmaincontenttitle{
    font-size: 16px;
    height: calc(100% - 70px);
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    place-content: center;
    text-align: center;
}
.Rsvpqnexplorer_portalcfmdeleteroot{
    font-size: 18px;
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.Rsvpqnexplorer_portalroot{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    place-content: space-between;
}
.Rsvpexplorer_title{
    display: flex;
    place-content: flex-start;
    font-size: 16px;
    text-decoration: underline;
}