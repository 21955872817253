.Itinerarytimeline_root{
    display:flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    align-items: center;
    position: relative;
}

.Itinerarytimeline_plusWEB{
    display: flex;
    background-color: white;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    border: thin solid rgb(112, 112, 112);
    padding: 5px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
}
.Itinerarytimeline_plusTABLET{
    display: flex;
    background-color: white;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    border: thin solid rgb(112, 112, 112);
    padding: 5px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
}
.Itinerarytimeline_plusMOBILE{
    display: flex;
    background-color: white;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    border: thin solid rgb(112, 112, 112);
    padding: 5px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
}
.Itinerarytimeline_imgroot{
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 40px 0px 0px 0px;
    cursor: pointer;
}
.Itinerarytimeline_imgcircle{
    position: relative;
    display: flex;
    width:70px;
    height: 70px;
    border:thin solid #707070;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}
.Itinerarytimeline_heart{
    color:#f77f7f;
    z-index:10;
}
.Itinerarytimeline_hearttransparent{
    color: transparent;
    z-index:10;
}
.Itinerarytimeline_banner{
    width: 100%;
    height: 100px;
    border-radius: 10px;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}