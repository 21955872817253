.APP_CARD_ROOT_w {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 145px;
    min-width: 100px;
    border: 1px solid transparent;
    border-radius: 5px;
    color: var(--maindark);
    background-color: white;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline: none;
    font-family: Lato;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    margin: 0px 4px;
    place-content: center;
    padding: 0px;
}

.APP_CARD_ROOT_SELECTED_w {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 145px;
    min-width: 100px;
    min-width: 100px;
    border-radius: 5px;
    color: var(--maindark);
    border: 1px solid var(--mainpink);
    background-color: #fff5f5;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline: none;
    font-weight: bold;
    font-family: Lato;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    margin: 0px 4px;
    place-content: center;
    padding: 0px;
}

.APP_CARD_ROOT_w:hover {
    background-color: #eb5d7c2f !important;
    color: #707070 !important;
    border: 1px solid transparent;
}

.APP_CARD_ROOT_mt {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 100px;
    min-width: 100px;
    border: 1px solid transparent;
    border-radius: 5px;
    color: var(--maindark);
    background-color: white;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline: none;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    padding: 0px;
    margin: 0px 5px;
}

.APP_CARD_ROOT_SELECTED_mt {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 100px;
    min-width: 100px;
    border-radius: 5px;
    color: var(--maindark);
    border: 1px solid var(--mainpink);
    background-color: #fff5f5;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    padding: 0px;
    outline: none;
    font-weight: bold;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    position: relative;
    margin: 0px 5px;
}

.APP_CARD_ICON {
    height: 60%;
    width: max-content;
    display: flex;
    place-content: center;
    align-items: center;
    place-self: center;
}

.APP_CARD_CONTENT {
    height: 40%;
    width: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0.4px;
    user-select: none;
    white-space: pre-line;
    margin-bottom:5px;
}

.APP_CARD_CONTENTLONG {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.4px;
    user-select: none;
}
