.Art_2btnroot{
    display: flex;
    padding: 10px 5px 0px 5px;
}
.Art_btnunselected{
    background-color: white;
    color: black;
    width:50%;
    height: 30px;
    border:none;
    border: 1px solid #cfcecf;
    font-weight: bold;
}
.Art_btnselected{
    background-color: #cfcecf;
    color: black;
    width:50%;
    height: 30px;
    border:none;
    border: 1px solid #cfcecf;
    font-weight: bold;
}
.Art_color >.Palette_root{
    padding:0px;
    margin:0px 5px;
}
.Art_color >.Palette_root >.Palette_subroot{
    width: 100% !important;
}
.Art_colortitle{
    margin: 10px 0px 0px 5px;
    font-weight: bold;
    place-content: flex-start;
    display: flex;
}
.Art_reload{
    display: flex;
    place-content: center;
    margin: 10px 0px 0px 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: 100%;
}
.Art_save{
    display: flex;
    place-content: center;
    margin: 10px 5px 0px 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: 100%;
}
.Art_shorter{
    width: calc( 100% - 10px ) !important;
    display: flex;
    flex-direction: column;
}

.Art_urhandler{
    display: flex;
    place-content: flex-start;
    font-weight: bold;
    height: max-content;
    color: white;
    background-color: black;
    flex-direction: row;
    border-radius: 5px;
    margin: 0px 5px;
    padding: 5px;
    cursor: pointer;
    min-height: 30px;
    overflow-x: hidden;
}
.Art_qrcodebtnrow{
    display: flex;
    flex-direction: row;
}
.Art_qrcodel{
    position: absolute;
    bottom: 0;
    left: 0;
}
.Art_qrcoder{
    position: absolute;
    bottom: 0;
    right: 0;
}
.Art_imginput{
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
}
.Art_img{
    display: flex;
    width: 50px;
    height: 50px;
    border: 1px solid black;
    margin: 0px 5px 0px 5px;
    border-radius: 5px;
    place-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.Art_row{
    display: flex;
    flex-direction: row;
}
.Art_col{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
.Art_imgclear{
    background-color: white;
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    place-self: center;
}
.Art_txtarea{
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 288px;
}
.Art_updatebtn{
    display: flex;
    place-content: center;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: calc( 100% - 8px );
    margin: 5px;
}
.Art_edittxtbtn{
    display: flex;
    place-content: center;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: calc( 100% - 10px );
    margin: 0px 5px;
    cursor: pointer;
}
.Art_icon{
    padding: 5px 5px 0px 5px;
    cursor: pointer;
}
.Art_modal{
    display: flex;
    position: absolute;
    width: 100%;
    height:  100%;
    overflow-y:auto;
    place-content: center;
    align-items: center;
}
.Art_modalbg{
    display: flex;
    position: absolute;
    width: 100%;
    height:  100%;
    overflow-y:auto;
    background-color: #70707059;
    place-content: center;
    align-items: center;
}
.Art_modalcontent{
    width: 300px;
    min-height: max-content;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #cfcecf;
    border-radius: 10px;
    align-items: center;
    z-index: 9;
}
.Art_select{
    display: flex;
    padding: 5px;
    margin: 5px;
    margin-bottom: 0px;
    border-radius: 5px;
    width: calc( 100% -  10px );
}
.Art_backbtn{
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 10px 5px 0px;
    font-family: Lato;
    cursor: pointer;
    z-index: 1;
    background-color: white;
    border-radius: 5px;
    border: 1px solid ;
    padding: 2px 5px;
}
.Art_backinner1{
    padding: 2px 0px 2px 10px;
    text-align: left;
    line-height: 12px;
}
.Art_backinner2{
    line-height: 12px;
    padding: 2px 0px 2px 10px;
}

.Artwork_rootWEB{
    flex-direction: row;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    place-content: center;
    display: flex;
    align-items: center;
    border-left:1px solid #eeee;
    position: relative;
    font-family: Lato;
    font-size: 14px;
    place-content: center;
}
.Artwork_rootWTINBTW{
    flex-direction: row;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    place-content: center;
    display: flex;
    align-items: center;
    border-left:1px solid #eeee;
    position: relative;
    font-family: Lato;
    font-size: 14px;
    place-content: center;
}
.Artwork_rootTABLET{
    flex-direction: row;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    place-content: center;
    display: flex;
    align-items: center;
    border-left:1px solid #eeee;
    position: relative;
    font-family: Lato;
    font-size: 12px;
    place-content: center;
}

.Artwork_rightWEB{
    min-width: 250px;
    height: 100%;
    border-left:1px solid #eeee;
    display: flex;
    flex-direction: column;
    position: relative;
}
.Artwork_rightWTINBTW{
    min-width: 250px;
    height: 100%;
    border-left:1px solid #eeee;
    display: flex;
    flex-direction: column;
    position: relative;
}
.Artwork_rightTABLET{
    min-width: 195px;
    height: 100%;
    border-left:1px solid #eeee;
    display: flex;
    flex-direction: column;
    position: relative;
}
.Artwork_righttop{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 50px;
}