.canvas{
    width:700px;
    height:auto;
    /* background:beige; */
    margin:auto;
    position: relative;
}

div.tooltip {	
    position: absolute;		
    display:flex;
    flex-direction: row;	
    text-align: center;			
    width: max-content;					
    height: max-content;					
    padding: 5px;				
    font: 12px sans-serif;		
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;	
}

@media only screen and (max-width:750px){
    .canvas{
        width:325px;
    }
}