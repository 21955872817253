.gblm_rootWEB{
    display: flex;
    flex-direction: column;
    min-width:270px;
    max-width:270px;
    height: calc( 100vh - 61px );
    position: relative;
} 
.gblm_rootTABLET{
    display: flex;
    flex-direction: column;
    min-width: 165px;
    max-width: 165px;
    height: calc( 100vh - 61px );
    position: relative;
} 
/* template */
.gblmtemplate_root{
    display: flex;
    flex-direction: column;
    padding:0px 10px;
}
.gblmtemplate_selected{
    color:#eb5d7b;
}

/* message */
.gblmmsg_root{
    display: flex;
    flex-direction: column;
    padding:0px 10px;
}
.gblmmsg_selected{
    color:#eb5d7b;
}
.gblmmsg_menuitem{
    font-size: 14px;
    display: flex;
    padding: 10px 5px;
    font-weight: bold;
    border:1px solid transparent;
    border-bottom: 1px solid var(--maindark);
    align-items: center;
    place-content: center;
    cursor: pointer;
    background-color:white;
    color: var(--maindark);
    border-top-left-radius:5px;
    border-top-right-radius:5px;
}
.gblmmsg_menuitemselected{
    font-size: 14px;
    display: flex;
    padding: 10px 5px;
    font-weight: bold;
    border:1px solid transparent;
    border-bottom: 1px solid var(--maindark);
    align-items: center;
    place-content: center;
    cursor: pointer;
    background-color: #eb5d7b;
    color: white;
    overflow: hidden;
}

/* default */
.gblmdefault_root{
    display: flex;
    flex-direction: column;
    padding:0px 10px;
}

/* setting */
.gblm_settingbtn{
    color: var(--maindark);
    font-size: 21px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    background-color: white;
    border: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 0px;
}
.gblm_settingbtnhighlight{
    color: var(--maindark);
    font-size: 21px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    background-color: white;
    color:#eb5d7b;
    border: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 0px;
}

/* shared */
.gblm_backbtn{
    width: 25px;
    height: 25px;
    vertical-align: middle;
    overflow: hidden;
    color:var(--maindark);
    place-self: center;
}
.gblm_titlebtn{
    color: var(--maindark);
    font-size: 21px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    background-color: white;
    border:none;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}
.gblm_frame{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc( 100vh - 135px );
    border: 1px solid var(--maindark);
    border-radius: 5px;
}

.gblm_topbarmobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 0px 10px;
    overflow-x: auto;
    padding-top: 70px;
    min-height: 60px;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index:1;
    padding-bottom: 10px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(200 200 200 / 24%);
}

.gblm_topbarselected{
    height: 40px;
    outline: none;
    background-color: #eb5d7b;
    padding: 0px 10px;
    border: 1px solid #9c0424;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    color: white;
    white-space: nowrap;
    min-height: max-content;
    display: flex;
    align-items: center;
    text-align: center;
    place-content: center;
    font-weight: bold;
    font-size: 15px;
}
.gblm_topbarunselected{
    height: 40px;
    outline: none;
    background-color: white;
    padding: 0px 10px;
    border: 1px solid rgb(112, 112, 112);
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    color: var(--maindark);
    white-space: nowrap;
    min-height: max-content;
    display: flex;
    align-items: center;
    text-align: center;
    place-content: center;
    font-size: 15px;
}