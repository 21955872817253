.ieditnavroot {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    height: min-content;
    position: relative;
}

.ieditnavrow {
    display: flex;
    flex-direction: row;
}

.ieditnavbtn {
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #333333;
    margin-right: 5px;
    background-color: white;
    width: 100px;
    height: 60px;
}

.ieditnavbtnselected {
    color: white;
    background-color: #eb5d7b;
    border: 1px solid #eb5d7b;
}

.ieditnavbarcontainer {
    height: 60px;
    width: 100%;
    border: 1px solid #333333;
    margin: 10px 0px;
    border-radius: 5px;
}

.ieditnavmenuitemsroot {
    display: flex;
    flex-direction: row;
    padding-left: 2px;
    padding-bottom: 10px;
    overflow-x: auto;
}

.ieditnavmenuitem {
    height: 40px;
    padding: 0px 10px;
    border: 1px solid #333333;
    margin-right: 5px;
}

.ieditnavmenuappselected {
    color: white !important;
    background-color: #eb5d7b !important;
    border: 1px solid #eb5d7b !important;
}

.ieditnavbaritem {
    color: #333333;
    background-color: white;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 77px;
    min-width: 77px;
    max-width: 77px;
    place-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    touch-action: manipulation;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    border: 1px solid transparent;
}

.ieditnavbaritemtext {
    height: 23px;
    font-size: 14px;
    display: flex;
    align-items: center;
    user-select: none;
    pointer-events: none;
}

.ieditnavbarurlitemroot {
    width: 100%;
    padding: 10px;
    overflow-y: auto;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #efeff0;
}

.ieditnavbarurlitem {
    width: 100%;
    min-height: 40px;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
    align-items: center;
    display: flex;
    background-color: white;
}

.ieditnavbarurlitemselected {
    border: 1px solid var(--firmgreen);
    background-color: var(--firmgreen);
    color: white;
}

.ieditnavnavbarexample {
    /* box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%); */
    width: 100%;
    height: 105px;
    padding: 5px;
    flex-direction: row;
    display: flex;
    place-content: flex-start;
    position: relative;
    place-content: center;
    overflow-x: auto;
    align-items: center;
}

.iedtinavnavbaraddbtn {
    border: 1px dashed #707070;
    border-top: none;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 5px;
    min-width: 73px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    place-content: center;
    place-self: center;
    position: relative;
}

.ieditnavdropdown {
    display: flex;
    flex-direction: row;
    margin: 15px;
    flex-wrap: wrap;
}

.ieditnavdropdownbtn {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    margin-bottom: 5px;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
}

.ieditnavdropdownbtn:hover {
    background-color: #eb5d7ba8;
    color: white;
}

.ieditnavdropdownbtn:nth-child(odd) {
    margin-right: 5px;
}

.ieditnavdropdownbtn:last-child {
    margin-bottom: 0px;
}

.ieditnavdropdownbtn:nth-last-child(2) {
    margin-bottom: 0px;
}

.ieditnavbaractualitem {
    color: #333333;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
    height: 73px;
    min-width: 73px;
    place-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    touch-action: manipulation;
    /* box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%); */
    border: 1px solid transparent;
    width: 73px;
    height: 73px;
}

.ieditnavbarportallogo {
    pointer-events: none;
    user-select: none;
}

.iedtinavbtnnavtype {
    width: auto;
    height: 45px;
    padding: 0px 10px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
    color: var(--maindark);
}

.iedtinavbtnnavtype_selected {
    color: #ffffff;
    background-color: var(--mainpink);

}

.ieditnavrow {
    display: flex;
    flex-direction: row;
}

.iediticonbtn_unselected {
    height: 30px;
    width: 100px;
    padding: 0px 10px;
    margin: 5px 0px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--maindark);
}

.iediticonbtn_selected {
    height: 30px;
    width: 100px;
    padding: 0px 10px;
    margin: 5px 0px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--maindark);
}