.gbrwd_plusrootm{
    width:100%;
    position: relative;
}
.gbrwd_plusm{
    display: flex;
    width: 50%;
    background-color: white;
    cursor: pointer;
    border: thin solid transparent;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    place-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #eeee;
}
.gbrwd_gallerygridm{
    margin-top:10px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    grid-template-rows: auto;
    overflow-y: auto;
    overflow-x: hidden;
}
.gbrwd_gallerycardm{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    border-radius: 10px;
}
@media (min-width:481px){ 
    .gbrwd_gallerygrid{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .gbrwd_gallerycard{ 
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px) / 3);
        width: calc( ( 100vw - 320px) / 3);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:641px){
    .gbrwd_gallerygrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .gbrwd_gallerycard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 165px ) / 4);
        width: calc( ( 100vw - 165px ) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:961px){
    .gbrwd_gallerygrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .gbrwd_gallerycard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 165px ) / 4);
        width: calc( ( 100vw - 165px ) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:1025px){
    .gbrwd_gallerygrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .gbrwd_gallerycard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px) / 4);
        width: calc( ( 100vw - 320px) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:1281px){ 
    .gbrwd_gallerygrid{
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .gbrwd_gallerycard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px) / 5);
        width: calc( ( 100vw - 320px) / 5);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:1400px){ 
    .gbrwd_gallerygrid{
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .gbrwd_gallerycard{ /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px ) / 5);
        width: calc( ( 100vw - 320px ) / 5);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
.gbrwd_deleteroot{
    display: flex;
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    width: 25px;
    height: 25px;
    padding: 13px;
    margin: 10px;
    border-radius:5px;
    place-content: center;
    align-items: center;
    border: thin solid black;
    background-color: white;
}
.gbrwd_galleryinput{
    visibility: hidden;
}
.gbrwd_gallery{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 3px 15px 3px;
}
.gbrwd_gallerycol{
    display: flex;
    flex-direction: column;
    overflow-y:auto;
}
.gbrwd_galleryindex{
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    margin-right: 13px;
}

.gbrwd_plusroot{
    display: flex;
    align-items: center;
    justify-content: center;
    place-self: center;
    align-self: center;
    justify-self: center;
    left: calc( 50% - 13px );
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: transparent;
    border: thin solid transparent;
    font-weight: bold;
}
