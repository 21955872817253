#Message_mobileroot {
    flex-direction: column;
    color: rgb(112, 112, 112);
    touch-action: manipulation;
    height: 100%;
    display: flex;
}

#Message_mobileinnerroot {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Message_mobileupperinfo {
    height: 60px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: rgb(100, 100, 100);
    flex-direction: row;
    text-align: center;
    border-bottom: 1px solid var(--lightdark);
}


#Message_mobilemaininfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: calc(100% - 60px - 85px - 35px);
    -webkit-overflow-scrolling: touch;
}

#Message_mobileconversationspart {
    width: 100%;
    background-color: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--lightdark);
    height: calc(100% - 0px);
}

#Message_mobiletextboxpart {
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    height: 100px;
    display: flex;
}

.Message_vendortab {
    display: flex;
    height: 70px;
    min-height: 70px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    background-color: white;
    outline: none;
    overflow: hidden;
    border: 1px solid var(--lightdark);
    color: var(--maindark);
}

.Message_vendortab:hover{
    background-color: var(--hoverpink);
}

.Message_txtboxroot {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100px;
    border-top: 1px solid var(--lightdark);
}

#Message_txtbox {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
    outline: none;
    border-radius: 0px;
    padding: 10px;
    margin: 0px;
    border: none;
    color: var(--maindark);
}

#Message_sendmsgbtn {
    height: 100%;
    width: 40px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: var(--maindark);
}