.typefilter_btndiv:hover {
    color: #eb5d7b !important;
    border: 1px solid #eb5d7b !important;
}

.aus_contentroot {
    display: flex;
    flex-flow: column wrap;
    padding: 8px;
    color: rgb(112, 112, 112);
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
    justify-content: center;
    width: 100%;
}

.aus_moreroot {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: rgb(218, 205, 179);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px 0px;
    max-width: 95%;
    min-width: 95%;
    place-content: center;
    align-self: center;
    text-align: center;
    margin: 20px 0px;
    border-radius: 5px;
    border: thin solid silver;
}

.aus_moreinner {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: rgb(255, 251, 243);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px 0px;
    max-width: 100%;
    min-width: 100%;
    place-content: center;
    align-self: center;
    text-align: center;
    border-radius: 5px;
    border: thin solid silver;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1' gradientTransform='rotate(9,0.5,0.5)'%3E%3Cstop offset='0' stop-color='%23ffffe6'/%3E%3Cstop offset='1' stop-color='%23fff0d1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='39' height='39' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='19.5' cy='19.5' r='19.5'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.22'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
}

.aus_contentrow {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
}

.aus_pastdatatitle {
    display: flex;
    align-self: start;
    background-color: white;
    font-weight: bold;
    color: white;
    background: black;
    padding: 5px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.aus_pastdataroot {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 30px 0px 10px; */
    margin: 0px 10px;
    padding: 10px;
    overflow-x: auto;
    width: calc(100% - 16px);
    border: 1px solid rgb(112, 112, 112);
    border-radius: 5px;
    place-self: center;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23f77f7f'/%3E%3Cstop offset='1' stop-color='%23f7c7be'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='24' height='24' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='12' cy='12' r='12'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.1'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    font-family: lato;
}

.aus_pastdatasubroot {
    display: flex;
    flex-direction: row;
}

.aus_pastdatafrm {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.aus_pastdatastatsboard {
    width: 120px;
    height: 50px;
    margin-top: 20px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
}

.aus_pastdataitem {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    border: thin solid rgb(112, 112, 112);
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    width: 120px;
}

.aus_selecttworoot {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.aus_selectiondomroot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding:0px 10px;
}

.aus_mth40 {
    /* display: flex;
    flex-direction: column;
    color: #707070;
    width:40%;
    margin: 5px; */
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    width: 50%;
    margin: 5px 0px;
    border: 1px solid var(--subgrey);
    border-radius: 10px 0px 0px 10px;
    padding: 5px;
}

.aus_select {
    background-color: transparent;
    margin: 0px;
    outline: none;
    border-radius: 5px;
    height: max-content;
    align-items: center;
    display: flex;
    place-content: center;
    place-self: center;
    border: none;
    width: 100%;
    text-align: center;
    font-family: 'Lato';
}

.aus_searchbtn {
    padding: 5px;
    background-color: white;
    outline: none;
    align-self: flex-end;
    width: 100%;
    height: 40px;
    margin: 0px;
    border-radius: 0px 0px 10px 10px;
    font-size: 16px;
    color: var(--maindark);
    border: 1px solid var(--subgrey);
    border-top: none;
    cursor: pointer;
}
.aus_searchbtn:hover{
    background-color: #eb5d7c2f !important;
}

@media (min-width:240px) {

    .aus_menutitle {
        font-size: 14px;
    }
    .aus_selectinput {
        font-size: 24px;
    }

    .aus_selectsearch {
        font-size: 14px;
    }

}

@media (min-width:641px) {

    .aus_menutitle {
        font-size: 14px;
    }

    .aus_selectinput {
        font-size: 24px;
    }

    .aus_selectsearch {
        font-size: 14px;
    }

}

@media (min-width:764px) {

    .aus_menutitle {
        font-size: 16px;
    }

    .aus_selectinput {
        font-size: 48px;
    }
    .aus_selectsearch {
        font-size: 16px;
    }

}