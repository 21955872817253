.projcard_root {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--subgrey);
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0ms;
    font-family: Lato;

}

.projcard_root:hover {
    box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px
}

.projcard_1del {
    position: absolute;
    display: flex;
    color: red;
    cursor: pointer;
    padding: 5px;
    right: 0;
}

.projcard_name {
    color: var(--maindark);
    font-weight: bold;
    padding: 5px 10px 0px 10px;
    overflow: auto;
}

.projcard_collabroot {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    outline: none;
    background-color: white;
    width: 100%;
    border: none;
    padding: 0px;
}

.projcard_collabfixedtext {
    color: var(--maindark);
    font-size: 12px;
}

.projcard_collab {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 10px;
}

.projcard_collabimg {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    margin: 5px;
    border: 1px solid var(--subgrey);
}

.projcard_collabrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.projcard_collabdn {
    display: flex;
    color: var(--maindark);
    font-size: 14px;
}

.projcard_collabvt {
    display: flex;
    color: var(--maindark);
    font-size: 12px;
}


.projcard_arrowdown {
    margin: 10px 10px 5px 10px;
    align-self: center;
    border: 4px solid var(--maindark);
    background-color: transparent;
    height: 0px;
    width: 0px;
    border-color: transparent;
    border-top-color: var(--maindark);
}

/* 0 */
.projcard_00 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* border-top-left-radius:10px;
    border-top-right-radius:10px; */
    /* border:thin solid white; */
    cursor: pointer;
}

.projcard_01 {
    display: flex;
    flex-direction: row;
}

.projcard_02 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* border:thin solid white; */
    cursor: pointer;
}

.projcard_03 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* border:thin solid white; */
    cursor: pointer;
}


/* 1 */
.projcard_10 {
    cursor: pointer;
}

.projcard_11 {
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.projcard_12 {
    /* border-top-left-radius: 10px; */
    /* border: thin solid white; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.projcard_13 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.projcard_14 {
    /* border-top-right-radius: 10px; */
    /* border: thin solid white; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.projcard_15 {
    /* border:thin solid white; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* 2 */
.projcard_20 {
    cursor: pointer;
}

.projcard_21 {
    background-position: center;
    background-size: cover;
    /* border-top-left-radius:10px;
    border-top-right-radius:10px; */
}