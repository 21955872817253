.Visualisevenue_root {
    display: flex;
    place-items: center;
    display: flex;
    flex-direction: column;
    font-family: Lato;
    color: var(--maindark);
    margin-bottom: 50px;
    place-content: center;
    position: relative;
}

.Visualisevenue_rootWEB {
    flex-direction: row;
    margin: 0px;
    overflow: hidden auto;
    height: 100%;
    overflow-y: hidden;
}

.Visualisevenue_rootTABLET {
    flex-direction: row;
    margin: 0px;
    overflow: hidden auto;
    height: 100%;
}

.Visualisevenue_rootMOBILE {
    flex-direction: row;
    margin: 0px;
    overflow: auto;
    height: 100%;
    width: 100%;
    position: fixed;

}

.Visualisevenue_title {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-items: center;
    padding: 10px 0px 0px 0px;
}

.Visualisevenue_lefttitle {
    width: 70%;
    font-size: 18px;

}

.Visualisevenue_righttitle {
    width: 30%;
    font-size: 32px;
    display: flex;
    place-self: center;
    place-content: center;
}

.Visualisevenue_subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    padding: 0px 10px;
}

.Visualisevenue_returnbtnroot {
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px 10px 0px 10px;
    display: flex;
    flex-direction: column;
}

.Visualisevenue_returnbtn {
    border: 1px solid var(--maindark);
    display: flex;
    padding: 7px;
    flex-wrap: wrap;
    border-radius: 5px;
    background-color: var(--maindark);
    color: #fff;
    width: max-content;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Visualisevenue_returnbtndiv {
    pointer-events: none;
}

.Visualisevenue_menuroot {
    width: 100%;
    height: 50px;
    place-items: center;
    display: flex;
    position: relative;
    flex-direction: column;
    place-content: center;
    place-self: center;
}

.Visualisevenue_menuroot_wt {
    height: max-content;
}

.Visualrevenue_menubtnroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: flex-start;
}

.Visualisevenue_menubtn {
    height: max-content;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    border-radius: 10px;
    margin: 3px;
    flex-direction: row;
    display: flex;
    text-transform: capitalize;
}

.Visualisevenue_menucirclered {
    border: 1px solid white;
    background-color: var(--mainpink);
    color: var(--mainpink);
    width: 5px;
    height: 5px;
    border-radius: 50px;
    place-self: center;
    padding: 5px;
    margin-left: 5px;
}

.Visualisevenue_menucircleblue {
    border: 1px solid white;
    background-color: skyblue;
    color: skyblue;
    width: 5px;
    height: 5px;
    border-radius: 50px;
    place-self: center;
    padding: 5px;
    margin-left: 5px;
}

.Visualisevenue_menubtnselected {
    color: white !important;
    background-color: var(--maindark) !important;

}

.Comparevenue_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    padding-right: 10px;
}

.Comparevenue_menubtnleft {
    display: flex;
    border-radius: 10px 0px 0px 10px;
    padding: 5px;
    color: var(--maindark);
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    border-right: none;
    width: 50%;
    place-content: center;
}

.Comparevenue_menubtnright {
    display: flex;
    border-radius: 0px 10px 10px 0px;
    padding: 5px;
    color: var(--maindark);
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    width: 50%;
    place-content: center;

}

.Comparevenue_columnitem {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 10px 0px;
}

.Comparevenue_selecteddatacard {
    width: 100%;
    height: max-content;
    min-height: 80px;
    border: 1px solid var(--maindark);
    border-radius: 10px;
    position: relative;
    flex-direction: row;
    display: flex;
    overflow: hidden;
    margin-top: 10px;
}

.Comparevenue_selecteddatacardrow {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    flex-wrap: wrap;
}

.Comparevenue_selecteddatacardtitle {
    font-size: 12px;
    font-weight: bold;
    margin-top: 10px;
}

.Comparevenue_selecteddatacardrowitem {
    border: 1px solid var(--subdark);
    padding: 1px 3px;
    margin-right: 3px;
    margin-top: 3px;
    border-radius: 5px;

}

.Comparevenue_crossbtn {
    background-color: #ffffff;
    min-width: 25px;
    height: 25px;
    border: none;
    place-content: center;
    text-align: center;
    place-items: center;
    display: flex;
    margin: 0px;
    padding: 0px;
    /* left: 0;
    top: 0;
    display: flex;
    position: absolute; */
}

.Comparevenue_heartbtn {
    background-color: #ffffff;
    border: none;
    width: 35px;
    display: flex;
    place-content: center;
    place-items: center;
    height: 100%;
}

.Comparevenue_selectedcarditemcontent {
    width: 100%;
    padding: 5px;
}

.Comparevenue_viewvendor {
    font-size: 14px;
    text-align: end;
    color: var(--subdark);
    border: none;
    background-color: transparent;
    margin-top: 5px;
}

.Comparevenue_viewvendor:hover {
    color: var(--maindark);
}

.Comparevenue_iconcol {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--maindark);
}

.Comparevenue_mobilebtm {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 10px;
}

.Comparevenue_mobilebtmbtnleft {
    width: 50%;
    border: 1px solid var(--maindark);
    border-radius: 10px 0px 0px 10px;
    background-color: #ffffff;
    border-right: none;
}

.Comparevenue_mobilebtmbtnright {
    width: 50%;
    border: 1px solid var(--maindark);
    border-radius: 0px 10px 10px 0px;
    background-color: #ffffff;
}

@media (min-width:240px) {
    .Visualisevenue_subtitle_wt {
        width: 100%;
    }

    .Visualisevenue_menuroot_wt {
        width: 220px;
    }
}

@media (min-width:340px) {
    .Visualisevenue_subtitle_wt {
        width: 100%;
    }

    .Visualisevenue_menuroot_wt {
        width: 300px;
    }
}


@media (min-width:400px) {
    .Visualisevenue_subtitle_wt {
        width: 390px;
    }

    .Visualisevenue_menuroot_wt {
        width: 360px;
    }
}

@media (min-width:641px) {
    .Visualisevenue_subtitle_wt {
        width: 620px;
    }

    .Visualisevenue_menuroot_wt {
        width: 500px;
    }
}

@media (min-width:764px) {
    .Visualisevenue_subtitle_wt {
        width: 640px;
    }

    .Visualisevenue_menuroot_wt {
        width: 500px;
    }
}

@media (min-width:961px) {
    .Visualisevenue_subtitle_wt {
        width: 750px;
    }

    .Visualisevenue_menuroot_wt {
        width: 500px;
    }
}

@media (min-width:999px) {
    .Visualisevenue_subtitle_wt {
        width: 750px;
    }

    .Visualisevenue_menuroot_wt {
        width: 500px;
    }

}

#Comparevenue_mobileportal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-top: 60px;
    padding-bottom: 60px;
}

.Comparevenue_questionmarknonmobile {
    width: 30px;
    min-width: 30px;
    height: 30px;
    border-radius: 1000px;
    border: 1px solid var(--maindark);
    background-color: white;
    margin-left: 5px;
    color: var(--maindark);
    place-self: center;
    font-size: 16px;
    display: flex;
    place-content: center;
    text-align: center;
}

.Comparevenue_datecol {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
}

.Comparevenue_datetitle {
    font-size: 12px;
    color: var(--maindark);
}

.Comparevenue_wrapitem {}


.Comparevenue_capacitybtn {
    font-size: 12px;
    margin: 0px 5px 5px 0px;
    padding: 5px 10px;
    background-color: white;
    border: 1px solid var(--maindark);
    border-radius: 100px;
    color: var(--maindark);
}

.Comparevenue_capacitybtnselected {
    background-color: var(--maindark);
    color: #ffffff;
}

.Comparevenue_nodatafound {
    display: flex;
    place-items: center;
    place-content: center;
}

.Comparevenue_iconrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: center;
    height: 52px;
    background-color: #ffffff;
    place-items: center;
    z-index: 1;
    place-content: space-between;
}

.Comparevenue_topicon {
    width: 65px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
    height: 32px;
}

.Comparevenue_topiconselected {
    color: #ffffff;
    background-color: var(--maindark);
    height: 32px;
}

#Comparevenue_weddingdatebtn {
    margin-top: 5px;
    display: flex;
    width: calc(100% - 5px);
    align-items: center;
    border-radius: 5px;
    background-color: var(--subpink);
    border: transparent;
    outline: none;
    margin-bottom: 5px;
    min-height: 30px;
    color: var(--maindark);
    border: 1px dashed var(--mainpink);
    text-align: center;
    place-content: center;
}

.Comparevenue_tagrow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.Comparevenue_tagitem {
    border: 1px solid var(--maindark);
    color: white;
    background-color: var(--maindark);
    padding: 2px 6px;
    border-radius: 25px;
    font-size: 12px;
    margin-top: 2px;
    margin-right: 2px;
    min-width: max-content;
    min-height: max-content;
    max-height: 27px;
    align-items: center;
    text-align: center;
    place-content: center;
}

.Comparevenue_hideexpand {
    height: 32px;
    border-radius: 10px;
    border: 1px solid var(--maindark);
    background-color: #fff;
}

.Comparevenue_coldataWEB {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.Comparevenue_coldataTABLET {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.Comparevenue_coldataMOBILE {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Comparevenue_colcard {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    margin-bottom: 10px;
    padding: 10px;
    place-self: center;
    width: 100%;
}

.Comparevenue_colcardWEB {
    width: 400px;
}

.Comparevenue_colcardTABLET {
    width: 400px;
}

.Comparevenue_colcardMOBILE {
    width: calc(100% - 20px);
}

.Comparevenue_colcardbtm {}

.Comparevenue_btmcardrow {
    display: flex;
    flex-direction: row;
}

.Comparevenue_selecteditem {
    margin-top: 10px;
    padding: 5px;
    color: var(--maindark);
    background-color: white;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    font-size: 14px;
}

.Comparevenue_selectednon {
    margin-top: 10px;
    padding: 5px;
    color: rgb(255, 255, 255);
    background-color: var(--maindark);
    border: 1px solid var(--maindark);
    border-radius: 5px;
    font-size: 14px;
}

.Comparevenue_colcardrow {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    place-content: space-between;
}

.Comparevenue_setting {
    width: 32px;
    height: 32px;
    display: flex;
    place-content: center;
    place-items: center;
    background-color: #fff;
    border: none;
    color: var(--maindark);
    border-radius: 50px;
    margin: 0px 10px;
}

.Comparevenue_sort {
    width: 32px;
    height: 32px;
    display: flex;
    place-content: center;
    place-items: center;
    background-color: #fff;
    border: none;
    color: var(--maindark);
    border-radius: 5px;
    /* margin: 0px 10px; */
}

.Comparevenue_sorted {
    background-color: var(--mainpink);
    color: #fff;

}

.Comparevenue_viewvendorenquire {
    font-size: 14px;
    border: none;
    background-color: var(--maindark);
    margin-top: 5px;
    padding: 0px 10px;
    border-radius: 5px;
    color: white;
    height: 32px;
    text-align: center;
    align-content: center;
    cursor: pointer;
    display: flex;
    place-items: center;
    width: 100%;
    place-content: center;
}

.Comparevenue_border {
    height: 24px;
    width: 1px;
    border-right: 1px solid var(--subdark);
    margin-top: 10px;
}

.Comparevenue_tabletsidebtn {
    max-width: 30px;
    max-height: 30px;
    margin: 0px 5px;
    background-color: #ffffff;
    border-radius: 100px;
    border: 1px solid var(--maindark);
    transform: rotate(-90deg);
    display: flex;
    place-content: center;
}

.Comparevenue_tabletclosesidebtn {
    max-width: 30px;
    max-height: 30px;
    margin: 0px 5px;
    background-color: #ffffff;
    border-radius: 100px;
    border: 1px solid var(--maindark);
    transform: rotate(90deg);
    display: flex;
    place-content: center;
}

.Comparevenue_allorselectedroot {
    height: 32px;
    margin: 0px;
    border-radius: 10px;
    border: 1px solid var(--maindark);
    background-color: #fff;
    width: 85px;
    place-self: center;
    place-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0px;
}

.Comparevenue_lineroot {
    height: 32px;
    width: 10px;
    display: flex;
    place-content: center;
    align-content: center;
}

.Comparevenue_linebridge {
    width: 10px;
    border-top: 3px solid var(--maindark);
    height: 1px;
    display: flex;
    place-self: center;
}

.Comparevenue_btnsrow {
    display: flex;
    flex-direction: row;
    padding: 3px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    border: 1px solid #fff;
}

.Comparevenue_avgpricecaptext {
    border: 1px solid var(--maindark);
    padding: 5px;
    place-content: center;
    text-align: center;
    border-radius: 10px;
    background-color: var(--maindark);
    color: #ffffff;
}

.Comparevenue_avgpriceportalenquire {
    display: flex;
    flex-direction: column;
}

.Comparevenue_inputfeatureroot {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.Comparevenue_enterfeature {
    margin-top: 5px;
    border: 1px solid var(--maindark);
    height: 40px;
    text-align: center;
    border-radius: 5px;
}

.Comparevenue_enterfeatureinput {
    padding: 5px;
    height: 40px;
    text-align: start;
    border: 1px solid var(--maindark);
    border-radius: 5px;
}

.Comparevenue_dndiamondrow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Comparevenue_diamondroot {
    width: 15px;
    height: 14px;
    background-size: 15px;
    background-repeat: no-repeat;
    margin: 0px 2px;
}

.Comparevenue_columnbox{
    display: flex;
    flex-direction: column;
}

.Comparevenue_plusminusroot {
    display: flex;
    flex-direction: row;
    pointer-events: auto;
    width: 100%;
    place-content: space-between;
    padding: 5px;
}

.Comparevenue_plusminussign {
    background-color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid silver;
    text-align: center;
}