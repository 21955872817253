.Pricelistcardbuildin_root {
    height: max-content;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid var(--subgrey);
    margin-bottom: 10px;
    border-radius: 5px;
    font-family: Lato;
}

.Pricelistcardbuildin_root:hover {
    border: 1px solid var(--maindark);
}

.Pricelistcardbuildin_expandedroot {
    cursor: pointer;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid var(--subgrey);
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    font-family: Lato;
}

.Pricelistcardbuildin_expandedroot:hover {
    border: 1px solid var(--maindark);
}

.Pricelistcardbuildin_plroot {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Pricelistcardbuildin_btm {
    display: flex;
    width: 100%;
}

.Pricelistcardbuildin_indicatortagroot {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.Pricelistcardbuildin_hiddenattrroot {
    display: flex;
    flex-direction: column;
    width: 55%;
}

.Pricelistcardbuildin_hiddenrow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Pricelistcardbuildin_hiddenattrs {
    display: flex;
    flex-direction: row;
    color: var(--maindark);
}

.Pricelistcardbuildin_hiddenattrstxt {
    padding: 0px 10px;
}

.Pricelistcardbuildin_hiddenpriceroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--lightdark);
    margin-top: 30px;
    height: 60px;
}

.Pricelistcardbuildin_hiddenpricestr {
    display: flex;
    flex-direction: row;
    color: var(--maindark);
    font-size: 20px;
    align-items: center;
    place-content: center;
    width: 100%;
}

.Pricelistcardbuildin_hiddentagroot {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    place-content: flex-start;
    font-size: 12px;
    padding: 0px 10px 10px;
}

.Pricelistcardbuildin_hiddenindicator {
    color: #707070;
    padding: 5px 10px;
    display: flex;
    place-content: center;
}

.Pricelistcardbuildin_hiddenindicator>div {
    display: flex;
    place-content: flex-start;
    width: 100%;
}

.Pricelistcardbuildin_expandcard {
    padding: 10px;
}

.Pricelistcardbuildin_expandattrroot{
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Lato;
    padding: 15px 0px;
    border-top: 1px solid var(--lightdark);
}

.Pricelistcardbuildin_expandattrrow{
    display: flex;
    flex-direction: row;
}
.Pricelistcardbuildin_expandattrcol{
    display: flex;
    flex-direction: column;
}

.Pricelistcardbuildin_expandattricon{
    display: flex;
    flex-direction: row;
    color: var(--maindark);
    place-content: flex-start;
    align-items: center;
    padding: 0px 10px;
}
.Pricelistcardbuildin_expandattriconlbltxt{
    padding-left: 10px;
    width: 140px;
    font-weight: bold;
}

.Pricelistcardbuildin_expandattricontxt{
    display: flex;
    place-content: center;
    padding: 0px 10px;
}

.Pricelistcardbuildin_expandattrextra{
    display: flex;
    place-content: flex-start;
    padding: 0px 10px 0px 40px;
}

.Pricelistcardbuildin_actionbtnroot{
    width: 100%;
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: center;
    padding: 30px;
}

.Pricelistcardbuildin_btnaction{
    width: 90px;
    border: 1px solid var(--maindark);
    padding: 5px 0px;
    background-color: #ffffff;
    height: 50px;
}

.Pricelistcardbuildin_btnhide{
    border-radius: 5px 0px 0px 5px;
}

.Pricelistcardbuildin_btnlookup{
    border-left: none;
    border-right: none;
}

.Pricelistcardbuildin_btnenquire{
    border-radius: 0px 5px 5px 0px;
    letter-spacing: pre;

}

@media (min-width:0px) {
    .Pricelistcardbuildin_btm {
        flex-direction: row;
    }

    .Pricelistcardbuildin_hiddenrow {
        margin-bottom: 15px;
    }

    .Pricelistcardbuildin_hiddentagroot {
        font-size: 12px;
    }

    .Pricelistcardbuildin_expandcard {
        font-size: 16px;
    }

    .Pricelistcardbuildin_hiddenindicator {
        font-size: 14px;
    }

    .Pricelistcardbuildin_btm {
        flex-direction: column;
    }

    .Pricelistcardbuildin_btnaction{
        width: 90px;
    }
    
}

@media (min-width:240px) {}

@media (min-width:320px) {}

@media (min-width:481px) {}

@media (min-width:641px) {}

@media (min-width:767px) {
    .Pricelistcardbuildin_hiddenrow {
        margin-bottom: 10px;
    }

    .Pricelistcardbuildin_hiddentagroot {
        font-size: 12px;
    }

    .Pricelistcardbuildin_expandcard {
        font-size: 18px;
    }

    .Pricelistcardbuildin_hiddenindicator {
        font-size: 14px;
    }

    .Pricelistcardbuildin_btm {
        flex-direction: row;
    }

    .Pricelistcardbuildin_btnaction{
        width: 100px;
    }
    
}

@media (min-width:961px) {}

@media (min-width:1025px) {}

@media (min-width:1281px) {}