.Texture_root {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex-flow: column;
    width: 100%;
    flex-direction: column;
}

.Texture_sqroot {
    display: flex;
    border-radius: 50px;
    cursor: pointer;
    width:calc( 100% - 36.66px);
}

.Texture_addbtn {
    border: 1px dashed #707070;
    border-radius: 5px;
    padding: 5px;
    width: 36.66px;
    height: 36.66px;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    align-self: flex-start;
}

.Texture_div {
    border-radius: 5px;
    min-height: 36.66px;
    min-width: calc( 100% - 5px );
    border: 1px solid #8b8b8b;
    background-size: cover;
}
.Texture_selection{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Texture_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
@media (min-width:320px) {
    .Texture_div {
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        background-size: cover;
    }
}

@media (min-width:481px) {
    .Texture_div {
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        background-size: cover;
    }
}

@media (min-width:641px) {
    .Texture_div {
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        background-size: cover;
    }
}

@media (min-width:961px) {
    .Texture_div {
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        background-size: cover;
    }
}

@media (min-width:1025px) {
    .Texture_div {
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        background-size: cover;
    }
}

@media (min-width:1281px) {
    .Texture_div {
        border-radius: 5px;
        border: 1px solid #8b8b8b;
        background-size: cover;
    }
}