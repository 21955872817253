.play2_root{
    width: 100%;
    height: 100vh;
    background-color: #282535;
    position: fixed;
    overflow: hidden;
}
.play2_imgbg{
    position: absolute;
    width: 80%;
    height: 80vh;
    display: flex;
    /* background-color: floralwhite; */
    background-color: #fff9ed;
    /* background-color:red; */
    left: 10%;
    top: 10vh;
    place-content: center;
    justify-content: space-between;
    
}
.play2_card{
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 450px;
    place-self: flex-end;
    position: absolute;
    left: calc( 50% - 450px / 2 );
    top: 5%;
}
.play2_cardtopimg{
    height: 65%;
    width:100%;
    background-color: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
/* .play2_cardtopimg{
    height: 65%;
    width:100%;
    background-color: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: contain;
} */
.play2_cardbtmtxt{
    height: 35%;
    width: 100%;
    background-color: white;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.play2_cardtxtonly{
    height: 100%;
    width: 100%;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.play2_btmname{
    font-size: 24px;
    margin: 10px 20px;
    height: 35px;
}
.play2_btmtxt{
    margin: 0px 20px;
}
.play2_next{
    align-items: center;
    display: flex;
    user-select: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    left: calc( 100% - 40px );
    top: calc( 50% - 40px );
}
.play2_back{
    align-items: center;
    display: flex;
    transform: rotate(180deg);
    user-select: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc( 50% - 40px );
}
.play2_col{
    display: flex;
    flex-direction: column;
    height: calc( 100% - 67px );
    min-height: calc( 100% - 67px );
    max-height: calc( 100% - 67px );
    overflow-y: auto;
}
.play2_row{
    display: flex;
    flex-direction: row;
    bottom: 0;
    align-items: center;
    width: 100%;
    place-content: space-between;
    height: 67px;
    max-height: 67px;
    min-height: 67px;
    position: relative;
    padding-top:10px;
}
.play2_audio{
    /* margin: 10px 20px; */
    place-content: flex-end;
    cursor: pointer;
    display: flex;
    right: 0;
    background-color: transparent;
    border:0px solid transparent;
    padding: 5px 10px;
    margin: 0px;
    height: 100%;
    
}
.play2_emojis{
    font-size: 27px;
    padding: 0px 20px;
    /* margin: 13px 0px; */
    overflow-x:auto;
    white-space: nowrap;
    overflow-y:hidden;
    height: 100%;
}