.Togglebtn_root {
    display: flex;
    flex-direction: row;
    background-color: #efeff0;
    border-radius: 5px;
    align-items: center;
    height: 40px;
    padding: 1px;
    width: max-content;
}
.Togglebtn_btn {
    height: 100%;
    width: 75px;
    outline: none;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin: 0px;
    font-size: 15px;
    line-height: 1em;
    place-content: center;
    transition: background-color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background-color: #efeff0;
    color: #aaaaaa;
    border: 1px solid transparent;
    border-radius: 5px;
    user-select: none;
    padding: 0px 10px;
}
.Togglebtn_selected{
    color: #333333;
    border-radius: 5px;
    z-index: 0;
    box-shadow: #19191940 0px 2px 3px;
    background-color: white;
}


/* 
.scedit_selected {
    border-radius: 5px;
    font-weight: bold;
    outline: none;
    transition: background-color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin: 0px;
    z-index: 0;
    font-size: 15px;
    width: 75px;
    line-height: 1em;
    place-content: center;
    box-shadow: #19191940 0px 2px 3px;
    background-color: white;
    color: black;
    height: 100%;
    border: 1px solid #e9e9e9;
}

.scedit_select {
    font-weight: bold;
    outline: none;
    transition: background-color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    align-items: center;
    flex-direction: row;
    display: flex;
    height: 100%;
    padding: 0px 5px;
    margin: 0px;
    font-size: 15px;
    line-height: 1em;
    width: 75px;
    place-content: center;
    border: 1px solid transparent;
    background-color: #efeff0;
    color: #aaaaaa;
}  */
