.gl_modalroot {
    display: flex;
    flex-direction: column;
    padding: 10px;
    color: #333333;
}

.gl_modalcommontitle {
    display: flex;
    font: weight 500;
}

.gl_modalcol {
    display: flex;
    flex-direction: column;
}

.gl_modalrsvpmid {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    width: 100%;
    text-align: start;
    margin: 30px 0px;
    place-content: center;
    align-items: center;
}

.gl_modalinput {
    margin-bottom: 10px;
    height: 40px;
}

.gl_updatebtn {
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    width: max-content;
    place-self: flex-end;
    margin: 10px 0px 0px 0px;
}

.gl_walletbg {
    display: flex;
    flex-direction: column;
    /* border: 1px solid var(--maindark); */
    margin: 10px;
    border-radius: 5px;
    background-color: var(--hoverpink);
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.gl_walletbtn {
    height: 40px;
    margin: 15px 10px 15px;
    background-color: white;
    border: 1px solid var(--maindark);
    border-radius: 5px;
}

.gl_portalbtnrow {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 20px);
    margin: 10px;
    height: 45px;

}

.gl_portalupdatebtn {
    width: 100%;
    height: 45px;
    background-color: var(--mainpink);
    color: white;
    font-size: 18px;
    border: 0px solid transparent;
    border-top: 1px solid var(--mainpink);
    border-radius: 10px;
    margin: 0px 0px 0px 5px;
}
.gl_portalupdatebtndisabled{
    width: 100%;
    height: 45px;
    color: var(--maindark);
    font-size: 18px;
    border: 1px solid var(--maindark);
    border-radius: 10px;
    margin: 0px 0px 0px 5px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-content: center;
}

.gl_portalbtnarrow {
    border-radius: 10px;
    background-color:#ffffff;
    border: 1px solid #333333;
    cursor: pointer;
    
}

.gl_portalbtnarrowleft {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0.5px solid #333333;
}

.gl_portalbtnarrowright {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0.5px solid #333333;
}

.swapcontent_draggableparent>div{
    top: auto !important;
    left: auto !important;
}