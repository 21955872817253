.lrb_root{
    display: flex;
    flex-direction: row;
}
.lrb_left{
    height:40px;
    width: 40px;
    background-color: white;
    border:1px solid #707070;
    border-right:none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding:5px;
    place-content: center;
    align-items:center;
    display: flex;
}
.lrb_right{
    height:40px;
    width: 40px;
    background-color: white;
    border:1px solid #707070;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding:5px;
    place-content: center;
    align-items:center;
    display: flex;
}