.gbrwd_default{
    padding: 10px 0px 20px 0px;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    overflow-y:auto;
}
.gbrwd_defaultcardiconWEB{
    width: 100%;
    height: 25%;
    place-content: center;
    display: flex;
    align-items: center;
    color: #707070;
}
.gbrwd_defaultcardiconTABLET{
    width: 100%;
    height: 20%;
    place-content: center;
    display: flex;
    align-items: center;
    color: #707070;
}
.gbrwd_defaultcardtitle{
    height: 5%;
    font-weight: bold;
    font-size: 21px;
    text-align: center;
    align-items: center;
    place-content: center;
    display: flex;
    color: #707070;
}
.gbrwd_defaultcardexplain{
    height: 35%;
    display: flex;
    padding: 10px;
    text-align: center;
    align-items: flex-start;
    place-content: center;
}
.gbrwd_defaultrow{
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    flex: 0 0 25%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0px 0px 10px;
}
@media (min-width:1025px){
    .gbrwd_defaultcardexplain{
        height: 30%;
        display: flex;
        padding: 10px;
        text-align: center;
        align-items: center;
        place-content: center;
    }
}
@media (min-width:481px){ 
  
}
@media (min-width:641px){
       

}
@media (min-width:961px){
  
 
}
@media (min-width:1025px){
   
    .gbrwd_defaultcardexplain{
        height: 30%;
        display: flex;
        padding: 10px;
        text-align: center;
        align-items: center;
        place-content: center;
    }
}
@media (min-width:1281px){ 
    
    .gbrwd_defaultcardexplain{
        height: 30%;
        display: flex;
        padding: 10px;
        text-align: center;
        align-items: center;
        place-content: center;
    }
}
@media (min-width:1400px){ 
   
    .gbrwd_defaultcardexplain{
        height: 30%;
        display: flex;
        padding: 10px;
        text-align: center;
        align-items: center;
        place-content: center;
    }
}
.gbrwd_defaultcardlink{
    max-height: 35%;
    min-height: 35%;
}
.gbrwd_defaultcol{
    display: flex;
    flex-direction: column;
}
.gbrwd_defaulticonandtxt{
    display: flex;
    flex-direction: row;
    border: thin solid #707070;
    border-radius: 5px;
    margin:5px 10px;
    padding: 5px;
    cursor:pointer;
    min-height: 30px;
    overflow-x:auto;
    background-color: white;
}
.gbrwd_defaultpure_txt{
    overflow-x:auto;
    display: flex;
    place-content: flex-start;
    font-weight: bold;
    flex-direction: column;
    height: max-content;
    color: white;
    background-color: var(--maindark);
    flex-direction: row;
    border-radius: 5px;
    margin: 5px 10px 10px 10px;
    padding: 5px;
    cursor: pointer;
    min-height: 30px;
    overflow-x: hidden;
    border:1px solid transparent;
}
.gbrwd_default20_icon{
    width: 20%;
    align-items: center;
    display: flex;
    place-content: center;
    color: var(--maindark);
}
.gbrwd_default80_txt{
    width: 80%;
    display: flex;
    place-content: flex-start;
    flex-direction: column;
    height: max-content;
    padding-left:5px;
    color: var(--maindark);
}
.gbrwd_howtouse{
    display:flex;
    flex-direction: column;
    margin: 0px 10px 10px 1px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 10px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    background-color: #fef6de;
    min-height: 170px;
    max-height: max-content;
    height: max-content;
    place-content: center;
}
.gbrwd_howtouserow{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.gbrwd_index{
    display: flex;
    padding: 8px 10px 8px 0px;
    font-weight: bold;
}
.gbrwd_explaintext{
    display: flex;
    font-size:14px;
    letter-spacing: 0.3px;
    padding:8px 0px;
    width: 75%;
}
.gbrwd_howtouse_m{
    width: 100%;
    padding: 10px;
    border: thin solid transparent;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #fef6de;
}
.gbrwd_howtouserow_m{
    padding: 5px;
    display: flex;
    flex-direction: row;
}
.gbrwd_index_m{
    padding-right: 5px;
    font-weight: bold;
}
.gbrwd_defaultcardsroot{
    display: flex;
    flex-direction: column;
    font-family: Lato;
}
.gbrwd_def0{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    padding: 20px 15px;
    border-radius: 5px;
    margin: 0px 0px 10px 0px;
    color: var(--maindark);
    font-family: Lato;
} 
.gbrwd_def0 :last-child{
    margin-bottom: 0px;
}
.gbrwd_def1m{
    display: flex;
    height: 75px;
    width: 100%;
}
.gbrwd_def2m{
    display: flex;
    width: 30%;
    align-items: center;
}
.gbrwd_def3m{
    display: flex;
    width: 70%;
    align-items: center;
    font-weight: bold;
    font-size: 21px;

}
.gbrwd_def4m{
    padding: 5px 10px 10px 10px;
}

.gbrwd_msgtimem{
    display: flex;
    color: #707070;
    align-items: center;
}
.gbrwd_plusrootm{
    width:100%;
}
.gbrwd_plusm{
    display: flex;
    width: 50%;
    background-color: white;
    cursor: pointer;
    border: thin solid transparent;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    place-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #eeee;
}
.gbrwd_artrootm{
    display: flex;
    flex-direction: column;
    margin-top: 120px !important;
    margin: 0px 15px ;
    border-radius: 10px;
}

.gbrwd_gallerycardm{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--maindark);
    border-radius: 10px;
}
.rd_card{
    color: var(--maindark);
    font-family: Lato;
    display: flex;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 10px;
    margin: 1px 0px 10px 2px;
    flex-direction: column;
    width: 250px;
    height: 400px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    background-color: white;
}
.rd_title{
    font-size: 18px;
    padding: 10px;
    font-weight: bold;
}
.rd_titleicon{
    width: 100%;
    min-height: 90px;
    max-height: 90px;
    display: flex;
    place-content: center;
    align-items: center;
}
.rd_descrip{
    padding: 0px 10px 5px;
    font-size: 14px;
    height: 90px;
    text-align: center;
    font-family: lato;
    letter-spacing: 0.3px;
}
