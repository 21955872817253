.mm_rootWEB {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 20px 5px 10px 5px;
}

.mm_rootTABLET {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 20px 5px 10px 5px;
}

.mm_rootMOBILE {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 10px 0px;
}

.mm_m_selected {
    padding: 10px 0px 5px 0px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    flex-direction: column;
    text-decoration: unset;
    /*
        border-radius: 5px;
        color: white;
        background-color: var(--mainpink);
    */
    color: var(--mainpink);
    border-bottom: 2px solid var(--mainpink);
    font-weight: bold;
    margin: 5px;
}

.mm_m {
    padding: 10px 0px 5px 0px;
    color: var(--maindark);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    border: none;
    background-color: white;
    flex-direction: column;
    text-decoration: unset;
    font-size: 13px;
    border-bottom: 2px solid transparent;
    margin: 5px;

}

.mm_wt_selected {
    position: relative;
    padding: 10px 0px 5px 0px;
    margin: 0px 15px;
    width: 70px;
    min-width: 70px;
    display: flex;
    justify-content: center;
    text-align: center;
    outline: none;
    cursor: pointer;
    flex-direction: column;
    text-decoration: unset;
    align-items: center;
    font-size: 14px;
    transition: background-color 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    user-select: none;
    letter-spacing: 0.5px;
    /* 
    border-radius: 5px;
    color: white; 
    background-color: var(--mainpink); 
    */
    color: var(--mainpink);
    border-bottom: 2px solid var(--mainpink);
    font-weight: bold;
}

.mm_wt {
    position: relative;
    padding: 10px 0px 5px 0px;
    margin: 0px 15px;
    width: 70px;
    min-width: 70px;
    color: var(--maindark);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    border: none;
    background-color: white;
    transition: background-color 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    cursor: pointer;
    flex-direction: column;
    text-decoration: unset;
    font-size: 14px;
    user-select: none;
    letter-spacing: 0.5px;
    border-bottom: 2px solid transparent;

}

.mm_wt:hover {
    position: relative;
    background-color: #eb5d7c2f !important;
    color: #707070 !important;
    border-bottom: 2px solid transparent;
    transition: background-color 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}