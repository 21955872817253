.Gblookup_root{
    align-items: center;
    place-content: center;
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
.Gblookup_load{
    width: 100%;
    height: 100vh;
    place-content: center;
    align-items: center;
    display: flex;
}
.Gblookup_empty{
    width: 100%;
    height: 100vh;
    place-content: center;
    align-items: center;
    display: flex;
    font-size: 16px;
    font-family: Lato;
}