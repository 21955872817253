.Banners_root{
    display: flex;
    flex-direction: column;
}
.Banners_img{
    margin: 0px;
    outline:none;
    font-weight: bold;
    background-color: white;
    outline: none;
    width: 100%;
    height: 105px;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3px;
    border: 1px solid var(--subgrey);
    margin-bottom: 0px;
    border-bottom: none;
}
.Banners_img:first-child{
    border-radius: 5px 5px 0px 0px;
}
.Banners_img:last-child{
    border: 1px solid var(--subgrey);
    border-radius: 0px 0px 5px 5px;
}
.Banners_selectedimg{
    margin: 0px;
    outline:none;
    font-weight: bold;
    border: 3px solid var(--mainpink);
    background-color: white;
    outline: none;
    width: 100%;
    position: relative;
    align-items: center;
    display: flex;
    height: 105px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3px;
}
.Banners_selectedimg:first-child{
    border-radius: 5px 5px 0px 0px;
}
.Banners_selectedimg:last-child{
    border: 3px solid var(--mainpink);
    border-radius: 0px 0px 5px 5px;
}
.Banners_semiroot{
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
}
.Banners_name{
    font-size: 12px;
    font-weight: normal;
    height: 30px;
    display: flex;
    place-content: center;
    align-items: center;
}