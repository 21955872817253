.img0 {
    background-color: black;
    width: 100%;
    height: 100vh;
}

.img1 {
    background-color: yellow;
    width: 100%;
    height: 100vh;
}

.dttextfield {
    font-size: 30px
}


.react-datepicker {
    font-size: 1.3rem !important;
    touch-action: manipulation;

}

.react-datepicker__current-month {
    font-size: 1.5rem !important;
    touch-action: manipulation;

}

.react-datepicker__navigation {
    top: 13px !important;
    touch-action: manipulation;

}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 0.5rem !important;
    touch-action: manipulation;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{
    background-color: var(--maindark);
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected{
    background-color: var(--maindark);
}

.react-datepicker__input-container input {
    border: 1px solid var(--maindark);
    width: 450px;
    height: 30px;
    border-radius: 5px;
}


::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #707070;
    opacity: 0.7;
    padding: 0;
}

.ussignup_root {
    position: fixed;
    width: 100%;
    height: 100%;
    color: #707070;
    touch-action: manipulation;
}

.ussignup_pageroot {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    touch-action: 'manipulation';
}

.ussignup_pagenumWeb {
    display: flex;
    flex-direction: row;
    height: 4%;
    z-index: 1;
    width: 100%;
    justify-content: center;
}

.ussignup_pagenumMobile {
    display: flex;
    flex-direction: row;
    height: 4%;
    z-index: 1;
    width: 100%;
    justify-content: flex-start;
}

.ussignup_arrow {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}

.ussignup_check {
    padding: 3px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    margin: 3px;
    min-height: max-content;
}

#usersignup_root {
    display: flex;
    min-height: 100%;
    width: 100vw;
    flex-direction: column;
    position: fixed;
    font-family: Lato;
    color: #333333;
    place-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

#usersignup_btm {
    width: 100%;
    place-content: center;
    align-items: center;
    display: flex;
    bottom: 0;
    position: fixed;
    z-index: 20;
    overflow: hidden;
    /* box-shadow: 0 -2px 6px 0 rgb(0 0 0 / 10%); */
    border-top: 1px solid var(--maindark);
    background-color: #ffffff;
}

#usersignup_back {
    background-color: transparent;
    border: none;
    margin: 10px;
}

#usersignup_next {
    background-color: transparent;
    border: none;
    margin: 10px;
}

#usersignuppage_root {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    touch-action: manipulation;
    background-color: var(--subpink);
}

.usersignuppage_mainbtn {
    background-color: transparent;
    border: none;
    font-weight: bold;
}

.usersignuppage_mainbtntxtselected {
    color: #eb5d7b;
    font-weight: bold;
}

#usersignuppage_page1 {
    display: flex;
    flex-direction: column;
}

#usersignuppage_pagetop {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

#usersignuppage_page1inputsroot {
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
}

.usersignuppage_generalinput {
    width: 100%;
    background-color: transparent;
    outline: none;
    border-top: transparent;
    border-right: transparent;
    border-bottom: thin solid #707070;
    border-left: transparent;
    border-radius: 0px;
    color: #707070;
    padding: 0px;
    margin: 0px;
    padding-top: 8px;
    align-self: center;
}

.usersignuppage_generalinputdisabled {
    opacity: 0;
}

.usersignup_page2commontxt {
    margin-bottom: 30px;
}

#usersignuppage_vtroot {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: center;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
}

.usersignup_commonsubtxt {
    padding-bottom: 30px;
}

#usersignuppage_authbtnroot {
    /* display: flex;
    flex-direction: row;
    font-size: 16px; */
    display: flex;
    flex-direction: row;
    font-size: 16px;
    position: fixed;
    width: 100%;
    left: 0;
    place-content: center;
    top: 10%;
}

.usersignuppage_authbtn {
    color: #333333;
    border: none;
    padding: 5px 15px;
    background-color: transparent;
}

.usersignuppage_authbtnselected {
    color: white;
    background-color: #eb5d7b;
    border-radius: 15px;
}

#usersignuppage_authcol {
    display: flex;
    flex-direction: column;
    position: fixed;
}

#usersignuppage_fb {
    background-color: #4575bb;
    color: #ffffff;
    font-size: 16px;
    padding: 5px 15px;
    margin: 15px 0px 0px 0px;
    outline: none;
    border: none;
    border-radius: 15px;
}

#usersignuppage_checkavabtn {
    padding: 5px 15px;
    background-color: white;
    border-radius: 15px;
    margin-top: 30px;
    border: 1px solid #707070;
}

#usersignuppage_termshere {
    color: blue;
    padding: 0px 3px;
}

#usersignuppage_termsroot {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

#usersignuppage_recaptchacol {
    display: flex;
    flex-direction: column;

}

#usersignuppage_recaptcha {
    /* position: fixed;
    display: flex;
    width: 100%;
    place-content: center;
    align-items: center;
    left: 0;
    margin-bottom: 30px;
    flex-direction: column; */
    position: fixed;
    display: flex;
    width: 100%;
    place-content: center;
    align-items: center;
    left: 0;
    margin-bottom: 30px;
    flex-direction: column;
}

.usersignuppage_smalltext {
    color: #707070;
    text-align: center;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    cursor: pointer;
}

.usersignuppage_smalltextcaptcha {
    color: white;
    text-align: center;
    font-size: smaller;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    background-color: #333333;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0px 5px;
}

.usersignuppage_fbfacade {
    background-color: #4575bb;
    color: #ffffff;
    border: none;
    font-size: 16px;
    padding: 9px 15px;
    margin: 15px 0px;
}

#usersignuppage_msg {
    display: flex;
    height: 40px;
    font-size: 12px;
    text-align: center;
    align-items: center;
    place-content: center;
    width: 100%;
    place-self: center;
    color: red;
}

#usersignuppage_inputroot {
    display: flex;
    flex-direction: column;
    place-self: center;
    position: relative;
}

#usersignuppage_inputmsg {
    display: flex;
    font-size: 12px;
    color: red;
    height: 35px;
}

.usersignuppage_idonthavedate {
    display: flex;
    width: 100%;
    text-align: center;
    place-content: center;
    padding-top: 10px;
    align-items: center;
    cursor: pointer;
    height: 30px;
}

.usersignup_donthavedatecheckbox {
    color: #eb5d7b;
}

.usersignup_donthavedatecheckboxlbl {
    padding-left: 5px;
}

#usersignup_termsandcond {
    flex-direction: column;
    display: flex;
    position: relative;
    overflow-y: auto;
    padding: 0px 25px 50px 25px;
    width: 100%;
    height: 100%;
}

#usersignup_clicktologin {
    color: red;
    font-size: 12px;
}

.usersignup_locationroot {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.usersignup_locbtn {
    width: 100% !important;
    margin: 0px 0px 5px 0px !important;
}

.usersignup_calendarroot {
    position: relative;
    height: 40px;
    min-height: 40px;
}

.usersignupmodalM{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 60px;
    padding-bottom: 60px;
}

.usersignupmodal{
    width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 60px;
  height: calc(100% - 60px);
}

@media (min-width:240px) {
    #usersignup_btm {
        height: 80px;
    }

    #usersignuppage_root {
        height: calc(100% - 80px);
        margin-bottom: 80px;
    }

    #usersignup_back {
        font-size: 20px;
    }

    #usersignup_next {
        font-size: 20px;
    }

    .usersignup_commontxt {
        font-size: 20px;
    }

    .usersignuppage_mainbtn {
        font-size: 32px;
    }

    #usersignuppage_page1inputsroot {
        width: 200px;
    }

    #usersignuppage_inputroot {
        width: 200px;
    }

    .usersignuppage_generalinput {
        font-size: 20px;
    }

    .usersignup_calendarroot {
        width: 200px;
    }

    .usersignup_locationroot {
        width: 200px;
    }

    #usersignuppage_vtroot {
        width: 100%;
    }

    #usersignuppage_recaptcha {
        bottom: 80px;
    }
}

@media (min-width:320px) {
    #usersignup_btm {
        height: 80px;
    }

    #usersignuppage_root {
        height: calc(100% - 80px);
        margin-bottom: 80px;
    }

    #usersignup_back {
        font-size: 24px;
    }

    #usersignup_next {
        font-size: 24px;
    }

    .usersignup_commontxt {
        font-size: 24px;
    }

    .usersignuppage_mainbtn {
        font-size: 36px;
    }

    #usersignuppage_page1inputsroot {
        width: 280px;
    }

    #usersignuppage_inputroot {
        width: 280px;
    }

    .usersignup_calendarroot {
        width: 280px;
    }

    .usersignup_locationroot {
        width: 280px;
    }

    .usersignuppage_generalinput {
        font-size: 24px;
    }

    #usersignuppage_vtroot {
        width: 100%;
        padding: 10px;
    }

    #usersignuppage_recaptcha {
        bottom: 80px;
    }
}

@media (min-width:481px) {
    #usersignup_btm {
        height: 80px;
    }

    #usersignuppage_root {
        height: calc(100% - 80px);
        margin-bottom: 80px;

    }

    #usersignup_back {
        font-size: 24px;
    }

    #usersignup_next {
        font-size: 24px;
    }

    .usersignup_commontxt {
        font-size: 24px;
    }

    .usersignuppage_mainbtn {
        font-size: 36px;
    }

    #usersignuppage_page1inputsroot {
        width: 300px;
    }

    #usersignuppage_inputroot {
        width: 300px;
    }

    .usersignuppage_generalinput {
        font-size: 24px;
    }

    .usersignup_calendarroot {
        width: 300px;
    }

    .usersignup_locationroot {
        width: 300px;
    }

    #usersignuppage_vtroot {
        width: 350px;
    }

    #usersignuppage_recaptcha {
        bottom: 80px;
    }
}

@media (min-width:641px) {
    #usersignup_btm {
        height: 80px;
    }

    #usersignuppage_root {
        height: calc(100% - 80px);
        margin-bottom: 80px;

    }

    #usersignup_back {
        font-size: 26px;
    }

    #usersignup_next {
        font-size: 26px;
    }

    .usersignup_commontxt {
        font-size: 26px;
    }

    .usersignuppage_mainbtn {
        font-size: 42px;
    }

    #usersignuppage_page1inputsroot {
        width: 300px;
    }

    #usersignuppage_inputroot {
        width: 300px;
    }

    .usersignuppage_generalinput {
        font-size: 26px;
    }

    .usersignup_calendarroot {
        width: 300px;
    }

    .usersignup_locationroot {
        width: 300px;
    }

    #usersignuppage_vtroot {
        width: 400px;
    }

    #usersignuppage_recaptcha {
        bottom: 80px;
    }
}

@media (min-width:961px) {
    #usersignup_btm {
        height: 120px;
    }

    #usersignuppage_root {
        height: calc(100% - 120px);
        margin-bottom: 120px;

    }

    #usersignup_back {
        font-size: 26px;
    }

    #usersignup_next {
        font-size: 26px;
    }

    .usersignup_commontxt {
        font-size: 26px;
    }

    .usersignuppage_mainbtn {
        font-size: 46px;
    }

    #usersignuppage_page1inputsroot {
        width: 300px;
    }

    #usersignuppage_inputroot {
        width: 300px;
    }

    .usersignuppage_generalinput {
        font-size: 26px;
    }

    .usersignup_calendarroot {
        width: 300px;
    }

    .usersignup_locationroot {
        width: 300px;
    }

    #usersignuppage_vtroot {
        width: 700px;
    }

    #usersignuppage_recaptcha {
        bottom: 120px;
    }
}

@media (min-width:1025px) {
    #usersignup_btm {
        height: 120px;
    }

    #usersignuppage_root {
        height: calc(100% - 120px);
        margin-bottom: 120px;

    }

    #usersignup_back {
        font-size: 28px;
    }

    #usersignup_next {
        font-size: 28px;
    }

    .usersignup_commontxt {
        font-size: 28px;
    }

    .usersignuppage_mainbtn {
        font-size: 56px;
    }

    #usersignuppage_page1inputsroot {
        width: 300px;
    }

    #usersignuppage_inputroot {
        width: 300px;
    }

    .usersignuppage_generalinput {
        font-size: 28px;
    }

    .usersignup_calendarroot {
        width: 300px;
    }

    .usersignup_locationroot {
        width: 300px;
    }

    #usersignuppage_vtroot {
        width: 700px;
    }

    #usersignuppage_recaptcha {
        bottom: 120px;
    }
}

@media (min-width:1281px) {
    #usersignup_btm {
        height: 120px;
    }

    #usersignuppage_root {
        height: calc(100% - 120px);
        margin-bottom: 120px;
    }

    #usersignup_back {
        font-size: 28px;
    }

    #usersignup_next {
        font-size: 28px;
    }

    .usersignup_commontxt {
        font-size: 28px;
    }

    .usersignuppage_mainbtn {
        font-size: 56px;
    }

    #usersignuppage_page1inputsroot {
        width: 300px;
    }

    #usersignuppage_inputroot {
        width: 300px;
    }

    .usersignuppage_generalinput {
        font-size: 28px;
    }

    .usersignup_calendarroot {
        width: 300px;
    }

    .usersignup_locationroot {
        width: 300px;
    }

    #usersignuppage_vtroot {
        width: 800px;
    }

    #usersignuppage_recaptcha {
        bottom: 120px;
    }
}