.Rart_btnparent{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Rart_btn{
    display: flex;
    width: 160px;
    height: 220px;
    text-align: center;
    align-items: center;
    background-color: white;
    margin: 0px 10px 0px 1px;
    border-radius: 5px;
    text-align: center;
    place-content: center;
    font-size: 15px;
    flex-direction: column;
    position: relative;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    border:1px solid rgb(255 255 255);
}
.Rart_btnselect{
    display: flex;
    width: 160px;
    height: 220px;
    text-align: center;
    align-items: center;
    color: white;
    background-color: #eb5d7b;
    border: 1px solid #eb5d7b;
    margin: 0px 10px 0px 1px;
    border-radius: 5px;
    text-align: center;
    place-content: center;
    font-size: 15px;
    font-weight: bold;
    flex-direction: column;
    position: relative;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
}
#Rart_icon{
    width: 50px;
    height: 50px;
    left: 0px;
    display: flex;
    align-items: center;
    font-size: 32px;
    padding: 0px 5px;
    position: absolute;
    top: 0px;
    place-content: center;
    border-radius: 100%;
    margin: 10px;
    background-color: #ffeaee;
    pointer-events: none;
}
#Rart_title{
    width: 100%;
    height: 50px;
    margin-top: 50px;
    align-items: center;
    place-content: center;
    display: flex;
    font-weight: bold;
    font-size: 18px;
    pointer-events: none;
}
#Rart_content{
    width:100%;
    height: 70px;
    font-size: 14px;
    pointer-events: none;
}
.Rart_maincontainer{
    width:100%;
    display: flex;
    flex-direction: column;
}
.Rart_introduction{
    display: flex;
    margin: 20px 5px 0px 0px;
    width: 100%;
}
#Rart_contentroot{

}
#Rart_rowimgs{
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    width: calc( 100% - 10px );
    overflow-x: auto;
    border: 15px solid black;
    border-right: none;
    border-radius: 5px;
}
#Rart_img{
    width: auto;
    height: 250px;
    border-right: 15px solid black;
}
#Rart_continue{
    border: thin solid rgb(235, 93, 123);
    background-color: rgb(235, 93, 123);
    color: white;
    padding: 0px 5px;
    border-radius: 5px;
    outline: none;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    text-decoration: none;
    width: max-content;
    font-size: 15px;
}
#Rart_selectbtn{
    border: thin solid rgb(235, 93, 123);
    background-color: white;
    color: rgb(235, 93, 123);
    border-radius: 5px;
    font-weight: bold;
    outline: none;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    margin-right: 4px;
    text-decoration: none;
    width: max-content;
    font-size: 15px;
    height: 40px;
    padding: 0px 5px;
}
#Rart_row{
    display:flex;
    flex-direction: row;
    position: absolute;
    right: 10px;
    bottom: 10px;
}