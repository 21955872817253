.pllook_root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    font-family: Lato;
}

.pllook_show {
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    outline: none;
    display: flex;
    position: absolute;
    right: 0px;
    bottom: 0px;
    color: var(--maindark);
    margin: 10px;
    align-items: center;
    padding: 0px 10px;
    place-content: center;
    align-content: center;
}

.pllook_arrow {
    height: 35px;
    width: 35px;
    color: var(--maindark);
    border: thin solid var(--maindark);
    display: flex;
    align-self: center;
    justify-content: center;
    border-radius: 10%;
    outline: none;
    align-items: center;
    background-color: white;
}

.pllook_name {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: var(--maindark);
    border-radius: 10px;
    text-align: center;
}

.pllook_bullet {
    margin: 15px;
    font-size: 14px;
    flex-wrap: wrap;
}

.pllook_bullet:last-child {
    margin-bottom: 30px;
}


.pllook_tag {
    display: flex;
    color: white;
    padding: 5px 10px;
    letter-spacing: 1px;
    border-radius: 5px;
    background-color: var(--maindark);
    margin-right: 5px;
}

.pllook_pricingroot {}

.pllook_destinationroot {}

.pllook_durationroot {}

.pllook_delieverablesroot {}


.pllook_commontitleroot {
    display: flex;
    width: 100%;
    place-content: flex-start;
    padding: 10px;
    align-items: center;
}

.pllook_titletext {
    padding-left: 7px;
    font-size: 15px;
    color: var(--maindark);
}

@media (min-width:240px) {
    .pllook_titletext {
        font-size: 13px;
    }

    .pllook_show {
        font-size: 12px;
        height: 30px;
    }
}

@media (min-width:320px) {}

@media (min-width:481px) {}

@media (min-width:641px) {
    .pllook_titletext {
        font-size: 14px;
    }

    .pllook_show {
        font-size: 14px;
        height: 35px;
    }
}

@media (min-width:961px) {}

@media (min-width:1025px) {}

@media (min-width:1281px) {}



.pllook_pdfitem {
    display: flex;
    flex-direction: column;
    width: 630px;
    border: 1px solid var(--subgrey);
    color: var(--maindark);
    border-radius: 10px;
    margin: 10px;
    min-height: 100px;
    align-items: center;
    place-content: center;
}