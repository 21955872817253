.Igallery_rootWEB {
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin: 0 auto;
        width: 100%;
        color:  var(--maindark);
        flex-direction: column;
}

.Igallery_rootTABLET {
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin: 0 auto 30px;
        width: 100%;
        color:  var(--maindark);
        flex-direction: column;
}

.Igallery_rootMOBILE {
        color: var(--maindark);
        flex-direction: column;
}

.pswp img {
        max-width: none;
        object-fit: contain;
}