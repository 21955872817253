.enquirycontent_input {
    height: 32px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
}

.enquirycontentadd_btn {
    border: 1px solid var(--maindark);
    color: var(--maindark);
    border-radius: 5px;
    background-color: white;
    margin-left: 5px;
}

.enquirycontentadd_dateitems {
    display: flex;
    place-content: flex-start;
    border: 1px solid var(--subgrey);
    padding: 0px 5px 0px 0px;
    flex-direction: row;
    margin-top: 5px;
    background-color: var(--subpink);
    border: none;
}

.enquirycontentadd_dateitem {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--maindark);
    width: 120px;
    margin: 5px 0px;
    place-content: space-between;
    padding: 5px;
    border-radius: 100px;
    margin-left: 5px;
    min-width: max-content;
    place-items: center;
    font-size: 12px;
    background-color: white;
    min-height: 30px;
}

.enquirycontentdeletedate_btn {
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    border: none;
}

.enquirycontent_chnselected {
    height: 40px;
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    place-content: flex-start;
    border: 1px solid var(--mainpink);
    background-color: #fff5f5;
}

.enquirycontent_chnunselected {
    height: 40px;
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--subdark);
    margin-right: 5px;
    cursor: pointer;
    place-content: flex-start;
}

.enquirycontent_icon {
    flex-direction: row;
    width: 35px;
    display: flex;
    place-content: center;
    place-items: center;
}

.enquirycontent_icontxt {
    height: 30px;
    width: 65px;
    display: flex;
    align-items: center;
    font-size: 12px;
    display: flex;
    place-content: center;
    padding-right: 5px;
}

.enquirycontent_templatebtn {
    height: 40px;
    width: 100px;
    background-color: transparent;
    display: flex;
    padding: 5px;
    border: 1px solid var(--subdark);
    border-radius: 5px;
    outline: none;
    align-items: center;
    place-content: center;
    margin-right: 5px;
    font-size: 12px;
}

.enquiry_title{
    display: flex;
    font-size: 12px;
    margin: 0px 10px 0px 10px;
    color: var(--maindark);
}

.enquiry_colscroll{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.enquirycontent_copybtn{
    height: 30px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    min-height: 30px;
    background-color: white;
    border-radius: 50px;
    margin: 10px 10px 0px 10px;
  }

#enquirycontent_copybtn{
    margin: 0px 10px;
    height: 30px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
}

.enquirycontent_vtselected{
    margin-left: 5px;
    border: 1px solid var(--maindark);
    background-color: var(--maindark);
    color: #ffffff;
    border-radius: 5px;
    padding: 0px 5px;
    margin-bottom: 5px;
    font-size: 11px;
    cursor: pointer;
}

.enquirycontent_vtunselected{
    margin-left: 5px;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    padding: 0px 5px;
    margin-bottom: 5px;
    font-size: 11px;
    cursor: pointer;
}