.questionpage_selected {
    background-color: var(--maindark);
    color: white;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 16px;
    padding: 12px 16px;
    margin: 5px 0px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: break-spaces;

}

.questionpage_nonselected {
    background-color: white;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    cursor: pointer;
    border-radius: 16px;
    padding: 12px 16px;
    margin: 5px 0px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    white-space: break-spaces;
}

.questionpage_nonselected:hover {
    background-color: var(--subpink);
    cursor: pointer;
    color: var(--maindark);
    border-radius: 16px;
    padding: 12px 16px;
    margin: 5px 0px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-color: #757575;
    display: flex;
    white-space: break-spaces;
}

.questionpage_root {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    color: var(--maindark);
}

.questionpage_qns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px 0px 10px 0px;

}