.Toolscommunityroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    place-items: center;
}

.Toolscommunitysocialgrp {
    display: flex;
    flex-direction: column;
}

.Toolscommunitysocialgrptitle {
    display: flex;
    flex-direction: row;
    place-items: center;
    background-color: white;
    color: var(--maindark);
    border-radius: 10px 10px 0px 0px;
    border: 1px solid var(--maindark);
    border-bottom: 0px;
}

.Toolscommunitysocialgrpsocialmediatitle {
    font-size: 16px;
}

.Toolscommunitysocialgrpitems {
    border: 1px solid var(--maindark);
    margin: 0px 0px 20px 0px;
    border-radius: 0px 0px 10px 10px;
}

.Toolscommunitysocialgrpitem {
    width: 100%;
}

.Toolscommunitysocialgrpitemtop {
    font-size: 16px;
    padding: 10px;
}

.Toolscommunitysocialgrpitembtm {
    color: var(--subdark);
    padding: 0px 10px;
}

.Toolscommunitysocialjoinroot{
    width: 100%;
    left: 0;
    position: relative;
    display: flex;
    place-content: flex-end;
    padding: 10px ;
}

.Toolscommunitysocialjoin {
    display: flex;
    margin: 10px;
    padding: 5px 10px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
    border-radius: 10px;
}