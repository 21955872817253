.Numtable_root{
    width: 100vw;
    height: 100%;
    background-color: #00000080;
    display: flex;
    place-content: center;
    position: fixed;
    top: 0px;
    z-index: 1002;
}
.Numtable_add{
    min-width: 50px;
    height: 50px;
    place-content: center;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 0px 10px;
}
.Numtable_minus{
    min-width: 50px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px 10px;
}
.Numtable_smalllarge{
    min-width: 75px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0px 10px;
}
.Numtable_selected{
    color:white !important;
    background-color: #eb5d7b !important;
    padding: 0px 10px;
}
.Numtable_seatcontainer{
    display: flex;
    height: 281px;
    border-top: 1px solid black;
    flex-direction: column;
    padding: 0px 0px 15px 0px;
}
.Numtable_seatgrid{
    display: grid;
    grid-template-columns: 50% 50%;
    overflow-x: hidden;
    padding-top: 5px;
}
.Numtable_3_0{

}
.Numtable_3_1{
    
}
.Numtable_4_0{

}
.Numtable_5_0{

}
.Numtable_6_0{

}
.Numtable_7_0{

}
.Numtable_8_0{

}
.Numtable_9_0{

}
.Numtable_10_0{

}
.Numtable_11_0{

}
.Numtable_12_0{

}

.Numtable_tableselectroot{
    white-space: pre;
    display: flex;
    place-content: center;
    align-items: center;
}
.Numtable_singletable{
    width: 100%;
    height: 180px;
    margin: 0px 10px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    color: black;
    padding: 0px;
}
.Numtable_create{
    width: calc( 50% - 10px );
    margin: 10px;
    padding: 5px;
    margin-left: 0px;
    border: 1px solid black;
    background-color: white;
    height: 40px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.Numtable_btnrow{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Numtable_cancel{
    width: calc( 50% - 10px );
    margin: 10px;
    padding: 5px;
    margin-right: 0px;
    border: 1px solid black;
    border-right: none;
    background-color: white;
    height: 40px;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
