/* setting */
.gbrwd_settingroot {
    padding: 10px 3px;
    width: 400px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.gbrwd_settingchangebtn {
    display: flex;
    padding: 5px 0px;
    margin: 5px 0px;
    width: 140px;
    background-color: white;
    color: #707070;
    border-radius: 5px;
    border: thin solid #707070;
    font-weight: bold;
    text-align: center;
    align-items: center;
    place-content: center;
    place-self: flex-end;
}

.gbrwd_settingerrs {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.gbrwd_settinginputsroot {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin: 0px 0px 10px 0px;
    border-radius: 5px;
    border: thin solid #707070;
    width: 100%;

}

.gbrwd_settingtitle {
    display: flex;
    font-weight: bold;
    font-size: 14px;
}

/* .gbrwd_settingsubtitle{
        display: flex;
        font-weight: bold;
        color:#707070;
        font-size: 13px;
    } */
.gbrwd_settinginput {
    overflow-x: auto;
    display: flex;
    place-content: flex-start;
    font-weight: bold;
    height: max-content;
    color: white;
    background-color: black;
    border-radius: 5px;
    margin: 5px 0px 10px 0px;
    padding: 5px;
    cursor: pointer;
    min-height: 30px;
    overflow-x: hidden;
}

.gbrwd_settingnewinput {
    overflow-x: auto;
    display: flex;
    place-content: flex-start;
    height: max-content;
    color: black;
    background-color: white;
    border-radius: 5px;
    margin: 5px 0px 10px 0px;
    padding: 5px;
    cursor: pointer;
    min-height: 30px;
    border: thin solid #707070;
}

.gbrwd_msgtypebtnval {
    border: thin solid #707070;
    border-radius: 5px;
    background-color: white;
    margin: 5px 0px;
    padding: 5px;
    color: #707070;
    font-weight: bold;
    height: 40px;
}

.gbrwd_msgtypebtnvalselected {
    border: thin solid #707070;
    border-radius: 5px;
    background-color: #eb5d7b;
    margin: 5px 0px;
    padding: 5px;
    color: white;
    font-weight: bold;
    height: 40px;
}

.gbrwd_settinginputsrootm {
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    border-radius: 5px;
    border: thin solid #707070;
    margin-bottom: 10px;
    width: 100%;
}

.gbrwd_msgtypebtnvalm {
    border: thin solid #707070;
    border-radius: 5px;
    background-color: white;
    margin: 5px 0px;
    padding: 5px;
    color: #707070;
    font-weight: bold;
}