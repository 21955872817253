
.gbcastmusic_root{
    display: flex;
    position: absolute;
    width:100%;
    height:100%;
    background-color: #808080d4;
    z-index:3;
}
.gbcastmusic_modal{
    display: flex;
    position: absolute;
    width:400px;
    height:70vh;
    top:calc(( 100% - 70vh ) / 2);
    left:calc(( 100% - 400px ) / 2);
    background-color: white;
    border-radius:5px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    flex-direction: column;
}
.gbcastmusic_row{
    display: flex;
    flex-direction: row;
    padding:5px;
    /* margin:5px; */
    border-radius: 5px;
    /* border: thin solid #707070; */
    /* width:calc( 100% - 10px); */
    width:100%;
    height: 45px;
}
.gbcastmusic_action{
    display: flex;
    padding:5px;
    width:38px;
    height: 38px;
    place-content: center;
    align-items: center;
}
.gbcastmusic_top{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: calc( 100% - 85px );
    overflow-y: auto;
}
.gbcastmusic_donebtn{
    display: flex;
    text-align: center;
    place-content: center;
    border: thin solid #707070;
    width: calc( 100% - 10px);
    height: 35px;
    cursor: pointer;
    text-align: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    font-weight: bold;
    color: #707070;
    font-size: 16px;
    position: relative;
    /* top: calc( 70vh - 45px ); */
}
.gbcast_musicobj{
    display: flex;
    flex-direction: row;
    height: 35px;
    font-size: 16px;
    padding:5px;
    margin:5px;
    border-radius: 5px;
    color:#707070;
    border:thin dashed #707070;
}
.gbcastmusic_audio{
    width: calc( 100% - 10px);
    background-color: #ffc9c9;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    min-height: 55px;
    max-height: 55px;
}