/* html {
    scroll-snap-type: y mandatory;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling:touch;
    -webkit-scroll-snap-type: mandatory; 
    overflow-y:inherit;
    height:max-content;
    max-height:'max-content';
} */

.UserLoginRoot {
    display: flex;
    flex-direction: column;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
    scroll-behavior: smooth;
    overscroll-behavior-y: none;
    overflow-x: hidden;
    font-family: Lato;
}

/* .UserLoginLogoName{
    display: flex;
    font-size: 30px;
    width:100%;
    place-content: center;
    font-family:'Marck Script';
} */
/* .UserLoginLogoName_m{
    display: flex;
    font-size: 35px;
    width:100%;
    place-content: center;
    letter-spacing: 0.2;
    font-weight: bold;
    font-family:'Marck Script';
} */
.UserLoginSubtitle_m {
    display: flex;
    width: 100%;
    font-size: 14px;
    padding-bottom: 20px;
    flex-direction: column;
    text-align: center;
}

.UserLoginSubtitleRoot {
    display: flex;
    width: 100%;
    height: max-content;
    justify-content: center;
    position: relative;
}

.UserLoginSubtitleSubRootWEB {
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    align-self: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 10px 50px;
    z-index: 2;
    margin: 100px 0px;
    padding: 20px;
    border: 2px solid #dacdb3;
    background-color: #fffbf3;
    width: 800px;
}

.UserLoginSubtitleSubRootTABLET {
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    align-self: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 10px 50px;
    z-index: 2;
    margin: 100px 0px;
    padding: 20px;
    border: 2px solid #dacdb3;
    background-color: #fffbf3;
    width: 600px;
}

.UserLoginSubtitleSubRootMOBILE {
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    align-self: center;
    background-color: white;
    border-radius: 10px;
    z-index: 2;
    width: 100%;
    padding: 0px 20px;
    padding-bottom: 50px;
}

.UserLoginTextRoot {
    display: flex;
    padding: 5px;
    margin: 5px;
    border: 2px solid #dacdb3;
    background-color: #fffbf3;
    flex-direction: column;
    border-radius: 10px;
}

.UserLoginText {
    display: flex;
    flex-wrap: wrap;
    place-self: start;
    padding: 5px;
    font-size: 14px;
    line-height: 22px;
}


.UserLoginTagBgItem {
    width: 100px;
    text-align: center;
    align-items: center;
    position: absolute;
    border: thin solid var(--maindark);
    color: var(--maindark);
    font-size: 14px;
    line-height: 22px;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    opacity: 0.5;
    background-color: white;
}

.UserLoginSectionBtm {
    overflow: hidden;
    display: flex;
    width: 100%;
    overflow: hidden;
    max-height: max-content;
    place-content: center;
    position: relative;
    align-items: center;
    min-height: 100vh;
    place-content: center;
    height: 100%;
    background-color: var(--subpink);
    top: 100%;
    overflow: hidden !important;
    flex-direction: column;
    padding: 60px 0px 60px 0px;
}

.weddinghero {
    -webkit-animation-name: fade1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 16000ms;
    animation-name: fade1;
    animation-iteration-count: infinite;
    animation-duration: 16000ms;
}

.eventhero {
    -webkit-animation-name: fade2;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 16000ms;
    animation-name: fade2;
    animation-iteration-count: infinite;
    animation-duration: 16000ms;
}

/* event first */
.weddinghero1 {
    -webkit-animation-name: fade2;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 16000ms;
    animation-name: fade2;
    animation-iteration-count: infinite;
    animation-duration: 16000ms;
}

.eventhero1 {
    -webkit-animation-name: fade1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 16000ms;
    animation-name: fade1;
    animation-iteration-count: infinite;
    animation-duration: 16000ms;
}

.userlogin_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    border-radius: 10px;
    min-width: 340px;
    display: flex;
    align-items: center;
    margin: 0px;
}

.selectedtitleul {
    color: #eb5d7b;
    font-weight: bold;
    transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.notselectedtitleul {
    color: var(--maindark);
    font-weight: bold;
    transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}


.ul_first_title {
    font-weight: bold;
    transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-animation-name: ul_wed_anim;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-name: ul_wed_anim;
    animation-iteration-count: infinite;
    animation-duration: 28000ms;
    color: #eb5d7b;
}

.ul_second_title {
    font-weight: bold;
    transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-transition: color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    -webkit-animation-name: ul_event_anim;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-name: ul_event_anim;
    animation-iteration-count: infinite;
    animation-duration: 28000ms;
}


@keyframes ul_wed_anim {
    0% {
        color: #eb5d7b
    }

    18.65% {
        color: #eb5d7b
    }

    37.25% {
        color: #eb5d7b
    }

    50% {
        color: var(--maindark);
    }

    51% {
        color: var(--maindark);
    }

    100% {
        color: var(--maindark);
    }
}

@keyframes ul_event_anim {
    0% {
        color: var(--maindark);
    }

    50% {
        color: var(--maindark);
    }

    51% {
        color: #eb5d7b
    }

    68.75% {
        color: #eb5d7b
    }

    81.25% {
        color: #eb5d7b
    }

    100% {
        color: var(--maindark);
    }
}

.ul_imgsroot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    z-index: -1;
    position: relative;
    background-color: transparent;
}

/* .ul_transparency{
    width:100%;
    height: 100%;
    position: absolute;
    opacity:0.05;
    top:0;
    left:0;
    background-color: black;
} */
.ul_rowimgsroot {
    position: absolute;
    display: flex;
    flex-direction: row;
    max-height: 100%;
    background-color: transparent;
}

.ul_sirf_0 {
    display: flex;
    background-color: transparent;
    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirf_0;
    -webkit-animation-name: ul_sirf_0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;
}

.ul_sirf_1 {
    display: flex;
    background-color: transparent;
    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirf_1;
    -webkit-animation-name: ul_sirf_1;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirf_2 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirf_2;
    -webkit-animation-name: ul_sirf_2;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirf_3 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirf_3;
    -webkit-animation-name: ul_sirf_3;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirf_4 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirf_4;
    -webkit-animation-name: ul_sirf_4;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirf_5 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirf_5;
    -webkit-animation-name: ul_sirf_5;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;
}

@-webkit-keyframes ul_sirf_0 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    1.6667%,
    40% {
        opacity: 1;
        transform: translateY(0px);
    }

    41.6667%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirf_1 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    3.3334%,
    41.6667% {
        opacity: 1;
        transform: translateY(0px);
    }

    43.3554%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirf_2 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    5%,
    43.3554% {
        opacity: 1;
        transform: translateY(0px);
    }

    45.0331%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }

}

@-webkit-keyframes ul_sirf_3 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    6.6668%,
    45.0331% {
        opacity: 1;
        transform: translateY(0px);
    }

    46.7108%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirf_4 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    8.3335%,
    46.7108% {
        opacity: 1;
        transform: translateY(0px);
    }

    48.3885%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirf_5 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    10%,
    48.3885% {
        opacity: 1;
        transform: translateY(0px);
    }

    50%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirf_0 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    1.6667%,
    40% {
        opacity: 1;
        transform: translateY(0px);
    }

    41.6667%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirf_1 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    3.3334%,
    41.6667% {
        opacity: 1;
        transform: translateY(0px);
    }

    43.3554%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirf_2 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    5%,
    43.3554% {
        opacity: 1;
        transform: translateY(0px);
    }

    45.0331%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }

}

@keyframes ul_sirf_3 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    6.6668%,
    45.0331% {
        opacity: 1;
        transform: translateY(0px);
    }

    46.7108%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirf_4 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    8.3335%,
    46.7108% {
        opacity: 1;
        transform: translateY(0px);
    }

    48.3885%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirf_5 {
    0% {
        opacity: 0;
        transform: translateY(-400px);
    }

    10%,
    48.3885% {
        opacity: 1;
        transform: translateY(0px);
    }

    50%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}


.ul_sirs_0 {
    display: flex;
    background-color: transparent;
    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirs_0;
    -webkit-animation-name: ul_sirs_0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirs_1 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirs_1;
    -webkit-animation-name: ul_sirs_1;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirs_2 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirs_2;
    -webkit-animation-name: ul_sirs_2;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirs_3 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirs_3;
    -webkit-animation-name: ul_sirs_3;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirs_4 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirs_4;
    -webkit-animation-name: ul_sirs_4;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

.ul_sirs_5 {
    display: flex;
    background-color: transparent;

    margin: 2.5px;
    border-radius: 10px;
    vertical-align: middle;
    object-fit: cover;
    will-change: transform;
    animation-name: ul_sirs_5;
    -webkit-animation-name: ul_sirs_5;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 28000ms;
    animation-duration: 28000ms;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: thin solid transparent;

}

@-webkit-keyframes ul_sirs_0 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    51.6667%,
    90% {
        opacity: 1;
        transform: translateY(0px);
    }

    91.6667%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirs_1 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    53.3334%,
    91.6667% {
        opacity: 1;
        transform: translateY(0px);
    }

    93.3554%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirs_2 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    55%,
    93.3554% {
        opacity: 1;
        transform: translateY(0px);
    }

    95.0331%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }

}

@-webkit-keyframes ul_sirs_3 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    56.6668%,
    95.0331% {
        opacity: 1;
        transform: translateY(0px);
    }

    96.7108%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirs_4 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    58.3335%,
    96.7108% {
        opacity: 1;
        transform: translateY(0px);
    }

    98.3885%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@-webkit-keyframes ul_sirs_5 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    60%,
    98.3885% {
        opacity: 1;
        transform: translateY(0px);
    }

    100%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirs_0 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    51.6667%,
    90% {
        opacity: 1;
        transform: translateY(0px);
    }

    91.6667%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirs_1 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    53.3334%,
    91.6667% {
        opacity: 1;
        transform: translateY(0px);
    }

    93.3554%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirs_2 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    55%,
    93.3554% {
        opacity: 1;
        transform: translateY(0px);
    }

    95.0331%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }

}

@keyframes ul_sirs_3 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    56.6668%,
    95.0331% {
        opacity: 1;
        transform: translateY(0px);
    }

    96.7108%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirs_4 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    58.3335%,
    96.7108% {
        opacity: 1;
        transform: translateY(0px);
    }

    98.3885%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}

@keyframes ul_sirs_5 {

    0%,
    50% {
        opacity: 0;
        transform: translateY(-400px);
    }

    60%,
    98.3885% {
        opacity: 1;
        transform: translateY(0px);
    }

    100%,
    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}


.ul_arrowbtn {
    position: absolute;
    display: flex;
    box-shadow: rgba(25, 25, 25, 0.25) 0px 2px 3px;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 40;
    outline: none;
    background-color: white;
    color: var(--maindark);
    align-self: center;
    margin: 5px 0px;
    padding: 5px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: thin solid silver;

}

.UserLoginOrline {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 5px 0px;
    height: max-content;
    justify-content: center;
    color: rgb(233, 233, 233);
}

.ULfirstchild {
    display: flex;
    width: 48%;
    align-items: center;
}

.ULsecondchild {
    display: flex;
    padding: 3px;
    font-size: 12px;
    color: var(--maindark);
    font-family: Lato !important;
}

.ULfirstgrandchildren {
    display: flex;
    width: 100%;
    border: thin solid rgb(233, 233, 233);
}

.Ul_singlefeatureitem {
    display: flex;
    flex-direction: column;
    width: calc(33.3333%);
    height: 110px;
    place-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 0px 20px;
    place-content: baseline;
}

.UserLoginTempbg {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100%;
}

.Ulfeatures_root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    font-size: 14px;
    font-weight: 500;
}

.Ulfeatures_row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Ulfeatures_SG {
    color: var(--mainpink);
    margin: 0px 5px;
    font-weight: bold;
}

.Ulfeatures_dot {
    font-size: 16px;
    padding: 0px 5px;
}

.ulloginsignup_login {
    background-color: #ffffff;
    text-align: center;
    color: var(--maindark);
    font-weight: bold;
    border: 1px solid #ffffff;
    height: max-content;
    width: 80px;
    padding: 5px;
    border-radius: 5px;
    margin: 5px 0px 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 3px -1px;
    cursor: pointer;
    z-index:4;
}

.ulloginsignup_signup {
    background-color: #ffffff;
    text-align: center;
    color: var(--maindark);
    font-weight: bold;
    border: 1px solid #ffffff;
    height: max-content;
    width: 80px;
    padding: 5px;
    border-radius: 5px;
    margin: 5px 10px 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 3px -1px;
    cursor: pointer;
    z-index:4;

}

.ullogin_loginfieldWEB {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 40px;
}

.ullogin_loginfieldTABLET {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 40px;
}

.ullogin_loginfieldMOBILE {
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 275px;
    padding: 15px 0px;
}

.ullogin_loginfieldTop {
    display: flex;
    flex-direction: row;
    padding: 10px 0px 5px;
    margin-top: 40px;
}

.ullogin_acctright {
    margin-right: 5px
}

.ullogin_clicksignup {
    cursor: pointer;
    align-items: center;
    color: #2b72bf;
    text-decoration: 1px solid #2b72bf;
}

.ullogin_loginfieldCenter {
    cursor: pointer;
    padding: 10px 0px 0px;
    color: #2b72bf;
}

.ullogin_loginfieldBtm {
    cursor: pointer;
    padding: 10px 0px 0px;
    color: #2b72bf;
}

.ullogin_bizbtnMOBILE {
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    font-size: 20px;
    width: 90%;
    height: max-content;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    align-self: center;
    color: var(--maindark);
}

.ullogin_bizbtnWEB:hover {
    color: white !important;
    text-decoration: none !important;
}

.ullogin_bizbtnTABLET {
    background-color: #9e7e6b;
    border: none;
    outline: none;
    text-decoration: none;
    font-size: 20px;
    width: 100%;
    height: max-content;
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    align-self: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.ullogin_bizbtnWEB {
    background-color: #9e7e6b;
    border: none;
    outline: none;
    text-decoration: none;
    font-size: 20px;
    width: 100%;
    height: max-content;
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: flex-end;
    padding: 10px;
    align-self: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.UL_logo {
    width: 100px;
    height: 100px;
    display: flex;
    align-self: center;
}

.UL_iconroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 30px;
}

.UL_iconrow {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 30px;
}
.UL_mainloginroot {
    position: absolute;
    display: flex;
    place-content: center;
    z-index: 1;
    width: max-content;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 2px -2px;
    border: 1px solid rgb(158, 126, 107);
}

.UL_maincatandfeaturesroot {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UL_maincatandfeaturespunchline {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-family: Lato;
    text-align: center;
}

.UL_mainloginrootMOBILE {
    position: absolute;
    display: flex;
    place-content: center;
    z-index: 1;
    width: max-content;
    border-radius: 20px;
}

.UL_maincatandfeaturespunchlineMOBILE {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    white-space: break-spaces;
    text-align: center;
}

#UL_imgholder {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    object-fit: contain;
}

#UL_loading {
    position: absolute;
    top: 0px
}

#UL_imguserloginmobile {
    /* height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1; */
    height: 130%;
    width: 130%;
    position: absolute;
    left: -15%;
    z-index: 3;

}

#UL_worehighlightroot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.5em;
    font-family: Lato;
}

#UL_and {
    padding: 0px 5px;
}

.UL_wsubtitle {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 12px;
}


.Ul_svgbg {
    background-color: var(--subpink);
    position: absolute;
    max-height: calc(100vh - 35px);
    min-height: calc(1074px - 35px);
    width: 100%;
    z-index: -2;
}

/* for web and tablet */
.UL_mainloginsubroot {
    display: flex;
    width: 370px;
    flex-direction: column;
    color: var(--maindark);
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding-top: 25px;
}

/* for mobile */
.UL_mainloginsubrootMOBILE {
    display: flex;
    max-height: max-content;
    min-height: 80%;
    width: max-content;
    justify-content: center;
    flex-direction: column;
    color: var(--maindark);
    align-items: center;
    background-color: white;
    border-radius: 20px;
}




.UserLoginSectionTopWEB {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 100vw;
    overflow: hidden;
    height: calc(100vh - 35px);
    place-content: center;
    position: relative;
    align-items: center;
    overflow: hidden;
}

.UserLoginSectionTopTABLET {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    /* max-height: calc(100vh - 35px);
    min-height: calc(1074px - 35px); */
    height: calc(100vh - 35px);
    place-content: center;
    position: relative;
    align-items: center;
    overflow: hidden;

}

.UserLoginSectionTopMOBILE {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 800px;
    overflow: hidden;
    place-content: center;
    position: relative;
    align-items: center;
    overflow: hidden;
}

.Userlogin_modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    margin-top: 60px;
    height: calc(100% - 60px);
    padding: 0px 10px;
}

.Userlogin_modalmobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 60px;
    padding-bottom: 60px;
    padding: 0px 10px;
}

.ulloginsignup_root {
    align-items: flex-start;
    background-position: 50%;
    background-size: cover;
    display: flex;
    flex-direction: row;
    height: 400px;
    place-content: flex-end;
    position: relative;
    right: 1%;
    width: 102%;
}

.ulogin_resendbtn{
    
}

@media (min-width:150px) {
    
}

@media (min-width:200px) {}

@media (min-width:320px) {}

@media (min-width:320px) {}

@media (min-width:481px) {
    .ulloginsignup_root{
        height: 677px;
    }
}

@media (min-width:641px) {
}

@media (min-width:961px) {}

@media (min-width:1025px) {}

@media (min-width:1281px) {}