.login_root{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.facebook-login{
    background-color:#4575bb;
    color:#fff;
    border:none;
    font-size: 16px;
    padding: 0px 15px;
    width:100%;
    height: 44px;
}

.VL_loginbtn{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    color: var(--maindark);
    min-height: 42px;
    height: 42px;
    width: 100%;
    margin-top: 10px;
    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    display: flex;
}
.VL_error{
    padding: 10px;
    border-radius: 5px;
    border: medium;
    background-color: white;
    color: var(--maindark);
    min-height: 42px;
    height: 42px;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}