.FMC_ROOTMOBILE {
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Lato;
    height: 100%;
}

.FMC_ROOTWEB {
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Lato;
    height: 100%;
}

.FMC_ROOTTABLET {
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Lato;
    height: 100%;
}

.FMC_TITLE {
    padding-left: 10px;
    color: var(--maindark);
    text-transform: uppercase;
    font-size: 16px;
}

.FMC_FCL_ROOT {
    display: flex;
    flex-direction: row;
    padding: 0px 30px 0px 0px;
    align-items: center;
    width: 259px;
    color: #707070;
}

.FMC_BOX {
    display: flex;
    flex-flow: row wrap;
    border: 1px solid var(--lightdark);
    margin: 0px 10px 20px 10px;
    padding: 10px;
    /* border-radius: 0px 10px 10px 10px; */
    -webkit-overflow-scrolling: touch;
}

.FMC_RESETBTNBOX {
    height: 25px;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    user-select: none;
}

.FMC_APPLYBTNBOX {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.FMC_EMPTYROOT {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-content: center;
    padding: 15px;
    flex-direction: row;
    color: #c5c5c5;
}

.FMC_EMPTYDIV {
    height: 25px;
    width: 25px;
}

.FMC_mainbox {
    min-height: max-content;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
}

.FMC_cbrow {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    height: 45px;
    width: 100%;
    padding: 10px 0px 10px 10px;
}

.FMC_cbrow:hover {
    background-color: #fce8e8;

}

.FMC_cblbl {
    color: var(--maindark);
    margin: 10px 0px 10px 5px;
    font-weight: normal;
    display: flex;
    width: max-content;
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 5px;
    user-select: none;
    cursor: pointer;
}

.FMC_cb {
    margin: 0px;
}