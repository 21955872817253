.Images_swap_btn {
    border-radius: 5px;
    border: 1px solid #dddddd;
    background-color: white;
    outline: none;
    color: var(--maindark);
    flex-direction: row;
    display: flex;
    font-size: 15px;
    transition: box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    min-width: 40px !important;
    min-height: 40px !important;
    align-items: center;
    place-content: center;
    line-height: 1;
    margin-bottom: 5px;
}

.Images_swap_btn:hover {
    background-color: #eb5d7ba8;
    color: white;
}

.Images_swap_done {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    place-content: center;
    border: thin solid #707070;
    padding: 1px;
    border-radius: 5px;
    cursor: pointer;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
    color: #707070;
    height: 40px;
    background-color: white;
    margin-bottom: 5px;
}

.Imagearrowbtn_left {
    padding: 2px;
    border-radius: 2px;
    display: flex;
    position: absolute;
    text-align: center;
    color: white;
    font-weight: bold;
    left: 12.5px;
    border: none;
    outline: none;
    background-color: #eb5d7b;
    top: calc(100% - 40px);
    align-items: center;
    place-content: center;
    justify-content: center;
    margin: 0px;
}

.Imagearrowbtn_right {
    padding: 2px;
    border-radius: 5px;
    display: flex;
    position: absolute;
    text-align: center;
    color: white;
    font-weight: bold;
    right: 12.5px;
    border: none;
    outline: none;
    background-color: #eb5d7b;
    top: calc(100% - 40px);
    align-items: center;
    place-content: center;
    justify-content: center;
    margin: 0px;

}

@media (min-width:0px) {
    .Imageinvitationcard_rootMOBILE {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: auto;
        grid-gap: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 0px 70px;
        width: 100%;
        margin-bottom: 10px;
    }

}

@media (min-width:547px) {
    .Imageinvitationcard_rootMOBILE {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 0px 70px;
        margin-bottom: 10px;
    }
}

.Imageinvitationcard_rootTABLET {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    grid-template-rows: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0px 70px;
    margin-bottom: 10px;
}

.Imageinvitationcard_rootWEB {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 6px;
    grid-template-rows: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0px 70px;
    margin-bottom: 10px;
}

.Imageic_btn {
    display: flex;
    background-color: white;
    color: var(--maindark);
    border: 1px solid var(--maindark);
    width: 100px;
    height: 40px;
    text-align: center;
    align-items: center;
    place-content: center;
    margin-left: 5px;
    border-radius: 5px;
}

.Imageic_uploading {
    display: flex;
    width: 100px;
    height: 40px;
    margin-left: 5px;
    text-align: center;
    align-items: center;
    place-content: center;
    color: white;
}

.Imageic_subtitle {
    font-size: 17px;
    color: #707070;
}

.Imageic_croproot {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000000000;
    background-color: var(--maindark);
}

.Imageic_cropcontainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
    height: 100%;
}

.Imageic_btnsrow {
    width: 100%;
    height: 80px;
    bottom: 0px;
    position: absolute;
    z-index: 1000000000;
    flex-direction: row;
    display: flex;
    /* background-color: var(--maindark); */
    align-items: center;
    place-content: flex-end;
}

.Imageic_defaulttxt {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    background-color: var(--transparentmaindark);
    align-items: center;
    place-content: center;
    text-align: center;
    width: 100%;
    font-size: 12px;
    padding: 5px;
    min-height: 30%;
}

.Images_swapimg {
    width: 100px;
    height: 100px;
    object-fit: contain;
    background-color: var(--transparentmaindark);
}

.Images_swapimgindex {
    padding: 10px;
}

.Images_swapimgentity {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    padding: 1px 0px;
    border: 1px solid var(--subgrey);
    width: 100%;
    border-radius: 10px 0px 0px 10px;
}

.Images_blinkicon {
    background-color: transparent;
    border: 1px solid var(--mainpink);
    position: absolute;
    padding: 0px;
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.Images_svgitem {
    width: 100%;
    height: 60px;
    padding: 10px;
    display: flex;
}

.Images_firstsvg {
    background: white;
    border: 1px solid var(--subgrey);
    border-radius: 10px 10px 0px 0px;
    height: 70px;
    stroke-width: 30px;
    padding: 0px;
    margin: 0px;
    border-bottom: none;
    position: relative;
}

.Images_midsvg {
    background: white;
    border: 1px solid var(--subgrey);
    border-radius: 0px;
    height: 70px;
    stroke-width: 30px;
    padding: 0px;
    margin: 0px;
    position: relative;
}


.Images_secondsvg {
    background: white;
    border: 1px solid var(--subgrey);
    border-radius: 0px 0px 10px 10px;
    height: 70px;
    stroke-width: 30px;
    padding: 0px;
    margin: 0px;
    border-top: none;
    position: relative;
}

.Images_borderselected {
    border: 3px solid var(--mainpink);
}

.Images_svgtxt {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;
}

.Images_animateselectroot {
    display: flex;
    flex-direction: row;
}

.Images_animatestate:first-child {
    border-radius: 5px 0px 0px 5px;
    border-right: none;
}

.Images_animatestate:last-child {
    border-radius: 0px 5px 5px 0px;
    border-left: none;
}

.Images_animatestate {
    height: 74px;
    width: 100px;
    border: 1px solid var(--maindark);
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    background-color: white;
}

.Images_animateht {
    height: 40px;
}


.Images_selected {
    color: white;
    background-color: var(--mainpink);
    border-bottom: none !important;
}

.Images_selectednone {}


.Images_sizebtn {
    width: 70px;
    border: none;
    margin-right: 5px;
    background-color: white;
}

.Images_sizebtnshaded {
    width: 70px;
    width: 70px;
    margin-right: 5px;
    background-color: var(--mainpink);
    border-radius: 10px;
    color: #ffffff;
    border: 1px solid var(--mainpink);
}