.projectlookup_mobileroot {
    overflow: hidden;
    touch-action: manipulation;
}

.projectlookup_webroot {
    background-color: #eeeeee;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

@-webkit-keyframes projectlookup_imgroot_anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes projectlookup_imgroot_anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.projectlookup_titleMOBILE {
    display: flex;
    text-align: center;
    place-content: center;
    align-items: center;
    ;
    align-self: center;
    font-size: 24px;
    margin: 6px 0px;
}

.projectlookup_titleTABLET {
    display: flex;
    text-align: center;
    place-content: center;
    align-items: center;
    ;
    align-self: center;
    font-size: 28px;
    margin: 8px 0px;
}

.projectlookup_titleWEB {
    display: flex;
    text-align: center;
    place-content: center;
    align-items: center;
    ;
    align-self: center;
    font-size: 32px;
    margin: 50px 0px 10px 0px;
}

.projectlookup_maintextMOBILE {
    display: flex;
    text-align: center;
    place-content: center;
    text-align: justify;
    padding: 15px;
    font-size: 14px;
}

.projectlookup_maintextTABLET {
    display: flex;
    text-align: center;
    place-content: center;
    text-align: justify;
    padding: 15px 30px 15px 30px;
    font-size: 16px;
}

.projectlookup_maintextWEB {
    display: flex;
    text-align: center;
    place-content: center;
    text-align: justify;
    padding: 15px 30px 15px 30px;
    font-size: 16px;
}

.projectlookup_collabroot {
    width: 100%;
}

.projectlookup_collatitle {
    font-weight: bold;
    padding: 10px 0px 3px 0px;
    color: var(--maindark);
    font-size: Lato;
}

.projectlookup_collablink {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: var(--maindark);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-decoration: none !important;
    align-items: center;
}

.projectlookup_collablink:hover {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: var(--maindark);
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-decoration: none !important;
}

.projectlookup_circleimg {
    width: 40px;
    height: 40px;
    border: 1px solid var(--subgrey);
    border-radius: 100px;
    margin-right: 5px;
}

.projectlookup_dn {
    font-size: 14px;
}

.projectlookup_vt {
    font-size: 12px;
    color: var(--maindark);
}

.projectlookup_owner {
    /* padding: 2.5px 5px 2.5px 10px; */
    padding-top: 5px;
    color: #707070;
    font-style: italic;
}

.projectlookup_boardbtn {
    border-radius: 10px;
    font-weight: bold;
    background-color: white;
    outline: none;
    width: 100%;
    color: var(--maindark);
    box-shadow: rgba(25, 25, 25, 0.2) 1px 1px 5px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    font-weight: none;
    padding: 10px;
    margin: 10px 0px;
    flex-direction: row;
    display: flex;
    place-content: center;
    align-items: center;
}

.projectlookup_boardbtnicon {
    padding: 0px 5px;
}

.projectlookup_boardbtn:hover {
    border-radius: 10px;
    font-weight: bold;
    background-color: var(--maindark);
    outline: none;
    width: 100%;
    color: white;
    box-shadow: rgba(25, 25, 25, 0.2) 1px 1px 5px;
    border: 1px solid var(--maindark);
    padding: 10px;
    margin: 10px 0px;
}

.projectlookup_imgroot {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.projectlookup_hiddenimgroot {
    position: absolute;
    margin-left: 10000px;
    visibility: hidden;
    transition: opacity 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.projectlookup_mobimg {
    display: block;
    width: stretch;
    height: auto;
    max-width: 100%;
}

.projectlookup_imgILWEB {
    padding: 0px;
    display: block;
    margin: 0px;
    height: auto;
    margin: 0px auto;
    width: 100%;
}

.projectlookup_imgILTABLET {
    padding: 0px;
    display: block;
    margin: 0px;
    height: auto;
    margin: 0px auto;
    width: stretch;
}

.projectlookup_imgILMOBILE {
    padding: 0px;
    display: block;
    margin: 0px auto;
    width: 100%;
}



.projectlookup_imgIPWEB {
    width: 100%;
    height: 100%;
}

.projectlookup_imgIPTABLET {
    width: 100%;
    height: 100%;
}

.projectlookup_imgIPMOBILE {
    width: 100%;
    height: 100%;
}


.projectlookup_imgPWEB {
    padding: 0px;
    display: block;
    margin: 0px;
    height: auto;
    margin: 0px auto;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: auto;
    max-height: 100%;
    max-width: auto;
}

.projectlookup_imgPTABLET {
    padding: 0px;
    display: block;
    margin: 0px;
    height: auto;
    margin: 0px auto;
    height: 100vh;
    width: auto;
}

.projectlookup_imgPMOBILE {
    padding: 0px;
    display: block;
    margin: 0px;
    height: auto;
    margin: 0px auto;
    width: stretch;
    height: auto;
}

.projectlookup_spaceWEB {
    width: 100%;
    height: 10px;
}

.projectlookup_spaceTABLET {
    width: 100%;
    height: 10px;
}

.projectlookup_spaceMOBILE {
    width: 100%;
    height: 5px;
}

.projectlookup_vid {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectlookup_vidiframe {
    width: 500px;
    height: 280px;
}

.projectlookup_textMOBILE {
    width: 100%;
    height: 5%;
    display: flex;
    text-align: justify;
    padding: 15px;
    font-size: 14px;

}

.projectlookup_textTABLET {
    width: 100%;
    height: 5%;
    display: flex;
    text-align: justify;
    padding: 15px 30px 15px 30px;
    font-size: 15px;
}

.projectlookup_textWEB {
    width: 100%;
    height: 5%;
    display: flex;
    text-align: justify;
    padding: 15px 30px 15px 30px;
    font-size: 16px;
}

.projectlookup_pinbtn {
    border-radius: 10px;
    font-weight: bold;
    background-color: white;
    outline: none;
    width: 100%;
    color: #707070;
    -webkit-box-shadow: rgb(25 25 25 / 20%) 1px 1px 5px;
    box-shadow: rgb(25 25 25 / 20%) 1px 1px 5px;
    border: thin solid #f1f1f1;
    padding: 10px;
    margin: 10px 0px;
}

.projectlookup_rowimg {
    display: flex;
    flex-direction: row;
    max-width: 100%;
}