.border_menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    place-content: space-between;
}

.border_menu1 {
    /* width: 33.33%;
    display: flex;
    flex-direction: row;
    margin: 0px 10px 10px 10px; */
    width: 33.33%;
    display: flex;
    flex-direction: row;
    margin: 5px 0px 0px 0px;
}

.border_menu2 {
    width: 33.33%;
    display: flex;
    flex-direction: row;
    /* margin: 0px 10px 10px 10px; */
    margin: 5px 0px 0px 0px;
}

.border_menu3 {
    width: 33.33%;
    display: flex;
    flex-direction: row;
    /* margin: 0px 10px 10px 10px; */
    margin: 5px 0px 0px 0px;
}

.border_iconbr {
    width: 28px;
    height: 28px;
    border: 1px solid black;
    margin: 0px 5px 0px 0px;
    border-radius: 10px;
    cursor: pointer;
}

.border_noiconbr {
    width: 28px;
    height: 28px;
    border: 1px solid black;
    margin: 0px 5px 0px 0px;
    cursor: pointer;
}

.border_styleselected {
    border: 2px solid rgb(235, 93, 123)
}

.borderitem_root {
    width: 100%;
    height: 100%;
    position: relative;
}

.borderitem_bg {
    background-color: #707070;
}

.borderitem1_common {
    border: 5px solid #c8c09e;
    margin: 5px;
    position: absolute;
}

/* 1 */
.borderitem1_1 {
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}

/* 2 */
.borderitem2_1 {
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}

.borderitem2_2 {
    margin: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
    top: 0;
}

/* 3 */
.borderitem4_1 {
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-right: none;
    border-bottom: none;
}

.borderitem4_2 {
    margin: 25px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 35px);
    position: absolute;
    top: 0;
}

.borderitem4_3 {
    margin: 25px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 35px);
    position: absolute;
    top: -15px;
}

.borderitem4_4 {
    margin: 20px;
    width: calc(100% - 45px);
    height: calc(100% - 20px);
    position: absolute;
    top: -10px;
    left: 2.5px;
}

/* 4 */
.borderitem3_1 {
    margin: 10px 22.5px;
    width: calc(100% - 45px);
    height: calc(100% - 20px);
}

.borderitem3_2 {
    margin: 22.5px 10px;
    width: calc(100% - 20px);
    height: calc(100% - 45px);
    position: absolute;
    top: 0;
}

/* 5 */
.borderitem5_1 {
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(45.5% - 2.5px);
    position: absolute;
}

.borderitem5_2 {
    margin: 10px;
    width: calc(45.5% - 2.5px);
    height: calc(100% - 20px);
    position: absolute;
}

/* 6 */
.borderitem6_1 {
    width: calc(100% - 20px);
    height: calc(33.33% - 5.5px);
    top: 5px;
    left: 5px;
}

.borderitem6_2 {
    width: calc(33.33% - 5.5px);
    height: calc(100% - 20px);
    top: 5px;
    left: 5px;
}

.borderitem6_3 {
    width: calc(66.66% - 12.5px);
    height: calc(66.66% - 12.5px);
    top: 5px;
    left: 5px;
}

.border_brthickthin {
    width: 28px;
    height: 28px;
    margin: 0px 5px 0px 0px;
    cursor: pointer;
    display: flex;
    place-content: center;
    align-items: center;
    padding: 5px;
}

.border_brthickcontent {
    width: 28px;
    border-top: 5px solid black;
}

.border_brthincontent {
    width: 28px;
    border-top: 1px solid black;
}

.border_br {
    border-radius: 2vmin;
}

.border_nobr {
    border-radius: 0;
}