.inviteedit_helproot{
    padding: 0px 10px 20px 10px;
}

.inviteedit_helptitle{
    margin: 0px 0px 15px 0px !important;
    padding: 0px 0px 5px 0px !important;
    font-size: 18px;
    text-align: center;
}

.ie_mse_mobile{
    display: flex;
    flex-direction: row;
    height: max-content;
    min-height: 60px;
    align-items: center;
    font-size: 14px;
    z-index: 3;
    user-select: none;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(200 200 200 / 24%);
}
.ie_modaltext{
    text-align:left;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 10px;
}
.ie_num{
    padding-right: 10px;
}

.ie_singlesm{
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 10px;
    background-color: white;
    border-right: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid var(--maindark);
}
.ie_sm{
    height: 40px;
    width: 40px;
    padding: 10px;
    background-color: white;
    border:1px solid var(--maindark);
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
}
.ie_md{
    height: 40px;
    width: 40px;
    padding: 10px;
    background-color: white;
    border: 1px solid var(--maindark);
    border-right: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
}
.ie_lg{
    height: 40px;
    width: 40px;
    padding: 10px;
    background-color: white;
    border: 1px solid var(--maindark);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
}
.ie_xlg{
    height: 40px;
    width: 40px;
    padding: 10px;
    background-color: white;
    border: 1px solid var(--maindark);
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
}
.ie_xxlg{
    height: 40px;
    width: 40px;
    padding: 10px;
    background-color: white;
    border: 1px solid var(--maindark);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
}
/* 
.Iedittopbarbtn0{
    height: 60px;
    border-radius: 0px;
    padding: 0px 10px;
    white-space: pre;
    font-family: Lato;
    font-size: 15px;
    display: flex;
    align-items: center;
    place-content: center;
    text-align: center;
    background-color: white;
    border: none;
    border-right: 1px solid #dddddd;
}
.Iedittopbarbtn1{
    height: 60px;
    border-radius: 0px;
    padding: 0px 10px;
    white-space: pre;
    font-family: Lato;
    font-size: 15px;
    display: flex;
    align-items: center;
    place-content: center;
    text-align: center;
    color: white;
    border: none;
    border-right: 1px solid #dddddd;
    background-color: #eb5d7b;
} */
.Iedittopbarbtn{
    height: 60px;
    border-radius: 0px;
    white-space: pre;
    font-family: Lato;
    font-size: 14px;
    display: flex;
    align-items: center;
    place-content: center;
    text-align: center;
    background-color: white;
    border: none;
    border-right: 1px solid #dddddd;
}
.Iedittopbarbtn:hover{
    background-color: var(--hoverpink);
}
.Iedittopbarbtnselected{
    background-color: var(--mainpink);
    color: white;
    height: 60px;
    border-radius: 0px;
    white-space: pre;
    font-family: Lato;
    font-size: 14px;
    display: flex;
    align-items: center;
    place-content: center;
    text-align: center;
    border: none;
    border-right: 1px solid #dddddd;
}

.Iedit_subtitle{
    font-size: 17px;
    color: #333333;
    text-align: start;
}
.Iedit_inputlabel{
    font-weight: bold;
    width: 100%;
    font-size: 14px;
    margin-bottom: 2px;
}
.Iedit_input{
    height: 40px;
    /* border-radius: 5px; */
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    padding: 5px;
    margin-right: 5px;
    width: 100%;
    margin-bottom: 5px;
    outline: none;
}
.Iedit_topinput{
    margin-bottom: 0px;
    margin-right: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.Iedit_midinput{
    border-radius: 0px;
    border: 1px solid #ccc;
    border-bottom: none;
    margin-bottom: 0px;
}
.Iedit_btminput{
    margin-top:0px;
    margin-bottom:0px;
    margin-right: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.Iedit_adddecroot{
    display: flex;
    flex-direction: row;
    align-items: center;
    place-content: space-between;
}
.Iedit_twoinputstopdown{
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    align-items: center;
    height: 130px;
}
.Iedit_threeinputs{
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    align-items: center;
    height: 135px;
}
.Iedit_twoinputs{
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    align-items: center;
    height: 95px;
}
.iepreview_btn{
    border-radius: 5px;
    font-weight: bold;
    border: thin solid #eb5d7b;
    color: #eb5d7b;
    background-color: white;
    outline: none;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    text-decoration: none !important;
    padding: 0px 10px;
    font-size: 15px;
    fill: #eb5d7b;
    font-weight: normal;
    margin: 10px 0px;
}
.iepreview_btn:hover{
    border: thin solid #eb5d7b;
    background-color: #eb5d7b;
    color: white;
    fill: white;
}
#iepreview_btninvisible{
    width:0px;
    height: 0px;
    position: absolute
}
.iesave_btnWEB{
    margin: 0px 0px 0px 5px;
}
.iesave_btnTABLET{
    margin: 0px 0px 0px 5px;
}
.iesave_btnMOBILE{
    margin: 0px 10px;
}
.iecommon_btn{
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    align-items: center;
    box-shadow: #19191940 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 15px;
    align-items: center;
    text-align: center;
    place-content: center;
}
.ietypequestionmark{
    display: flex;
    padding: 5px;
    text-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: var(--maindark);
    color: white;
    border-radius: 30px;
    place-content: center;
    cursor: pointer;
    margin-left: 5px;
}
.ietypeselecterroot{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ie_previewbtn_root{
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0px;
    margin-top: 60px;
    height: 60px;
    place-content: center;
    align-items: center;
    top: 0px;
    right:10px;
}
.Iedittopbarleftmenu_root{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: 60px;
    max-height: 60px;
    align-items: center;
    place-content: space-between;
    padding: 0px 10px;
    z-index: 2;
    color: #333333;
    background-color: white;
    width: 100%;
}
.Iedittopbarleftmenubtn_rootWEB{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    overflow: auto;
    max-height: 60px;
    overflow-y: hidden;
}
.Iedittopbarleftmenubtn_rootTABLET{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    overflow: auto;
    max-height: 60px;
    overflow-y: hidden;
}
.Iedittopbarleftmenubtn_rootMOBILE{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
    overflow: auto;
}
.Iedittopbar_root{
    display: flex;
    flex-direction: row;
    height: 100%;
}

.invisiblesavebtn{
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: transparent;
    outline: none;
    color: transparent;
    align-items: center;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 15px;
    margin-right: 5px;
}