.QRcodediv_root{
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
    touch-action: manipulation;
    height: min-content;
    overflow-y: auto;
}
.QRcodediv_contentroot{
    padding: 10px 0px;
    width: 100%;
    place-content: center;
    display: flex;
    border: 2px solid rgb(218, 205, 179);
    background-color: rgb(255, 251, 243);
    place-items: center;
    border-radius: 10px;
    flex-direction: column;
    width: 100%;
}
.Qrcodediv_row{
    display: flex;
    flex-direction: row;
    place-self: center;
    width: 100%;
}
.Qrcodediv_btn{
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    place-self: center;
    border: thin solid black;
    border-radius: 10px;
    margin: 10px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    align-items: center;
}
.Ieditcomp_qr{
    margin-right: 0px;
    height: 44px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
    color: var(--maindark);
    font-size: 16px;
    /* margin-top: 10px; */
    width: max-content;
    padding: 0px 10px;
    border-radius: 5px;
    place-self: flex-end;
    flex-direction: row;
    display: flex;
    align-items: center;
}