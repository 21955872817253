.UFav_root {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    /* position: fixed; */
    /* top: 35px; */
    z-index: 1;
    font-family: Lato;
}



@media (min-width:0px) {}

@media (min-width:320px) {}

@media (min-width:481px) {
    .UFav_root {
        position: fixed;
        top: 35px;
    }

}

@media (min-width:767px) {}

@media (min-width:961px) {}

@media (min-width:1025px) {}

@media (min-width:1281px) {}