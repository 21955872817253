.Palette_root{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex-flow: row wrap;
    width:100%;
}
.Palette_subroot{
    display: flex;
    flex-direction: row;
    width: calc(100% - 36.66px);
    overflow-x: auto;
}
.Palette_circleroot{
    display:flex;
    padding:3px;
    border-radius: 50px;
    margin:2px;
    cursor: pointer;
}
.Palette_addbtn{
    border: 1px dashed #707070;
    padding: 5px;
    width: 36.66px;
    height: 36.66px;
    cursor: pointer;
    background-color: white;
    display: flex;
    place-content: center;
    align-items: center;
    border-radius: 100px;
}
.Palette_col{
    display:flex;
    flex-direction: column;
    width:100%;
    margin-top: 10px;
}
.Palette_col>input{
    height:34px;
}

@media (min-width:320px)  { 
    .Palette_div{
        border-radius: 100%;
        min-height: 30px;
        min-width: 30px ;
        border:1px solid #8b8b8b;
    }
}
@media (min-width:481px)  { 
    .Palette_div{
        border-radius: 100%;
        min-height: 30px;
        min-width: 30px ;
        border:1px solid #8b8b8b;
    }
 }
@media (min-width:641px)  {
    .Palette_div{
        border-radius: 100%;
        min-height:  36.66px;
        min-width:  36.66px;
        border:1px solid #8b8b8b;
    }
}
@media (min-width:961px)  {
    .Palette_div{
        border-radius: 100%;
        min-height:  36.66px;
        min-width:  36.66px;
        border:1px solid #8b8b8b;
    }
}
@media (min-width:1025px) { 
    .Palette_div{
        border-radius: 100%;
        min-height:  36.66px;
        min-width:  36.66px;
        border:1px solid #8b8b8b;
    }
}
@media (min-width:1281px) { 
    .Palette_div{
        border-radius: 100%;
        min-height:  36.66px;
        min-width:  36.66px;
        border:1px solid #8b8b8b;
    }
}
