:root {
    --mainpink: #eb5d7b;
    --weakermainpink:#e68498;
    --skyblue : #5dd0eb;
    --subpink:#eb5d7b0d;
    --hoverpink: #fff1f4;
    --maindark: #333333;
    --transparentmaindark: #ffffffb5;
    --subdark: #7c7c7c;
    --subgrey: #cbcbcb;
    --lightdark: #e5e5e5;
    --donegreen: #4aac47;
    --firmgreen: #008000;
}

.app_navroot {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    display: flex;
    z-index: 1;
    background-color: var(--maindark);
    place-content: space-between;
}

.app_navright {
    display: flex;
    flex-direction: row;
}

.app_navbaricon {
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: none;
}

.Portalinnerelem {
    height: 45px;
    width: calc(100% - 20px);
    margin: 0px 10px 0px 10px;
    padding: 10px;
    /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 6px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px; */
    background-color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    place-content: center;
    border: 1px solid var(--subgrey);
    border-bottom: none;
}

.Portalinnerelem:hover {
    transition: all 0.2s ease 0s;
    background-color: var(--hoverpink);
}

.Portalinnerelem:last-child {
    border-bottom: 1px solid var(--subgrey) !important;
}

.Portalinnerelem_selected {
    height: 45px;
    width: calc(100% - 20px);
    margin: 0px 10px 0px 10px;
    padding: 10px;
    /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 6px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px; */
    background-color: var(--mainpink);
    color: white;
    display: flex;
    align-items: center;
    position: relative;
    place-content: center;
}

.MainselectionbgWedding {
    top: 0px;
    position: absolute;
    width: 140%;
    height: 130%;
    margin-left: -15%;
    margin-top: -5%;
    opacity: 0.2;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    text-decoration: underline #eb5d7b12;
}

.MainselectionbgEvent {
    top: 0px;
    position: absolute;
    width: 140%;
    height: 130%;
    margin-left: -20%;
    opacity: 0.2;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: contain;
    text-decoration: underline #eb5d7b12;
}

.App_linksroot{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.App_linksroot{

}

.AppNavItemM {
    text-decoration: none !important;
    min-height: max-content !important;
    color: var(--maindark) !important;
    display: flex !important;
    padding: 15px !important;
    justify-items: flex-start !important;
    /* font-size: 1.3em; */
    font-size: 18px;
    place-content: flex-start !important;
    color: var(--maindark) !important;
}
.AppNavItemM:hover{
    color: var(--mainpink) !important;
}

.SELECTED_MSB_vtbtn {
    height: 36px;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 15px;
    white-space: nowrap;
    margin: 5px;
    color: white !important;
    border: 1px solid transparent !important;
    background-color: #eb5d7b !important;
    position: relative;
    align-items: center;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    letter-spacing: 1px;
    white-space: pre-wrap;
}

.MSB_vtbtn {
    height: 36px;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 15px;
    white-space: nowrap;
    margin: 5px;
    color: var(--maindark) !important;
    border: 1px solid transparent;
    background-color: white !important;
    position: relative;
    align-items: center;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    letter-spacing: 1px;
    user-select: none;
}

.MSB_vtbtn:hover {
    background-color: #eb5d7c2f !important;
    color: var(--maindark) !important;
    height: 36px;
    border: 1px solid white !important;
    user-select: none;
}

.Portalmodal_vtroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 60px;
}

.Portalmodal_msbroot {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    place-content: flex-start;
    padding: 0px 10px;
    /* height: 500px; */
    height: 100%;
    justify-content: center;
}

.navbar_weavetxtroot {
    font-weight: bold;
    text-decoration: none;
    height: 100%;
}

.navbar_weavetxtroot:hover {
    text-decoration: none;
}

input[type='checkbox']:checked {
    filter: grayscale(1);
    margin: 0px;
    width: 15px;
    height: 15px;
}

input[type=checkbox] {
    margin: 0px;
    width: 15px;
    height: 15px;
}

input[type=checkbox]:hover {
    filter: grayscale(1);
    margin: 0px;
    width: 15px;
    height: 15px;
}

input[type=checkbox]:checked ::before {
    color: #ff0000; /* Change the color to your preferred value */
}

.react-datepicker__header {
    background-color: white;
    touch-action: manipulation;
}

.react-datepicker__current-month {
    color: #333333;
}

.leaflet-control-zoom-in {
    width: 27px !important;
    height: 27px !important;
    line-height: 0px !important;
    display: flex !important;
    align-items: center !important;
    place-content: center !important;

}

.leaflet-control-zoom-out {
    width: 27px !important;
    height: 27px !important;
    line-height: 0px !important;
    display: flex !important;
    align-items: center !important;
    place-content: center !important;
}

/* 
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}  */

.navtopbaritem{
    display: flex;
    align-items: center;
    outline: 0px;
    height: 35px;
    padding: 0px 10px;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 1000ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.navtopbaritem:hover{
    background-color: var(--mainpink);
    color: white;
}

textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus {   
    border-color: none;
    box-shadow: none;
    outline: none;
}