#Userboard_root {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    z-index: 1;
    font-family: Lato;
}

#Userboard_sidebar {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid var(--lightdark);
    height: 100%;
}

@media (min-width:0px) {}

@media (min-width:320px) {}

@media (min-width:481px) {
    #Userboard_root {
        position: fixed;
        top: 35px;
    }

    #Userboard_sidebar {
        width: 200px;
    }

}

@media (min-width:767px) {}

@media (min-width:961px) {}

@media (min-width:1025px) {
    #Userboard_sidebar {
        width: 250px;
    }

}

@media (min-width:1281px) {}

#Userboard_viewmorevendorbtn{
    display: flex;
    flex-direction: row;
    border: 1px solid var(--subdark);
    border-radius: 5px;
    padding: 0px 10px;
    margin: 5px 0px 5px 5px;
    background-color: white;
    text-align: center;
    place-content: center;
    align-items: center;
}


#Userboard_grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, max-content));
    /* justify-content: center; */
    width: 100%;
    padding: 0px;
    margin: 0px;  
    overflow-y: auto; 
    padding-bottom: 50px; 
    place-content: flex-start;
}

#Userboard_viewmoreroot{
    display: flex;
    flex-direction: row;
    place-self: flex-start;
    width: 100%;
    max-height: 55px;
    min-height: 55px;
    border-bottom: 1px solid var(--lightdark);
}

.Userboard_trash{
    position: absolute;
    border-radius: 100px;
    height: 25px;
    width: 25px;
    place-content: center;
    display: flex;
    padding: 0px;
    margin: 10px 3px 0px 0px;
    background-color: white;
    border: 1px solid var(--mainpink);
    align-items: center;
    right: 0;
}