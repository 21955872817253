#Ieditcomp_root {
    display: flex;
    touch-action: manipulation;
    overflow: hidden;
    color: #333333;
    width: 100%;
    /* overflow-y: auto; */
    height: calc(100% - 120px);
}

#Ieditcomp_contentroot {
    width: 100%;
    align-items: center;
    position: relative;
}

#Ieditcomp_weddinggridroot1 {
    position: relative;
    display: grid;
    overflow-y: hidden;
    width: 100%;
    align-content: flex-start;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
    padding: 30px 0px 0px 0px;
    overflow-x: hidden;
}


#Ieditcomp_weddinggridroot {
    position: relative;
    display: grid;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    align-content: flex-start;
    justify-content: center;
    /* place-content: flex-start; */
}

.Ieditcomp_div {
    display: flex;
    flex-direction: column;
    touch-action: manipulation;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 70px);
}

.Ieditcomp_sq {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    border-radius: 10px;
    transition: box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    cursor: pointer;
    place-self: center;
    position: relative;
}

.Ieditcomp_sq:hover {
    /* box-shadow: 0 1px 17px 3px #40404014; */
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    ;
}

.Ieditcomp_sqtop {
    padding: 7px 0px;
}

.Ieditcomp_iconcss {
    user-select: none;
    pointer-events: none;
    vertical-align: middle;
    fill: currentcolor;
    overflow: hidden;
}

.iedit_swaproot {
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
    left: 0;
    place-content: center;
    align-items: center;
    background-color: white;
    border-top: 1px solid #dddddd;
    height: 65px;
    padding: 10px;
    z-index:2;
}

.iedit_cancel {
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    place-content: center;
    background-color: white;
    border: 1px solid var(--mainpink);
    border-right: none;
    font-size: 18px;
    color: var(--mainpink);
    width: 50%;
}

.iedit_save {
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    place-content: center;
    width: 50%;
    border: 1px solid var(--mainpink);
    background-color: var(--mainpink);
    color: #ffffff;
    font-size: 18px;
}
.iedit_add{
    border-radius: 10px;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    place-content: center;
    width: 100%;
    background-color: #ffffff;
    color: var(--maindark);
    font-size: 18px;
    border: 1px solid transparent;
}
/* .iedit_add{
    border-radius: 10px;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    place-content: center;
    width: 100%;
    border: 1px solid var(--mainpink);
    background-color: var(--mainpink);
    color: #ffffff;
    font-size: 18px;
} */

@media (min-width:0px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 120px;
        width: 120px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
}

@media (min-width:400px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(130px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 130px;
        width: 130px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
}

@media (min-width:500px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(135px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 135px;
        width: 135px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
}

@media (min-width:600px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 150px;
        width: 150px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
    
}


@media (min-width:700px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 150px;
        width: 150px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:800px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(165px, max-content));
        padding: 30px 0px 85px 0px;
        grid-gap: 10px;
    }

    .Ieditcomp_sq {
        height: 165px;
        width: 165px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 35px;
        height: 35px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:961px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(132px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 132px;
        width: 132px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
        padding: 0px 13px;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 10px 10px 60px;
    }
    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1025px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(135px, max-content));
        padding: 30px 0px 50px 0px;
    }

    .Ieditcomp_sq {
        height: 135px;
        width: 135px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 90%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }
    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1100px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
        padding: 30px 0px;
    }

    .Ieditcomp_sq {
        height: 150px;
        width: 150px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        white-space: pre-wrap;
        display: flex;
        word-wrap: break-word;
        width: 100%;
        height: 40px;
        text-align: center;
        align-items: center;
        place-content: center;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }

    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1200px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(160px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 160px;
        width: 160px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }


    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1281px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(165px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 165px;
        width: 165px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        height: max-content;
        margin-top: 5px;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }


    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1300px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(165px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 165px;
        width: 165px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        height: max-content;
        margin-top: 5px;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }

    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1320px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: repeat(auto-fit, minmax(165px, max-content));
        padding: 30px 0px 85px 0px;
    }

    .Ieditcomp_sq {
        height: 165px;
        width: 165px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        height: max-content;
        margin-top: 5px;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }

    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1350px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        padding: 30px 10px 100px 10px;
    }

    .Ieditcomp_sq {
        height: 150px;
        width: 150px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        height: max-content;
        margin-top: 5px;
    }

    .Ieditcomp_iconcss {
        width: 40px;
        height: 40px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }


    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1400px) {
    #Ieditcomp_weddinggridroot {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        padding: 30px 10px 100px 10px;
    }

    .Ieditcomp_sq {
        height: 155px;
        width: 155px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        height: max-content;
        margin-top: 5px;
    }

    .Ieditcomp_iconcss {
        width: 43px;
        height: 43px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }


    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }

}

@media (min-width:1450px) {
    #Ieditcomp_weddinggridroot {
        height: max-content;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        padding: 30px 10px 100px 10px;
    }

    .Ieditcomp_sq {
        height: 160px;
        width: 160px;
    }

    .Ieditcomp_sqbtm {
        font-size: 14px;
        height: max-content;
        margin-top: 5px;
    }

    .Ieditcomp_iconcss {
        width: 43px;
        height: 43px;
        margin: 4px;
    }

    .Ieditcomp_div {
        padding: 20px 20px 60px;
    }


    .iedit_cancel {
        width: 50%;
    }

    .iedit_save {
        width: 50%;
    }


}