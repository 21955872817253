.scedit_root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    max-width: 100vw;
}

.scedit_btn {
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    align-items: center;
    box-shadow: rgba(25, 25, 25, 0.25) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 32px;
}

.scedit_importbtn {
    padding: 5px;
    margin: 0px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    align-items: center;
    box-shadow: rgba(25, 25, 25, 0.25) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 32px;
    min-width: 50px;
    justify-content: center;
}

.scedit_savingbtn {
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    box-shadow: #19191940 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 15px;
    transition: box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    margin-right: 5px;
    width: 75px;
    align-items: center;
    place-content: center;
    line-height: 1;
}

.scedit_exportbtn {
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    box-shadow: #19191940 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 15px;
    width: 75px;
    align-items: center;
    place-content: center;
    line-height: 1;
    font-weight: normal;
}

.scedit_importbtn {
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    box-shadow: #19191940 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 15px;
    width: 75px;
    align-items: center;
    place-content: center;
    line-height: 1;
    font-weight: normal;
}

.scedit_qrbtn {
    padding: 0px 5px;
    border-radius: 5px;
    border: 1px solid var(--subgrey);
    background-color: white;
    outline: none;
    color: var(--maindark);
    flex-direction: row;
    display: flex;
    font-size: 15px;
    transition: box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    margin-right: 5px;
    min-width: 40px !important;
    min-height: 40px !important;
    align-items: center;
    place-content: center;
    line-height: 1;
}

.scedit_csvreader {
    flex-wrap: wrap;
    font-size: 12px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    border: thin solid transparent;
    border-radius: 5px;
    margin: 5px;
    background-color: white;
    position: relative;
    align-self: center;
    padding: 0px
}


.scedit_arrowleft {
    background-color: white;
    border: thin solid transparent;
    padding: 0px;
    margin: 0px;
}

.scedit_etypeandhelp {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scedit_help {
    display: flex;
    padding: 5px;
    text-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #707070;
    color: white;
    border-radius: 30px;
    place-content: center;
    cursor: pointer;
}

.scedit_bottom {
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    top: 0px;
    overflow-x: hidden;
}

.scedit_dlimportsave {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scedit_dlimportsavehidden {
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0;
    left: 10;
    user-select: none;
    pointer-events: none;
}

.scedit_etypebtn {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid transparent;
    background-color: white;
    outline: none;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    box-shadow: rgba(25, 25, 25, 0.25) 0px 2px 3px;
    color: #707070;
    margin: 3px;
    align-items: center;
    flex-direction: row;
    display: flex;
}

.scedit_right {
    width: calc(100vw - 270px);
    margin-left: 270px;
    padding-top: 61px;
    font-family: Lato;
    overflow: hidden;
    display: flex;
}

.scedit_rightbtnroot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    right: 0;
    z-index: 2;
    width: calc(100% - 270px);
}

.scedit_rightmode {
    background-color: #eb5d7b;
    border: 1px solid #eb5d7b;
    color: white;
    display: flex;
    place-self: center;
    border-radius: 5px;
    padding: 0px 10px;
    color: white;
    height: 40px;
    text-align: center;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    margin: 10px;
    font-size: 16px;
}

.scedit_btnrow {
    display: flex;
    flex-direction: row;
}

.scedit_designroot {
    position: relative;
    padding: 71px 10px 100px 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}


/* .scedit_righttableroot {
    position: relative;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    top: 60px;
    margin: 10px;
} */

/* .scedit_righttableroot {
    position: relative;
    padding: 51px 10px 100px 10px;
    display: flex;
    flex-flow: row wrap;
} */

/* @media (min-width:641px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        top: 60px;
    }
} */

@media (min-width:750px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: calc(50% - 8.32px) calc(50% - 8.32px);
        top: 60px;
        margin: 10px;
        grid-gap: 15px;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 130px);
        padding-bottom: 210px;
    }
}

@media (min-width:1025px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: calc(33.33% - 11.32px) calc(33.33% - 11.32px) calc(33.33% - 10.32px);
        top: 60px;
        margin: 10px;
        grid-gap: 15px;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 130px);
        padding-bottom: 210px;
    }
}

@media (min-width:1121px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: calc(33.33% - 11.32px) calc(33.33% - 11.32px) calc(33.33% - 10.32px);
        top: 60px;
        margin: 10px;
        grid-gap: 15px;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 130px);
        padding-bottom: 210px;
    }
}

@media (min-width:1169px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: calc(33.33% - 11.32px) calc(33.33% - 11.32px) calc(33.33% - 10.32px);
        top: 60px;
        margin: 10px;
        grid-gap: 15px;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 130px);
        padding-bottom: 210px;
    }
}

@media (min-width:1217px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: calc(33.33% - 11.32px) calc(33.33% - 11.32px) calc(33.33% - 10.32px);
        top: 60px;
        margin: 10px;
        grid-gap: 15px;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 130px);
        padding-bottom: 100px;
    }
}

@media (min-width:1217px) {
    .scedit_righttableroot {
        position: relative;
        display: grid;
        grid-template-columns: calc(25% - 11.5px) calc(25% - 11.5px) calc(25% - 11.5px) calc(25% - 11.5px);
        top: 60px;
        margin: 10px;
        grid-gap: 15px;
        overflow: auto;
        width: 100%;
        height: calc(100vh - 130px);
        padding-bottom: 210px;
    }
}

.scedit_table {
    position: relative;
    display: inline-table;
    height: auto;
    flex-direction: column;
    height: auto;
    background-color: white;
    border-radius: 5px;
    min-height: 320px;
    height: min-content;
    box-shadow: '#00000021 0px 2px 6px 0px';
}

.scedit_singleseatedroot {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.scedit_droppable>div {
    width: 100%;
}

.scedit_righttext {
    display: flex;
    place-self: center;
    border-radius: 5px;
    padding: 0px 10px;
    background-color: #333333;
    margin: 10px 5px 10px 0px;
    color: white;
    height: 40px;
    text-align: center;
    align-items: center;
    line-height: 1;
    width: 75px;
    flex-direction: column;
    place-content: center;
}

.scedit_icon {
    border-radius: 2px;
    width: max-content;
    padding: 5px;
    height: 20px;
    align-items: center;
    align-self: flex-end;
    margin: 5px;
    place-content: center;
    display: flex;
    color: #707070;
    cursor: pointer;
    place-self: flex-end;
}

.scedit_iconput {
    border-radius: 2px;
    width: max-content;
    padding: 5px;
    height: 20px;
    align-items: center;
    align-self: flex-end;
    margin: 5px;
    place-content: center;
    display: flex;
    color: #707070;
    cursor: pointer;
    place-self: center;
    font-weight: bold;
    color: #0094ff;

}

.scedit_iconhide {
    visibility: hidden;
    color: transparent;
}

.scedit_leftroot {
    background-color: white;
    border-right: thin solid rgb(221, 221, 221);
    position: fixed;
    width: 270px;
    height: calc(100% - 62px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    bottom: 0px;
    padding-left: 6px;
}

.scedit_helproot {
    padding: 10px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    min-width: 500px;
}

.scedit_modalroot {
    /* padding: 10px; */
    padding-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: relative;
}

.scedit_gmodal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff5e2;
    padding: 10px;
    box-shadow: #19191940 0px 2px 3px;
    height: 150px;
    max-height: 150px;
    min-height: 150px;
    border: 2px solid #707070;
    border-radius: 10px;
    margin-bottom: 20px;
}

.scedit_troot {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 15px 10px;
    /* height: 78px;
    max-height: 78px;
    min-height: 78px; */
    border: 1px solid var(--maindark);
    border-radius: 5px;
    margin: 0px 0px 10px 0px;
}

.scedit_catroot {
    position: relative;
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
}

.scedit_selectroot {
    position: absolute;
    top: 0px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    width: 5%;
    background-color: transparent;
    height: 100%;
    border: none;
    left: 94%;
}

.scedit_ddbtn {
    height: 100%;
    background-color: transparent;
    border: 0px solid transparent;
    outline: none;
    padding: 0px 0px 0px 10px;
    place-self: center;
}

.scedit_gstripleft {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    margin: 0px 0px 10px 0px;
    /* box-shadow: rgb(0 0 0 / 15%) 1px 1px 6px 0px; */
    border: 1px solid var(--subgrey);
    background-color: white;
    border-radius: 5px;
    width: 235px;
}

.scedit_gstripright {
    display: flex;
    flex-direction: row;
    padding: 10px 5px;
    margin: 0px 0px 10px 0px;
    /* box-shadow: rgb(0 0 0 / 15%) 1px 1px 6px 0px; */
    border: 1px solid var(--subgrey);
    background-color: white;
    border-radius: 5px;
    width: calc(100% - 40px);
    font-family: Lato;
}

.scedit_modeoneroot {
    display: flex;
    flex-direction: column;
}

.scedit_modeonename {
    font-weight: bold;
}

.scedit_modeonerela {
    color: grey;
}

.scedit_modeonecat {
    color: grey;
}

.scedit_guestrow {
    display: flex;
    flex-direction: row;

}

.scedit_guestindex {
    width: 25px;
    height: 30px;
    display: flex;
    padding: 0px 5px 10px 0px;
    text-align: center;
    align-items: center;
    color: silver;
    place-content: center;
    place-self: center;
    align-items: center;
}

.scedit_selectedguestindex {
    width: 25px;
    height: 30px;
    display: flex;
    padding: 0px 5px 0px 0px;
    text-align: center;
    align-items: center;
    color: #0094ff;
    font-weight: bold;
    place-content: center;
    place-self: center;
    align-items: center;
}

.scedit_singleseatednum {
    width: 25px;
    height: 30px;
    color: silver;
    padding: 0px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    place-content: center;
    place-self: center;
    align-items: center;
    margin:0px 2.5px 10px 2.5px;
}

.scedit_singleselectedseatednum {
    width: 25px;
    height: 30px;
    color: #0094ff;
    padding: 0px 5px 0px 0px;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    place-content: center;
    place-self: center;
    align-items: center;
}

.scedit_modalbtn {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--maindark);
    background-color: white;
    outline: none;
    width: 100%;
    color: var(--maindark);
    margin: 10px 0px;
    font-size: 16px;
    height: 40px;
    align-items: center;
    display: flex;
    place-content: center;
}

.scedit_props {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 60px 10px 0px 10px;
}

.scedit_name {
    display: flex;
    font-weight: bold;
    font-size: 16px;
}

.scedit_txtroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
}

.scedit_ffroot {
    padding: 5px;
    width: 100%;
    margin: 0px 5px 0px 5px;
    display: flex;
    height: 32px;
    min-height: 32px;
}

.scedit_row {
    display: flex;
    flex-direction: row;
    width: calc(100% - 5px);
}

.scedit_txtarea {
    display: flex;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    outline: none;
    border: thin dashed #707070;
    width: calc(100% - 12px);
    height: calc(120px - 17px);
    margin: 5px;
    resize: none;
    height: 150px;
}

.scedit_imgroot {
    width: 100%;
    min-height: max-content;
    border: thin solid #707070;
    border-radius: 5px;
    padding: 5px;
    flex-direction: column;
    display: flex;
}

.scedit_imgtop {
    width: calc(100% - 12px);
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    user-select: none;
    border: thin dashed #707070;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
}

.scedit_imgright {
    width: 49%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1);
    user-select: none;
    border: thin dashed #707070;
    border-radius: 5px;
}

.scedit_imgs {
    display: flex;
    flex-direction: row;
}

.scedit_cornerleft {
    border: thin dashed #707070;
    width: 150px;
    height: 150px;
    margin: 5px 0px 5px 5px;
    padding: 5px;
    border-radius: 5px;
    background-size: contain;
    background-repeat: no-repeat;
}

.scedit_cornerright {
    border: thin dashed #707070;
    width: 150px;
    height: 150px;
    margin: 5px 5px 5px 0px;
    padding: 5px;
    border-radius: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg) scaleY(-1);
}

.scedit_btncol{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: 30px;
    padding: 10px;
    background-color: #ffcaca59;
    border-radius: 10px;
}

.scedit_portalbtn{
    height: 40px;
    align-items: center;
    display: flex;
    place-content: center;
    border: 1px solid var(--mainpink);
    color: var(--mainpink);
    background-color: #ffffff;
}

.scedit_btntop{
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
}

.scedit_btnbtm{
    border-radius: 0px 0px 10px 10px;
}

.scedit_addtablebtn {
    width: 60px;
    min-width: 60px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 5px;
    outline: none;
    background-color: #eb5d7b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: thin solid transparent;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    margin: 30px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    user-select: none;
    z-index: 1;
}

.scedit_plus {
    font-size: 20px !important;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: bold !important;
    font-family: Lato;
}

.scedit_addbtn {
    width: 60px;
    min-width: 60px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 5px;
    outline: none;
    background-color: #eb5d7b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: thin solid transparent;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    margin: 30px;
    position: fixed;
    bottom: 0px;
    user-select: none;
    z-index: 99999;
}

.scedit_selectorrow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.scedit_selecter {
    display: flex;
    flex-direction: row;
    background-color: #efeff0;
    border-radius: 5px;
    align-items: center;
    height: 40px;
    padding: 1px;
    width: max-content;
}

.scedit_qnmark {
    display: flex;
    padding: 5px;
    text-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: var(--maindark);
    color: white;
    border-radius: 30px;
    place-content: center;
    cursor: pointer;
    margin-left: 5px;
}

.scedit_topmenubar {
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(57, 76, 96, 0.15);
    flex-direction: row;
    display: flex;
    position: fixed;
    z-index: 1;
    place-content: space-between;
    width: calc(100% - 270px);
    align-items: center;
}

.scedit_preview {
    border-radius: 5px;
    border: thin solid #eb5d7b;
    color: #eb5d7b;
    background-color: white;
    outline: none;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    text-decoration: none !important;
    padding: 0px 10px;
    font-size: 15px;
    fill: #eb5d7b;
    font-weight: normal;
    margin: 10px 10px 10px 0px;
    font-family: Lato;
}

.scedit_preview:hover {
    border: thin solid #eb5d7b;
    background-color: #eb5d7b;
    color: white;
    fill: white;
}

.scedit_csvdownloaderbtn {
    width: 0px;
}

.scedit_csvdownloaderbtn:hover {
    text-decoration: none;
}

.scedit_savebtn {
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    align-items: center;
    box-shadow: #19191940 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 15px;
}

.scedit_saveroot{
    position: absolute;
    bottom: 0;
    width:100%;
    height: 80px;
    place-content: center;
    display: flex;
    align-items: center;
    background-color: var(--maindark);
    z-index: 2;
}