.Up_root{
    flex-direction: column;
    width: 100%;
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: manipulation;
    font-family: Lato;
}

.Up_subrootMOBILE{
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100%;
}

.Up_subrootTABLET{
    display:flex;
    flex-direction:column;
    align-items:center;
}

.Up_subrootWEB{
    display:flex;
    flex-direction:column;
    align-items:center;
}
.Up_containertop{
    width: 100%;
    display: flex;
    flex-direction: column;
    color: rgb(112, 112, 112);
    margin-bottom: 10px;
    margin-top: 40px;
    border: thin solid rgb(217, 216, 216);
    padding: 10px;
    border-radius: 5px;
}
.Up_containermid{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
}
.Up_containerbtm{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 40px;
    width: 100%;
    border: thin solid rgb(217, 216, 216);
    border-radius: 5px;
}
.Up_profileimgexplain{
    margin: 0px 0px 10px 0px;
}
.Up_imgdiv{
    position: relative;
    border: thin solid #707070;
    border-radius: 75px;
    object-fit: cover;
}
.Up_imgcontainer{
    position: relative;
    width: 150px;
    height: 150px;
    border: thin solid #707070;
    border-radius: 75px;
   object-fit: cover;
}
.Up_imginputroot{
    visibility: hidden;
}
.Up_plusicon{
    /* position:absolute;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    right: 0px;
    bottom: 0px;
    border:thin solid #707070;
    align-items: center;
    place-content: center;
    background-color: white;
    text-align: center;
    display: flex;
    cursor:pointer; */
    position:absolute;
    width: 35px;
    height: 35px;
    padding: 0px;
    margin: 0px;
    display: flex;
    place-content: center;
    align-items: center;
    border-radius: 40px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    border:thin solid #707070;
    cursor:pointer;
}
.Up_uneditablediv{
    display: flex;
    border: thin solid #d9d8d8;
    border-radius: 5px;
    padding: 10px;
    background-color: #eeeeee;
    color: black;
    margin-bottom: 15px;
}
.Up_editablediv{
    display: flex;
    border: thin solid #d9d8d8;
    border-radius: 5px;
    padding: 10px;
    background-color: #ffffff;
    color: black;
    margin-bottom: 15px;
}
.Up_msgroot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
.Up_btnroot{
   width:100%;
   height: 40px;
}
.Up_btn{
    float: right;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
}
.Up_div{
    padding: 10px;
    margin-right:5px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
}