.Bmker_rowcolroot {
    flex-direction: column;
    margin: 0px 15px;
    box-shadow: rgb(238, 238, 238) 0px 20px 40px -12px;
    border: 2px solid #dacdb3;
    background-color: #fffbf3;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    margin-bottom: 20px;
}

.Bmker_rowtrue {
    display: flex;
    flex-direction: row;
    padding: 10px 0px 0px 0px;
    width: 100%;
    position: relative;
    justify-content: flex-end;
    top: 0px;
}

.Bmker_rowfalse {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    width: 100%;
    position: relative;
}

.Bmker_col {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    width: 100%;
    position: relative;
}

.Bmker_msg {
    display: flex;
    text-align: flex-start;
    padding: 10px 0px;
}

.Bmker_addbud {
    display: flex;
    text-align: flex-start;
}

/* .Bmker_addnewcat{
    padding: 5px;
    outline: none;
    border-radius: 0px 0px 5px 5px;
    margin: 0px 5px 20px 0px;
    border: thin solid #f77f7f;
    width: 100%;
    height: max-content;
    background-color: #f77f7f;
    color: white;
    font-weight: bold;
} */

.Bkmer_ragroottrue {
    display: flex;
    flex-direction: column;
    min-width: calc(100% - 750px);
}

.Bkmer_ragrootfalse {
    display: flex;
    flex-direction: column;
}

.Bkmer_dragrectroot {
    border: thin solid #707070;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.Bkmer_dragtoproot {
    display: flex;
    padding: 5px;
    flex-direction: row;
}

.Bkmer_dragname {
    padding: 5px;
    width: 100%;
    white-space: nowrap;
    border: none;
    outline: none;
}

.Bkmer_expandtab {
    padding: 5px;
    display: flex;
    align-items: center;
}

.Bkmer_dragpriceWEB {
    display: flex;
    margin: 0px 10px;
    font-size: 24px;
    color: #8c8c8c;
    font-style: italic;
    justify-content: flex-end;
}

.Bkmer_dragpriceTABLET {
    display: flex;
    margin: 0px 10px;
    font-size: 24px;
    color: #8c8c8c;
    font-style: italic;
    justify-content: flex-end;
}

.Bkmer_dragpriceMOBILE {
    display: flex;
    margin: 0px 10px;
    font-size: 16px;
    color: #8c8c8c;
    font-style: italic;
    justify-content: flex-end;
}

.Bkmer_modalrootWEB {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 10px 10px;
    margin: 0px 10px 10px 10px;
    min-width: 500px;
}

.Bkmer_modalrootTABLET {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 10px 10px;
    margin: 0px 10px 10px 10px;
    min-width: 500px;
}

.Bkmer_modalrootMOBILE {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 10px 10px;
    margin: 0px 10px 10px 10px;
    min-width: auto;
}

.Budgetsettingroot {
    width: 150px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    background-color: white;
    color: var(--maindark);
    border: 1px solid var(--maindark);
}

.Budgetsettingroot_graphspreadsheet {

    width: 150px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    border: 1px solid var(--maindark);
    background-color: var(--mainpink);
    color: white;

}

.Budgetsettingroot_spreadsheetgraph {

    width: 150px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    border: 1px solid var(--maindark);
    background-color: var(--mainpink);
    color: white;
}

.Budgetedit_webcontentroot {
    width: 100%;
    display: flex;
}

.Budgetedit_tabletcontentroot {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.Budgetedit_mobilecontentroot {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.Budgetedit_spreadsheet_web {
    width: calc(100% - 750px);
    min-height: 100%;
    flex-direction: column;
    display: 1;
    /* margin-left: 10px; */
}

.Budgetedit_graph_web {
    width: 750px;
    /* height: 500px; */
    height: 700px;
    top: 0;
    position: sticky;
    /* border: 1px solid var(--subgrey); */
    /* box-shadow: 0px 12px 16px -4px rgba(16,24,40,.06); */
    /* border-radius: 5px; */

}

.Budgetedit_spreadsheet_others {
    width: 100%;
    flex-direction: column;
    display: 1;
}

.Budgetedit_graph_others {
    width: 100%;
    min-height: max-content;
    place-self: center;
    margin: 10px 0px;
    box-sizing: border-box;
    border: 1px solid var(--subgrey);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, .06);
    border-radius: 5px;
}

.Budgetedit_sectionadd {
    width: calc(100% - 10px);
    padding: 10px 0px;
    border: 1px solid var(--maindark);
    color: rgb(255, 255, 255);
    background-color: var(--maindark);
    border-radius: 5px;
    font-size: 26px;
    margin: 5px;
}

.Budgetedit_collapsibleroot {
    padding: 10px 0px;
}

.Budgetedit_chartbtn{
    width: 100px;
    padding: 5px 0px;
    margin: 0px 5px 5px 0px;
    background-color: white;
    border: 1px solid var(--maindark);
    border-radius: 5px;
}
.Budgetedit_chartbtnselected{
    background-color: var(--mainpink);
    color: #ffffff !important;
    border: 1px solid var(--mainpink);
}   