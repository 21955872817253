.Tools_root {
    display: flex;
    flex-direction: column;
    align-items: center;
    touch-action: manipulation;
}

.Tools_toolstrip_strip {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    -webkit-overflow-scrolling: 'touch';
    overflow-x: auto;
    overflow-y: hidden;
    align-items: center;
    margin-bottom: 20px;
}

.Tools_contentWEB {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    max-width: 1000px;
    min-width: 1000px;
}

.Tools_contentTABLET {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    max-width: 600px;
    min-width: 600px;
}

.Tools_contentMOBILE {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    max-width: 375px;
    min-width: 375px;
}

.Tools_main{
    padding: 15px 5px 10px 5px;
    border-radius: 5px;
    border: 1px solid var(--subgrey);
    background-color: white;
    outline: none;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 120px;
    height: 140px;
    align-items: center;
    font-size: 14px;
    text-align: center;
}

.Tools_main:hover{
    border:1px solid var(--maindark);
}

.Tools_mainWEB:hover {
    color: #3279b7;
    text-decoration: none;
}

.Tools_mainTABLET:hover {
    color: #3279b7;
    text-decoration: none;
}

.Tools_mainMOBILE:hover {
    color: #3279b7;
    text-decoration: none;
}

.Tools_unauthheader {
    place-content: center;
    display: flex;
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-bottom: 30px; */
}

.Tools_unauthheadertransparency {
    place-content: center;
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-bottom: 30px; */
    background-color: #70707042;

}

.Tools_7btnroot {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 140px;
    width: 100px;
    border: thin solid transparent;
    border-radius: 5px;
    color: #707070;
    margin: 5px;
    background-color: white;
    padding: 5px 3px;
    outline: none;
    --borderWidth: 4px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
    display: flex;
    place-content: center;
    align-items: center;
}

.Tools_7text {
    font-weight: bold;
}

.Tools_7 {
    font-weight: bold;
    color: white;
    background-color: #df4646;
    border-radius: 30px;
    height: 30px;
    width: 30px;
    text-align: center;
    align-items: center;
    display: flex;
    place-content: center;
    margin-top: 5px;
}

.Tools_7btnroot:after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
}


@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.Tools_authitem{
    margin-bottom: 200px;
    min-height: 260px;
    width: 100%;
    display: flex;
    place-content: flex-start;
    flex-direction: column;
}