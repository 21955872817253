.Renderdetail_pricecardsrootWEB {
    display: flex;
    flex-direction: column;
    overflow-x: auto;

}

.Renderdetail_pricecardsrootTABLET {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

.Renderdetail_pricecardsrootMOBILE {
    display: flex;
    flex-direction: column;
}


.Renderdetail_pricecardroot {
    border: 1px solid var(--subgrey);
    border-radius: 5px;
    padding: 10px;
    color: var(--maindark);
}


.Renderdetail_pricecardrootTABLET {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 10px;
    width: calc(100% - 20px);
    padding-bottom: 10px;
    overflow-x: auto;
    min-width: 320px;
    max-width: 320px;
}

.Renderdetail_pricecardrootTABLET:first-child {
    /* margin-bottom: 10px; */
}


.Renderdetail_pricecardrootMOBILE {
    display: flex;
    flex-direction: column;
    margin: 0px 10px 10px 10px;
}

.Renderdetail_pricecardrootMOBILE:last-child {
    margin-bottom: 0px;
}

.Renderdetail_pricecardtop {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    place-content: center;
    align-items: center;
    border-bottom: 1px solid var(--subgrey);
}

.Renderdetail_vt {
    padding-left: 10px;
    font-size: 14px;
}

.Renderdetail_pricecardbtm {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 10px 0px 0px 0px;
}

.Renderdetail_pricecardstartsat {
    font-size: 14px;
    width: 120px;
    display: flex;
    place-content: flex-start;
    padding: 0px 20px 0px 5px;
}

.Renderdetail_pricing {
    font-size: 18px;
    border-left: 1px solid var(--subgrey);
    padding-left: 20px;
    width: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    text-align: center;
    color: var(--mainpink);
}

.Renderdetail_pricecardrootWEB {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    padding-bottom: 10px;
    overflow-x: auto;
    min-width: 320px;
    max-width: 320px;
}

.Renderdetail_pricecardrootWEB:first-child {
    margin-right: 0px;
    margin-bottom: 10px;
}

.Renderdetail_faqinnerroot {
    width: calc(100% - 20px);
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--subgrey);
    border-right: 1px solid var(--subgrey);
    border-left: 1px solid var(--subgrey);
    border-image: initial;
    color: var(--maindark);
    user-select: none;
    border-radius: 0px;
    align-items: center;
}

.Renderdetail_faqinnerroot:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Renderdetail_faqcloseroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 15px 10px;
    font-size: 15px;
}

.Renderdetail_qnopened {
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    padding: 15px 0px;
    margin: 0px 10px;
    font-size: 15px;
}

.Renderdetail_tag {
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 12px;
    color: var(--maindark);
    border: 1px solid var(--maindark);
    background-color: #ffffff;
    margin: 0px 5px 5px 0px;
}


.Renderdetail_tagcattitle_MOBILE {
    color: var(--maindark);
    margin: 10px;
    font-size: 14px;
    text-decoration: underline;
    font-family: Lato;
    font-weight: bold;
}

.Renderdetail_tagcattitle_TABLET {
    color: var(--maindark);
    margin: 10px 10px 0px 10px;
    font-size: 14px;
    text-decoration: underline;
    font-family: Lato;
    font-weight: bold;
}
.Renderdetail_tagcattitle_WEB {
    color: var(--maindark);
    margin: 10px 10px 0px 10px;
    font-size: 14px;
    text-decoration: underline;
    font-family: Lato;
    font-weight: bold;
}

.Renderdetail_taggroup{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.Renderdetail_taggroup_WEB{
    padding: 10px 10px 0px 10px;

}

.Renderdetail_taggroup_TABLET{
    padding: 10px 10px 0px 10px;

}

.Renderdetail_taggroup_MOBILE{
    padding: 0px 10px;
}

/* RenderVendorspecific_web & RenderVendorspecific_mobile  */
.Renderdetail_tagroot{
    display: flex;
    flex-direction: column;
    padding: 30px 0px 40px;
    border: none;
    color: var(--maindark);
    font-family: Lato;
}

/* RenderFaqs_web & RenderFaqs_mobile */
.Renderdetail_faqroot {
    display: flex;
    flex-direction: column;
    padding: 30px 0px 40px;
    border: none;
    color: var(--maindark);
    font-family: Lato;
}

.Renderdetail_tagtitle{
    width: 100%;
    place-content: flex-start;
}



/* RenderVendorspecific_web */


.Renderdetail_tagprimarycontent_web {
    width: 100%;
    padding: 0px 0px 0px 10px;
}

.Renderdetail_tagsecondarycontent_web {
    width: 100%;
    padding: 10px 0px 10px 10px;
}

.Renderdetail_tagcat_web {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 10px;
    border: 1px solid var(--subgrey);
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    font-size: 15px;
    color: var(--maindark);
}

.Renderdetail_tagcatcontent_web {
    border: 1px solid var(--subgrey);
    border-radius: 0px 0px 5px 5px;
}

/* RenderVendorspecific_mobile */


.Renderdetail_tagprimarycontent_mobile {
    width: 100%;
    padding: 0px 10px;
}

.Renderdetail_tagcat_mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 10px;
    border: 1px solid var(--subgrey);
    border-bottom: none;
    border-radius: 5px 5px 0px 0px;
    font-size: 15px;
    color: var(--maindark);
}

.Renderdetail_tagcatcontent_mobile {
    border: 1px solid var(--subgrey);
    border-radius: 0px 0px 5px 5px;
}

.Renderdetail_taglbl{
    background-color: var(--maindark);
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    text-align: center;
    place-content: center;
    place-self: center;
    margin: 0px 5px;
}

.Renderdetail_vtroot{
    display: flex;
    flex-direction: column;
    padding: 30px 0px 40px;
    /* border-bottom: 1px solid var(--lightdark); */
}
.Renderdetail_faqspecificMOBILE{
    display: flex;
    flex-direction: column;
}

.Renderdetail_faqspecificTABLET{
    display: flex;
    flex-direction: column;
}

.Renderdetail_faqspecificWEB{
    width:100%;
    flex-direction: column;
}
.Renderdetail_faqspecificrow{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.Renderdetail_faqspecificcol{
    display: flex;
    flex-direction: column;
}

.Renderdetail_sep{
    border-bottom: 1px solid var(--lightdark);
    width:100%;
}