.Openings_root{
    display: flex;
    flex-direction: column;
    touch-action: manipulation;
    overflow-y: auto;
    padding: 10px;
}
.Openings_rowapp{
    display: flex;
    flex-direction: row;
}
.Openings_body{
    border-radius: 10px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.Openings_typeopeningfalse{
    padding: 10px;
    border-radius: 10px;
    font-weight: 400;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    width: 60px;
    flex-direction: column;
    align-items: center;
    display: flex;
    color: rgb(112, 112, 112);
    font-size: 12px;
    line-height: 20px;
    margin: 0px 5px 10px 0px;
    font-size: 13px;
}

.Openings_typeopeningtrue{
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    border: thin solid #eb5d7b;
    background-color: #eb5d7b;
    outline: none;
    width: 60px;
    flex-direction: column;
    align-items: center;
    display: flex;
    color: white;
    font-size: 12px;
    line-height: 20px;
    margin: 0px 5px 10px 0px;
    font-size: 13px;
}
.Openings_typeopeningicon{
    width: 45px;
    height: 45px;
    border-radius: 30px;
    border: thin solid white;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgb(112, 112, 112);
    background-color: white;
    display: flex;
}
.Openings_typeopeningname{
    padding: 10px;
}

.Openings_content{
    width: 100%;
    border:thin solid rgb(221, 221, 221);
    border-radius: 10px;
    text-align: center;
    color: rgb(112, 112, 112);
    padding: 10px;
}
.Openings_openingcontent{
    font-size: 14px;
    text-align: start;
}
.Openings_exampleroot{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    color: rgb(112, 112, 112);
    flex-direction: column;
    overflow-x: auto;
    padding: 10px 0px;
}
.Openings_exampletitle{
    font-size: 12px;
    font-weight: bold;
    
}
.Openings_examplerow{
    display: flex;
    flex-direction: row;
}
.Openings_examplebtn{
    display: flex;
    border: thin solid rgb(112, 112, 112);
    border-radius: 5px;
    padding: 5px;
    margin: 0px 10px 0px 0px;
    width: max-content;
    cursor: pointer;
}
.Opening_menuroot{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}
.Opening_contentroot{
    display: flex;
    flex-direction: column;
    padding: 10px 10px 25px 10px;
    width: 100%;
    align-items: center;
}
.Opening_convoitem{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    margin: 0px 0px 20px 0px;
    border-radius: 10px;
    box-shadow: rgb(25 25 25 / 25%) 5px 5px 3px;
    border: thin solid silver;
    color: rgb(112, 112, 112);
    height: max-content;
}