.homeuser_arrow_1 {
    position: absolute;
    height: 500px;
    width: max-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    text-align: center;
}

.homeuser_arrow_2 {
    height: 250px;
    width: 45px;
    background-color: rgba(0, 0, 0, 0.51);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.homeuser_arrow {
    transform: rotate(180deg);
}

.homeuser_modalclose {
    border-radius: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    height: max-content;
    top: 0;
    padding: 0px;
    margin: 0px;
    height: 34px;
    width: 34px;
}

.homeuser_vtbadgeroot {
    margin-top: 10px;
    grid-template-columns: repeat(auto-fit, 130px);

}

/* .homeuser_vtbadge {
    font-size: 12px;
    margin: 0px 5px 5px 0px;
    border-radius: 15px;
    padding: 5px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
} */

.Homeuser_vtbadge {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 0px 1px;
    height: 49px;
    padding: 5px;
    white-space: pre-wrap;
    border-radius: 13px;
    align-items: center;
    font-size: 13px;
    outline: none;
    min-width: 125px;
    max-width: 125px;
    display: flex;
    flex-direction: row;
    place-content: center;
    user-select: none;
    border: 1px solid var(--subgrey);
    margin: 5px;
    font-family: Lato;
}