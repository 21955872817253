.dd{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #707070;
    display: block;
    padding: 6px 10px;
    border: thin solid #707070,
}

.dd_selected{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    background-color: #eb5d7b;
    display: block;
    padding: 6px 10px;
    font-weight: bold;
    border: none;
}
