.rrequests_root {
    display: flex;
    place-content: center;
    /* width: 100%; */
}

.rrequests_rootPricelist {
    width: max-content !important;
}

.rrequests_title {
    display: flex;
    font-size: 14px;
    margin: 0px 10px 0px 10px;
}

.rrequests_displaymsg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}

.rrequests_telegramsendroot {
    visibility: hidden;
}

.rrequests_enquiremodalrootMOBILE {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 15px;
    margin-bottom: 0px;
    min-height: calc(100vh - 78px);
}

.rrequests_enquiremodalrootWEB {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0px 15px;
    margin-bottom: 0px;
}

.rrequests_row {
    display: flex;
    flex-direction: row;
    margin: 0px 10px 10px 10px;
    overflow-x: auto;
}

.rrequests_rownowrap{
    display: flex;
    flex-direction: row;
    margin: 0px 10px 10px 10px;
    /* overflow-x: hidden; */
    white-space: nowrap;
}

.rrequests_msgchn {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 100px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    /* border: 1px solid var(--subgrey); */
    border: 1px solid var(--subdark);
    margin-right: 5px;
    cursor: pointer;
    letter-spacing: 0.4px;
}

.rrequests_msgchnselected {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 100px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 5px;
    color: var(--maindark);
    cursor: pointer;
    border: 1px solid var(--mainpink);
    background-color: #fff5f5;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.rrequests_icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
}

.rrequests_icontxt {
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.rrequests_iconname {
    height: 55%;
}

.rrequests_templatebtn {
    background-color: transparent;
    display: flex;
    padding: 5px;
    border: 1px solid var(--subdark);
    border-radius: 5px;
    outline: none;
    height: 60px;
    width: 100px;
    font-size: 12px;
    align-items: center;
    place-content: center;
    margin-right: 5px;
    font-size: 13px;
}

.rrequests_txtroot {
    display: flex;
    place-content: flex-end;
    margin: 10px 0px 10px 0px;
    align-items: center;
}

.rrequests_sendbtn {
    border-radius: 10px;
    background-color: #ffffff;
    height: 45px;
    font-size: 14px;
    font-weight: normal;
    color: var(--maindark);
    border: 1px solid var(--maindark);
    padding: 0px 15px;
    display: flex;
    align-items: center;
}

.rrequests_enquirebtn {
    width: 100%;
    place-content: center;
}

.rrequests_sendbtndisabled {
    border-radius: 5px;
    background-color: var(--maindark);
    color: white;
    border: none;
    box-shadow: rgba(25, 25, 25, 0.2) 1px 1px 5px;
    height: 45px;
    width: 100%;
    font-size: 14px;
}

.rrequests_btn {
    padding: 10px 15px;
    border-radius: 10px !important;
    border: none;
    /* background-color: var(--mainpink); */
    background-color: var(--maindark);
    outline: none;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.1px;
}

.rrequest_Pricelist {
    /* pricelistlookup */

}

.rrequest_Vendorlookup {
    /* vendorlookup */
    height: 48px;
    border-radius: 5px;
    /* width: calc( 100% - 20px ); */
    margin: 0px 10px;
    /* 
    height: 65px;
     */
    /* 
    height: 87px;
    background-color: #ffffff;
    color: var(--maindark);
    border-left: 1px solid var(--maindark);
    border-radius: 0;
     */
}

.rrequest_Vendorlookup_WEB {}

.rrequest_Vendorlookup_TABLET {}

.rrequest_Vendorlookup_MOBILE {
    width: 100%;
}

.rrequests_rootFavouritevendor {
    place-content: flex-start !important;
}

.rrequest_Pricelistcard {
    /* vendorlookup's pricelistcard */
    margin: 5px 0px 0px;
    display: flex;
    align-items: center;
    box-shadow: rgba(25, 25, 25, 0.2) 1px 1px 5px;
    border-radius: 10px;
    height: 42px;
}

.rrequest_Bubble {
    /* favourite page comparator */
    height: 30px;
    place-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
}

.rrequest_Pricelistcardinbuilt{

}

.rrequests_displayname{
    font-weight: bold;
    padding:0px 3px 0px 3px;
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.rrequests_txtarea{
    font-size: 14px;
    padding: 5px;
    outline: none;
    margin: 0px 10px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
  }