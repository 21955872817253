.headerrootWEB {
    place-content: center;
    display: flex;
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1500ms cubic-bezier(0.23, 1, 0.32, 1) 1500s;
}

.headerrootTABLET {
    place-content: center;
    display: flex;
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1500ms cubic-bezier(0.23, 1, 0.32, 1) 1500s;
}

.headerrootMOBILE {
    place-content: center;
    display: flex;
    width: 100%;
    /* height:450px; */
    height: 480px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1500ms cubic-bezier(0.23, 1, 0.32, 1) 1500s;
}

.headerabsrootWEB {
    position: absolute;
    background-color: #c0c0c042;
    place-content: center;
    display: flex;
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1500ms cubic-bezier(0.23, 1, 0.32, 1) 1500s;
}

.headerabsrootTABLET {
    position: absolute;
    background-color: #70707042;
    place-content: center;
    display: flex;
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1500ms cubic-bezier(0.23, 1, 0.32, 1) 1500s;
}

.headerabsroot {
    position: absolute;
    background-color: #70707042;
    place-content: center;
    display: flex;
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 1500ms cubic-bezier(0.23, 1, 0.32, 1) 1500s;
}

.headercircularWEB {
    z-index: 1;
    background-color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-radius: 100% 100% 0 0;
    border-top: 1px solid var(--subgrey);
    /* border-top: thick solid #eb5d7b; */
}

.headercircular {
    z-index: 1;
    background-color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    border-top: 1px solid var(--subgrey);
}

.headercontentrootWEB {
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    position: absolute;
    width: calc(100% - 45px - 45px);
    place-self: center;
}

.headercontentrootTABLET {
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    position: absolute;
    width: calc(100% - 45px - 45px);
    place-self: center;
}

.headercontentrootMOBILE {
    display: flex;
    flex-direction: column;
    justify-items: center;
    position: absolute;
    place-self: center;
    justify-content: flex-end;
    /* height: calc(100% - 93px); */
    top: calc(100% - 210px);

}

.headercontentsubrootWEB {
    display: flex;
    flex-wrap: wrap;
    color: white;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 145px);
    padding-top: 250px;
    align-items: center;
    z-index: 4;
    place-content: center;
}

.headercontentsubrootTABLET {
    display: flex;
    flex-wrap: wrap;
    color: white;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 145px);
    padding-top: 250px;
    align-items: center;
    z-index: 4;
    place-content: center;
}

.headercontentsubrootMOBILE {
    /* display:flex;
    flex-wrap: wrap;
    color:white;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height: calc( 100% - 145px);
    padding-top:220px;
    align-items: center;
    z-index:4;
    place-content: center; */

    display: flex;
    flex-wrap: wrap;
    color: white;
    text-align: center;
    flex-direction: column;
    z-index: 4;
    place-content: center;
    justify-content: flex-end;
}

.headercolumnfonts {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.headertitlelogotext {
    letter-spacing: 0px;
    z-index: 1;
    position: absolute;
    color: #ffffff;
    /* filter: blur(20px);
    color: #eb5d7b; */
    font-family: 'Marck Script';
    font-size: 21px;
}

.headertitlelogorealtextmobile {
    letter-spacing: 0;
    z-index: 1;
    color: white !important;
    font-family: 'Marck Script';
    color: #555555;
    font-size: 22px;
    letter-spacing: 0.5px;
    height: 100%;
    display: flex;
    place-items: center;
    cursor: pointer;
}

.headertitlelogorealtext {
    letter-spacing: 0;
    z-index: 1;
    color: white;
}

.headerlocroot {
    position: absolute;
    display: flex;
    background-color: white;
    max-width: max-content;
    min-width: max-content;
    border-radius: 5px;
    justify-content: center;
    left: 0px;
    margin: 7px 5px 5px 7px;
}

.headerplanningroot {
    position: absolute;
    margin: 7px 7px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    right: 1px;
}

.headerplanningdiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.headernamerootWEB {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 52.5px;
    z-index: 3;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* border-top: thick solid #eb5d7b; */
    border-top: 1px solid var(--subgrey);
    border-left: 1px solid var(--subgrey);
    border-right: 1px solid var(--subgrey);
    color: var(--maindark);
    font-size: 14px;
    max-width: 100%;
    place-self: center;
    padding: 0px 5px;
    overflow: hidden;
    width: 470px;
    cursor: pointer;
}

.headernamerootTABLET {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 55px;
    z-index: 3;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* border-top: thick solid #eb5d7b; */
    border-top: 1px solid var(--subgrey);
    border-left: 1px solid var(--subgrey);
    border-right: 1px solid var(--subgrey);
    color: var(--maindark);
    font-size: 14px;
    max-width: 100%;
    place-self: center;
    padding: 0px 5px;
    overflow: hidden;
    width: 470px;
    cursor: pointer;
}

.headernamerootMOBILE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 55px;
    z-index: 3;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* border-top: thick solid #eb5d7b; */
    border-top: 1px solid var(--subgrey);
    /* border-left:1px solid var(--subgrey);
    border-right:1px solid var(--subgrey); */
    color: var(--maindark);
    font-size: 12px;
    font-family: Lato;
    max-width: 100%;
    place-self: center;
    padding: 0px 5px;
    overflow: hidden;
    width: 290px;
    cursor: pointer;
}

.headernamesubroot {
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    text-align: center;
    font-family: Lato;
}

.headername {
    padding: 0px 12.5px;
    user-select: none;
    font-size: 15px;
}

.headerdate {
    user-select: none;
    font-family: Lato;
}

.headericonroot {
    background-color: white;
    border: 1px solid var(--maindark);
    border-bottom: 0px solid transparent;
    color: var(--maindark);
    display: flex;
    place-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    outline: none;

}

.headericondevice_WEB {
    width: 43px;
    height: 43px;
}

.headericondevice_TABLET {
    width: 40px;
    height: 40px;
}

.headericondevice_MOBILE {
    width: 37px;
    height: 37px;
}

.headericonroot:hover {
    color: var(--mainpink);
    transform: scale(1.2);
    border: 1px solid var(--mainpink);
}

.headericonselected {
    color: white;
    text-align: center;
    align-items: center;
    padding: 0px 5px;
    margin: 5px 10px;
    border-radius: 5px;
    display: flex;
    background-color: black;
    letter-spacing: 1px;
    position: relative;
    /* filter:drop-shadow(2px 4px 6px white); */
}

.headericonnameselected {
    z-index: 1;
}

.headericonarrow {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: black;
    left: calc(100% - 5px);
    transform: rotate(45deg);
    z-index: 1;
    border-radius: 0;
}

.headericonnotselected {
    color: transparent;
    text-align: center;
    align-items: center;
    padding: 0px 5px;
    display: flex;
    min-height: 0px;
    min-width: 0px;
    visibility: hidden;
}