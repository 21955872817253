.gbcast_msgbtm{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0px 0px 0px;
}
.gbcast_msgprofileimg{
    min-width:35px;
    min-height: 35px;
    padding:5px;
    margin: 5px 5px 5px 0px;
    border-radius:35px;
    border:thin solid rgb(117, 117, 117);
    background-color: white;
}
.gbcast_msgright{
    display: flex;
    flex-direction: column;
    place-content: center;
    width: 100%;
}
.gbcast_msgname{
    font-size: 16px;
    /* width: calc( 100% - 45px ); */
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}
.gbcast_msgtimeselected{
    width:100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: white;
}
.gbcast_msgtime{
    width:100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #777;
}
.gbcastmsg_singlemsg{
    display: flex;
    margin:10px 10px 0px 10px;
    padding:15px 10px;
    border-radius:5px;
    background-color: white;
    font-size: large;
    position: relative;
    top:0px;
    cursor: pointer;
    flex-direction: column;
    overflow: hidden;
    /* box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px; */
    box-shadow:rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}
.gbcastmsg_singlemsgselected{
    display: flex;
    margin: 10px 10px 0px 10px;
    padding: 15px 10px;
    border-radius:5px;
    border: thin solid #eb5d7b;
    background-color: #eb5d7b;
    color:white;
    font-size: large;
    position: relative;
    top:0px;
    cursor: pointer;
    flex-direction: column;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px
}
.gbcastmsg_mainimg{
    display: flex;
    width: 150px;
    height: 150px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}