.play2_rootmobile{
    width: 100%;
    min-height: max-content;
    background-color: #282535;
    top: 0;
    display: flex;
    flex-direction: column;
    overflow-x:hidden;

}
.play2_cardmobile{
    /* height: calc( 80vh - 20px ); */
    /* height: 100vh; */
    width: calc( 100% - 20px);
    background-color: white;
    display: flex;
    position: relative;
    margin: 10px;
    border-radius: 20px;
    flex-direction: column;
}
/* 
.play2_cardtopimgmobile{
    display: flex;
    height: 65%;
    min-height: max-content;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
*/
.play2_cardtopimgmobile{
    display: flex;
    height: 100%;
    width:100%;
    object-fit:contain;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.play2_txtmobile{
    display: flex;
    height: 35%;
    min-height: 35%;
    max-height: 35%;
    width: 100%;
}
.play2_btmonlytxtmobile{
    display: flex;
    height: 100%;
    width: 100%;
    padding : 0px 20px;
}
.play2_btmtxtmobile{
    width: calc( 100% - 40px );
    margin : 0px 20px;
}
.play2_colmobile{
    display: flex;
    flex-direction: column;
    height: 300px;
    border-bottom-left-radius:20px;
    border-bottom-right-radius:20px;
}