.loginpc_root {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
    padding: 5px;
    width: 100%;
    height: 100vh;
    align-items: center;
    text-align: center;
    font-family: Lato;
}

.loginpc_title {
    padding: 0px 0px 10px 0px;
    font-size: 16px;
    font-weight: normal;
}

.loginpc_subroot {
    display: flex;
    flex-direction: column;
}

.loginpc_lbl {
    font-weight: bold;
    display: flex;
    align-self: flex-start;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 14px;
}

.loginpc_input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    font-weight: normal;
}

.loginpc_btn {
    border-radius: 5px;
    padding: 0px 10px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    background-color: white;
    outline: none;
    align-self: flex-end;
    margin-top: 10px;
    font-size: 14px;
    height: 40px;
    width: 100%;
    font-weight: normal;
}

.loginpc_errmsg {
    display: flex;
    height: 20px;
    font-size: 12px;
    place-content: flex-end;
    margin-top: 10px;
    font-weight: normal;
}

.loginpc_lockicon {
    display: flex;
    place-content: center;
    padding: 15px;
    padding-bottom: 5px;
    width: max-content;
    border-radius: 50px;
    place-self: center;
}