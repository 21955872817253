.Jmap_root {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: Lato;
    height: 100vh;
    width: 100vw;
}

.Jmap_subroot {
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.Jmap_map {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 320px);
    height: calc(100% - 45px);
    /* height: 100%; */
}

.Jmap_right {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    overflow-y: overlay;
    min-height: 100px;
    z-index: 99999999999999;
    position: absolute;
    left: calc(100vw - 320px);
}

.Jmap_tagsroot {
    height: 45px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    z-index: 1;
    position: fixed;
    bottom: 0;
}

.Jmap_tagtrue {
    display: flex;
    min-width: max-content;
    padding: 5px;
    margin: 2.5px;
    text-align: center;
    align-items: center;
    background-color: var(--maindark);
    color: white;
    border: none;
    outline: none;
    border: thin solid var(--maindark);
    border-radius: 5px;
    place-content: center;
    font-size: 16px;
    overflow: hidden;
    white-space: pre;
}

.Jmap_tagfalse {
    display: flex;
    min-width: max-content;
    padding: 5px;
    margin: 2.5px;
    text-align: center;
    align-items: center;
    background-color: white;
    color: var(--maindark);
    border: none;
    outline: none;
    border: thin solid var(--maindark);
    border-radius: 5px;
    place-content: center;
    font-size: 16px;
    overflow: hidden;
    white-space: pre;
}

.Jmap_tooltiproot {
    width: max-content !important;
    height: max-content !important;
}

.Jmap_popuproot {
    width: 300px !important;
    height: 250px !important;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: var(--maindark);
    padding-top: 20px;
}

.Jmap_popuptitle {
    font-weight: bold;
}

.Jmap_popuptags {
    display: flex;
    flex-direction: row;
    overflow: overlay;
    white-space: nowrap;
}

.Jmap_popuptag {
    display: flex;
    width: 95px !important;
    height: 34px !important;
    padding: 5px !important;
    ;
    margin: 2.5px 2.5px 2.5px 0px;
    text-align: center;
    align-items: center;
    background-color: var(--maindark);
    color: white;
    border: none;
    outline: none;
    border: thin solid var(--maindark);
    border-radius: 5px;
    place-content: center;
    font-size: 16px;
}

.Jmap_righttitle {
    display: flex;
    font-size: 16px;
    font-weight: bold;
}

.Jmap_imgroot {
    display: flex;
    width: 100%;
    height: 120px;
}

.Jmap_galleryroot {
    display: flex;
    flex-direction: column;
}

.Jmap_rightusroot {
    display: flex;
    flex-direction: row;
}

.Jmap_rightuslookup {
    width: 38px;
    display: flex;
    text-align: center;
    padding: 5px 0px;
    border: 1px solid var(--maindark);
    margin: 0px 10px 5px 0px;
    align-items: center;
    place-content: center;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    text-decoration: none;
    color: var(--maindark) !important;
}

.Jmap_rightuslookup:hover {
    text-decoration: none;
    color: var(--maindark) !important;
    background-color: #fce8e8;
    color: white;
}

.Jmap_rightus {
    width: 100%;
    display: flex;
    padding: 10px;
    border: thin solid var(--maindark);
    margin-bottom: 5px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: var(--maindark) !important;
}

.Jmap_rightus:hover {
    text-decoration: none !important;
    color: var(--maindark) !important;
    ;
    background-color: #fce8e8;
}

.Jmap_rightout {
    display: flex;
    padding: 5px;
    border: thin dashed var(--maindark);
    margin-bottom: 5px;
    border-radius: 5px;
    margin-right: 10px;
    color: var(--maindark);
    text-decoration: none !important;
}

.Jmap_rightout :hover {
    color: var(--maindark);
    text-decoration: none !important;
}

.Jmap_logoroot {
    position: fixed;
    z-index: 999999999;
    background-color: var(--maindark);
    color: white;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    font-family: Marck Script;
    font-size: 19px;
    text-align: center;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    user-select: none;
    height: 42px;
    width: 130px;
    place-content: center;
}

.Jmap_logo {
    width: 30px;
    height: 30px;
}

.Jmap_txt {
    padding: 5px;
}

.Jmap_circle {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    border-style: solid;
    font-size: 16px;
    font-weight: bold;
}

.Jmap_circle.Jmap_circlu {
    background: #d82a56bd;
    border-color: var(--mainpink);
    color: white;
}

.leaflet-popup-content-wrapper {
    border-radius: 5px;
    padding: 5px;
}

.leaflet-popup-content {
    margin: 10px;
}

.leaflet-popup-close-button {
    display: flex !important;
    align-items: center !important;
    margin: 10px !important;
    place-content: center !important;
    padding: 10px !important;
    border: 1px solid #c3c3c3 !important;
    border-radius: 100px !important;
}
.Jmapcenter{
    height: 100px;
  width: 50px;
  text-align: center;
  display: flex;
  position: absolute;
  left: calc( 50% - 25px);
  top: calc( 50% - 50px);
}