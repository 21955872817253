.Notiboard_root{
    margin:  10px;
    width: calc(100% - 20px);
    border-radius: 5px;
    height: 100px;
    border: thin solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fef6de;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.2);
    font-family: lato;
    color: #707070;
    height: max-content;
}
.Notiboard_top{
    font-weight: 700;
    padding: 5px;
}
.Notiboard_center{
    border-radius: 5px;
    place-self: center;
    padding:10px;
}
.Notiboard_btm{
    font-weight: 700;
    padding: 5px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 5px;
}