.Standardaccordion_root {
    min-height: 50px !important;
    color: var(--maindark);
    display: flex;
    flex-direction: column;
    place-content: center;
    overflow: hidden;
    font-family: Lato;
}

.Standardaccordion_title {
    min-height: 50px;
    display: flex;
    margin: 0px;
    align-items: center;
    user-select: none;
    flex-direction: row;
    place-content: space-between;
    cursor: pointer;
}

.Standardaccordion_col {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0 ;
    transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.Standardaccordion_colexpand{
    max-height: 1000px ;
    overflow: visible;
}

.Standardaccordion_item {
    display: flex;
    place-content: center;
}

.Standardaccordion_item:last-child {
    margin-bottom: 15px;
}

.Standardaccordion_arrow {
    cursor: pointer;
}

.Standardaccordion_arrowdown {
    transform: rotate(360deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.Standardaccordion_arrowright {
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(270deg);
}