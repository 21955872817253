.Details_singleline {
    align-items: center;
    display: flex;
    width: max-content;
    padding: 0px 10px 10px 10px;
    border-radius: 5px;
    align-self: start;
    font-weight: bold;
    flex-direction: row;
    overflow-x: auto;
    min-width: 100%;
    max-width: 100%;
}

.Details_nametypebtn {
    display: flex;
    flex-direction: column;
    place-content: space-between;
    align-items: center;
    width: max-content;
    height: max-content;
    background-color: white;
    cursor: pointer;
    border: 1px solid black;
    border-right: none;
    height: 110px;
    overflow: hidden;
}

.Details_nametypebtn:first-child {
    border-right: 0px solid transparent;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Details_nametypebtn:last-child {
    border: 1px solid black;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Details_nametypebtnselected {
    background-color: var(--mainpink) !important;
    color: white;
}

.Details_nametypecontent {
    display: block;
    flex-direction: column;
    width: 100%;
    user-select: none;
    text-align: center;
    font-size: 14px;
    white-space: pre;
}

.Details_nametypeanimbtn {
    height: 38px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid #707070;
    background-color: white;
}

.Details_nametypeanimbtn:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
}

.Details_nametypeanimbtn:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.Details_nametypenowrap {
    align-items: flex-start;
}

.Details_nttitle {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    place-content: center;
    border-bottom: 1px solid black;
}

.Details_nttilleselected {
    background-color: black;
    border-bottom: 1px solid black;
}

.Details_ntcontentroot {
    display: flex;
    padding: 5px;
    height: 100%;
    place-content: center;
    align-items: center;
    flex-direction: column;
}

.Ieditcontact_icon {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
}

.Ieditcontact_del {
    margin-left: 5px;
    color: rgb(235, 93, 123);
    border: none;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 10px;
    background-color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    border-radius: 5px;
}

@media (min-width:320px) {
    .Details_ntwt {
        min-width: fit-content;
        width: 100%;
    }

    .Ieditcontact_icon {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }

    .Ieditcontact_del {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }
}

@media (min-width:481px) {
    .Details_ntwt {
        min-width: fit-content;
        width: 100%;
    }

    .Ieditcontact_icon {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }

    .Ieditcontact_del {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }
}

@media (min-width:641px) {
    .Details_ntwt {
        min-width: fit-content;
        width: 100%;
    }

    .Ieditcontact_icon {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }

    .Ieditcontact_del {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }
}

@media (min-width:961px) {
    .Details_ntwt {
        width: fit-content;
        flex-basis: 33.33%;
    }

    .Ieditcontact_icon {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }

    .Ieditcontact_del {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
    }
}

@media (min-width:1025px) {
    .Details_ntwt {
        width: fit-content;
        flex-basis: 33.33%;
    }

    .Ieditcontact_icon {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
    }

    .Ieditcontact_del {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
    }
}

@media (min-width:1281px) {
    .Details_ntwt {
        width: fit-content;
        flex-basis: 33.33%;
    }

    .Ieditcontact_icon {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
    }

    .Ieditcontact_del {
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
    }
}

.Details_settingbtn {
    width: max-content;
    height: 74px;
    width: 80px;
    border: 1px solid var(--maindark);
    padding: 0px 10px;
    background-color: white;
    border-left: 0px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
}

.Details_settingbtn:first-child {
    border-left: 1px solid #707070;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Details_settingbtn:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Details_settingbtn>i {
    /* Prevent user from clicking the icon */
    pointer-events: none;
    margin: 5px;
}

.Details_settingbtn>div {
    /*  Prevent user from clicking the text */
    pointer-events: none;
    margin-top: 5px;
}

.Details_nameformatcol {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    /* background-color: #70707014; */
    height: 100%;
}

.Details_selectedbtn {
    background-color: var(--mainpink);
    color: white;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom: none !important;
}

.Details_selectednone {
    border-bottom: none;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.Details_grp {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
}

.iedit_details_mainroot {
    border-bottom: 1px solid #eaeaea;
    max-height: max-content;
    padding: 1px 0px;
    user-select: none;
}

.iedit_details_mainbtn {
    transition: background-color 100ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 15px;
    padding: 5px 10px;
}

.iedit_details_mainbtn:hover {
    transition: background-color 100ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background-color: #eb5d7ba8;
    color: white !important;
    border-radius: 15px;
}

.iedit_details_mainbtn_selected {
    color: var(--mainpink);
}

.iedit_details_plusicon {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 10px 5px;
}

.iedit_details_namerow {
    display: flex;
    flex-direction: row;
}

.iedti_details_typeiconroot {
    padding: 0px 30px 0px 0px;
    display: flex;
    text-align: center;
    align-items: center;
}

.iedti_details_typeicontxt {
    display: flex;
    text-align: center;
    align-items: center;
}

.Ieditcontact_root {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    height: 40px;
    align-items: center;
}

.Ieditcontact_input {
    min-height: 40px;
    max-height: 40px;
    width: calc(100% - 45px);
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
}

.Ieditcontact_selection {
    display: flex;
    flex-direction: column;
    margin: 15px;
    align-items: center;
    font-size: 15px;
}

.Ieditcontact_singleselection {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
    place-content: center;
    border: 0px solid transparent;
    background-color: white;
    margin-bottom: 5px;
    flex-direction: row;
}

.Ieditcontact_singleselection:last-child {
    margin-bottom: 0px;
}

.Ieditcontact_singleselection:hover {
    color: white !important;
    fill: white !important;
    background-color: #eb5d7ba8;
}

.Ieditcontact_mediatxt {
    min-width: 100px;
    text-align: start;
    padding-left: 12px;
    pointer-events: none;
}

.Ieditcontact_addbtn {
    height: 40px;
    width: max-content;
    min-height: 40px;
    min-width: 40px;
    padding: 10px;
    background-color: white;
    border-right: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    place-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px dashed var(--maindark);
    place-self: center;
}

.Ieditcontact_addbtn:hover {
    background-color: #eb5d7ba8;
    color: white;
}

.Ieditcontact_addtxt {
    padding: 0px 0px 0px 5px;
    font-weight: normal;
}

.Details_txtarea {
    display: block;
    outline-color: #005fcc;
    padding: 6px 12px;
    line-height: 1.42857;
    color: rgb(85, 85, 85);
    background-color: #ffffff;
    background-image: none;
    width: 100%;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset;
    border-radius: 4px;
    border: 1px solid #cccccc;
}

.Details_dark {
    background-color: #fafafa;
    color: #cccccc;
}

.Details_notirow {
    display: flex;
    flex-direction: row;
    place-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
}

.Details_notitxt {
    width: max-content;
    padding: 0px 10px;
}

.Details_notitxt_small {
    width: max-content;
    padding: 0px 5px 0px 0px;
    font-size: 12px;
}


/* The switch - the box around the slider */
.Details_switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 28px;
    margin-bottom: 0px;
}

.Details_switch_small {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
    margin-bottom: 0px;
}

/* Hide default HTML checkbox */
.Details_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.Details_switch_small input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.Details_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.Details_slider_small {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.Details_slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
}

.Details_slider_small:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0px;
    background-color: white;
    transition: .4s;
    right: 0px;
    margin: 2px;
}

.Details_switch :first-child:checked+.Details_slider {
    background-color: var(--firmgreen);
}

.Details_switch :first-child:focus+.Details_slider {
    box-shadow: 0 0 1px #2196F3;
}

.Details_switch :first-child:checked+.Details_slider:before {
    transform: translateX(22px);
}

.Details_switch_small :first-child:checked+.Details_slider_small {
    background-color: var(--firmgreen);
}

.Details_switch_small :first-child:focus+.Details_slider_small {
    box-shadow: 0 0 1px #2196F3;
}

.Details_switch_small :first-child:checked+.Details_slider_small:before {
    transform: translateX(18px);
}


/* Rounded sliders */
.Details_slider.round {
    border-radius: 34px;
}

.Details_slider.round:before {
    border-radius: 50%;
}

.Details_directionbtn {
    background-color: transparent;
    border: none;
    height: 100%;
    padding: 0px;
}

/* Rounded sliders */
.Details_slider_small.round {
    border-radius: 34px;
}

.Details_slider_small.round:before {
    border-radius: 50%;
}

.Details_directionbtn_small {
    background-color: transparent;
    border: none;
    height: 100%;
    padding: 0px;
}

.Details_lineacontainer {
    border: 1px solid var(--maindark);
    width: 100%;
    border-radius: 0px 5px 5px;
    margin-bottom: 40px;
}

.Details_linercol {
    display: flex;
    flex-direction: column;
    margin-top: 5px;

}

.Details_linerroot {
    height: 40px;
    padding: 5px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
}

.Details_linerselected {
    background-color: var(--mainpink);
    color: white;
}

.Detailsfont_root {
    display: flex;
    flex-direction: column;
}

.Detailsfont_top {
    display: flex;
    flex-direction: column;

}

.Detailsfont_container {
    height: 275px;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    padding: 3px 0px 0px 3px;
}

.Detailsfont_left {
    padding: 15px;
    border-radius: 10px;
    margin-right: 10px;
    height: max-content;
    display: flex;
    flex-direction: column;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}

.Detailsfont_right {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 3px;
    overflow: auto;
    width: calc(100% - 222px );
    place-content: flex-start;
}

.Detailsfont_btm {
    display: flex;
    flex-direction: column;
}

.Detailsfont_ul {
    padding: 0 10px;
    margin: 5px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Detailsfont_li {
    width: 100%;
    height: 120px;
    padding: 5px 10px;
    display: inline-flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 1px solid var(--subgrey);
    border-bottom: none;
    color: var(--maindark);
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
}
.Detailsfont_liselected{
    border: 3px solid var(--mainpink) !important;
}

.Detailsfont_toprightff{
    font-size: 12px;
    font-family:Lato;
    place-self: flex-end;
    top: 5px;
    right:10px;
    position: absolute;
}
.Detailsfont_actualtxt{
    font-size: 21px;
}

.Detailsfont_li:last-child {
    border-bottom: 1px solid var(--subgrey);
}

.Detailsfont_li:hover {
    /* border: 2px solid var(--mainpink); */
    transition: all 0.2s ease 0s;
    background-color: var(--hoverpink);
}   
/* .Detailsfont_arrowsroot{
    display: flex;
    flex-direction: row;
    place-content: flex-end;
    margin-top: 10px;
} */

/* .Detailsfont_arrowleft{
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #333333;
    cursor: pointer;
    padding:10px;

    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0.5px solid #333333;
}
.Detailsfont_arrowright{
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #333333;
    cursor: pointer;
    padding:10px;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0.5px solid #333333;
} */


.Detailsfont_arrowsroot{
    display: flex;
    flex-direction: column;
    place-content: space-between;
    padding: 0px 0px 0px 5px;
    align-items: center;

}
.Detailsfont_arrowleft{
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #333333;
    cursor: pointer;

    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0.5px solid #333333;
    transform: rotate(90deg);

    border: none;
    background-color: transparent;
    width:max-content;
    height:max-content;

    
}
.Detailsfont_arrowright{
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #333333;
    cursor: pointer;

    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0.5px solid #333333;
    transform: rotate(-270deg);

    border: none;
    background-color: transparent;
    width:max-content;
    height:max-content;
}
.Detailsfont_fontslen{

     border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-image: initial;
    position: absolute;
    bottom: 0px;
    width: 100%;
    place-content: center;
    font-family: Lato;
    padding: 5px 0px;
    background-color: #efeff0;
    color: var(--maindark);
    font-size: 12px;
}
.Detailsfont_closebtn{
    
    display: flex;
    place-content: center;
    font-size: 12px;
    border: none;
    width: max-content;
    padding: 3px 10px;
    border-radius: 5px;
    align-items: center;
    place-self: center;
    cursor: pointer;
    background-color: transparent;
    
}
.Detailsdrag_root{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color: white;
    overflow:visible;
}
.Detailsdrag_closeicon{
    border-radius: 10px 0px 0px 10px;
    border-top: 1px solid rgb(204, 204, 204);
    border-right: none;
    border-bottom: 1px solid rgb(204, 204, 204);
    border-left: 1px solid rgb(204, 204, 204);
    border-image: initial;
    display: flex;
    place-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    background-color: white;
    min-width: 35px;

}
.Detailsdrag_dragicon{
    display: flex;
    place-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    height: 100%;
    border-top: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-left: none;
    border-image: initial;
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    min-width: 35px;
}
.Detailsdrag_col{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Detailsdrag_iconroot{
    height: 40px;
    background-color: white;
    border-top: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: none;
    border-left: 1px solid rgb(204, 204, 204);
    border-image: initial;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: max-content;
    padding: 0px 10px;
    border-radius: 10px 10px 0px 0px;
}
