.rightex_root{
    display: flex;
    width: 30vw;
    height: calc( 100vh - 40px );
    flex-direction: column;
    background-color: transparent;
    overflow: hidden;
    position: absolute;
    right: 0px;
    margin: 10px;
    border-radius: 5px;
    top: 20px;
    place-content: flex-end;
    align-items: center;
}
.rightex_banner{
    display: flex;
    width: 30vw;
    height: 100px;
    background-position: center center;
    background-color: white;
    background-repeat: no-repeat;
    background-size: contain;
    position: fixed;
    z-index: 1;
    margin-top: -20px;
    border-radius: 5px;
    box-shadow: #00000033 -1px 6px 20px 3px;
}
.rightex_col{
    display: flex;
    flex-direction: column;
    height: max-content;
}