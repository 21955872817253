#sclookcontent_root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    overflow-x: hidden;
}

#sclookcontent_topportion {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: max-content;
    place-content: space-between;
    /* padding-bottom: 10px; */
}

.sclookcontent_corner {
    object-fit: contain;
}

.sclookcontent_leftpartial {
    transform: scaleX(-1);
}

.sclookcontent_rightpartial {
    position: relative;
}


.sclookcontent_topht {
    height: 20vw;
    min-height: fit-content;
}

#sclookcontent_leftcorner {
    width: 20vw;
    height: 20vw;
}

#sclookcontent_rightcorner {
    width: 20vw;
    height: 20vw;
}

#sclookcontent_toptexts {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    text-align: center;
    width: 60vw;
    height: max-content;
    place-self: center;
}

.sclookcontent_banner {
    height: auto;
    object-fit: contain;
    padding-bottom: 10px;
}

.sclookcontent_tablename {
    display: flex;
    place-content: center;
    align-items: center;
    text-align: center;
}

.sclookcontent_seatnamescol {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    text-align: center;
}

@media (min-width:250px) {
    .sclookcontent_colrow {
        display: flex;
        flex-direction: column;
    }

    .sclookcontent_tableandseat {
        flex-direction: column;
        width: 100vw;
        padding: 30px 0px;
        align-items: center;
        place-content: center;
        display: flex;
        text-align: center;
    }

    .sclookcontent_tableandseat:first-child {
        padding-top: 60px;
    }

    .sclookcontent_tableandseat:last-child {
        padding-bottom: 160px;
    }

    .sclookcontent_table {
        font-size: 24px;
        padding-bottom: 5px;
    }

    .sclookcontent_seat {
        font-size: 18px;
    }


    .sclookcontent_searchmodal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #707070b5;
    }
    
    .sclookcontent_searchmodalcenter {
        display: flex;
        flex-direction: column;
        /* background-color: white; */
        box-shadow: rgb(0 0 0 / 10%) 0px 1px 20px 0px;
        place-self: center;
        align-self: center;
        position: absolute;
        border-radius: 5px;
        overflow-y: auto;
        place-content: space-between;
    }
    .sclookcontent_modalsearchbtn{
        bottom: 0;
        width: 100%;
        padding: 5px;
        border: 1px solid transparent;
        border-radius: 5px;
        height: 65px !important;
        font-size: 18px;
        background-color: transparent;
        font-family: Lato;
    }
    .sclookcontent_searchinput{
        border: 1px solid transparent;
        height: 40px;
        outline: none !important;
        font-size: 16px;
        border-bottom: 1px solid var(--maindark);
        margin: 0px 10px;
    }
    .sclookcontent_searchinput::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        /* color: inherit; */
        color: var(--subdark);
        
    }
    .sclookcontent_searchinput:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        /* color: inherit; */
        color: var(--subdark);
        opacity: 1;
    }
    .sclookcontent_searchinput::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        /* color: inherit; */
        color: var(--subdark);
        opacity: 1;
    }
    .sclookcontent_searchinput:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        /* color: inherit; */
        color: var(--subdark);

    }

    .sclookcontent_searchresult{
        display: flex;
        flex-direction: row;
        margin-top: 5px;
    }
    .sclookcontent_searchresultcol{
        height: calc( 100% - 64px );
        max-height: calc( 100% - 64px );
        overflow-x: hidden;
        padding-bottom: 20px;
        margin: 10px;
    }
    .sclookcontent_searchresultcontent{
        width:50%;
        padding: 5px;
    }

    .sclookcontent_searchicon {
        height: 60%;
        width: 100%;
        place-content: center;
        display: flex;
        align-items: center;
        pointer-events: none;
        user-select: none;
    }

    .sclookcontent_btmbar {
        display: flex;
        height: 80px;
        position: fixed;
        bottom: 0;
        place-content: center;
        border-bottom:none;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        align-items: center;
    }
    .sclookcontent_searchmodalcenter{
        width: 250px;
        height: 350px;
        right: calc(50% - 125px);
        top: calc(50% - 175px);
    }


}

@media (min-width:641px) {
    .sclookcontent_colrow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        place-content: center;
        align-items: center;
    }

    .sclookcontent_tableandseat {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 30px 0px;
        align-items: center;
        place-self: start;
    }

    .sclookcontent_table {
        font-size: 24px;
        padding-bottom: 5px;
    }

    .sclookcontent_seat {
        font-size: 18px;
    }

    .sclookcontent_tableandseat:first-child {
        padding-top: 30px;
    }

    .sclookcontent_tableandseat:last-child {
        padding-bottom: 160px;
    }

    .sclookcontent_btmbar {
        display: flex;
        height: 80px;
        position: fixed;
        bottom: 0;
        place-content: center;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
    .sclookcontent_searchmodalcenter{
        width: 320px;
        height: 450px;
        right: calc(50% - 160px);
        top: calc(50% - 225px);
    }
}
