.Jmap_mobileroot {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    position: relative;
    font-family: Lato;
}

.Jmap_mapmobile {
    width: 100vw;
    overflow: hidden;
}

.Jmap_popupbtnclick {
    width: 100% !important;
    height: 34px !important;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    margin: 2.5px 0px;
}

.Jmap_modalmobile {
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: white;
    z-index: 9999;
    padding: 10px;
    flex-direction: column;
    overflow: hidden;

}

.Jmap_rightuslookupmobile {
    width: 38px;
    display: flex;
    text-align: center;
    padding: 5px 0px;
    border: 1px solid var(--maindark);
    margin: 0px 0px 5px 0px;
    align-items: center;
    place-content: center;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    text-decoration: none;
    color: var(--maindark) !important;
}

.Jmap_titlemobile {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 5px;
}

.Jmap_titleclose {
    width: 80px;
    display: flex;
    padding: 10px;
    border: 1px solid #d82a56bd;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: white;
    background-color: #d82a56bd;
    font-weight: bold;
    place-content: center;
    place-self: flex-end;
}

.Jmap_tagwrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 10px 10px 10px;
}

.Jmap_tagmobiletrue {
    min-width: max-content;
    padding: 10px;
    margin: 5px 0px;
    text-align: center;
    align-items: center;
    background-color: var(--maindark);
    color: white;
    outline: none;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    place-content: center;
    font-size: 14px;
    overflow: hidden;
    white-space: pre;
    height: 30px;
    display: flex;
    min-height: 30px;
    width: max-content;
    margin-right: 5px;
}

.Jmap_tagmobilefalse {
    min-width: max-content;
    padding: 10px;
    margin: 5px 0px;
    text-align: center;
    align-items: center;
    background-color: #ffffff;
    color: white;
    outline: none;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    border-radius: 5px;
    place-content: center;
    font-size: 14px;
    overflow: hidden;
    white-space: pre;
    height: 30px;
    display: flex;
    min-height: 30px;
    width: max-content;
    margin-right: 5px;
}

.Jmap_rowmobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Jmap_titleclosemobile {
    width: 80px;
    display: flex;
    padding: 10px;
    border: 1px solid #d82a56bd;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: white;
    background-color: #d82a56bd;
    font-weight: bold;
    place-content: center;
    place-self: flex-end;
}

.Jmap_taghexmobile {
    width: 80px;
    border: 1px solid var(--maindark);
    cursor: pointer;
    text-decoration: none !important;
    color: white;
    background-color: var(--maindark);
    place-content: center;
    place-self: center;
    border-radius: 5px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    margin: 5px;
    padding: 0px;
    height: 40px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
}

.Jmap_taghexunclickable {
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none !important;
    color: var(--maindark);
    background-color: white;
    font-weight: bold;
    place-self: flex-start;
    font-size: 24px;
    align-items: center;
    place-content: center;
    text-align: center;
}