.Checklist_equal {
    padding: 0px 10px;
    border-radius: 5px;
    margin: 20px 10px 10px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #6dc761;
    color: white;
    place-self: flex-end;
    border: 1px solid #6dc761;
    font-weight: normal;
}

.Checklist_nonequal {
    padding: 0px 10px;
    border-radius: 5px;
    margin: 20px 10px 10px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    place-self: flex-end;
    border: 1px solid var(--maindark);
    background-color: var(--maindark);
    color: #ffffff;
    font-weight: normal;
}

.Checklist_additem {
    margin: 20px 10px 10px 0px;
    width: max-content;
    height: max-content;
    text-align: center;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    padding: 5px 10px;
}

.Checklist_delitem {
    padding: 0px 5px;
    margin: 20px 0px 10px 5px;
    border-radius: 5px;
    width: max-content;
    height: max-content;
    text-align: center;
    border: thin solid rgb(112, 112, 112);
    color: var(--mainpink);
    font-weight: bold;
    padding: 5px 10px;
}

.Checklist_deledititem {
    display: flex;
    align-self: center;
    place-content: center;
    align-items: center;
    min-height: 30px;
    min-width: 30px;
    max-width: 30px;
    margin: 5px;
    border-radius: 5px;
    background-color: transparent;
    outline: none;
    border: none;
    /* border: thin solid #707070; */
}

.Checklist_chkbtnroot {
    height: 60px;
    width: max-content;
    display: flex;
    align-items: center;
    place-self: center;
    padding: 5px;
}

.Checklist_chkbtncheck {
    margin: 5px;
    padding: 8px 8px 8px 0px;
    color: #6dc761;
}

.Checklist_chkbtnblank {
    margin: 5px;
    padding: 8px 8px 8px 0px;
    color: #707070;
}

.Checklist_content {
    position: relative;
    width: 100%;
    outline: none;
    width: 100%;
    flex-wrap: wrap;
    min-height: 60px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    border-radius: 0px 5px 5px 0px;
    margin: 0px;
    padding: 5px;
}

.Checklist_link {
    align-self: center;
    margin: 5px 0px 0px;
    padding: 0px 4px;
    border-radius: 5px;
    color: white;
    background-color: #f77f7f;
    text-align: center;
}

#Checklist_main {
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px;
    padding: 0px 10px;
    display: flex;
    background-color: #fbfbfb;
    align-items: center;
}

.Checklistedit_devicebtns {
    width: 100%;
    display: flex;
    place-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--subgrey);
    background-color: #ffffff;
    position: relative;
    margin: 10px 0px;
}

.Checklistedit_title {
    padding: 0px 5px;
    margin-bottom: 10px;
    white-space: nowrap;
    outline: none;
    background-color: #ffffff;
    border: 1px solid var(--subgrey);
    overflow-x: scroll;
    display: flex; 
    flex-direction: column;
}

.Checklistedit_titlenohover {
    padding: 0px 5px;
    margin-bottom: 10px;
    white-space: nowrap;
    outline: none;
    background-color: #ffffff;
    border: 1px solid var(--subgrey);
    overflow-x: scroll;
    display: flex; 
    flex-direction: column;
}

.Checklistedit_objsroot {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 0px;
}

@media (min-width:240px) {
    .Checklistedit_devicebtns {
        width: 100%;
    }

    .Checklistedit_title {
        width: 100%;
        font-size: 24px;
        min-height: 70px;
    }

    .Checklistedit_titlenohover {
        width: 100%;
        font-size: 24px;
        min-height: 70px;
    }

    .Checklistedit_objsroot {
        width: 100%;
    }
}

@media (min-width:641px) {
    .Checklistedit_devicebtns {
        width: 100%;
    }

    .Checklistedit_title {
        width: 100%;
        font-size: 24px;
        min-height: 85px;

    }


    .Checklistedit_titlenohover {
        width: 100%;
        font-size: 24px;
        min-height: 85px;
    }


    .Checklistedit_objsroot {
        width: 100%;
    }
}

@media (min-width:764px) {
    .Checklistedit_devicebtns {
        width: 700px;
    }

    .Checklistedit_title {
        width: 700px;
        font-size: 32px;
        min-height: 90px;

    }

    .Checklistedit_titlenohover {
        width: 700px;
        font-size: 32px;
        min-height: 90px;

    }

    .Checklistedit_objsroot {
        width: 700px;
    }
}

@media (min-width:961px) {
    .Checklistedit_devicebtns {
        width: 750px;
    }

    .Checklistedit_title {
        width: 750px;
        font-size: 48px;
        min-height: 118px;
    }

    .Checklistedit_titlenohover {
        width: 750px;
        font-size: 48px;
        min-height: 118px;
    }

    .Checklistedit_objsroot {
        width: 750px;
    }
}

@media (min-width:999px) {
    .Checklistedit_devicebtns {
        width: 900px;
    }

    .Checklistedit_title {
        width: 900px;
        font-size: 48px;
        min-height: 118px;
    }
    .Checklistedit_titlenohover {
        width: 900px;
        font-size: 48px;
        min-height: 118px;
    }

    .Checklistedit_objsroot {
        width: 900px;
    }
}

.Checklist_cattitle_withouthover {
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.Checklist_archivecattitle {
    padding: 5px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;

}

.Checklist_cattitle {
    padding: 5px;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;

}

.Checklist_cattitle:hover {
    box-shadow: -1px 0px 14px 0 rgba(31, 31, 31, 0.12);
}

.Checklistedit_title:hover {
    box-shadow: -1px 0px 14px 0 rgba(31, 31, 31, 0.12);
}

.dragggg:hover {
    box-shadow: -1px 0px 14px 0 rgba(31, 31, 31, 0.12);
}

.Checklist_collapsible_item:hover {
    box-shadow: -1px 0px 14px 0 rgba(31, 31, 31, 0.12);

}

.Checklist_maindrag {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.Checklist_checkboxrow {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    height: 45px;
    width: 100%;
    margin: 0px 10px 15px 10px;
}

.Checklist_lbl {
    color: var(--maindark);
    margin: 10px 0px 10px 5px;
    font-weight: normal;
    width: max-content;
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 5px;
    user-select: none;
    cursor: pointer;
}

.Checklist_dateroot {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 0px 10px 15px 10px;
}

.Checklist_dateinput {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    height: 40px;
}

.Checklist_calendarbtnclose {
    color: #eb5d7b;
    padding: 5px;
    z-index: 1;
    right: 0px;
    background-color: transparent;
    border: none;
    outline: none;
    place-content: center;
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
    position: relative;
}

.Checklistedit_deleteroot {
    width: 100%;
    place-content: center;
    align-items: center;
    display: flex;
    margin-top: 50px;
}

.Checklistedit_delete {
    width: 150px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    background-color: white;
    border: 1px solid var(--maindark);
    border-radius: 5px;
}

.Checklist_addcat {
    width: 100%;
    padding: 10px 0px;
    border: 1px solid var(--maindark);
    margin-top: 10px;
    color: rgb(255, 255, 255);
    background-color: var(--maindark);
    border-radius: 5px;
    font-size: 26px;
}

.Checklist_templatebtn {
    margin: 0px 10px 15px;
    width: calc(100% - 20px);
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
}

.Checklist_templatebtn:hover {
    background-color: var(--hoverpink);
}

.Checklist_downloadbtn {
    margin: 0px 0px 10px 10px;
    width: calc(100% - 20px);
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
}

.Checklistedit_modebtn {
    width: 100px;
    height: 40px;
    background-color: white;
    color: var(--maindark);
    border: 1px solid var(--maindark);
    font-size: 16px;
}

.Checklistedit_modebtnselected {
    width: 100px;
    height: 40px;
    background-color: var(--maindark);
    color: white;
    border: 1px solid var(--maindark);
    font-size: 16px;
}

.Checklistedit_modbtn {
    height: 40px;
    background-color: #ffffff;
    color: var(--maindark);
    border: 1px solid var(--maindark);
}

.Checklist_archiveroot {
    border: 1px solid #d3d3d3;
    margin-bottom: 15px;
}

.Checklist_archivesectionname {
    height: 40px;
}

.Checklist_archiveitemroot {}

.Checklist_archiveitem {
    height: 60px;
    border-top: 1px solid #d3d3d3;
}

.Checklist_deletesectionroot {
    display: flex;
    flex-direction: row;
    place-content: center;
}

.Checklist_deletesectioncancel {
    width: 150px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    background-color: white;
    border: 1px solid var(--maindark);
    border-right: none;
    border-radius: 5px 0px 0px 5px;
}

.Checklist_deletesectioncancel:hover {
    background-color: var(--hoverpink);
}
.Checklist_deletesectioncfm {
    width: 150px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    background-color: white;
    border: 1px solid var(--maindark);
    border-radius: 0px 5px 5px 0px;
}

.Checklist_deletesectioncfm:hover {
    background-color: var(--hoverpink);
}

.Checklist_deletetext {
    display: flex;
    text-align: center;
    margin: 0px 10px 10px 10px;
}