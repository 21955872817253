.Table_checkboxroot {
    width: 100%;
    height: 100%;
    place-content: center;
    align-items: center;
    display: flex;
}

.Table_headwrapper {
    z-index: 3;
    /* width: calc(100vw - 2px); */
    position: sticky;
    overflow: hidden scroll;
}

.Table_bodywrapper {
    width: 100%;
    overflow: scroll;
}

@media (min-width:320px) {
    .Table_bodywrapper {
        height: calc(100vh - 300px);
    }
}

@media (min-width:481px) {
    .Table_bodywrapper {
        height: calc(100vh - 300px);
    }
}

@media (min-width:641px) {
    .Table_bodywrapper {
        height: calc(100vh - 200px);
    }
}

@media (min-width:961px) {
    .Table_bodywrapper {
        height: calc(100vh - 200px);
    }
}

@media (min-width:1025px) {
    .Table_bodywrapper {
        height: calc(100vh - 200px);
    }
}

@media (min-width:1281px) {
    .Table_bodywrapper {
        height: calc(100vh - 200px);
    }
}

.Table_checkbox{
    width: 17px !important;
    height: 17px !important;
}
.th:hover{
    background-color: var(--maindark) !important;
    color: #ffffff;
}