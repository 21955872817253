.Renderpricelists_tagroot{
    display: flex;
    overflow: auto;
    width: 100%;
    place-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    flex-direction: row;
    padding-bottom: 15px;
    flex-wrap: wrap;
}
.Renderpricelists_filtertag{
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    letter-spacing: 0.5px;
    white-space: nowrap;
    border: 1px solid var(--maindark);
    background-color: var(--maindark);
    color: white;
    height: 30px;
    align-items: center;
    place-content: center;
    display: flex;
    text-align: center;
}

.Renderpricelists_slash{
    display: flex;
    align-items: center;
    padding: 0px 5px;
}

.Renderpricelists_expandiconroot{
    width: 30px;
    display: flex;
    align-items: center;
    place-content: center;
}

.Renderpricelists_expand{
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid var(--subgrey);
    padding: 8px;
    display: flex;
    flex-direction: row;
    color: var(--maindark);
    font-family: 'Lato';
    align-items: center;
    place-content: center;
    min-width: 120px;
    min-height: 40px;
}
.Renderpricelists_expandname{
    min-width:70px;
    display: flex;
    place-content: center;
    align-items: center;
    text-align: center;
}