.Reviewcard_root{
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    width: 100%;
    justify-content: center;
    border: 1px solid var(--subgrey);
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: Lato;
}

.Reviewcard_root:hover{
    border: 1px solid var(--maindark);
}

.Reviewcard_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.Reviewcard_row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.Reviewcard_profileimg{
    margin: 0px 10px 10px 0px;
    border: 1px solid var(--subgrey);
    border-radius: 40px;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
}

.Reviewcard_col{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Reviewcard_namestar{
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: space-between;
    padding: 5px 0px 15px 0px;
}

.Reviewcard_name{
    width: max-content;
    overflow-x: hidden;
    color: var(--maindark);
    margin-bottom: 10px;
}

.Reviewcard_date{
    color: var(--maindark);
    font-size: 14px;
    place-self: center;
}

.Reviewcard_more{
    background-color: white;
    border: none;
    color: var(--maindark);
    width: 100%;
    margin-top: 10px;
    letter-spacing: 0.5px;
    font-size: 12px;
}