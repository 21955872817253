.T2_root{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    overflow: hidden;
    position: fixed;
    background-color: #fff4f4;
}
.T2_imgtop{
    object-fit: contain;
    width: 100%;
    height: 45%;
    position: absolute;
    top: -22.5%;
    transform: rotate(180deg);
    user-select: none;
}
.T2_imgbtm{
    object-fit: contain;
    width: 100%;
    height: 45%;
    position: absolute;
    bottom: -22.5%;
    user-select: none;
}
.T2_contentWEB{
    position: absolute;
    top: 20%;
    width: 70%;
    height: 60%;
    border: thin solid black;
    border-radius: 15px;
    place-self: center;
    background-color: white;
}
.T2_contentTABLET{
    position: absolute;
    top: 20%;
    width: 70%;
    height: 60%;
    border: thin solid black;
    border-radius: 15px;
    place-self: center;
    background-color: white;
}
.T2_contentMOBILE{
    position: absolute;
    top: 10%;
    width: 95%;
    height: 80%;
    /* border: thin solid black; */
    /* border: 0.2em solid #ffc6c629; */
    border: 0.2em solid white;
    border-radius: 15px;
    place-self: center;
    background-color: #fffbf7;
}
.T2_innercontentWEB{
    
}

.T2_innercontentTABLET{

}

.T2_innercontentMOBILE{
    top: 22.5%;
    position: absolute;
    width: 95%;
    left: 2.5%;
    padding: 5px 10px;
    height: 55%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    font-family: Lato;
}
.T2_navicon{
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 5px 0px;
    border: 1px solid #eeee;
}
.T2_rsvp{
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid black;
    font-size:18px;
    bottom: 0;
    position: absolute;
    margin: 10px;
    padding: 10px;
    right: 0px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 5px 0px;
    border: 1px solid #eeee;
}
