.Scatterplot_root {}

.Scatterfixedxaxis_root {
    left: 0;
    position: fixed;
    background-color: #fffc;
}

.Scatterplotwindow_root{
    left: 0;
    position: fixed;
    background-color: #fffc;
}

.Scatterplot_tooltiproot {
    display: flex;
    flex-direction: column;
    width: 270px;
    min-height: 240px;
    max-height: auto;
    /* height: max-content;
    min-height: 145px; */
    background-color: white;
    color: var(--maindark);
    font-family: Lato;
    border: 1px solid var(--maindark);
    font-size: 14px;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    z-index: 999999999
}

.Scatterplot_tooltiptop {
    font-size: 12px;
    font-weight: bold;
}

.Scatterplot_tooltiprow {
    display: flex;
    flex-direction: row;
    height: 36px;
}

.Scatterplot_tooltip {
    position: absolute;
    top: 0;
    left: 0;
}

.Scatterplot_highlighted {
    stroke: gold;
    /* Add a black border around the highlighted circle */
    stroke-width: 2px;
    /* Increase the width of the border */
    fill-opacity: 0.7;
    /* Reduce the opacity of the circle to make it semi-transparent */

}

/* for touchscreen */
.Scatterplot_tooltiproottouchscreen {
    display: flex;
    flex-direction: column;
    width: 270px;
    min-height: 240px;
    max-height: auto;
    background-color: white;
    color: var(--maindark);
    font-family: Lato;
    border: 1px solid var(--maindark);
    font-size: 14px;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    z-index: 999999999;
    place-content: space-between;

}

.Scatterplot_tooltipclose {
    border-radius: 10px 0px 0px 10px;
    background-color: #fff;
    border: 1px solid var(--maindark);
    padding: 5px;
    width: 50%;
}

.Scatterplot_tooltipaddtoselection {
    border-radius: 0px 10px 10px 0px;
    background-color: #fff;
    border: 1px solid var(--maindark);
    border-left: none;
    padding: 5px;
    width: 50%;
}

#mobilebtn {
    opacity: 0;
}

.Scatterplot_fontlabel {
    font-weight: bold;
}

.Scatterplot_text {
    white-space: break-spaces;
    padding-bottom: 3px;
    text-overflow: ellipsis;
}

.Scatterplot_capacityitem {
    padding-right: 5px;
}

.Scatterplot_popup {
    display: flex;
    place-content: center;
    width: 100%;
    position: fixed;
    z-index: 99;
    bottom: 10%;
    overflow: hidden;
}

.Scatterplot_popupitem {
    background-color: var(--maindark);
    color: white;
    padding: 10px 50px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    place-content: center;
    text-align: center;
}