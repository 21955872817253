.charart_fmsgroot{
    width: 100%;
    display: flex;
    justify-content: center;
}
.charart_fmsgcontent{
    width: 80%;
    height: auto;
    padding: 70px;
    margin: 5px;
    background-color: black;
    color: white;
    border-radius: 10px;
    letter-spacing: 2px;
    text-align: center;
    display: flex;
    position: absolute;
    z-index:3;
}
.charart_blackext{
    position: absolute;
    width: 100vw;
    left: 0px;
    z-index: 2;
    height:150vh;
    background-color: #00000099;
}
.chartart_sqtransparent{
    position: absolute;
}