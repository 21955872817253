@media (min-width:481px){ 
    .R_imggallerygrid{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .R_imgcard{ 
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px) / 3);
        width: calc( ( 100vw - 320px) / 3);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:641px){
    .R_imggallerygrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .R_imgcard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 165px ) / 4);
        width: calc( ( 100vw - 165px ) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:961px){
    .R_imggallerygrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .R_imgcard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 165px ) / 4);
        width: calc( ( 100vw - 165px ) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:1025px){
    .R_imggallerygrid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .R_imgcard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px) / 4);
        width: calc( ( 100vw - 320px) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:1281px){ 
    .R_imggallerygrid{
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .R_imgcard{  /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px) / 5);
        width: calc( ( 100vw - 320px) / 5);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}
@media (min-width:1400px){ 
    .R_imggallerygrid{
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .R_imgcard{ /* Gallery */
        display: flex;
        flex-direction: column;
        border: thin solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc( ( 100vw - 320px ) / 5);
        width: calc( ( 100vw - 320px ) / 5);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: black;
        place-content: flex-end;
        align-items: flex-end;
    }
}