.selectedcat{
    justify-content: center; 
    border-bottom: 5px solid #eb5d7b;
    letter-spacing: 1px;
    color:#eb5d7b;
    user-select: none;
}
.unselectedcat{
    justify-content: center; 
    letter-spacing: 1px;
    border-bottom: 5px solid transparent;
    color:rgb(112, 112, 112);
    user-select: none;
}

.UHC_vtbtn:hover{
    background-color: rgb(247 127 127 / 0.2) !important;
    border:thin solid transparent !important;
    color:#707070!important;
    height:36px;
    user-select: none;
 }
 