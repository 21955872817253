.gbedit_wtroot{
    display: flex;
    flex-direction: row;
    top: 61px;
    position: relative;
}

.gbedit_mroot{
    display: flex;
    flex-direction: column;
    position: relative;
    touch-action: manipulation;
}

.gbedit_lookupbtn{
    border-radius: 5px;
    font-weight: bold;
    border: thin solid #eb5d7b;
    color: #eb5d7b;
    background-color: white;
    outline: none;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 40px;
    text-decoration: none !important;
    padding: 0px 10px;
    font-size: 15px;
    fill: #eb5d7b;
}

.gbedit_lookupbtn:hover{
    border: thin solid #eb5d7b;
    background-color: #eb5d7b;
    color: white;
    fill: white;
}

.gbedit_comp_WEB{
    display: flex;
    width:calc( 100vw - 270px );
    height: calc( 100vh - 61px );
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.gbedit_comp_TABLET{
    display: flex;
    width:calc( 100vw - 165px );
    height: calc( 100vh - 61px );
    position: relative;
    overflow-y: auto;
}
.gbedit_comp_MOBILE{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.gbedit_mmnetwork_selected{
    display: flex;
    width: 100%;
    border-radius: 5px;
    min-height: max-content;
    padding: 5px;
    height: 40px;
    margin: 0px 0px 15px 0px;
    align-items: center;
    place-content: center;
    flex-direction: column;
    max-height: max-content;
    font-size: 16px;
    background-color: white;
    border: 1px solid rgb(235, 93, 123);
    background-color: rgb(235, 93, 123);
    color: white;
    font-weight: bold;
}

.gbedit_mmnetwork{
    display: flex;
    width: 100%;
    border-radius: 5px;
    min-height: max-content;
    padding: 5px;
    height: 40px;
    margin: 0px 0px 15px 0px;
    align-items: center;
    border: 1px solid black;
    place-content: center;
    flex-direction: column;
    max-height: max-content;
    font-size: 16px;
    background-color: white;
    border: 1px solid black;
}
