#Inquiries_root {
    place-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    place-items: center;
    padding-bottom: 30px;
}

.Inquiries_item {
    width: 450px;
    padding: 10px;
    margin-bottom: 10px;
}

.Inquiries_vendorinforoot {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Inquiries_vendorinfo {
    margin-right: 5px;
    border: 1px solid var(--maindark);
    padding: 0px 10px;
    border-radius: 10px;
    color: var(--maindark);
    background-color: #ffffff;
}

.Inquiries_vendorinfo:hover {
    background-color: var(--hoverpink);
}

.Inquiries_userinforoot {
    margin-top: 10px;
}

.Inquiries_mainmsgroot {
    margin-top: 10px;
}

.Inquiries_title {
    font-size: 12px;
    color: var(--subdark);
}

.Inquiries_mainmsg {}

.Inquiries_yourcontactroot {
    margin-top: 10px;
}

.Inquiries_yourcontactitem {}

.Inquiries_extrainfo {
    margin-top: 15px;
    font-size: 10px;
    color: var(--subdark);
    display: flex;
    place-content: flex-end;
}


@media (min-width:320px) {
    .Inquiries_item {
        width: 100%;
        box-shadow: none;
        border-left: none;
        border-right: none;

    }

}

@media (min-width:481px) {
    .Inquiries_item {
        width: 450px;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border: 1px solid var(--lightdark);

    }

}
