.igl_successportal{
    height: calc(100% - 125px);
    width: 100%;
    font-family: Lato;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    place-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}
.igl_successtxt{
    font-size: 24px;
    color: var(--donegreen);
    padding: 10px 36px;
    display: flex;
    text-align: center;
}