.rc-slider-handle {
  border: 1px solid var(--maindark);
}

.rc-slider-dot-active {
  border: 1px solid var(--maindark);
}

.rc-slider-track {
  background-color: var(--maindark) !important;
}

.rc-slider-handle:focus {
  border-color: var(--maindark);
  box-shadow: 0 0 0 5px var(--maindark);
}

.rc-slider-handle-click-focused:focus {
  border-color: var(--maindark);
}

.rc-slider-handle:hover {
  border-color: var(--maindark);
}

.rc-slider-handle:active {
  border-color: var(--maindark);
  box-shadow: 0 0 5px var(--maindark);
}

.Pricelist_card_root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  margin: 7px;
  border: 1px solid #cdcdcd;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
}

.Pricelist_card_a:hover {
  text-decoration: none;
  color: var(--maindark);
}

.Pricelist_card_a:focus {
  text-decoration: none;
  color: var(--maindark);
}

.Pricelist_card_firstcomp {
  height: 20%;
  max-height: 20%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: center;
  color: var(--maindark);
}

.Pricelist_card_seccomp {
  height: 50%;
  max-height: 50%;
  max-width: 100%;
  min-width: 100%;
  width: min-content;
  align-self: center;
  position: relative;
}

.Pricelist_card_thirdcomp {
  display: flex;
  flex-direction: column;
  height: 30%;
  max-height: 30%;
}

.Pricelist_filtermoneyroot {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding: 20px 0px;
  pointer-events: none;
  user-select: none;
}

.Pricelist_filterdollarbox {
  width: 40%;
  border: 1px solid silver;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
}

.Pricelist_filterhyphen {
  width: 10%;
  display: flex;
  place-content: center;
  align-items: center;
  font-size: 20px;
}

.Pricelist_filterpricelabel {
  padding: 5px 0px 0px 10px;
  font-size: 12px;
  pointer-events: none;
  text-align: left;
}

.Pricelist_filterdollar {
  padding: 0px 10px;
  text-align: flex-start;
  font-size: 16px;
  height: 100%;
  align-items: center;
  place-content: flex-start;
  display: flex;
  pointer-events: none;
}

.Pricelist_apply {
  position: relative;
  bottom: 0;
  background-color: #ffffff;
  color: var(--maindark);
  border: 1px solid var(--maindark);
  min-height: 45px;
  width: 100%;
  margin: 0px;
}

.rc-slider-handle {
  width: 18px !important;
  height: 18px !important;
}

.Pricelistcard_root {
  transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0ms;
}

.Pricelistcard_root:hover {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px
}
.Pricelist_card_btmnamevt{
  border-top: 1px solid var(--lightdark);
  height: 83px;
  width: 100%;
  display: flex;
  place-content: center;
  place-items: center;
  position: relative;
}