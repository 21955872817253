#itineraryedit_root {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: fixed;
    top: 0px;
    z-index: 1;
    font-family: Lato;
}

.itineraryedit_btm {
    display: flex;
    flex-direction: row;

}

.itineraryedit_left {
    position: fixed;
    padding: 10px;
    overflow-y: auto;
}

.itineraryedit_leftWEB {
    width: calc(100% - 400px);
    height: calc(100% - 60px);
    position: fixed;
    background-color: #fbfbfb;
}

.itineraryedit_leftTABLET {
    width: calc(100% - 270px);
    height: calc(100% - 60px);
    position: fixed;
    background-color: #fbfbfb;
}

.itineraryedit_right {
    display: flex;
    flex-direction: column;
    right: 0px;
    top: 0px;
    z-index: 2;
    border: 1px solid #dddddd;
    border-top: none;
    border-bottom: none;
    border-right: none;
    overflow: auto;
    margin-top: 62px;
    background-color: white;
    position: fixed;
}

.itineraryedit_rightWEB {
    width: 400px;
    height: calc(100% - 60px);
    overflow-x: hidden;
}

.itineraryedit_rightTABLET {
    width: 270px;
    height: calc(100% - 60px);
    overflow-x: hidden;

}

#itineraryedit_mobilebtm {
    padding: 10px;
    height: 100%;
    place-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#itineraryedit_mobilebtmbtn {
    background-color: #ffffff;
    margin: 10px;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    padding: 5px 20px;
}

.itineraryedit_devicebtns {
    width: 100%;
    display: flex;
    place-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid var(--subgrey);
    border-radius: 5px;
    background-color: #ffffff;
}

#itineraryedit_leftcontentroot {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    margin-top: 10px;
    place-content: center;
    align-items: center;
}

#itineraryedit_or {
    padding: 5px;
}

.itineraryedit_startbtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 100px;
    place-content: center;
    margin-bottom: 10px;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    padding: 10px;

}

.itineraryedit_startbtntxt {
    padding: 5px 0px 0px 0px;
    pointer-events: none;
    user-select: none;
}

.itineraryedit_leftbtn {
    width: 100px;
    height: 40px;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    background-color: #ffffff;
}

.itinerary_rightcontentroot {
    width: 100%;
    /* height: 100%; */
    place-content: flex-start;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.itinerary_rightcontentsubcomp {
    display: flex;
    width: calc(100% - 20px);
}

.Itinerary_rightcontentsubcompfsroot {
    display: flex;
    flex-direction: row;
}

.Itineraryedit_rightcontentrootbtn {
    width: calc(100% - 20px);
    height: 40px;
    margin: 10px 10px 5px 10px;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
}

.Itineraryedit_closebtn {
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    color: #707070;
    height: 60px;
    width: 100%;
    align-items: center;
    display: flex;
    position: relative;
    left: calc(100% - 42px);
}

.itinerary_rightcontentbtngrp {
    display: flex;
    flex-direction: row;
}

.itinerary_rightcontentbtn {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
}

.itinerary_rightcontentnum {
    width: 100%;
    display: flex;
    place-content: center;
    align-items: center;
}

.itinerary_timelinemenu {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding: 0px 10px 0px 10px;
    margin-bottom: 10px;
}

.itinerary_timelinemenubtn {
    width: 33.33%;
    height: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    border-bottom: 5px solid transparent;
    font-size: 14px;
}

.itinerary_timelinemenubtnselected {
    border-bottom: 5px solid var(--mainpink);
    color: var(--mainpink);
    font-weight: bold;
}

.itinerary_timelinemenuicon {
    height: 50px;
    width: 50px;
    display: flex;
    place-self: flex-start;
    align-items: center;
    place-content: center;
    background-color: #ffffff;
    color: var(--maindark);
    border: 1px solid var(--maindark);
    border-radius: 5px;
}

.itinerary_deletedbtn {
    /* border: 1px solid var(--mainpink);
    color: var(--mainpink);
    background-color: #ffffff;
    height: 40px;
    align-items: center;
    place-content: center;
    display: flex;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 10px 15px;
    margin-left: 10px; */
    border: 1px solid var(--maindark);
    color: var(--maindark);
    background-color: #ffffff;
    height: 40px;
    align-items: center;
    place-content: center;
    display: flex;
    border-radius: 5px;
    margin: 10px 0px;
    margin-left: 10px;
}

#itinerary_gridbtn {
    display: flex;
    height: 40px;
    padding: 0px 10px;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    margin-left: 10px;
}

.itinerary_colorbtn {
    /* border: 1px solid var(--maindark); */
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    margin: 0px;
    padding: 0px;
    display: flex;
    place-content: center;
    margin-left: 5px;
    border-radius: 5px;
    align-items: center;
}

.itineraryedit_templateroot {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.itinerary_templatecontent {
    display: flex;
    flex-direction: column;
}

.itinerary_templatebtn {
    display: flex;
    height: 40px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    text-align: center;
    align-items: center;
}