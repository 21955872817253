.T0_TOP_ROOT {
    display: flex;
    min-height: 30px;
    max-height: 30px;
    height: auto;
    width: 100%;
    background-color: transparent;
    white-space: nowrap;
}

.T0_MAIN_ROOT {
    display: flex;
    min-height: 100px;
    max-height: 100px;
    height: auto;
    width: 100%;
    background-color: transparent;
    white-space: nowrap;
}

/* floralplacement : 0 start */
.T0_TOP_BANNERIMGFP0 {
    min-height: 60px;
    max-height: 60px;
    place-self: center;
    padding: 10px;
    object-fit: scale-down;
}

.T0_TOP_CORNERFP0 {
    min-height: 170px;
    max-height: 170px;
    width: 170px;
    place-self: center;
    object-fit: scale-down;
    position: absolute;
}

.T0_TOP_LEFTFP0 {
    transform: scaleX(-1);
    left: 0;
    top: 0;
}

.T0_TOP_RIGHTFP0 {
    right: 0;
    top: 0;
}

/* floralplacement : 0 end */

/* floralplacement : 1 start */
.T0_TOP_RGHTP1 {
    min-height: 370px;
    max-height: 370px;
    width: 370px;
    place-self: center;
    object-fit: scale-down;
    position: absolute;
    left: calc(50% + 101px);
    top: -12%;
    transform: rotate(285deg);
}

.T0_TOP_RGHTP11 {
    min-height: 370px;
    max-height: 370px;
    width: 370px;
    place-self: center;
    object-fit: scale-down;
    position: absolute;
    left: calc(50% + 10px);
    top: -20%;
    transform: rotate(189deg) scale(-1);
    z-index: -1;
}

.T0_TOP_RGHTP111 {
    min-height: 370px;
    max-height: 370px;
    width: 370px;
    place-self: center;
    object-fit: scale-down;
    position: absolute;
    left: -36%;
    top: -16%;
    transform: rotate(259deg) scale(-1);
    z-index: -1;
}

.T0_TOP_RGHTP1111 {
    min-height: 370px;
    max-height: 370px;
    width: 370px;
    place-self: center;
    object-fit: scale-down;
    position: absolute;
    left: calc(-39%);
    top: -9%;
    transform: rotate(249deg) scale(-1);
    z-index: -1;
}

/* floralplacement : 1 end */

.T0_TOPMAINCONTENT {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 200px);
    margin: 15px 100px 0px 100px;
    z-index: 999;
}

.TO_TOPMAINCONTENT1 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 200px);
    margin: 25px 100px 40px 100px;
    z-index: 999;
}

.T0_ASSIGNBTN {
    height: 40px;
    width: auto;
    padding: 5px 10px;
    border: 0.5px dashed black;
    border-bottom: none;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: white;
}

.T0_TABLENAME {
    width: 100%;
    place-content: center;
    align-items: center;
    display: flex;
}

.T0_SEATNAME {
    height: 100%;
    width: 100%;
    background-color: transparent;
    flex-direction: column;
    display: flex;
    align-items: center;
    place-content: center;
    overflow: hidden;
}

.T0_UNDERLINE {
    text-decoration: underline;
}