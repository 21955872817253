.fullscreen-enabled .my-component {
    background: white;
}
.gbcast_root{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: visible;
    flex-direction: row;
    display: flex;
    font-family: Lato;
}

.gbcastclosed_left{
    display: flex;
    width: 70vw;
    height: 100vh;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
}   
.gbcast_right{
    display: flex;
    width: 30vw;
    height: calc( 100vh - 45px );
    flex-direction: column;
    background-color: #fbfbfb;
    overflow-y: auto;
    margin-top:45px;
    border-left:thin solid rgba(112, 112, 112, 0.22);
}
.gbcastimg_root{
    width:auto;
    height:calc( 70vw / 8 );
    margin:5px;
    background-size: cover;
    background-color:var(--maindark);
    object-fit: scale-down;
    border-radius: 5px;
}
.gbcastimg_expandedroot{
    width:100%;
    height:100%;
    background-size: contain;
    background-color:var(--maindark);
    object-fit: scale-down;
    background-repeat: no-repeat;
    background-position: center;
}
.gbcastvid_root{
    width:125px;
    height:125px;
    margin:5px;
}
.gbcastvid_expandedroot{
    width:100%;
    height:100%;
}
.gbcastvid_index{
    color: white;
    background-color: var(--maindark);
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    width: max-content;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
    height: 30px;
    bottom:45px;
}
.gbcast_index{
    color: white;
    background-color: var(--maindark);
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
    height: 30px;
    top: calc( 100% - 40px);
    max-width: 90%;
    overflow: hidden;
}
.gbcast_hex{
    color: #f7c54c;
    padding-right: 5px;
}
.gbcastmedias_root{
    display: -ms-flexbox;
    display: grid;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    height: calc( 100% - 45px );
    overflow: auto;
    place-content: flex-start;
    bottom: -45px;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: 5px;
    grid-template-rows: auto;
    padding: 10px;
}
.gbcastdom_root{
    display:flex;
    flex-direction: row;
    height: 45px;
    position: absolute;
}
.gbcast_loadingroot{
    width:100vw;
    height: 100vh;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: white;
}

.gbcastexpandedmsg_root{
    display: flex;
    flex-direction: column;
    position:relative;
    /* height: 100vh; */
    /* flex-direction:column-reverse; */
}
.gbcast_secroot{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    width:max-content;
    border:thin solid #707070;
    border-radius:5px;
    padding:3px;
    margin: 5px;
    left:80px;
    position: absolute;
    background-color: white;
    z-index: 3;
}
.gbcast_sec{
    width:35px;
    height:35px;
    place-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    cursor:pointer;
}
.gbcast_secselected{
    width:35px;
    height:25px;
    place-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    cursor:pointer;
    color: white;
    background-color: #d82a56cf;
    user-select: none;
    border-radius: 5px;
}
.gbcastsignalon{
    width: 35px;
    height: 35px;
    position: absolute;
    right: 40px;
    z-index: 3;
    margin: 5px;
    border-radius: 5px;
    border:thin solid #d3f179;
    background-color:#7cca7c
}
.gbcastsignaloff{
    width: 35px;
    height: 35px;
    position: absolute;
    right: 40px;
    z-index: 3;
    margin: 5px;
    border-radius: 5px;
    border:thin solid #d3f179;
    background-color:red
}
.gbcastinputmedia{
    position: absolute;
    cursor: pointer;
    cursor: pointer;
    width:35px;
    height: 35px;
    opacity:0;
    padding:0px;
    margin:5px;
    left:0px;
}
.gbcastexpandsvg{
    z-index: 3;
    cursor:pointer;
    width:35px;
    height:35px;
    margin:5px;
    right:0px;
    vertical-align:middle;
    fill:#707070;
    overflow:hidden;
    position:absolute;
    display:flex;
    background-color:white;
    border-radius:5px;
    border:thin solid #707070
}