.gbrwd_msgroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0px 4px 15px 4px;
}

.gbrwd_numbers_m {
    display: flex;
    place-content: flex-end;
    height: 30px !important;
    min-height: 40px;
    margin-bottom: 10px;
}

.gbrwd_numbers {
    display: flex;
    place-content: flex-end;
    margin-top: 20px;
    height: 30px !important;
    min-height: 30px;
    margin-bottom: 10px;
}

.gbrwd_numberitem {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    margin-right: 10px;
}

.gbrwd_digit_m {
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 0px 10px;
    margin-left: 5px;
    height: 40px;
    display: flex;
    align-items: center;
}

.gbrwd_digit {
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 0px 10px;
    margin-left: 5px;
    height: 25px;
    display: flex;
    align-items: center;
}

.rwd_audioplayer {
    width: 100%;
    padding: 5px 0px;
    height: 60px;
}

.rwd_txt {
    overflow-wrap: break-word;
    height: auto;
    min-height: calc(400px * 0.6);
    padding-bottom: 10px;
}

.rmsg_img {
    width: calc(400px * 0.2);;
    height: calc(400px * 0.2);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #707070;
    border-radius: 5px;
    margin-bottom: 5px;
}

.rwd_nameroot {
    display: flex;
    flex-direction: row;
    border: 1px solid #bfbfbf;
    margin-top: 5px;
    padding: 0px 5px;
    border-radius: 10px;
}

.rwd_nameicon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    fill: currentcolor;
    overflow: hidden;
    display: flex;
    place-self: center;
    margin-right: 5px;
}

.rwd_name {
    height: calc(400px * 0.1);
    align-items: center;
    display: flex;
}

.rwd_emojiroot {
    height: calc(400px * 0.1);
    align-items: center;
    display: flex;
    border: 1px solid #bfbfbf;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 80px;
    position: relative;
    align-items: flex-start;
}

.rwd_emojilabel {
    font-weight: 500;
    padding: 5px 10px 0px 10px;
    left: 0;
    font-size: 12px;
}

.rwd_emoji {
    margin: 5px 10px 0px 10px;
}

.rwd_col{
    display: flex;
    flex-direction: column;
}

.rwd_row {
    /* height: calc(400px * 0.1); */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rwd_menurow {
    right: 100%;
    display: flex;
    place-content: flex-end;
}

.rwd_time {
    display: flex;
    align-items: center;
    background-color: black;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    padding: 2px 5px;
    margin-left: 2px;
    height: 30px;
}

.rwd_index {
    display: flex;
    align-items: center;
    background-color: #dd3653;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    padding: 2px 5px;
    height: 30px;
}

.rwd_deleteroot {
    display: flex;
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    width: 25px;
    height: 25px;
    padding: 0px;
    margin: 0px;
    place-content: center;
    align-items: center;
}

.rwd_deleteicon {
    display: flex;
    position: absolute;
    z-index: -1;
}

.rwd_subcard {
    border-radius: 5px !important;
}

.rwd_subcard>div {
    display: flex;
    place-content: center;
}

/* msg strip */
.gbrwd_msgrootm {
    display: flex;
    flex-direction: column;
    height: max-content;
    padding: 15px 10px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 5px;
    margin-bottom: 10px;
}

.gbrwd_msgtxtm {
    white-space: pre-wrap;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding-bottom: 10px;
}

.gbrwd_msgnamem {
    margin-top: 10px;
    display: flex;
    font-weight: bold;
}

.gbrwd_msgrowm {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: space-between;
    align-items: center;
}


.gbrwd_msgtypebtnvalselectedm {
    border: thin solid #707070;
    border-radius: 5px;
    background-color: #eb5d7b;
    margin: 5px 0px;
    padding: 5px;
    color: white;
    font-weight: bold;
}

.gbrwd_imgroot {
    display: flex;
    flex-direction: column;
}

@media (min-width:481px) {
    .gbrwd_msggrid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rwd_msgroot {
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        height: auto;
        width: 100%;
        /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px; */
    }

    .gbrwd_imggrid {
        /* Message -> IMAGE */
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .grwd_imgroot {
        /* Message -> IMAGE */
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc((100vw - 320px) / 3);
        width: calc((100vw - 320px) / 3);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
    }
}

@media (min-width:641px) {
    .gbrwd_msggrid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rwd_msgroot {
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        height: auto;
        width: 100%;
        /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px; */
    }

    .gbrwd_imggrid {
        /* Message -> IMAGE */
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .grwd_imgroot {
        /* Message -> IMAGE */
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc((100vw - 165px) / 4);
        width: calc((100vw - 165px) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
    }
}

@media (min-width:961px) {
    .gbrwd_msggrid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rwd_msgroot {
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        height: auto;
        width: 100%;
        /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px; */
    }

    .gbrwd_imggrid {
        /* Message -> IMAGE */
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .grwd_imgroot {
        /* Message -> IMAGE */
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc((100vw - 165px) / 4);
        width: calc((100vw - 165px) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
    }
}

@media (min-width:1025px) {
    .gbrwd_msggrid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rwd_msgroot {
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        height: auto;
        width: 100%;
        /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px; */
    }

    .gbrwd_imggrid {
        /* Message -> IMAGE */
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .grwd_imgroot {
        /* Message -> IMAGE */
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc((100vw - 320px) / 4);
        width: calc((100vw - 320px) / 4);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
    }
}

@media (min-width:1281px) {
    .gbrwd_msggrid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rwd_msgroot {
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        height: auto;
        width: 100%;
        /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px; */
    }

    .gbrwd_imggrid {
        /* Message -> IMAGE */
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .grwd_imgroot {
        /* Message -> IMAGE */
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc((100vw - 320px) / 5);
        width: calc((100vw - 320px) / 5);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
    }
}

@media (min-width:1400px) {
    .gbrwd_msggrid {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .rwd_msgroot {
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px 10px 10px 10px;
        height: auto;
        width: 100%;
        /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px; */
    }

    .gbrwd_imggrid {
        /* Message -> IMAGE */
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-gap: 10px;
        grid-template-rows: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .grwd_imgroot {
        /* Message -> IMAGE */
        display: flex;
        flex-direction: column;
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        padding: 10px;
        height: calc((100vw - 320px) / 5);
        width: calc((100vw - 320px) / 5);
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 2px 1px -1px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: black;
    }
}

.gbrwd_msgcol {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.gbrwd_rootWEB {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.gbrwd_rootTABLET {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

#rmsgdownloadable {
    display: flex;
    flex-direction: column;
    width: 400px;
}

#rmsgcard {
    /* height: 300px; */
    width: 380px;
    border: 1px solid #333333;
    padding: 10px;
    margin: 10px;
}