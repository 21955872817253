@media only screen and (min-width: 960px) {
    /* styles for browsers larger than 960px; */
    .title-brand{
        position: absolute;
        color:white;
        width:100%;
        height:25vh;
        justify-content: center;
        display:flex;
        font-size: 100px;
        font-weight: bold;
        align-items: center;
        text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
    }

}
@media only screen and (min-width: 1440px) {
    /* styles for browsers larger than 1440px; */
    .title-brand{
        position: absolute;
        color:white;
        width:100%;
        height:25vh;
        justify-content: center;
        display:flex;
        font-size: 100px;
        font-weight: bold;
        align-items: center;
        text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
    }
 
}
@media only screen and (min-width: 2000px) {
    /* for sumo sized (mac) screens */
    .title-brand{
        position: absolute;
        color:white;
        width:100%;
        height:25vh;
        justify-content: center;
        display:flex;
        font-size: 100px;
        font-weight: bold;
        align-items: center;
        text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
    }

}
@media only screen and (max-device-width: 480px) {
   /* styles for mobile browsers smaller than 480px; (iPhone) */
   .title-brand{
    position: absolute;
    color:white;
    width:100%;
    height:30vh;
    justify-content: center;
    display:flex;
    font-size: 60px;
    font-weight: bold;
    align-items: center;
    text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
}

}
@media only screen and (device-width: 768px) {
   /* default iPad screens */
   .title-brand{
    position: absolute;
    color:white;
    width:100%;
    height:25vh;
    justify-content: center;
    display:flex;
    font-size: 100px;
    font-weight: bold;
    align-items: center;
    text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
    }

}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  .title-brand{
    position: absolute;
    color:white;
    width:100%;
    height:25vh;
    justify-content: center;
    display:flex;
    font-size: 100px;
    font-weight: bold;
    align-items: center;
    text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
}

}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
  .title-brand{
    position: absolute;
    color:white;
    width:100%;
    height:25vh;
    justify-content: center;
    display:flex;
    font-size: 100px;
    font-weight: bold;
    align-items: center;
    text-shadow: rgba(56, 56, 56, 1) 6px 4.5px 100px
    }
}

.primary{
    width:100%;
    display:flex;
    flex-direction: column;
    height:max-content;
    color:#707070;
}


.about-image{
    width:100%;
    height:35vh;
    background-image:url(../../assets/about/about.jpeg);
    background-position:center center;
    background-size: cover;
    opacity: 0.92;
}

.ourstory-card{
    position: relative;
    padding: 20px;
    background-color: #fff;
    margin: -80px 12px 0;
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(111, 111, 111, 0.5);
}

.ourstory-card :first-child{
   font-size: 2em;
   font-weight: bold;
   color:#707070;
}

.ourstory-card-btneng{
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(217, 216, 216);
    color:#707070;
    background-color: white;
    outline: none;
    margin: 5px 5px 5px 0px;
}

.ourstory-card-btnchi{
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(217, 216, 216);
    color:#707070;
    background-color: white;
    outline: none;
    margin: 5px;
}
