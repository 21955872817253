.Plancontent_root{
    display: flex;
    flex-direction: column;
    margin: 15px 0px 20px;
}
.Plancontent_detail{
    padding: 5px;
    flex-direction: column;
    width: 100%;
    box-shadow: #eeeeee 0px 20px 40px -12px;
    border: 2px solid #dacdb3;
    background-color: #fffbf3;
    border-radius: 10px;
    display: flex;
    margin-bottom: 20px;
}
.Plancontent_head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.Plancontent_mtxt{
    margin: 15px;
}
.Plancontent_fields{
    padding: 10px 5px 0px 5px;
}
.Plancontent_msg{
    color: #707070;
    padding: 10px 0px 0px 0px;
}