.SearchRoot {
    position: relative;
    display: flex;
    place-content: center;
    height: 70px;
    flex-direction: column;
    width: 95%;
    min-width: 300px;
    align-items: center;
}

.SearchfilterVisible {
    visibility: visible;
    place-content: center;
    border: none;
    outline: none;
    display: flex;
    color: var(--maindark);
    height: 15px;
    padding: 0px 15px;
    align-self: flex-start;
    font-size: smaller;
    background-color: white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: max-content;
}

.SearchfilterInvisible {
    visibility: hidden;
    place-content: center;
    border: none;
    outline: none;
    display: flex;
    color: var(--maindark);
    height: 15px;
    padding: 0px 15px;
    align-self: flex-start;
    font-size: smaller;
    background-color: white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: max-content;
}

.SearchdropdownWEB {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    margin-top: 42px;
    position: fixed;
    width: 470px;
    height: max-content;
    /* height:375px; */
    z-index: 15;
    color: var(--maindark);
}

.SearchdropdownTABLET {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    margin-top: 42px;
    position: fixed;
    width: 470px;
    height: max-content;
    /* height:375px; */
    z-index: 15;
    color: var(--maindark);
}

.SearchdropdownMOBILE {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    margin-top: 42px;
    position: fixed;
    width: 290px;
    height: 320px;
    height: max-content;
    z-index: 15;
    color: var(--maindark);
}

.iteminsearchdom {
    border-bottom: 1px solid var(--maindark);
    padding: 10px;
    text-align: left;
    cursor: pointer;
}

.iteminsearchdom:last-child {
    border-bottom: 0px solid transparent;
}

.SearchVisibleWEB {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px;
    border-radius: 5px;
    visibility: visible;
    width: fit-content;
}

.SearchInvisibleWEB {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px;
    border-radius: 5px;
    visibility: hidden;
    width: fit-content;

}

.SearchVisibleTABLET {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px;
    border-radius: 5px;
    visibility: visible;
    width: fit-content;

}

.SearchInvisibleTABLET {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px;
    border-radius: 5px;
    visibility: hidden;
    width: fit-content;

}

.SearchVisibleMOBILE {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 5px;
    visibility: visible;
    overflow: hidden;
    /* box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px; */
    /* width:fit-content; */
}

.SearchInvisibleMOBILE {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    /* box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 2px 0px; */
    padding: 0px 10px;
    border-radius: 5px;
    visibility: hidden;
    width: fit-content;

}


.Searchdialogtitle {
    display: flex;
    justify-content: flex-end;
}

.SearchdialogtitleX {
    color: var(--maindark);
    cursor: pointer;
}

.SearchBtnWT {
    display: flex;
    padding: 5px;
    align-items: center;
    border: none;
    outline: none;
    text-align: center;
    height: 40px;
    width: 70px;
    justify-content: center;
    color: white;
    background-color: var(--mainpink);
    border-radius: 0px 5px 5px 0px;
    letter-spacing: 0.5px;
    font-family: Lato;
    font-weight: normal;
    font-size: 15px;
}

.SearchInputWT {
    display: flex;
    padding: 5px 0px 5px 35px;
    width: 370px;
    border: none;
    height: 40px;
    outline: none;
    color: var(--maindark);
    border-radius: 5px 0px 0px 5px;
}

.SearchBtnM {
    border: none;
    outline: none;
    height: 40px;
    width: 60px;
    justify-content: center;
    background-color: var(--mainpink);
    color: white;
    border-radius: 0px 5px 5px 0px;
    font-size: 14px !important;
    align-items: center;
    text-align: center;
    display: flex;
}

.SearchInputM {
    display: flex;
    padding: 5px 0px 5px 35px;
    width: 230px;
    border: none;
    height: 40px;
    font-size: 14px;
    outline: none;
    color: var(--maindark);
    /* border-radius: 5px 0px 0px 5px; */
}

.Searchspanroot {
    display: flex;
    height: max-content;
    padding: 10px;
    margin: 0px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #DDDDDD;
}

.SearchImg {
    border-radius: 50px;
    min-width: 50px;
    min-height: 50px;
    max-height: 50px;
    max-width: 50px;
    border: thin solid silver;
}

.SearchLink {
    display: flex;
    text-align: center;
    align-content: flex-start;
    padding: 0px 10px;
    height: 100%;
    align-items: center;
    color: black;
    text-decoration: none;
    width: 100%;
}

.SearchmodalM {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 60px;
    padding-bottom: 60px;
}

.Searchmodal {
    /* width:490px;
    height:500px;
    display:flex;
    flex-direction: column; */
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 60px;
    height: calc(100% - 60px);
}

.Searchnoresult {
    display: flex;
    place-content: center;
    align-items: center;
    height: 100%;
}