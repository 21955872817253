#Presentation_root {
    position: absolute;
    height: max-content;
    width: 100%;
}

#Presentation_mid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Set height to the viewport height for vertical centering */
    width: 100vw;
    position: fixed;
    z-index: 999999999;
    top: 0;
    flex-direction: column;
}

.Presentation_elem {
    border: 1px solid var(--mainpink);
    background-color: var(--mainpink);
    padding: 15px 30px;
    margin: 15px 30px;
    border-radius: 50px;
    font-size: 24px;
    text-align: center;
    width: max-content;
    color: white;
    box-shadow: 5px 5px 25px #eb5d7b;
}

.Presentation_twocolumnroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    place-content: center;
    height: 100%;
    transition: background-color 0.2s ease 0s;
}

.Presentation_twocolumntitle {
    display: flex;
    place-content: center;
    font-size: 48px;
    font-weight: bold;
    background-color: white;
    width: max-content;
    padding: 0px 90px;
    border-radius: 100px;
    border: 5px solid var(--maindark);
    position: absolute;
    top: 60px;
    place-self: center;
}

.Presentation_twobtmcolumntitle {
    display: flex;
    font-size: 48px;
    font-weight: bold;
    padding: 0px 90px;
    border-radius: 100px;
    position: absolute;
    bottom: 60px;
    background-color: transparent;
    place-content: center;
    align-content: center;
    align-content: center;
    text-align: center;
    width: 100%;
    text-shadow: yellow 2px -5px 5px;
}

.Presentation_hopping {
    -webkit-animation-name: scrolldown;
    -webkit-animation-duration: 3s;
    animation-name: scrolldown;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    border: none;
    box-shadow: none;
}

.Presentation_twocolumnpart {
    width: 50%;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
}

.ScrollDownImageChangeAndReason_data {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-content: center;
    width: 100%;
    place-self: center;
    place-items: center;
}