.Mod_root {
    width: 100vw;
    height: 100vh;
    background-color: #00000080;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    font-family: Lato;
    color: #333333;
    z-index: 2000;
}

.Mod_subroot {
    /* width: 200px;
    position: absolute;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    flex-direction: column;
    place-content: center;
    align-items: center;
    left:calc( 50% - 100px );
    text-align: center;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%); */
    position: absolute;
    display: flex;
    border-radius: 10px;
    background-color: white;
    align-items: center;
    text-align: center;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%);
    flex-direction: column;
    place-content: space-between;

    width: 300px;
    left: calc(50% - 150px);
    height: max-content;
    max-height: max-content;
    min-height: max-content;
}

.Mod_text {
    /* display: flex;
    text-align: center;
    padding: 10px;
    font-size: 15px; */
    display: flex;
    text-align: center;
    font-size: 15px;
    width: 100%;
    place-content: center;
    align-items: center;
    padding: 10px;
    min-height: 120px;
}

.Mod_row {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 45px;
    max-height: 45px;
    border-top: 1px solid rgb(230, 230, 230);
}

.Mod_ok {
    width: 50%;
    display: flex;
    place-content: center;
    font-weight: bold;
    cursor: pointer;
    /* color: #5cb650; */
    color: #4aac47;
    align-items: center;

}

.Mod_no {
    width: 50%;
    display: flex;
    place-content: center;
    font-weight: bold;
    cursor: pointer;
    /* color: red; */
    color: #d85161;
    align-items: center;
}


@media (min-width:150px) {
    .Mod_subroot {
        width: 100%;
        height: max-content;
        max-height: max-content;
        min-height: max-content;
        left:0px;
    }
}
@media (min-width:200px) {
    .Mod_subroot {
        width: 150px;
        left: calc(50% - 75px);
        height: max-content;
        max-height: max-content;
        min-height: max-content;
    }
}

@media (min-width:320px) {
    .Mod_subroot {
        width: 300px;
        left: calc(50% - 150px);
        height: max-content;
        max-height: max-content;
        min-height: max-content;
    }
}

/* 
@media (min-width:320px) {
    .Mod_subroot {
        width: 300px;
        left: calc(50% - 150px);
        height: max-content;
        max-height: max-content;
        min-height: max-content;
    }
}

@media (min-width:481px) {
    .Mod_subroot {
        width: 300px;
        min-height: 150px;
        left: calc(50% - 150px);
    }
}

@media (min-width:641px) {
    .Mod_subroot {
        width: 300px;
        min-height: 150px;
        left: calc(50% - 150px);
    }
}

@media (min-width:961px) {
    .Mod_subroot {
        width: 300px;
        min-height: 150px;
        left: calc(50% - 150px);
    }
}

@media (min-width:1025px) {
    .Mod_subroot {
        width: 300px;
        min-height: 150px;
        left: calc(50% - 150px);
    }
}

@media (min-width:1281px) {
    .Mod_subroot {
        width: 300px;
        min-height: 150px;
        left: calc(50% - 150px);
    }
} */