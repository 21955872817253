.DL_ROOT {
    display: flex;
    flex-direction: column;
    /* background-color: white; */
}

.DL_SINGLE {
    display: flex;
    flex-direction: column;
    height: 300px;
    position: relative;
}

@media (min-width:250px) {

    .DL_SINGLE_MIMGLEFTMOBILE {
        width: calc(141.3px * 0.4);
        position: absolute;
        left: 73.4%;
        top: 9.5%;
        height: auto;
    }

    .DL_IMG {
        width: calc(495px * 0.4);
        height: calc(279px * 0.4);
        border-radius: 0px;
        position: relative;
    }

    .DL_SINGLE_MIMGRIGHTMOBILE {
        width: 29%;
        height: 190px;
        position: absolute;
        right: 72%;
        top: 10%;
    }


    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 25%;
        position: absolute;
        right: 80%;
        top: 20%;
    }

    .DL_SINGLE_MIMGLEFTTABLET {
        width: 25%;
        position: absolute;
        left: 80%;
        top: 20%;
    }

    .DL_SINGLE {
        width: 240px;
        height: max-content;
        position: relative;
    }


    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: -17%;
        width: 200px;
        height: 133px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        opacity: 0;
        margin-left: -25%;
        width: 200px;
        height: 133px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }

    .DL_SINGLE_IMG_RIGHT {
        opacity: 0;
        margin-right: -17%;
        width: 200px;
        height: 133px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: -17%;
        width: 200px;
        height: 133px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
    }

}

@media (min-width:350px) {

    .DL_SINGLE_MIMGLEFTMOBILE {
        width: calc(145px * 0.57);
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_IMG {
        width: calc(550px * 0.57);
        height: calc(310px * 0.57);
        border-radius: 0px;
        position: relative;
    }


    .DL_SINGLE_MIMGRIGHTMOBILE {
        width: 29%;
        height: 190px;
        position: absolute;
        right: 72%;
        top: 10%;
    }

    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 25%;
        position: absolute;
        right: 80%;
        top: 20%;
    }

    .DL_SINGLE_MIMGLEFTTABLET {
        width: 25%;
        position: absolute;
        left: 80%;
        top: 20%;
    }

    .DL_SINGLE {
        width: 340px;
        height: max-content;
        position: relative;
    }

    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: -17%;
        width: 300px;
        height: 200px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        opacity: 0;
        right: -10px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        display: flex;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }

    .DL_SINGLE_IMG_RIGHT {
        opacity: 0;
        margin-right: -17%;
        width: 300px;
        height: 200px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: -17%;
        width: 298;
        height: 198;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        display: flex;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
        margin-right: -17%;
    }

}

@media (min-width:481px) {
    .DL_SINGLE_MIMGRIGHTMOBILE {
        width: 29%;
        height: 95%;
        position: absolute;
        right: 72%;
        top: 11%;
    }

    .DL_SINGLE_MIMGLEFTMOBILE {
        width: calc(145px * 0.6);
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_IMG {
        width: calc(550px * 0.6);
        height: calc(310px * 0.6);
        border-radius: 0px;
        position: relative;
    }

    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 30%;
        position: absolute;
        right: 71.5%;
        top: 5%;
    }

    .DL_SINGLE_MIMGLEFTTABLET {
        width: 30%;
        position: absolute;
        left: 71.5%;
        top: 5%;
    }

    .DL_SINGLE {
        width: 480px;
        height: max-content;
        position: relative;
    }

    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: 0%;
        width: 400px;
        height: 266px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        opacity: 0;
        margin-left: 0%;
        right: 0;
        width: 400px;
        height: 266px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }

    .DL_SINGLE_IMG_RIGHT {
        opacity: 0;
        margin-right: 0%;
        width: 400px;
        height: 266px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: 0%;
        width: 400px;
        height: 266px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
    }
}

@media (min-width:641px) {

    .DL_SINGLE_MIMGRIGHTMOBILE {
        width: 29%;
        height: 100%;
        position: absolute;
        right: 72%;
        top: 8%;
    }

    .DL_SINGLE_MIMGLEFTMOBILE {
        width: calc(145px * 0.75);
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 30%;
        position: absolute;
        right: 71.5%;
        top: 5%;
    }

    .DL_SINGLE_MIMGLEFTTABLET {
        width: calc(145px * 0.75);
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_IMG {
        width: calc(550px * 0.75);
        height: calc(310px * 0.75);
        border-radius: 0px;
        position: relative;
    }

    .DL_SINGLE {
        width: 550px;
        height: max-content;
        position: relative;
    }

    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        right: 0;
        opacity: 0;
        margin-left: 0%;
        width: calc(495px * 0.9);
        height: calc(279px * 0.9);
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_RIGHT_IMG {
        opacity: 0;
        margin-right: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }
}

@media (min-width:961px) {
    .DL_SINGLE_MIMGRIGHTMOBILE {
        width: 29%;
        height: 190px;
        position: absolute;
        right: 72%;
        top: 10%;
    }

    .DL_SINGLE_MIMGLEFTMOBILE {
        width: 29%;
        height: 190px;
        position: absolute;
        left: 72%;
        top: 10%;
    }

    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 30%;
        position: absolute;
        right: 71.5%;
        top: 5%;
    }

    .DL_SINGLE_MIMGLEFTTABLET {
        width: calc(145px * 0.8);
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_IMG {
        width: calc(550px * 0.8);
        height: calc(310px * 0.8);
        border-radius: 0px;
        position: relative;
    }

    .DL_SINGLE {
        width: 700px;
        height: max-content;
        position: relative;
    }

    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        right: 0;
        opacity: 0;
        margin-left: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT {
        opacity: 0;
        margin-right: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: 0%;
        width: 450px;
        height: 270px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }
}

@media (min-width:1025px) {
    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 25%;
        position: absolute;
        right: 80%;
        top: 20%;
    }

    /* .DL_SINGLE_MIMGLEFTTABLET {
        width: 157px;
        position: absolute;
        left: 73.4%;
        top: 9.5%;
    } */

    .DL_SINGLE {
        width: 600px;
        height: max-content;
        position: relative;
    }

    .DL_SINGLE_MIMGLEFTWEB {
        width: calc(145px * 0.9);
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_IMG {
        width: calc(550px * 0.9);
        height: calc(310px * 0.9);
        border-radius: 0px;
        position: relative;
    }

    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        right: 0;
        opacity: 0;
        margin-left: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT {
        opacity: 0;
        margin-right: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }
}

@media (min-width:1281px) {

    .DL_SINGLE_MIMGRIGHTTABLET {
        width: 25%;
        position: absolute;
        right: 80%;
        top: 20%;
    }

    .DL_SINGLE {
        width: 700px;
        height: max-content;
        position: relative;
    }

    .DL_SINGLE_MIMGLEFTWEB {
        width: 145px;
        position: absolute;
        left: 75.4%;
        top: 16.4%;
    }

    .DL_IMG {
        width: 550px;
        height: 310px;
        border-radius: 0px;
        position: relative;
    }

    .DL_SINGLE_IMG_LEFT {
        opacity: 0;
        margin-left: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_LEFT_SELECTED {
        opacity: 0;
        margin-left: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_LEFT 1500ms forwards;
        -webkit-transform: translate(-222px, 0);
        -ms-transform: translate(-222px, 0);
        transform: translate(-222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT {
        opacity: 0;
        margin-right: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        position: relative;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }

    .DL_SINGLE_IMG_RIGHT_SELECTED {
        opacity: 0;
        margin-right: 0%;
        width: 500px;
        height: 300px;
        border-radius: 10px;
        box-shadow: #00000021 0px 10px 50px;
        -webkit-animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        animation: SELECTEDIMG_ANIM_RIGHT 1500ms forwards;
        -webkit-transform: translate(222px, 0);
        -ms-transform: translate(222px, 0);
        transform: translate(222px, 0);
    }
}

@-webkit-keyframes SELECTEDIMG_ANIM_LEFT {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    5% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes SELECTEDIMG_ANIM_RIGHT {
    0% {
        transform: translateX(222px);
        opacity: 0;
    }

    5% {
        transform: translateX(222px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SELECTEDIMG_ANIM_LEFT {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }

    5% {
        transform: translateX(-100px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes SELECTEDIMG_ANIM_RIGHT {
    0% {
        transform: translateX(222px);
        opacity: 0;
    }

    5% {
        transform: translateX(222px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}


.DL_TOP {
    display: flex;
    flex-direction: row;
    height: max-content;
    position: relative;
}

.DL_BTM {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
}

.DL_TITLE_N_CONTENT {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    margin-bottom: 50px;
    z-index: 2;
    position: relative;
    padding: 0px 15px;
}

.DL_ICON_ROOT {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.DL_ICON {
    min-height: 60px;
    min-width: 60px;
    z-index: 2;
    border-radius: 60px;
    /* border: 1px solid #c0c0c03d; */
    box-shadow: rgba(0, 0, 0, 0.25) 3px 3px 3px -1px;
    /* background-color: #fef5f6; */
    background-color: #ffffff;
    /* border: 1px solid var(--subdark); */
    color: var(--maindark);
    display: flex;
    place-content: center;
    align-items: center;
    filter: drop-shadow(2px 4px 6px #eb5d7c2f);
}

.DL_MID {
    height: 50%;
    width: 100%;
    border-bottom: 1px solid var(--maindark);
}

.DL_SENSOR {
    background-color: transparent;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 3;
    top: 50%;
}

.DL_ICON_BTMLINE {
    height: 100%;
    display: flex;
    border-right: 1px solid var(--maindark);
    align-self: flex-start;
}

.DL_TITLE {
    height: max-content;
    /* padding: 0px 15px 15px 15px; */
    padding: 0px 0px 15px 0px;
    display: flex;
    font-weight: bold;
    z-index: 1
}

.DL_CIRCLE {
    position: absolute;
    z-index: 1;
    top: 50%;
}

/* IPHONE DESKTOP */
.DL_SINGLE_MIMGRIGHTWEB {
    width: 145px;
    position: absolute;
    right: 72%;
    top: 8%;
}


.DL_CONTENT_L_WEB {
    height: max-content;
    padding: 0px 0px 15px 0px;
    display: flex;
}

.DL_CONTENT_L_TABLET {
    height: max-content;
    padding: 0px 0px 15px 0px;
    display: flex;
}

/* .DL_CONTENT_L_MOBILE {
    height: max-content;
    padding: 0px 0px 15px 0px;
    display: flex;
    min-height: 135px;
    max-height: 175px;
    width: calc(100% - 60px);
} */


.DL_CONTENT_R_WEB {
    height: max-content;
    padding: 0px 0px 15px 0px;
    display: flex;
}

.DL_CONTENT_R_TABLET {
    height: max-content;
    padding: 0px 0px 15px 0px;
    display: flex;
}

.DL_CONTENT_L_MOBILE,
.DL_CONTENT_R_MOBILE {
    height: max-content;
    padding: 0px 0px 15px 0px;
    display: flex;
    min-height: 135px;
    max-height: 175px;
    width: calc(100% - 60px);

}

.DL_WEBMENU {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: calc(50% - 240px);
    font-family: Lato;
    font-size: 18px;
    padding: 0px 30px;
    height: 400px;
}

.DL_SELECTEDTITLE {
    color: var(--mainpink);
    font-size: 20px;
    padding: 10px 0px;
    white-space: pre-wrap;
    width: 200px;
    font-weight: bold;
}

.DL_NONSELECTEDTITLE {
    color: var(--subgrey);
    padding: 10px 0px 0px 0px;
    white-space: pre-wrap;
    width: 190px;
    transition: font-size 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.DL_TOPRIGHT_1 {
    min-width: 60px;
    height: 50%;
    place-content: flex-end;
    display: flex;
}

.DL_TOPRIGHT_CORNER_1 {
    min-width: 30px;
    height: 100%;
    border-bottom: 1px solid rgb(112, 112, 112);
    border-left: 1px solid rgb(112, 112, 112);
    border-bottom-left-radius: 5px;
}

.DL_BTMLEFT_1 {
    min-width: 60px;
    min-height: 100%;
    display: flex;
    place-content: flex-start;
}

.DL_BTMLEFT_CORNER_1 {
    min-width: 30px;
    height: 26px;
    align-self: flex-end;
    border-top: 1px solid rgb(112, 112, 112);
    border-right: 1px solid rgb(112, 112, 112);
    border-top-right-radius: 5px;
}

.DL_BTMLEFT_2 {
    min-width: 60px;
    min-height: 100%;
    display: flex;
    place-content: flex-end;
}

.DL_TOPRIGHT_2 {
    min-width: 60px;
    min-height: 100%;
    display: flex;
    place-content: flex-start;
}

.DL_BTMLEFT_CORNER_2 {
    min-width: 30px;
    height: 26px;
    border-top: 1px solid #707070;
    border-top-left-radius: 5px;
    border-left: 1px solid #707070;
    align-self: flex-end;
}

.DL_TOPRIGHT_CORNER_2 {
    min-width: 30px;
    height: 25px;
    border-bottom: 1px solid #707070;
    border-bottom-right-radius: 5px;
    border-right: 1px solid #707070;
}

.DL_ICON_1 {
    width: 60px;
    margin-bottom: 10px;
}