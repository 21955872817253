.csv-input {
    color: transparent;
    flex-wrap: wrap;
    max-width: 100px;
    max-height: 60px;
    min-width: 100px;
    min-height: 60px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: 'center';
    border-radius: 5px;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
}

#csvparent div {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    /* padding:0px !important; */
}

#csvparent span:nth-child(1) {
    background-color: transparent !important;
    font-size: 14px !important;
    padding: 0 !important;
}

#csvparent span:nth-child(2) {
    background-color: transparent !important;
    font-size: 12px !important;
    padding: 0 !important;
}

:root {
    --navbarwt: 110px;
}

.iglbtntrue {
    font-size: 15px;
    align-items: center;
    display: flex;
    border: none;
    background-color: #f7f7f7 !important;
    color: #b0b0b0;
    position: relative;
    flex-direction: row;
    height: 60px;
    padding: 0px 0px 0px 10px;
    border-right: 1px solid #dddddd;
    user-select: none;
}

.iglbtnfalse {
    font-size: 14px;
    align-items: center;
    display: flex;
    border: none;
    background-color: white;
    position: relative;
    flex-direction: row;
    height: 60px;
    border-right: 1px solid #dddddd;
    max-width: var(--navbarwt);
    min-width: var(--navbarwt);
    width: var(--navbarwt);
    padding: 0px 10px;
    place-content: center;
}

.iglbtnfalse:hover {
    background-color: #fff6f6 !important
}

.iglbtnfirstchild {
    border-left: 1px solid #dddddd;
}

.iglbtnnonselected {
    background-color: #efeff0;
    color: #aaaaaa;
}

.igl_root {
    display: flex;
    flex-direction: column;
    color: rgb(112, 112, 112);
    width: 100%;
    max-width: 100%;

    /* overflow: scroll; */
    touch-action: manipulation;
    font-family: Lato;
    /* margin-bottom:10px; */
    /* overflow-y: auto; */
    overflow: hidden;
    min-height: 100vh;
}

.ig_delcolbtn {
    /* left: calc( 100% - 20px );
    top: calc( 100% - 20px ); */
    /* outline: none;
    border: none;
    background-color: transparent;
    color: red;
    font-family: fantasy;
    position: absolute;
    left: calc( 100% - 20px );
    top: 0px;
    align-items: center;
    text-align: center;
    place-content: center;
    place-self: center; */
    color: rgb(178, 18, 18);
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    place-content: center;
    align-items: center;
    left: calc(100% - 25px);
    top: 0px;
    cursor: pointer;
}

.igl_tcsmalltitle_relative {
    position: relative !important;
    touch-action: manipulation !important;
    font-size: 14px !important;
    min-width: 78px !important;
    max-width: 78px !important;
    width: 78px !important;
    min-height: 57px !important;
    max-height: 57px !important;
    height: 57px !important;
    padding: 4px 28px 4px 24px !important;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
    place-content: center !important;
    text-align: center !important;
    background-color: white !important;
    z-index: 1;
    display: flex !important;
}

.igl_tcsmalltitle_fixed {
    position: relative !important;
    touch-action: manipulation !important;
    font-size: 14px !important;
    min-width: 78px !important;
    max-width: 78px !important;
    width: 78px !important;
    min-height: 57px !important;
    max-height: 57px !important;
    height: 57px !important;
    padding: 4px 28px 4px 24px !important;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
    place-content: center !important;
    text-align: center !important;
    background-color: white !important;
    z-index: 1;
    display: flex !important;
}

.igl_tclarge {
    position: relative !important;
    touch-action: manipulation !important;
    border-left: 1px solid #e0e0e0 !important;
    font-size: 14px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    flex-wrap: wrap;
}

.igl_tclargeactionandaction {
    position: relative !important;
    touch-action: manipulation !important;
    border-left: 1px solid #e0e0e0 !important;
    font-size: 14px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    flex-wrap: wrap;
}

.igl_tcsmallcell {
    position: relative !important;
    font-size: 13px !important;
    min-width: 78px !important;
    max-width: 78px !important;
    width: 78px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    height: 48px !important;
    padding: 4px 28px 4px 24px !important;
    color: #707070 !important;
    flex-wrap: wrap;
    align-items: center !important;
    justify-content: center !important;
    place-content: center !important;
    text-align: center !important;
}

.igl_tclargecellfalse {
    position: relative !important;
    font-size: 13px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    border-left: 1px solid #e0e0e0 !important;
    color: #707070 !important;
    flex-wrap: wrap;
    z-index: 4;
}

.igl_tclargecelltrue {
    position: relative !important;
    font-size: 13px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    border-left: 1px solid #e0e0e0 !important;
    color: #b0b0b0 !important;
    flex-wrap: wrap;
    z-index: 4;
}

.igl_tclargecellsendandaction {
    position: relative !important;
    font-size: 13px !important;
    min-width: 200px !important;
    max-width: 200px !important;
    border-left: 1px solid #e0e0e0 !important;
    color: #707070 !important;
    flex-wrap: wrap;
    z-index: 4;
}

.igl_iconbtn {
    outline: none;
    border: none;
    background-color: transparent;
    /* z-index: 4; */
    z-index: inherit;
    padding: 1px 6px;
    color: inherit;
}

.igl_iconbtn:hover {
    color: var(--mainpink);
}

.igl_disabledscreen {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: calc(100% - 120px);
    background-color: #f7f7f7b8;
    z-index: 3;
}

.ig_btntxt {
    text-align: left;
    font-size: 15px;
    flex-wrap: wrap;
    white-space: pre-line;
    line-height: 1em;
    align-items: center;
    place-content: center;
    place-self: center;
    width: 100%;
    padding: 0px;
    height: 100%;
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 7px;
}


.ig_btnicon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.ig_topbar {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    border-bottom: 1px solid rgb(224, 224, 224);
    background-color: white;
    z-index: 100;
    height: 60px;
}


.ig_toprowbtns {
    display: flex;
    flex-direction: row;
    width: calc(100% - 145px);
    overflow-x: overlay;
    overflow-y: hidden;
}

.ig_toprowbtns:hover {
    overflow-x: overlay;
}

.ig_publishroot {
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-self: center;
}

.ig_publish {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #eb5d7b;
    color: #eb5d7b;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    height: 40px;
    padding: 0px 10px;
    place-content: center;
    font-size: 15px;
}

.ig_published {
    display: flex;
    flex-direction: row;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    place-content: center;
    background-color: #eb5d7b;
    border: 1px solid #eb5d7b;
    color: white;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    font-size: 15px;
    padding: 0px 10px;
    margin: 10px 0px;
}

.ig_tableloading {
    position: absolute;
    overflow: overlay;
    min-width: 100vw;
    height: calc(100vh - 120px);
    top: 120px;
    place-content: center;
    align-items: center;
    font-size: 24px;
    display: flex;
    text-align: center;
    z-index: 1000;
}

.ig_altrsvproot {
    position: absolute;
    overflow: overlay;
    min-width: 100vw;
    height: calc(100vh - 120px);
    top: 120px;
    place-content: center;
    align-items: center;
    font-size: 24px;
    display: flex;
    text-align: center;
    flex-direction: column;
    /* z-index: 999999; */
    z-index: 1000;
}

.parent-overlay {
    max-height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    overflow: hidden;
    pointer-events: none;
    touch-action: none;
    user-select: none;
    /* position: absolute; */
    /* height: 50px; */
}

.ig_tableroot {
    /* position: fixed; */
    /* height: calc(100vh - 120px);
    top: 120px; */
    overflow: hidden;
    min-width: 100vw;
    height: 100%;
    overflow-y: auto;
    overflow-x: scroll;
    font-family: Lato;
    top: 0;
    left: 0;

}

.ig_preview {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #eb5d7b;
    background-color: white;
    outline: none;
    color: #eb5d7b;
    align-items: center;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    margin-left: 10px;
    text-decoration: none;

}

.igladdbtn {
    width: 60px;
    min-width: 60px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: #eb5d7b;
    color: white;
    border: 1px solid transparent;
    /* box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px; */
    /* color: var(--maindark); */
    /* background-color: white; */
    /* border: 1px solid #19191940; */
}

/* .igladdbtn {
    width: 60px;
    min-width: 60px;
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    border-radius: 5px;
    outline: none;
    background-color: #eb5d7b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    margin: 30px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    user-select: none;
    z-index: 1003
} */

.igl_plus {
    font-size: 20px !important;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: bold !important;
    font-family: Lato;
    padding-right: 5px;
}

.ig_commontxt {
    width: 100%;
    align-items: center;
    place-content: center;
    display: flex;
    /* margin-left: 8px; */
    margin-top: 3px;
}

/* for label & input within a portal*/
.ig_lblinputroot {
    display: flex;
    flex-direction: column;
    margin: 0px 10px 15px 10px;
    width: calc(100% - 20px);
    overflow: hidden;
    outline: none;
}

.ig_lbl {
    display: flex;
    color: var(--maindark);
    padding: 0px 10px 0px 0px;
    overflow-x: auto;
}

.ig_input {
    height: 40px;
    min-height: 40px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.ig_txtarea {
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    resize: vertical;
}

.ig_select {
    /* height: 40px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background-color: #efeff0; */
    height: 40px;
    width: 100%;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background-color: #efeff0;
    outline:none;
    /* border-top: 1px solid var(--maindark); */
}

.ig_catrowtxt {
    border: 1px solid rgb(221, 221, 221);
    max-height: 100%;
    border-radius: 5px;
    width: calc(100% - 20px);
    margin: 0px 10px;
    place-self: center;
    padding: 5px;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    align-content: flex-start;
}

.ig_catrow {
    border: 1px solid rgb(221, 221, 221);
    min-height: 200px;
    max-height: 100%;
    border-radius: 5px;
    width: calc(100% - 20px);
    margin: 0px 10px;
    place-self: center;
    padding: 5px;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    align-content: flex-start;
}

.ig_tagroot {
    display: flex;
    flex-flow: row wrap;
}

.ig_tag {
    display: flex;
    padding: 2px 10px;
    border: 1px solid rgb(51, 51, 51);
    border-radius: 10px;
    width: max-content;
    margin-right: 5px;
    margin-bottom: 5px;
}

.ig_searchroot {
    display: flex;
    width: 100%;
    height: 50px;
    flex-direction: row;
    align-items: center;
    position: relative;
    background-color: rgb(239, 239, 240);
}

.ig_searchitem {
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 50px;
    text-align: center;
    background-color: var(--maindark);
    color: white;
    position: absolute;
    outline: none;
    height: calc(100% - 10px);
    display: flex;
    place-content: center;
    align-items: center;
}

.ig_searchcontent {
    display: flex;
    flex-direction: row;

    margin-left: 100px;
    height: 100%;
}

.ig_searchicon {
    position: absolute;

}

.ig_searchinput {
    width: 100%;
    border: 1px solid #707070;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-radius: 0px;
    outline: none;
    margin: 0px;
}

.ig_caticon {
    background-color: white;
    height: 100%;
    display: flex;
    place-content: center;
    align-items: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 5px 10px;
    border: 1px solid #707070;
    border-left: none;
    outline: none;
    flex-direction: row;
}

.ig_caticon:hover {
    background-color: #efeff0;
}

.ig_catselected {
    padding: 0px 5px 0px 0px;
}

.igl_btnrefresh {
    margin: 0px 5px;
    height: 40px;
    width: 60px;
    border-radius: 5px;
    color: var(--maindark);
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: center;
    border: 1px solid #19191940;
    background-color: white;
}

.igl_numbertimer {
    font-size: 14px !important;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    font-family: Lato;
}

.igl_navicon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    user-select: none;
    pointer-events: none;
    vertical-align: middle;
    fill: transparent;
    overflow: hidden;

}

@media (min-width:320px) {
    .ig_btntxt {
        width: auto;
        font-size: 14px !important;
    }

    .ig_searchroot {
        padding: 5px;
        font-size: 14px;
    }

    .ig_searchitemmedia {
        width: 120px;
    }

    .ig_searchiconmedia {
        left: 135px;
    }

    .ig_searchcontentmedia {
        width: calc(100% - 100px);
        right: 100px;
    }

    .ig_searchinputmedia {
        padding: 10px 20px 10px 30px;
    }
}

@media (min-width:720px) {
    .ig_btntxt {
        width: 100%;
        font-size: 14px !important;
    }

    .ig_searchroot {
        padding: 5px 10px;
        font-size: 15px;
    }

    .ig_searchitemmedia {
        width: 150px;
    }

    .ig_searchiconmedia {
        left: 170px;
    }

    .ig_searchcontentmedia {
        width: calc(100% - 100px);
        right: 100px;
    }

    .ig_searchinputmedia {
        padding: 10px 20px 10px 90px;
    }
}


@media (min-width:1320px) {}


.iglheadercheckboxroot {
    display: flex;
    place-content: center;
    height: 100%;
    align-items: center;
}

.iglheadercheckboxinput {
    width: 17px !important;
    height: 17px !important;
}

.iglnonheadercheckbox {
    width: 17px !important;
    height: 17px !important;
}

.iglcheckbox {
    width: 18px !important;
    height: 18px !important;
    place-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.iglcolumnroot {
    display: flex;
    flex-direction: row;
    place-content: space-between;
    cursor: pointer;
    align-items: center;
}

.iglcolumnroot:hover {
    background-color: var(--maindark);
    color: #ffffff;
}

.iglcolumnsortbtn {
    height: 18px;
    width: 18px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0px;
    padding: 0px;
    display: flex;
    place-content: center;
    align-items: center;
}

.iglcolumncommonheader {
    display: flex;
    width: 100%;
    place-content: center;
    text-align: center;
    cursor: pointer;
}

.ig_selectgrpldr {
    width: 40px;
    height: 40px;
    padding: 0px 10px;
}

#ig_selectgrpldr {
    width: 40px;
    height: 40px;
    padding: 0px 10px;
}

.ig_btnleft {
    width: 50%;
    height: 40px;
    border-top-left-radius: 5px;
    border-top: 1px solid var(--maindark);
    border-right: none;
    border-bottom: 1px solid var(--maindark);
    border-left: 1px solid var(--maindark);
    border-image: initial;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
}

.ig_btnright {
    width: 50%;
    height: 40px;
    background-color: white;
    border-top-right-radius: 5px;
    border-top: 1px solid var(--maindark);
    border-right: 1px solid var(--maindark);
    border-bottom: 1px solid var(--maindark);
    border-left: none;
    border-image: initial;
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom-right-radius: 5px
}

.ig_btnselected {
    background-color: var(--mainpink);
    color: #ffffff;
}

.igl_btnrefresh:hover {
    background-color: #fff6f6;
}

.igl_statustitle {
    margin: 0px 0px 10px 0px;
    display: flex;
    font-size: 16px;
}

.igl_statusbtnorigin {
    border: none;
    background-color: #ffffff;
    padding: 0px;
}

/* .igl_statusbtnorigin:hover{
    border: 1px solid var(--firmgreen);
} */

.igl_statusbtnroot {
    display: flex;
    width: 100%;
    padding: 10px;
    border: 1px solid currentColor;
    border-radius: 5px;
    background-color: #ffffff;
}

.igl_statusbtnroot_highlight {
    border: 1px solid var(--firmgreen);
    background-color: var(--firmgreen);
    color: #ffffff;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.igl_statusexplain {
    display: flex;
    font-size: 12px;
    text-align: left;
    padding: 5px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-top: none;
}

.igl_statusexplain_highlight {
    color: var(--firmgreen);
    border: 1px solid var(--firmgreen);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.ig_altrsvplabel{
    display: flex;
    border: 1px solid var(--maindark);
    padding: 5px;
    min-width: 150px;
    border-radius: 5px;
    place-content: center;
}