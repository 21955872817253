.ilookup_root {
    overflow: hidden;
    user-select: none;
    top: 0;
    bottom: 0;
}

.ilookup_left_1k1k {
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
}

.ilookup_right_1k1k {
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    -webkit-transform: scale(-1);
    transform: scale(-1);
}

@media (min-width:767px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .ilookup_left_1k1k {
        background-position: -25vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }

    .ilookup_right_1k1k {
        background-position: -25vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }
}

@media (min-width:961px) {

    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .ilookup_left_1k1k {
        background-position: -65vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }

    .ilookup_right_1k1k {
        background-position: -65vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    .ilookup_left_1k1k {
        background-position: -25vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }

    .ilookup_right_1k1k {
        background-position: -25vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }
}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    .ilookup_left_1k1k {
        background-position: -25vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }

    .ilookup_right_1k1k {
        background-position: -25vw 35vh;
        background-size: contain;
        -webkit-animation: myfirst 5s;
        /* Chrome, Safari, Opera */
        animation: myfirst 5s;
    }
}


/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes myfirst {
    from {
        opacity: 0.2;
    }

    to {
        opacity: 1;
    }
}

.ilookup_noticloseroot {
    position: absolute;
    display: flex;
    align-items: center;
    color: #707070;
    z-index: 99;
    background-color: transparent;
}

.Ilookup_closeroot {
    position: fixed;
    box-shadow: 0 2px 3px #19191900;
    padding: 10px;
    border-bottom: thin solid #dddddd00;
    justify-content: space-between;
    color: #707070;
    max-height: 61px;
    min-height: 61px;
    height: 61px;
    z-index: 99;
    top: 0;
    width: max-content;
    left: 0;
    place-content: center;
    display: flex;
    align-items: center;
}

.Ilookup_noticlose {
    margin: 0px;
    border-radius: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    height: max-content;
    position: absolute;
    top: 0;
    padding: 10px;
}

.Ilookup_close {
    padding: 0px;
    margin: 0px;
    border-radius: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    height: max-content;
}

.Ilookup_passinput {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    outline: none;
}

.Ilookup_submitbtn {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    align-self: flex-end;
    margin-top: 5px;
}

.Ilookup_passroot {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
    padding: 5px;
    width: 100vw;
    height: 100vh;
    align-items: center;
    text-align: center;
    color: #707070;
    background-color: #fffbf3;
}

.Ilookup_subroot {
    display: flex;
    flex-direction: column;
}

.Ilookup_passcode {
    font-weight: 500;
    display: flex;
    align-self: flex-start;
    max-width: 100%;
    margin-bottom: 5px;
}

.ilookup_txtfitm {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    place-self: center;
    font-weight: normal;
}

.ilookup_txtfitw {
    position: relative;
    font-weight: bold;
    flex-direction: column;
    display: flex;
    place-content: center;
    font-weight: normal;
    padding: 20px;
    z-index: 2;

}

.ilookup_nametyperoot {
    height: inherit;
    width: inherit;
}

.ilookup_nt0 {
    width: 100%;
    align-items: center;
    place-content: center;
    white-space: nowrap;
    display: flex;
    line-height: normal;
}

.ilookup_nt1 {
    width: 100%;
    align-items: center;
    place-content: center;
    white-space: nowrap;
    display: flex;
    line-height: normal;
}

.ilookup_nt2 {
    width: 100%;
    align-items: center;
    place-content: center;
    white-space: nowrap;
    display: flex;
    line-height: normal;
}

.ilookup_n1 {
    min-height: 42.5%;
    line-height: normal;
}

.ilookup_n2 {
    min-height: 15%;
    line-height: normal;
}

.ilookup_n3 {
    min-height: 42.5%;
    line-height: normal;
}

.ilookup_mobilefadeinandout {
    -webkit-animation-name: mobilefadeInAndOut;
    /* -webkit-animation-duration: 7s; */
    animation-name: mobilefadeInAndOut;
    /* animation-duration: 7s; */
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@-webkit-keyframes mobilefadeInAndOut {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes mobilefadeInAndOut {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ilookup_mobilemaintxt {
    -webkit-animation-name: maintxtfadein;
    -webkit-animation-duration: 3s;
    animation-name: maintxtfadein;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@-webkit-keyframes maintxtfadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes maintxtfadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.ilookup_webfadein {
    -webkit-animation-name: webfadeIn;
    -webkit-animation-duration: 0s;
    animation-name: webfadeIn;
    animation-duration: 0s;
}

@-webkit-keyframes webfadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ilookup_scrolldown {
    -webkit-animation-name: scrolldown;
    -webkit-animation-duration: 3s;
    animation-name: scrolldown;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
}

@keyframes scrolldown {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(32px)
    }

    100% {
        transform: translateY(0px)
    }
}


.ilookup_btmnavbaritem {
    height: 60px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor: pointer;
}

.ilookup_contacticon {
    width: 40px;
    height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    padding: 0px 0px 0px 3px;
}

.ilookup_singlecontact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    height: 80px;
    place-self: center;
    border-bottom: 1px solid #7070703d;
}

.ilookup_whoval {
    display: flex;
    flex-direction: column;
}

.ilookup_contactwho {
    padding: 10px 10px 0px 10px;
    height: 40px;
    overflow-x: auto;
    overflow-y: hidden;
}

.ilookup_contactval {
    margin: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    font-style: italic;
    font-weight: 200;
    white-space: pre;
}

/* notification */
.ilookupnoti {
    position: fixed;
    display: flex;
    z-index: 9999999;
    bottom: 0;
    box-shadow: rgb(121 121 121 / 50%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
    width: 100%;
    place-content: center;
    align-items: center;
    flex-direction: column;
}

.ilookupnotititle {
    width: 100%;
    place-content: flex-start;
    display: flex;
    font-weight: bold;
}

.T1_btmnav {
    height: 80px;
}

.ilookupnoticol {
    position: relative;
    height: 100%;
}

.ilookupnoticontent {
    padding: 42px 40px;
    height: 100%;
    place-content: space-between;
    display: flex;
    flex-direction: column;
}

.ilookupnotitxt {
    white-space: pre-wrap;
    overflow-y: auto;
    max-height: 500px !important;
}


/* MORE START */

.ilookup_moreroot {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ilookup_moreicon {
    width: 40px;
    height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    margin: 0px 0px 0px 2px;
}

.ilookup_moreval {
    padding: 10px;
    width: 100%;
    /* width: calc(100% - 180px); */
    overflow-x: auto;
    overflow-y: hidden;
    cursor: pointer;
    font-size: 18px;
}

.ilookup_morebtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    min-height: 80px;
    place-self: center;
    border: none;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    text-decoration: none;
}

.ilookup_morebtn:hover {
    text-decoration: none;
}


/* MORE END */

@media (min-width:0px) {

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem,
    .ilookup_5_btmnavbaritem,
    .ilookup_6_btmnavbaritem {
        min-width: 66px;
        padding: 0px;
    }

    .ilookup_btmtxt {
        height: 18px;
        font-size: 12px;
        padding: 0px 5px;
    }



    /* non-icon */
    .T1_btmnav_1_nonicon,
    .T1_btmnav_2_nonicon,
    .T1_btmnav_3_nonicon,
    .T1_btmnav_4_nonicon,
    .T1_btmnav_5_nonicon,
    .T1_btmnav_6_nonicon {
        place-content: flex-start;
    }

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon,
    .ilookup_5_itemwithouticon,
    .ilookup_6_itemwithouticon {
        padding: 0px 5px;
        font-size: 14px;
    }



    .ilookup_whoval {
        width: calc(100% - 40px);
    }

    .ilookup_singlecontact {
        width: 100%;
    }

    .ilookup_morebtn {
        width: 100%;
    }

    .ilookupnotititle {
        font-size: 16px;
    }

    .ilookupnotitxt {
        font-size: 14px;
    }

    .ilookupnoti {
        height: 250px;
    }

    .ilookupnoticol {
        width: 100%;
    }
}

@media (min-width:66px) {

    .T1_btmnav_1 {
        place-content: center;
    }

    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: start;
    }

}

@media (min-width:132px) {

    .T1_btmnav_1,
    .T1_btmnav_2 {
        place-content: center;
    }

    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: start;
    }

}

@media (min-width:198px) {

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3 {
        place-content: center;
    }

    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: start;
    }



}

@media (min-width:264px) {

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4 {
        place-content: center;
    }

    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: start;
    }

    /* non-icon */
    .T1_btmnav_1_nonicon,
    .T1_btmnav_2_nonicon,
    .T1_btmnav_3_nonicon,
    .T1_btmnav_4_nonicon {
        place-content: center;
    }

    .T1_btmnav_5_nonicon,
    .T1_btmnav_6_nonicon {
        place-content: flex-start;
    }

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon,
    .ilookup_5_itemwithouticon,
    .ilookup_6_itemwithouticon {
        padding: 0px 5px;
        font-size: 14px;
    }

}

@media (min-width:330px) {

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5 {
        place-content: center;
    }

    .T1_btmnav_6 {
        place-content: flex-start;
    }

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem {
        min-width: 66px;
        padding: 0px;
    }

    .ilookup_5_btmnavbaritem,
    .ilookup_6_btmnavbaritem {
        min-width: 60px;
        padding: 2px;
    }


    /* non-icon */
    .T1_btmnav_1_nonicon,
    .T1_btmnav_2_nonicon,
    .T1_btmnav_3_nonicon,
    .T1_btmnav_4_nonicon {
        place-content: center;
    }

    .T1_btmnav_5_nonicon {
        place-content: center;
    }

    .T1_btmnav_6_nonicon {
        place-content: flex-start;
    }

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon {
        padding: 0px auto;
        font-size: 14px;
    }

    .ilookup_5_itemwithouticon {
        padding: 0px auto;
        font-size: 13px;
    }

    .ilookup_6_itemwithouticon {
        padding: 0px auto;
        font-size: 14px;
    }

}

@media (min-width:350px) {

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: center;
    }

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem {
        padding: 0px 5px;
    }

    .ilookup_5_btmnavbaritem {
        min-width: 60px;
        padding: 2px;
    }

    .ilookup_6_btmnavbaritem {
        min-width: 60px;
        padding: 2px;
    }


    /* non-icon */
    .T1_btmnav_1_nonicon,
    .T1_btmnav_2_nonicon,
    .T1_btmnav_3_nonicon,
    .T1_btmnav_4_nonicon,
    .T1_btmnav_5_nonicon {
        place-content: center;
    }

    .T1_btmnav_6_nonicon {
        place-content: flex-start;
    }

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon,
    .ilookup_5_itemwithouticon,
    .ilookup_6_itemwithouticon {
        padding: 0px auto;
        font-size: 14px;
    }

}

@media (min-width:390px) {

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: center;
        overflow: hidden !important;
    }

}

@media (min-width:420px) {

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: center;
    }

}

@media (min-width:481px) {

    .ilookup_btmnavbaritem {
        height: 60px;
    }

    .T1_btmnav_1,
    .T1_btmnav_2,
    .T1_btmnav_3,
    .T1_btmnav_4,
    .T1_btmnav_5,
    .T1_btmnav_6 {
        place-content: center;
    }

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem {
        min-width: 60px;
    }

    .ilookup_5_btmnavbaritem {
        padding: 0px 10px;
    }

    .ilookup_6_btmnavbaritem {
        padding: 0px 10px;
    }

    /* non-icon */
    .T1_btmnav_1_nonicon,
    .T1_btmnav_2_nonicon,
    .T1_btmnav_3_nonicon,
    .T1_btmnav_4_nonicon,
    .T1_btmnav_5_nonicon,
    .T1_btmnav_6_nonicon {
        place-content: center;
    }

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon,
    .ilookup_5_itemwithouticon,
    .ilookup_6_itemwithouticon {
        padding: 0px auto;
        font-size: 16px;
    }





    .ilookup_whoval {
        width: calc(100% - 40px);
    }

    .ilookup_singlecontact {
        width: 100%;
    }

    .ilookup_morebtn {
        width: 100%;
    }

    /* .ilookup_noticloseroot {
        bottom: 140px;
    } */
    .ilookupnotititle {
        font-size: 16px;
    }

    .ilookupnotitxt {
        font-size: 14px;
    }

    .ilookupnoti {
        height: 250px;
    }

    .ilookupnoticol {
        width: 100%;
    }
}

@media (min-width:560px) {

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon,
    .ilookup_5_itemwithouticon,
    .ilookup_6_itemwithouticon {
        padding: 0px 15px;
        font-size: 16px;
    }
}

@media (min-width:641px) {

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem,
    .ilookup_5_btmnavbaritem,
    .ilookup_6_btmnavbaritem {
        padding: 0px 10px;
        min-width: 90px;
    }


    .ilookup_btmtxt {
        height: 23px;
        font-size: 15px;
        display: flex;
        align-items: center;
    }


    .ilookup_singlecontact {
        width: 390px;
    }

    .ilookup_morebtn {
        width: 390px;
    }

    /* .ilookup_noticloseroot {
        bottom: 140px;
    } */
    .ilookupnotititle {
        font-size: 16px;
    }

    .ilookupnotitxt {
        font-size: 14px;
    }

    .ilookupnoti {
        height: 230px;
    }

    .ilookupnoticol {
        width: 70%;
    }

    .ilookupnotitxt {
        font-size: 16px;
    }

}

@media (min-width:961px) {

    .ilookup_btmnavbaritem {
        height: 60px;
    }

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem,
    .ilookup_5_btmnavbaritem {
        min-width: 90px;
        padding: 0px;
    }

    .ilookup_6_btmnavbaritem {
        padding: 0px 10px;
    }

    .ilookup_btmtxt {
        height: 23px;
        font-size: 15px;
        display: flex;
        align-items: center;
    }

    .ilookup_1_itemwithouticon,
    .ilookup_2_itemwithouticon,
    .ilookup_3_itemwithouticon,
    .ilookup_4_itemwithouticon,
    .ilookup_5_itemwithouticon,
    .ilookup_6_itemwithouticon {
        padding: 0px 15px;
        font-size: 16px;
    }

    .ilookup_singlecontact {
        width: 390px;
    }

    .ilookup_morebtn {
        width: 390px;
    }

    /* .ilookup_noticloseroot {
        bottom: 140px;
    } */
    .ilookupnotititle {
        font-size: 17px;
    }

    .ilookupnotitxt {
        font-size: 15px;
    }

    .ilookupnoti {
        height: 230px;
    }

    .ilookupnoticol {
        width: 70%;
    }

    .ilookupnotitxt {
        font-size: 16px;
    }

}

@media (min-width:1025px) {

    .ilookup_btmnavbaritem {
        height: 60px;
    }

    .ilookup_1_btmnavbaritem,
    .ilookup_2_btmnavbaritem,
    .ilookup_3_btmnavbaritem,
    .ilookup_4_btmnavbaritem,
    .ilookup_5_btmnavbaritem {
        min-width: 100px;
    }

    .ilookup_6_btmnavbaritem {
        padding: 0px 10px;
    }


    .ilookup_btmtxt {
        height: 23px;
        font-size: 15px;
        display: flex;
        align-items: center;
    }

    .ilookup_1_itemtop,
    .ilookup_2_itemtop,
    .ilookup_3_itemtop,
    .ilookup_4_itemtop,
    .ilookup_5_itemtop {
        padding: 10px;
        font-size: 16px;
    }




    .ilookup_singlecontact {
        width: 390px;
    }

    .ilookup_morebtn {
        width: 390px;
    }

    /* .ilookup_noticloseroot {
        bottom: 140px;
    } */
    .ilookupnoti {
        height: 200px;
    }

    .ilookupnoticol {
        width: 70%;
    }

    .ilookupnotititle {
        font-size: 17px;
    }

    .ilookupnotitxt {
        font-size: 15px;
    }

}

@media (min-width:1281px) {

    .ilookup_btmnavbaritem {
        height: 60px;
    }

    .ilookup_btmtxt {
        height: 23px;
        font-size: 15px;
        display: flex;
        align-items: center;
    }

    .ilookup_singlecontact {
        width: 390px;
    }

    .ilookup_morebtn {
        width: 390px;
    }

    .ilookupnoti {
        height: 200px;
    }

    /* .ilookup_noticloseroot {
        bottom: 140px;
    } */

    .ilookupnoticol {
        width: 50%;
    }

    .ilookupnotititle {
        font-size: 17px;
    }

    .ilookupnotitxt {
        font-size: 15px;
    }

}

.ilookupnotiexpand {
    position: fixed;
    display: flex;
    z-index: 9999999;
    bottom: 0;
    box-shadow: rgb(121 121 121 / 50%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
    width: 100%;
    place-content: center;
    align-items: center;
    flex-direction: column;
}

.ilookupexpand {
    display: flex;
    background-color: transparent;
    border: none;
    place-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 0px 0px 0px;
}

.ilookupexpandtxt {
    padding: 5px 10px;
    border-radius: 5px;
}

.ilookup_rsvpbtn {
    transition: all .2s ease;
}

.ilookup_rsvpbtn:hover {
    transform: scale(1.05);
}

.ilookup_itinerarybtn {
    height: 45px;
    padding: 5px;
    background-color: transparent;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    border-radius: 5px;
}

.ilookup_itinerarytitle {
    width: 100%;
    font-size: 14px;
    margin-bottom: 3px;
}





/* top nav */

.ilookup_topnav {
    height: 60px;
    place-content: center;
}

.ilookup_topnavsingleitembasic {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor: pointer;
}



@media (min-width:0px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: flex-start; }
    .ilookup_topnav_4 { place-content: flex-start; }
    .ilookup_topnav_5 { place-content: flex-start; }
    .ilookup_topnav_6 { place-content: flex-start; } 

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5,
    .ilookup_topnavsingleitem_6 {
        font-size: 13px;
        padding: 0px 5px;
    }
}

@media (min-width:66px) {}

@media (min-width:132px) {}

@media (min-width:198px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: flex-start; }
    .ilookup_topnav_4 { place-content: flex-start; }
    .ilookup_topnav_5 { place-content: flex-start; }
    .ilookup_topnav_6 { place-content: flex-start; } 

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5,
    .ilookup_topnavsingleitem_6 {
        font-size: 13px;
        padding: 0px 5px;
    }
}

@media (min-width:231px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: flex-start; }
    .ilookup_topnav_4 { place-content: flex-start; }
    .ilookup_topnav_5 { place-content: flex-start; }
    .ilookup_topnav_6 { place-content: flex-start; } 

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5,
    .ilookup_topnavsingleitem_6 {
        font-size: 13px;
        padding: 0px 5px;
    }
}


@media (min-width:264px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: center; }
    .ilookup_topnav_4 { place-content: center; }
    .ilookup_topnav_5 { place-content: center; }
    .ilookup_topnav_6 { place-content: center; }

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5 {
        font-size: 12px;
        padding: 0px 5px;
    }
    .ilookup_topnavsingleitem_6 {
        font-size: 11px;
        padding: 0px 5px;
    }
}

@media (min-width:360px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: center; }
    .ilookup_topnav_4 { place-content: center; }
    .ilookup_topnav_5 { place-content: center; }
    .ilookup_topnav_6 { place-content: center; }

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5{
        font-size: 13px;
        padding: 0px 5px;
    }
    .ilookup_topnavsingleitem_6 {
        font-size: 12px;
        padding: 0px 5px;
    }
}

@media (min-width:380px) {}

@media (min-width:400px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: center; }
    .ilookup_topnav_4 { place-content: center; }
    .ilookup_topnav_5 { place-content: center; }
    .ilookup_topnav_6 { place-content: center; }

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5,
    .ilookup_topnavsingleitem_6 {
        font-size: 13px;
        padding: 0px auto;
    }
}

@media (min-width:560px) {
    .ilookup_topnav_1 { place-content: center; }
    .ilookup_topnav_2 { place-content: center; }
    .ilookup_topnav_3 { place-content: center; }
    .ilookup_topnav_4 { place-content: center; }
    .ilookup_topnav_5 { place-content: center; }
    .ilookup_topnav_6 { place-content: center; }

    .ilookup_topnavsingleitem_1,
    .ilookup_topnavsingleitem_2,
    .ilookup_topnavsingleitem_3,
    .ilookup_topnavsingleitem_4,
    .ilookup_topnavsingleitem_5,
    .ilookup_topnavsingleitem_6 {
        font-size: 16px;
        padding: 0px 10px;
    }
}

@media (min-width:641px) {}

@media (min-width:961px) {}

@media (min-width:1025px) {}

@media (min-width:1281px) {}