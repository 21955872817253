.schedules_arrow {
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    cursor: pointer;
}

.schedules_toparrow {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
}

.schedules_btmarrow {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.schedules_arrowsroot {
    flex-direction: column;
    width: 10%;
    display: flex;
    place-content: center;
    height: 100%;
    align-items: center;
}

.schedules_addbtn {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin: 5px;
    outline: none;
    background-color: #eb5d7b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    align-self: center;
}

.schedules_leftbtn {
    border: 1px solid #707070;
    border-radius: 5px 0px 0px 5px;
    background-color: #ffffff;
    height: 60px;
    width: 100px;
    border-right: none;
}

.schedules_rightbtn {
    border: 1px solid #707070;
    border-radius: 0px 5px 5px 0px;
    background-color: #ffffff;
    height: 60px;
    width: 100px;
    border-left: none;
}

.schedules_btnselected {
    background-color: #eb5d7b;
    color: #ffffff;
}

.schedules_whocanviewroot {
    flex-direction: row;
    border-top: 1px solid rgb(204, 204, 204);
    color: #ffffff;
    background-color: var(--maindark);
    text-align: center;
    place-content: flex-start;
    align-items: center;
    display: flex;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
}

.schedules_guestitem {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    text-align: left;
    place-content: center;
    overflow: hidden;
}

.schedules_nametxt {
    padding-bottom: 5px;
    pointer-events: none;
}

.schedules_idtxt {
    display: flex;
    width: 100%;
    white-space: nowrap;
    pointer-events: none;
}

.schedules_btn {
    width: 33.33%;
    height: 40px;
    margin-bottom: 5px;
    background-color: rgb(255, 255, 255);
    border: 1px solid var(--maindark);
    border-radius: 5px;
    align-items: center;
    display: flex;
    place-content: center;
}

.schedules_btnhighlight {
    background-color: var(--mainpink);
    color: white;
}

.schedules_clear {
    min-width: 60px;
    height: 40px;
    background-color: #fff;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    display: flex;
    place-content: center;
    align-items: center;
    place-self: flex-end;
    position: relative;
    margin-left: 5px;
}