.uhd_root{
    display: flex;
    position: absolute;
    z-index: 5;
    top: 30px;
    left:0;
    margin-left:7px;
    flex-direction: row;
    justify-content: center;
    border-radius: 5px;
    background-color:#fffbf3;
    user-select: none;
    min-width: 300px;
}
.uhd_compWEB{
    display: flex;
    width: 80px;
    height: 110px;
    margin: 5px;
    padding: 3px;
    border-radius: 5px;
    flex-direction: column;
    background-color:white;
    border:2px solid #dacdb3;
 
}
.uhd_compTABLET{
    display: flex;
    width: 80px;
    height: 110px;
    margin: 5px;
    border-radius: 5px;
    flex-direction: column;
    background-color:white;
    border:2px solid #dacdb3;
}
.uhd_compMOBILE{
    display: flex;
    width: 80px;
    height: 110px;
    margin: 5px;
    border-radius: 5px;
    flex-direction: column;
    background-color:white;
    border:2px solid #dacdb3;
}
.uhd_topcomp{
    display:flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 45%;
    letter-spacing: 1px;
    font-size: 12px;
}
.uhd_midcomp{
    display: flex;
    height: 30%;
    place-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    color: #b21212;
    padding: 0px 2px;
}
.uhd_btmcomp{
    display:flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 25%;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    color: #b21212;
    padding: 0px 2px;
}
.uhd_col{
    display: flex;
    flex-direction: column;
}
.uhd_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: inherit;
}
.uhd_closebtn{
    display: flex;
    color: #b21212;
    writing-mode: vertical-lr;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    display: flex;
    place-content: center;
    user-select: none;
    cursor: pointer;
    align-items: center;
    height:240px;
    width: 37px;
    padding-bottom: 8px;
}
.uhd_closebtnchar{
    margin-top: 8px;
    transform: rotate(-90deg);
    text-transform: uppercase;
    user-select: none;
}
.uhd_starbtnchar{
    margin-top: 8px;
    transform: rotate(-90deg);
    text-transform: uppercase;
    user-select: none;
    place-content: center;
    align-items: center;
    place-self: center;
}
.uhd_openbtn{
    /* display: flex;
    position: absolute;
    z-index: 5;
    top: 30px;
    left:0px;
    margin-left: 7px;
    color: white;
    writing-mode: vertical-lr;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    display: flex;
    place-content: center;
    user-select: none;
    cursor: pointer;
    border:thin solid white;
    border-radius: 10px;
    padding-bottom: 8px;
    justify-content:center;
    background-color: #00000094;
    height: 240px;
    width: 37px; */

    color: white;
    writing-mode: vertical-lr;
    font-size: 16px;
    text-align: center;
    display: flex;
    place-content: center;
    user-select: none;
    align-items: center;
    height: 240px;
    width: 35px;
    padding-bottom: 8px;
    border: 1px solid white;
    z-index: 5;
    cursor: pointer;
    margin-left: 7px;
    left: 0;
    position: absolute;
    top: 30px;
    /* background-color: #eb5d7b99; */
    background-color: var(--maindark);
    font-weight: bold;
    border-radius: 5px;

}
.uhd_openbtnchar{
    margin-top: 8px;
    transform: rotate(-90deg);
    text-transform: uppercase;
    user-select: none;
}