#Portalmodal_root {
    width: 100%;
    height: 100%;
}

.Portalmodal_modal {
    width: 100%;
    height: 100%;
    font-family: Lato;
}

.Portalmodal_opening{
    transform: translate(100%, 0px);
    animation-name: openingportal;
    animation-iteration-count: 1;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
.Portalmodal_closing{
    transform: translate(0%, 0px);
    animation-name: closingportal;
    animation-iteration-count: 1;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes openingportal{
    0% {
        transform: translate(100%, 0px);
    }

    100% {
        transform: translate(0%, 0px);
    }
}

@keyframes closingportal{
    0% {
        transform: translate(0%, 0px);
    }

    100% {
        transform: translate(100%, 0px);
    }
}


@media (min-width:641px) {
    .Portalmodal_modal {
        width: 80%;
        height: 80%;
    }
}

@media (min-width:961px) {
    .Portalmodal_modal {
        width: 80%;
        height: 80%;
    }
}

@media (min-width:1025px) {
    .Portalmodal_modal {
        width: 80%;
        height: 80%;
    }
}

@media (min-width:1281px) {
    .Portalmodal_modal {
        width: 80%;
        height: 80%;
    }
}