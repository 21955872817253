:root {
    --t1borderpx: 2.5px;
    --t1dimpx: 40px;
}

.T1border_root {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 5px;
    height: max-content;
    overflow: hidden;
    position: relative;
}
/* 0 */
.T1border_0 {
    border: var(--t1borderpx) solid transparent;
}

/* 2 */
.T1border_2_root {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 5px;
    height: max-content;
    overflow: hidden;
    position: relative;
    flex-direction: column;
}

.T1border_2_subroot1{
    width: calc( 100% - 10px );
    height: calc( 100% - 10px );
    display: flex;
    justify-content: center;
    z-index: 500;
    overflow: hidden;
    position: absolute;
    margin: 5px;
}
.T1border_2_subroot2{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 0px 0px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    right: 0px;
}

/* 3 */
.T1border_3_root {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 5px;
    height: max-content;
    overflow: hidden;
    position: relative;
    flex-direction: column;
}

.T1border_3_subroot1 {
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 5px 20px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    right: -5px;
}

.T1border_3_subroot2 {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 10px 0px;
    overflow: hidden;
    position: absolute;
    top: 5px;
    right: 0px;
}

/* 4 */
.T1border_4_root {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 5px;
    height: max-content;
    overflow: hidden;
    position: relative;
    flex-direction: column;
}

.T1border_4_subroot1 {
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 5px 20px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    right: -5px;
}

.T1border_4_subroot2 {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 10px 0px;
    overflow: hidden;
    position: absolute;
    top: 5px;
    right: 0px;
}

.T1border_4_subroot3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 500;
    margin: 10px 0px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    right: 0px;
}

/* 5 */
.T1border_5_top_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
}

.T1border_5_btm_container {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0;
}

.T1border_5_top_item {
    position: absolute;
}

.T1border_5_btm_item {
    /* bottom: calc(-1 * var(--t1borderpx));
    left: calc(-1 * var(--t1borderpx)); */
    position: absolute;
    border-top-right-radius: 2rem;
}

.T1border_5_top_container :nth-child(1) {
    width: calc(var(--t1dimpx) + var(--t1borderpx));
    height: calc((var(--t1dimpx)/2) + var(--t1borderpx));
}

.T1border_5_top_container :nth-child(2) {
    width: calc((var(--t1dimpx)/2) + var(--t1borderpx));
    height: calc(var(--t1dimpx) + var(--t1borderpx));
}

.T1border_5_btm_container :nth-child(1) {
    width: calc(var(--t1dimpx) + var(--t1borderpx));
    height: calc((var(--t1dimpx)/2) + var(--t1borderpx));
}

.T1border_5_btm_container :nth-child(2) {
    width: calc((var(--t1dimpx)/2) + var(--t1borderpx));
    height: calc(var(--t1dimpx) + var(--t1borderpx));
}

/* 6 */
.T1border_6_top_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
}

.T1border_6_top_item {
    top: calc(-1 * var(--t1borderpx));
    left: calc(-1 * var(--t1borderpx));
    position: absolute;
}

.T1border_6_top_container :nth-child(1) {
    width: calc(var(--t1dimpx) + var(--t1borderpx));
    height: calc((var(--t1dimpx)/3) + var(--t1borderpx));
}

.T1border_6_top_container :nth-child(2) {
    width: calc((var(--t1dimpx)*(2/3)) + var(--t1borderpx));
    height: calc((var(--t1dimpx)*(2/3)) + var(--t1borderpx));
}

.T1border_6_top_container :nth-child(3) {
    width: calc((var(--t1dimpx)/3) + var(--t1borderpx));
    height: calc(var(--t1dimpx) + var(--t1borderpx));
}

.T1border_6_btm_container {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0;
}

.T1border_6_btm_item {
    bottom: calc(-1 * var(--t1borderpx));
    left: calc(-1 * var(--t1borderpx));
    position: absolute;
}

.T1border_6_btm_container :nth-child(1) {
    width: calc(var(--t1dimpx) + var(--t1borderpx));
    height: calc((var(--t1dimpx)/3) + var(--t1borderpx));
}

.T1border_6_btm_container :nth-child(2) {
    width: calc((var(--t1dimpx)*(2/3)) + var(--t1borderpx));
    height: calc((var(--t1dimpx)*(2/3)) + var(--t1borderpx));
}

.T1border_6_btm_container :nth-child(3) {
    width: calc((var(--t1dimpx)/3) + var(--t1borderpx));
    height: calc(var(--t1dimpx) + var(--t1borderpx));
}


.T1_br{
    border-radius: 1vmin;
}
.T1_nobr{
    border-radius: 0vmin;
}