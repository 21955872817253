.appendMovingDots:after {
    content: '. ';
    animation: dots 1s steps(1, end) infinite;
    background-color: 'white';
}
@keyframes dots {
    0% {
        opacity: 0;
    }
    12.5% {
        opacity: 1;
    }
    25% {
        text-shadow: .5em 0;
    }
    50% {
        text-shadow: .5em 0, 1em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0, 1.5em 0;
    }
    100% {
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0;
    }
    /* 87.5%, 100%{
        text-shadow: .5em 0, 1em 0, 1.5em 0, 2em 0, 2.5em;
    } */
}	
.Opening_nextbtn{
    /* padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    margin: 5px 0px;
    align-self: flex-start;
    color: rgb(112, 112, 112); */
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    margin: 20px 0px;
    height: max-content;
}
.Opening_dt{
    font-size: 12px;
    line-height: 20px;
    padding: 3px;
    background-color: white;
    box-shadow: rgb(25 25 25 / 25%) 2px 2px 3px;
    border: thin solid rgb(173, 173, 173);
    border-radius: 5px;
    margin: 3px;
}