#Favouritevendor_root {
    display: flex;
    flex-direction: column;
    font-family: Lato;
    width: 100%;
    height: 100%;
}

.Favouritevendor_col {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Favouritevendor_row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Favouritevendor_vt {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    background-color: transparent;
    color: var(--maindark);
    height: 55px;
    border: none;
    border-right: 1px solid var(--lightdark);
    align-items: center;
    padding: 0px;
    margin: 0px;
}

#Favouritevendor_maincontent {
    place-content: flex-start;
    display: flex;
    flex-direction: column;
}

#Favouritevendor_spreadsheet {
    display: flex;
    flex-direction: column;
    place-content: flex-start;
    font-size: 16px;
    overflow: auto;
    position: relative;
    /* top:calc( 35px + 85px + 60px + 100px); */
    /* height: calc(100% - 485px); */
    /* height: 200px; */
}

#Favouritevendor_sidebar {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

@media (min-width:0px) {}

@media (min-width:320px) {}

@media (min-width:481px) {

    #Favouritevendor_spreadsheet {
        display: flex;
        flex-direction: column;
        place-content: flex-start;
        font-size: 16px;
        overflow: auto;
        height: 100%;
        padding-bottom: 50px;
    }

    .Favouritevendor_vt {
        width: 200px;
    }

    #Favouritevendor_sidebar {
        width: 200px;
    }

    #Favouritevendor_maincontent {
        width: calc(100% - 200px);
    }
}

@media (min-width:767px) {}

@media (min-width:961px) {}

@media (min-width:1025px) {

    .Favouritevendor_vt {
        width: 250px;
    }

    #Favouritevendor_sidebar {
        width: 250px;
    }

    #Favouritevendor_maincontent {
        width: calc(100% - 250px);
    }
}

@media (min-width:1281px) {}

.Favouritevendor_vtselected {
    background-color: var(--mainpink);
    color: #ffffff;
}

.Favouritevendor_maincontenticon {
    /* border-radius: 50px;
    margin: 20px 10px 0px 10px;
    display: flex;
    place-self: center; */
    border-radius: 50px;
    display: flex;
    place-self: center;
}

.Favouritevendor_selectedvttitle {
    /* font-size: 30px;
    margin-bottom: 5px;
    display: flex;
    place-self: center; */

    /* font-size: 16px;
    display: flex;
    place-self: center; */

    font-size: 14px;
    display: flex;
    place-self: center;
}

.Favouritevendor_readablevttxt {
    width: 100%;
    align-items: center;
    place-content: center;
    display: flex;
    padding: 10px 0px;
    margin: 0px;
    border-radius: 50px;
    transition: background-color 100ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 100ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.Favouritevendor_readablevttxt:hover {
    background-color: var(--hoverpink);
}

.Favouritevendor_readablevtselected {
    background-color: var(--mainpink);
    color: white !important;
}

.Favouritevendor_readablevtselected:hover {
    background-color: var(--mainpink);
    color: white !important;
}

.Favouritevendor_readablevtselected {
    background-color: var(--mainpink);
    color: white !important;
    align-items: center;
    place-content: center;
    display: flex;
    padding: 10px 0px;
    margin: 0px;
    border-radius: 50px;
}

#Favouritevendor_titlerow {
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    width: fit-content;
    border: 1px solid var(--lightdark);
    border-left: none;
    border-right: none;
    padding-top: 5px;
}

.Favouritevendor_spreadsheetcellsrow {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--lightdark);
    border-left: none;
    border-right: none;
    padding: 7px 0px;
    border-bottom: none;
    width: fit-content;
}

.Favouritevendor_spreadsheetcellsrow:hover {
    background-color: var(--hoverpink);
}

.Favouritevendor_singlecell {
    padding: 0px 10px;
    min-width: 170px;
    min-height: 30px;
    overflow: hidden;
    font-weight: bold;
    font-size: 14px;
}

.Favouritevendor_nontitlesinglecell {
    padding: 0px 10px;
    max-width: 170px;
    min-width: 170px;
    min-height: 30px;
    overflow: hidden;
    font-size: 13px;
    align-items: center;
    display: flex;
}

.Favouritevendor_rowiconpack {
    display: flex;
    flex-direction: row;
}

.Favouritevendor_vendorprofileimg {
    border-radius: 100px;
    border: 1px solid var(--subgrey);
}

.Favouritevendor_cellicon {
    border: none;
    margin: 3px 3px 3px 0px;
    background-color: transparent;
}

.Favouritevendor_celliconenquire {
    border: 1px solid var(--maindark);
    border-radius: 10px;
}

#Favouritevendor_enquirebtnid {
    background-color: white;
    border: 1px solid var(--maindark);
    color: var(--maindark);
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
}


#Favouritevendor_txt {
    display: flex;
    padding: 10px;
    color: var(--maindark);
    place-content: center;
    font-size: 13px;
    border: 1px solid var(--maindark);
    border-radius: 30px;
    margin: 10px;
    max-height: 40px;
}

#Favouritevendor_viewmoreroot {
    display: flex;
    flex-direction: row;
    place-self: flex-start;
    width: 100%;
    max-height: 55px;
    min-height: 55px;
}

#Favouritevendor_viewmorevendorbtn {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--subdark);
    border-radius: 5px;
    padding: 0;
    padding-right: 10px;
    margin: 5px;
    background-color: white;
}

.Favouritevendor_enquirebtn {
    background-color: var(--maindark);
    border: 1px solid var(--maindark);
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    text-align: center;
}