#gbpage_root{
    overflow: hidden;
    overscroll-behavior: contain;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    touch-action: manipulation;
    user-select: none;
    place-content: space-between;
    height: 85vh;
    width: 100%;
}

#gbpage_title{
    font-size: 28px;
    touch-action: manipulation;
    user-select: none;
}

#gbpage_name{
    display: flex;
    width: 100%;
    font-size: 16px;
    touch-action: manipulation;
    user-select: none;
}

#gbpage_nextbtn{
    display: flex;
    font-size: 16px;
    height: 60px;
    place-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 3;
    touch-action: manipulation;
    user-select: none;
    font-size: 20px;
}

#gbpage_backbtn{
    display: flex;
    font-size: 16px;
    height: 60px;
    place-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 5px;
    width: 70px;
    touch-action: manipulation;
    user-select: none;
}

#gbpage_upper{
    display: flex;
    height: calc( 100% - 125px );
    place-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    touch-action: manipulation;
    user-select: none;
    margin: auto;
}

#gbpage_lower{
    display: flex;
    place-content: center;
    align-items: center;
    background-color: white;
    touch-action: manipulation;
    user-select: none;
    margin: 0px auto;
    flex-direction: column;
    background-color: inherit;
}
#gbpage_err{
    display: flex;
    height: 20px;
    place-content: center;
    align-items: center;
    padding: 10px 0px;
}
#gbpage_btnrow{
    display: flex;
    flex-direction: row;
    background-color: inherit;
}
#gbpage_txtarea{
    height: 80%;
    width:100%;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid black;
    border-bottom: 0px solid transparent !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#gbpage_input{
    height: 60px;
    width: 100%;
    border-radius: 10px;
    font-size: 24px;
    border: 1px solid black;
}

#gbpage_ffdropdown{
    height: 40px;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    background-color: white;
    border: 1px solid black;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#gbpage_emojiinput{
    border-radius: 10px;
    font-size: 32px;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 9px 0px;
    padding: 0px 10px;
    flex-direction: row;
    display: flex;
    place-content: flex-start;
    background-color: white;
}

#gbpage_emojibtn{
    background-color: white;
    border: 0px solid transparent;
    padding: 0px;
    margin: 0px;
}

#gbpage_emojikeyboard{
    height: 80%;
    width: 100%;
    position: relative;
}

#gbpage_catselect{
    height: 40px;
    width: 100%;
    font-size: 16px;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid black;
}

#gbpage_slidercontainer{
    display: flex;
    flex-direction: row;
    position: relative;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    width: 100%;
    height: calc( 100% - 110px);
    overscroll-behavior-x: contain;
    border: 1px solid #000000; 
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    flex-wrap: wrap;
    background-color: white;
}

#gbpage_emojisearch{
    height: 50px;
}
#gbpage_emojisearchinput{
    height: 40px;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 16px;
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 0px 3px;
    background-color: white;
}

#gbpage_sliderspacer{
    width: calc(var(--gradient-width)*1.5);
    padding: 0;
}

#gbpage_section{
    padding-bottom: min(30px,2.25vh) !important;
    position: relative;
    display: block;
    flex-shrink: 0;
    pointer-events: none;
    padding: 0 15px 25px 25px;
    width: 100%;
    height: 400px;
    border-right: 0.5px dashed black;
}

#gbpage_singleemoji{
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 40px;
}

#gbpage_namerow{
    display: flex;
    flex-direction: row;
    place-content: space-between;
    width: 100%;
}
#gbpage_audiobtnsrow{
    display: flex;
    flex-direction: row;
    width: 100%;
}

#gbpage_startrecord{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
    height: 60px;
    width: 100%;
    font-size: 20px;
}

#gbpage_audiostartrecord{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    border: 1px solid #4da74d;
    background-color: #4da74d;
    color: white;
    height: 60px;
    width: 50%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: thin solid transparent;
    font-size: 16px;
}

#gbpage_audioclear{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
    width: 100%;
    font-size: 16px;
    height: 60px;
    font-size: 16px;
}

#gbpage_audiostop{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    border: 1px solid #707070;
    background-color: white;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 50%;
    height: 60px;
    font-size: 16px;
}

.gbpage_invisibleinput{
    width: 0px !important;
    height: 0px !important;
    visibility: hidden !important;
}

#gbpage_imgbtn{
    padding: 5px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
    height: 60px;
    width: 100%;
    font-size: 20px;
}

#gbpage_img{
    display: flex;
    width: 100%;
    height: 100%;
    margin: 5px 0px;
    border-radius: 5px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
}

@media (min-width:320px)  { 
    #gbpage_nextbtn{
        width:200px;
    }
    #gbpage_upper{
        width:260px;
    }
    #gbpage_emojidisplay{
        width:260px;
    }
}

@media (min-width:390px)  { 
    #gbpage_nextbtn{
        width:200px;
    }
    #gbpage_upper{
        width:320px;
    }
    #gbpage_emojidisplay{
        width:320px;
    }
}
@media (min-width:481px)  { 
    #gbpage_nextbtn{
        width:420px;
    }
    #gbpage_upper{
        width:420px;
    }
    #gbpage_emojidisplay{
        width:420px;
    }
 }
@media (min-width:641px)  {
    #gbpage_nextbtn{
        width:420px;
    }
    #gbpage_upper{
        width:520px;
    }
    #gbpage_emojidisplay{
        width:520px;
    }
}
@media (min-width:961px)  {
    #gbpage_nextbtn{
        width:220px;
    }
    #gbpage_upper{
        width:520px;
    }
    #gbpage_emojidisplay{
        width:520px;
    }
}
@media (min-width:1025px) { 
    #gbpage_nextbtn{
        width:230px;
    }
    #gbpage_upper{
        width:520px;
    }
    #gbpage_emojidisplay{
        width:520px;
    }
}
@media (min-width:1281px) { 
    #gbpage_nextbtn{
        width:275px;
    }
    #gbpage_upper{
        width:520px;
    }
    #gbpage_emojidisplay{
        width:520px;
    }
}
