.planning_main_root {
    display: flex;
    flex-direction: column;
    color: var(--maindark);
    overflow-x: hidden;

}

#planningedit_selectionbox {
    width: 100%;
    padding: 10px;
}

.planning_toolstrip_root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    /* border-bottom: 1px solid #00000033; */
    /* margin-bottom: 25px; */
}

.planning_apps_strip {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    align-items: center;
}

.planning_addbtn {
    height: 90px;
    min-height: 90px;
    width: 90px;
    min-width: 90px;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--maindark);
    color: var(--maindark);
    border-radius: 5px;
    padding: 0px 5px;
    margin: 0px 5px 10px 0px;
    background-color: white;
    outline: none;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    font-family: Lato;
}

.planning_existroot_unselected {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 90px;
    width: 170px;
    min-height: 90px;
    min-width: 170px;
    margin: 0px 2.5px;
    border-radius: 5px;
    overflow: hidden;
    flex-wrap: wrap;
    border: thin solid var(--maindark);
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.planning_existroot_selected {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 80px;
    width: 170px;
    min-height: 90px;
    min-width: 170px;
    cursor: pointer;
    margin: 0px 2.5px;
    border-radius: 5px;
    overflow: hidden;
    flex-wrap: wrap;
    border: thin solid #f77f7f;
    background-color: #fff5f5;
    box-shadow: #00000033 2px 2px 0px 0px;
    margin-bottom: 10px;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    font-weight: bold;
}

.planning_selectionboxroot {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 110px;
}

.planning_existroot :hover {
    background-color: #f77f7f33 !important;
}

.planning_existroot_1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-wrap: anywhere;
    flex-direction: column;
    background-color: transparent;
    padding: 0px 25px;
    position: absolute;
    user-select: none;
}

.planning_imgmodalroot {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    height: fit-content;
}

.planning_imgbtnsroot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.planning_imgbtntrue {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid transparent;
    background-color: #f77f7f;
    color: white;
    outline: none;
    margin: 2.5px;
}

.planning_imgbtnfalse {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid transparent;
    background-color: white;
    outline: none;
    margin: 2.5px;
}

.planning_imgroot {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.planning_dim {
    display: flex;
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;
    background-color: #f77f7f;
    color: white;
    border-top-left-radius: 10px;
}

.planning_imginputwrapper0 {
    border: thin solid #eeee;
    width: 100%;
    height: 100%;
    position: absolute;
}

.planning_imginputwrapper1 {
    border: thin solid #eeee;
    border-left: thin solid transparent;
    width: 100%;
    height: 100%;
    position: absolute;
}

.planning_imginput {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid var(--maindark);
    background-color: white;
    outline: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
}

.planning_img {
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    padding: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    align-self: center;
}

.planning_inputroot {
    display: flex;
    flex-direction: column;
    height: 400px;
    place-content: flex-start;
    align-items: center;
}

.planning_inputsubroot {
    display: flex;
    height: 60%;
    width: 100%;
    position: relative;
    align-items: center;
    place-self: center;
    place-content: center;
}

.planning_updatebtn {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    width: 50%;
    margin: 10px 0px;
}

.planning_noimagebtn {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    width: 50%;
    margin: 10px 0px;
    place-content: center;
}

.planning_heartbtnroot {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.planning_heartbtntrue {
    border: thin solid #f77f7f;
    border-radius: 5px;
    color: #f77f7f;
    background-color: white;
    display: flex;
    align-items: center;
    outline: none;
    margin: 5px 0px;
    width: 50%;
    text-align: center;
}

.planning_heartbtnfalse {
    border: thin solid var(--maindark);
    border-radius: 5px;
    color: var(--maindark);
    background-color: white;
    display: flex;
    align-items: center;
    outline: none;
    margin: 5px 0px;
    width: 50%;
    text-align: center;
}

.planning_onoff {
    margin: 5px;
    text-align: center;
    display: flex;
    align-items: center;
}

.planning_delbtn {
    padding: 0px 5px;
    margin: 20px 0px 10px 5px;
    border-radius: 5px;
    width: max-content;
    height: max-content;
    text-align: center;
    border: thin solid var(--maindark);
    color: #e25a5a;
    font-weight: bold;
}

.planning_usablebtn {
    padding: 0px 5px;
    margin: 20px 5px 10px 0px;
    border-radius: 5px;
    width: max-content;
    height: max-content;
    text-align: center;
    border: thin solid var(--maindark);
}

.Planning_addnewcatcircular {
    padding: 5px;
    outline: none;
    border-radius: 0px 0px 5px 5px;
    margin: 5px 0px;
    border: thin solid #f77f7f;
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    background-color: #f77f7f;
    color: white;
    font-weight: bold;
    position: fixed;
    width: max-content;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: rgb(25 25 25 / 20%) 1px 1px 5px;
}

.Planning_addnewcatbg {
    outline: none;
    -webkit-box-shadow: rgb(0 0 0 / 5%) 0px 3px 9px 3px;
    box-shadow: rgb(0 0 0 / 5%) 0px 3px 9px 3px;
    -webkit-transition: background-color 0.25s ease 0s, color 0.25s ease 0s;
    -o-transition: background-color 0.25s ease 0s, color 0.25s ease 0s;
    transition: background-color 0.25s ease 0s, color 0.25s ease 0s;
    border: thin solid #e5e5e5;
    position: fixed;
    z-index: 1001;
    background-color: #fffbf3;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    place-self: center;
    padding: 0px;
    bottom: 0px;
    width: 98%;
    max-width: 98%;
    min-width: 98%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    height: 60px;
    left: 1%;
}

.planning_midsubroot {
    display: flex;
    flex-direction: row;
}

.planning_txt {
    padding: 0px 10px;
}

.plan_detailroot {
    display: flex;
    flex-direction: column;
    margin: 15px 0px 20px;
}

.plan_detailcontent {
    padding: 5px;
    flex-direction: column;
    width: 100%;
    box-shadow: #eeeeee 0px 20px 40px -12px;
    border: 2px solid #dacdb3;
    background-color: #fffbf3;
    border-radius: 10px;
    display: flex;
    margin-bottom: 20px;
}

.plan_dhead {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.plan_dmtext {
    margin: 15px;
}

.plan_dfields {
    padding: 10px 5px 0px 5px;
}

.plan_mainmsg {
    color: var(--maindark);
    padding: 10px 0px 0px 0px;
}

.planning_templatebtn {
    border-radius: 5px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    cursor: pointer;
    width: max-content;
    color: rgb(112, 112, 112);
    align-items: center;
    white-space: nowrap;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
}

.planning_qrbtn {
    padding: 5px;
    margin: 0px;
    border-radius: 5px;
    font-weight: bold;
    border: thin solid var(--maindark);
    color: var(--maindark);
    background-color: white;
    outline: none;
    align-items: center;
    box-shadow: rgba(25, 25, 25, 0.25) 0px 2px 3px;
    flex-direction: row;
    display: flex;
    height: 32px;
    min-width: 50px;
    justify-content: center;
}

.planning_optionfalse {
    display: flex;
    flex-direction: row;
    min-width: 125px;
    max-width: 125px;
    height: 55px;
    border-radius: 5px;
    margin: 0px 10px 0px 0px;
    align-items: center;
    justify-content: center;
    border: thin solid #c5c5c5;
    cursor: pointer;
    text-align: center;
    white-space: pre-wrap;
    background-color: white;
}

.planning_optiontrue {
    display: flex;
    flex-direction: row;
    min-width: 125px;
    max-width: 125px;
    height: 55px;
    border-radius: 5px;
    margin: 0px 10px 0px 0px;
    align-items: center;
    justify-content: center;
    background-color: #fff5f5;
    color: var(--maindark);
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    white-space: pre-wrap;
    border: thin solid #f77f7f;
}

.planning_left {
    width: 5px;
    height: 100%;
    background-color: rgb(223, 70, 70);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.planning_lefttransparent {
    width: 5px;
    height: 100%;
    background-color: transparent;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.planning_optiontop {
    width: 40%;
    height: 100%;
    align-items: center;
    place-content: center;
    display: flex;
}

.planning_optionbtm {
    width: 60%;
    align-items: center;
    place-content: center;
    text-align: center;
    display: flex;
}

.planning_topbar {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 10px;
    border-bottom: 1px solid #dddddd;
    background-color: white;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--maindark);
    z-index: 100;
    font-family: Lato;
    touch-action: manipulation;
}

.planning_backicon {
    padding: 0px;
    margin: 0px;
    outline: none;
    background-color: white;
    border: thin solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--maindark);
}

.Plan_fg {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.Plan_ft {
    font-weight: bold;
    margin-bottom: 3px;
}

.Plan_err {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 25px;
}

.Plan_contncreat {
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    width: max-content;
    place-self: flex-end;
    margin-top: 10px;
    color: inherit;
    margin-left: 5px;
    color: inherit;
    text-decoration: none !important;
}

.Plan_contncreat:hover {
    color: var(--maindark);
}

.Plan_mroot {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.Plan_r {
    display: flex;
    flex-direction: row;
}

.Plan_inp {
    width: 100%;
    border: 1px solid #ccc;
    padding: 6px 12px;
    border-radius: 5px;
}

#planningedit_root {
    display: flex;
    padding: 0px !important;
    font-family: Lato;
    color: var(--maindark);
    flex-direction: column;
}

.planning_tool_login {
    display: flex;
    padding: 10px;
    place-self: center;
    border: 1px solid var(--maindark);
    font-family: Lato;
    place-content: center;
}

.planning_tool_title {
    display: flex;
    width: 100%;
    place-content: center;
    align-items: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

@media (min-width:240px) {
    #planningedit_root {
        flex-direction: column;
    }

    .planning_tool_title {
        font-size: 24px;
        margin: 24px 0px;
    }

    .planning_tool_subtitle {
        font-size: 14px;
        font-weight: normal;
        margin: 12px 10px 10px 12px;
    }

    .planning_apps_strip {
        padding: 16px 5px;
        place-content: flex-start;
    }
}

@media (min-width:641px) {
    #planningedit_root {
        margin: 0px;
    }

    .planning_tool_title {
        font-size: 32px;
        margin: 16px 0px;
        height: 110px;
        place-content: flex-start;
    }

    .planning_tool_subtitle {
        font-size: 14px;
        font-weight: normal;
        width: 641px;
    }

    .planning_apps_strip {
        padding: 18px 5px;
        place-content: flex-start;
    }
}

@media (min-width:764px) {
    #planningedit_root {
        width: 100%;
        place-content: center;
    }

    .planning_apps_strip {
        padding: 21px 5px;
        place-content: flex-start;
    }
}

@media (min-width:961px) {
    #planningedit_root {
        width: 900px;
        place-self: center;
    }

    .planning_tool_title {
        font-size: 42px;
        margin: 21px 0px;
        height: 155px;
        place-content: flex-start;
    }

    .planning_tool_subtitle {
        font-size: 16px;
        font-weight: normal;
        width: 720px;
    }

    .planning_apps_strip {
        padding: 21px 5px;
        place-content: flex-start;
    }
}

@media (min-width:999px) {
    #planningedit_root {
        width: 900px;
        place-content: center;
    }

    .planning_apps_strip {
        padding: 21px 5px;
        place-content: flex-start;
    }
}

@media (min-width:1120px) {
    #planningedit_root {
        width: 900px;
        place-content: center;
    }

    .planning_apps_strip {
        padding: 21px 5px;
        place-content: center;
    }
}


.planning_editbtn {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    place-self: flex-end;
    margin-left: 5px;
    background-color: #ffffff;
    border: 1px solid var(--subgrey);
    border-radius: 5px;
}

.planning_lookupbtn {
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    outline: none;
    color: var(--maindark) !important;
    align-items: center;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 16px;
    align-items: center;
    text-align: center;
    place-content: center;
    margin-right: 5px;
    text-decoration: none !important;
}

.planning_lookupbtn:hover {
    text-decoration: none !important;
    color: var(--maindark) !important;
}

.planning_contbtn {
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    outline: none;
    color: var(--maindark);
    align-items: center;
    flex-direction: row;
    display: flex;
    height: 40px;
    font-size: 16px;
    align-items: center;
    text-align: center;
    place-content: center;
    width: max-content;
    place-self: flex-end;
}

.planning_msgcreate {
    display: flex;
    place-self: flex-end;
}