.Party_root {
    display: flex;
    flex-direction: column;
}

.Party_colroot {
    display: flex;
    flex-direction: column;
}

.Party_itemroot {
    display: flex;
    flex-direction: row;
    height: 110px;
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 5px;
}

.Party_img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.Party_col {
    display: flex;
    flex-direction: column;
    padding: 0px 5px 0px 10px;
}

.Party_name {}

.Party_title {
    font-size: 12px;
    font-weight: bold;
}

.Party_info {
    /* overflow-y: scroll;
    overflow-x: hidden; */
    overflow: hidden;
    font-size: 11px;
    text-overflow: ellipsis;
}

.Party_row {
    display: flex;
    flex-direction: row;
    padding: 4px;
}

.Party_selectbtn {
    height: 30px;
    padding: 0px 10px;
    margin-right: 5px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    color: var(--maindark);
}

.Party_selectedbtn {
    height: 30px;
    padding: 0px 10px;
    margin-right: 5px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--mainpink);
    color: var(--mainpink);
}

.Party_editicon {
    display: flex;
    height: 50%;
    max-width: 35px;
    min-width: 35px;
    place-content: center;
    place-items: center;
    border: 1px solid rgb(204, 204, 204);
    border-left: none;
    border-bottom-right-radius: 5px;
    border-bottom: none;
    border-right: none;
    background-color: #ffffff;
}

.Party_shifticon {
    display: flex;
    height: 50%;
    place-content: center;
    place-items: center;
    border: 1px solid rgb(204, 204, 204);
    border-left: none;
    border-top: none;
    border-top-right-radius: 5px;
    border-right: none;
    border-bottom: none;
    background-color: #ffffff;
}

.Party_portalroot {
    margin: 10px;
}

.Party_updatediv {
    width: 100%;
    display: flex;
    place-content: flex-end;
}

.Party_portalupdate {
    width: 100px;
    height: 45px;
    border: 1px solid var(--maindark);
    background-color: #ffffff;
    border-radius: 10px;

}

.Party_input{
    
}