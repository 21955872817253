.Highlighttitle_root{
    display: flex;
    flex-direction: row;
    place-content: space-between;
    align-items: center;
    padding: 0px 10px;
    background-color: #333333;
    margin: 0px;
    cursor: pointer;
    z-index: 3;
    width: 100%;
    height: 70px;
}
.Highlighttitle_itemroot{
    width: 100%;
    display: flex;
    flex-direction: row;
    place-content: space-between;
    align-items: center;
    color: white;
}
.Highlighttitle_iconandname{
    display: flex;
    flex-direction: row;
    place-content: center;
    align-items: center;
}
.Highlighttitle_txt{
    font-size: 24px;
    font-weight: bold;
    padding-left: 10px;
}
.Highlighttitle_back{
    width: 25px;
    height: 25px;
    margin:4px;
    user-select: none;
    pointer-events: none;
    vertical-align: middle;
    fill:white;
    overflow: hidden;
}