.Play_root{
    width:100%;
    overflow: hidden;
}
.Play_msgroot{
    display: flex;
    place-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
}
.Play_msgimgWEB{
    max-width:100%;
    height:auto;
    object-fit: contain;
}
.Play_msgimgTABLET{
    max-width:100%;
    height:auto;
    object-fit: contain;
}
.Play_msgimgMOBILE{
    max-width:100%;
    height:auto;
    object-fit: contain;
}
.Play_msgtxt{
    background-color: white;
    color: black;
    position: absolute;
    width: calc( 100% - 20px );
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    min-height: 60px;
    align-items: center;
    flex-direction: column;
}
.Play_msgtxtrightransparentWEB{
    background-color: white;
    color: black;
    position: absolute;
    width: 450px;
    height: min-content;
    min-height: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    right: 0px;
    font-size: 16px;
    opacity: 90%;
    color: transparent;
}
.Play_msgtxtrightransparentTABLET{
    background-color: white;
    color: black;
    position: absolute;
    width: 450px;
    height: min-content;
    min-height: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    right: 0px;
    font-size: 16px;
    opacity: 90%;
    color: transparent;
}
.Play_msgtxtrightransparentMOBILE{
    background-color: white;
    color: black;
    position: absolute;
    width: calc( 100% - 20px);
    height: min-content;
    min-height: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    right: 0px;
    font-size: 16px;
    opacity: 90%;
    color: transparent;
}
.Play_msgtxtrightWEB{
    background-color: transparent;
    color: black;
    position: absolute;
    width: 450px;
    height: min-content;
    min-height: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    min-height: 60px;
    align-items: center;
    flex-direction: column;
    right: 0px;
    font-size: 16px;
}
.Play_msgtxtrightTABLET{
    background-color: transparent;
    color: black;
    position: absolute;
    width: 450px;
    height: min-content;
    min-height: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    min-height: 60px;
    align-items: center;
    flex-direction: column;
    right: 0px;
    font-size: 16px;
}
.Play_msgtxtrightMOBILE{
    background-color: transparent;
    color: black;
    position: absolute;
    width: calc( 100% - 20px);
    height: min-content;
    min-height: auto;
    padding: 10px;
    margin: 10px;
    display: flex;
    border-radius: 10px;
    bottom: 0px;
    place-content: center;
    text-align: center;
    min-height: 60px;
    align-items: center;
    flex-direction: column;
    right: 0px;
    font-size: 16px;
}
.Play_msgname{
    margin-top: 5px;
}    
