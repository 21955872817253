.VL_btmbar {
    outline: none;
    box-shadow: 0 -3px 8px 0 rgba(37, 37, 37, .1);
    position: fixed;
    z-index: 1001;
    background-color: #ffffff;
    justify-content: center;
    display: flex;
    align-items: center;
    place-self: center;
    padding: 0px;
    bottom: 0px;
    width: 100%;
}

.VL_btmbarMOBILE {
    height: 80px;
}

.VL_btmbarTABLET {
    height: 80px;
}

.VL_btmbarWEB {
    height: 80px;
}

.VL_vtminprice {
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    border: 1px solid var(--maindark);
    background-color: white;
    outline: none;
    color: white;
    height: 65px;
    white-space: nowrap;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-content: center;
}

.Vl_inforoot {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--maindark);
}

.Vl_infofirst {
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
}

.Vl_infoicon {
    display: flex;
    justify-content: center;
    color: var(--maindark);
}

.VL_infocontent {
    color: var(--maindark);
    font-family: Lato;
    /* white-space: pre;
    overflow-x: overlay;
    overflow-y: hidden; */
    position: relative;
    word-break: break-word;
}

.VL_infomore {
    padding: 0px 5px;
    margin: 0px 10px 0px 5px;
    border: 1px solid var(--maindark);
    border-radius: 5px;
    font-size: 10px;
    display: flex;
    text-align: center;
    align-content: center;
    place-self: center;
    white-space: nowrap;
    cursor: pointer;
}

.VL_infomore:hover {    
    background-color: var(--maindark);
    color: #ffffff;
}


.VL_numinfo {
    height: 50px;
    min-height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
}

.Vl_numsingle {
    width: 32%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--subgrey);
    border-radius: 5px;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.Vl_numsingle:hover {
    transition: all 0.2s ease 0s;
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    cursor: pointer;
}

.Vl_numsubtitle {
    font-size: 11px;
    font-family: Lato;
}

.Vl_vts {
    min-height: 30px;
    width: 100%;
    color: var(--maindark);
    font-size: small;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
}

.VL_similarvendor {
    display: flex;
    font-family: Lato;
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid var(--subgrey);
    text-align: center;
    align-items: center;
    width: min-content;
    min-width: 250px;
    max-width: 250px;
    border-radius: 5px;
    overflow-x: hidden;
    place-content: flex-start;
}

.VL_similarvendor:hover {
    position: relative;
    background-color: #eb5d7c2f !important;
    color: #707070 !important;
    transition: background-color 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.VL_displayname {
    /* height: 30px; */
    min-height: 30px;
    width: 100%;
    padding: 1px;
    font-size: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: var(--maindark);
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    font-family: Lato;
    white-space: pre;
}

.VL_iconroot {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
}


.VL_topbtn {
    width: 65px;
    border: none;
    font-family: Lato;
    margin: 10px 0px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    transition: border 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background-color: #ffffff;
    color: var(--maindark);
    place-content: center;
    text-align: center;
    display: flex;
}

.VL_selectedtopbtn {
    border-radius: 5px;
    background-color: var(--maindark);
    color: #ffffff;
    display: flex;
    place-content: center;
    text-align: center;
}

.VL_moreportalcontentWEB{

}

.VL_moreportalcontentTABLET{

}


.VL_moreportalcontentMOBILE{

}

.VL_moreitemroot{
    display: flex;
    flex-direction: row;
    min-height: 60px;
    width: 100%;
    padding: 5px 10px;
    border-bottom: 1px solid var(--subgrey);
}
.VL_moreitemroot{

}
.VL_titlecontentroot{
    display: flex;
    flex-direction: column;
}
.VL_moretitle{
    overflow:hidden;
    margin-bottom: 5px;
}
.VL_morecontent{
    word-break: break-all;
}

.Vendorlookup_enquirebtn{
    border: none;
    border-radius: 10px !important;
    font-size: 16px;
    letter-spacing: .1px;
    outline: none;
    padding: 10px 15px;
    height: 48px;
  margin: 0 10px;
  background-color: var(--maindark);
  color: #fff;
}


.Vendorlookup_enquirebtnWEB{
    
}

.Vendorlookup_enquirebtnTABLET{
    
}

.Vendorlookup_enquirebtnMOBILE{
    
}