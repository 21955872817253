.bsbtn{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #707070;
    display: block;
    padding: 6px 10px;
}

.bsbtn:hover{
    background-color: #eb5d7c2f;
    text-decoration: none;
    color:#707070
}

.bsbtn_selected{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    background-color: #eb5d7b;
    display: block;
    padding: 6px 10px;
    border: none;
}

.movingslide {
    animation: 25s linear 0s infinite normal none running slide;
    color:white;
    flex: 1 1 auto;
    font-family: Noto Sans JP,sans-serif;
    font-size: larger;
}

@keyframes slide {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}	
.marketerr_root{
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
}
.marketerr_single{

}