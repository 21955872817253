.bg_ultroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 80px);
}

.bg_explainroot {
    padding: 10px 10px 0px 10px;
}

.bg_btmrootWEB {
    display: flex;
    flex-direction: row;
    padding: 5px;
    flex-wrap: wrap;
    position: relative;
    -webkit-overflow-scrolling: touch;
    /* overflow-y: overlay; */
    margin-bottom: 5%;
}

.bg_btmrootTABLET {
    display: flex;
    flex-direction: row;
    padding: 5px;
    flex-wrap: wrap;
    position: relative;
    -webkit-overflow-scrolling: touch;
    /* overflow-y: auto; */
    margin-bottom: 5%;
}

.bg_btmrootMOBILE {
    display: flex;
    flex-direction: row;
    padding: 5px;
    flex-wrap: wrap;
    /* -webkit-overflow-scrolling: touch; */
    width: 100%;
    height: max-content;
}

.bg_tickMOBILE {
    position: absolute;
    top: calc(50% - 15px);
    right: calc(50% - 15px);
    margin: 0px;
    font-size: 20px;
    color: white;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: #eb5d7b;
    border: 2px solid white;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
}

.bg_tickWEB {
    position: absolute;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
    margin: 0px;
    font-size: 20px;
    color: white;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: #eb5d7b;
    border: 2px solid white;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
}

.bg_tickTABLET {
    position: absolute;
    top: calc(50% - 20px);
    right: calc(50% - 20px);
    margin: 0px;
    font-size: 20px;
    color: white;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: #eb5d7b;
    border: 2px solid white;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
}

.bg_emptycard {
    width: 85%;
    /* height: 100px; */
    border: thin dashed rgb(112, 112, 112);
    border-radius: 10px;
}

.bg_common {
    position: relative;
    display: flex;
    border-radius: 10px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: border 100ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.leftbg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: none;
    left: 50%;
    top: -50%;
    transform: scaleY(1) scale(-1);
}

.rightbg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: none;
    right: 50%;
    bottom: -50%;
}

.bg_selected {
    border: 2px solid #eb5d7b;
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
}

.bg_circleselected {
    border: 2px solid #eb5d7b;
    background-color: #eb5d7b;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
}

.bg_unselected {
    border: 2px solid #707070;
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
}

.bg_circleunselected {
    border: 2px solid #707070;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
}

.bg_formationselected {
    border: 1px solid #eb5d7b;
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
}

.bg_formationunselected {
    border: 1px solid #707070;
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
}

/* @media (min-width:0px) {
    
}

@media (min-width:500px) {
    
}

@media (min-width:800px) {
   
}

@media (min-width:961px) {
   
}

@media (min-width:1025px) {
   
}

@media (min-width:1100px) {
  
}

@media (min-width:1200px) {
   
}

@media (min-width:1281px) {
   
}

@media (min-width:1300px) {
   
}

@media (min-width:1320px) {
  
}

@media (min-width:1350px) {
   
}

@media (min-width:1400px) {
       .bg_ultroot {
        height: calc(100% - 78px);
    }
}

@media (min-width:1450px) {
    .bg_ultroot {
        height: calc(100% - 78px);
    }
} */
