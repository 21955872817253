#stat_root {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
    font-family: Lato;
}

.stat_col {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
}

.stat_row {
    display: flex;
    flex-direction: row;
}

.stat_card {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    margin-bottom: 15px;
    position: relative;
}

.stat_title {
    display: flex;
    place-content: center;
    align-items: center;
    margin-bottom: 25px;
    font-size: 18px;
}

.stat_num {
    font-size: 16px;
}

.stat_response {
    display: flex;
    flex-direction: column;
}

.stat_qnframe {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--maindark);
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.stat_optionroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px dashed var(--maindark);
    padding: 15px;
}

.stat_optionroot:first-child {
    border-top: 1px solid var(--maindark);
}

.stat_optionroot:last-child {
    border-bottom: 0px dashed var(--maindark);
}

.stat_optionname {
    width: calc(100% - 50px);
    white-space: pre-wrap;
}

.stat_optioncount {
    width: 50px;
    display: flex;
    place-content: center;
    align-items: center;
}



@media (min-width:280px) {
    #stat_root {
        width: 100%;
    }

    .stat_card {
        padding: 15px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 20px;
    }

    .stat_qntxt {
        font-size: 15px;
        padding: 18px 15px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 15px;
    }

}

@media (min-width:320px) {
    #stat_root {
        width: 100%;
    }

    .stat_card {
        padding: 15px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 20px;
    }

    .stat_qntxt {
        font-size: 15px;
        padding: 18px 10px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 10px;
    }
}

@media (min-width:481px) {
    #stat_root {
        width: 100%;
    }

    .stat_card {
        padding: 15px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 25px;
    }

    .stat_qntxt {
        font-size: 15px;
        padding: 18px 10px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 10px;
    }
}

@media (min-width:641px) {
    #stat_root {
        width: 500px;
    }

    .stat_card {
        padding: 30px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 30px;
    }

    .stat_qntxt {
        font-size: 15px;
        padding: 18px 15px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 15px;
    }
}

@media (min-width:961px) {
    #stat_root {
        width: 500px;
    }

    .stat_card {
        padding: 30px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 30px;
    }

    .stat_qntxt {
        font-size: 15px;
        padding: 18px 15px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 15px;
    }
}

@media (min-width:1025px) {
    #stat_root {
        width: 500px;
    }

    .stat_card {
        padding: 30px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 30px;
    }

    .stat_qntxt {
        font-size: 16px;
        padding: 20px 15px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 15px;
    }
}

@media (min-width:1281px) {
    #stat_root {
        width: 700px;
    }

    .stat_card {
        padding: 30px;
    }

    /* for mcq qn */
    .stat_qnframe {
        margin-top: 30px;
    }

    .stat_qntxt {
        font-size: 16px;
        padding: 20px 15px;
    }

    .stat_optionsroot {
        font-size: 14px;
    }

    .stat_optionroot {
        padding: 15px;
    }
}