/* 
.T1simmobile_root {
    font-family: Lato;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow-x: hidden;
    box-shadow: rgb(0 0 0 / 13%) 0px 10px 50px; 
}
*/

/* Hide scrollbar for Chrome, Safari and Opera */
.T1simmobile_root::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.T1simmobile_root {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.T1simmobile_root2::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.T1simmobile_root2 {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.T1simmobile_navitem {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor: pointer;
    width: 40px;
    height: 50px;
}

.topright_m_simulator {
    width: 250px;
    height: 250px;
    left: 45%;
    bottom: 65%;
}

.btmleft_m_simulator {
    width: 250px;
    height: 250px;
    right: 45%;
    top: 65%;
}

.topleft_m_simulator {
    width: 250px;
    height: 250px;
    right: 45%;
    bottom: 65%;
}

.btmright_m_simulator {
    width: 250px;
    height: 250px;
    left: 45%;
    top: 65%;
}

.btm_m_simulator {
    width: 140%;
    height: 50%;
    background-position: bottom;
    display: flex;
    bottom: 0;
    left: -20%;
}


.T1sim_m_topnav {
    display: flex;
    flex-direction: row;
    height: 30px;
    top: 0;
    position: absolute;
    z-index: 1000;

}

.T1simmobile_topnavitem {
    place-content: center;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 0px 4px !important;
}

.T1sim_m_nav {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    bottom: 0;
    z-index: 1000;
}

.T1simmobile_navitem_withouticon {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor: pointer;
    height: 50px;
}