#Inbox_root {
    height: 100%;
    position: fixed;
    width: 100%;
}

.Inbox_msgorinqroot {
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: center;
    height: 60px;
    place-items: center;
    min-height: 60px;
}

.Inbox_msgorinq {
    width: 100px;
    height: 40px;
    background-color: #ffffff;
    border: 1px solid var(--maindark);
    color: var(--maindark);
}

.Inbox_msgorinq_left {
    border-right: none;
    border-radius: 10px 0px 0px 10px;
}

.Inbox_msgorinq_right {
    border-radius:0px 10px 10px 0px;
}

.Inbox_msgorinq_selected {
    background-color: var(--mainpink);
    color: #ffffff;
}

#Inbox_main {
    display: flex;
    height:calc( 100% - 35px - 85px);
    flex-direction: column;
    overflow-y: auto;
}