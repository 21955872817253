.Bulletm_root{
    display:flex;
    position: relative;
    flex-direction: row;
    overflow-y: hidden;
    max-height: 100vh;
    height:100vh;
    background-color: black;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    width:100vw;
    max-width: 100vw;
}

.Bulletm_root::-webkit-scrollbar {
    display: none;
}

.Bm_imgroot{
    width:100%;
    min-width: 100%;
    height:100vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
}

.danmu_root{
    display:flex;
    width:100%;
    height: 100%;
    flex-direction: column;
    position: absolute;
}

.dan{
    color: #fff;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-family: Noto Sans JP,sans-serif;
    font-size: larger;
    position: relative;
    right: 0;
    font-size: 32px;
    border-radius:15px;
    padding:5px;
    text-align: center;
}