.Importcontent_rootWEB{
    padding:0px 10px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    min-width:500px;
}
.Importcontent_rootTABLET{
    padding:0px 10px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    min-width:500px;
}
.Importcontent_csvreader{
    max-height: 55px;
    background-color: transparent;
    cursor: pointer;
    align-self: center;
    width:100%;
}
.Importcontent_csvreader>div{
    padding:0px !important;
}
.Importcontent_glroot{
    display: flex;
    width:100%;
    padding: 5px;
    margin: 0px 0px 10px 0px;
}


.Importcontent_col{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Importcontentt_excelroot{
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}
.Importcontent_firstrow{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    color: white;
}
.Importcontent_secrow{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}
.Importcontent_thirdrow{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}
.Importcontent_firstrowcell{
    padding: 5px;
    width: 25%;
    border-top: 1px solid var(--maindark);
    border-bottom: 1px solid var(--maindark);
    border-left: 1px solid var(--maindark);
    border-image: initial;
    background-color: var(--maindark);
}
.Importcontent_secrowcell{
    padding: 5px;
    width: 25%;
    border-left: 1px solid var(--maindark);
    border-image: initial;
}
.Importcontent_thirdrowcell{
    padding: 5px;
    width: 25%;
    border-top: 1px solid var(--maindark);
    border-bottom: 1px solid var(--maindark);
    border-left: 1px solid var(--maindark);
    border-image: initial;
}

