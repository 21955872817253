.locationselector_root {
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #707070;
}

.locmaintext {
    width: 100%;
    padding: 10px;
    display: flex;
    place-content: flex-start;
    align-items: flex-start;
}

.selectedloc {
    height: 45px;
    width: calc(100% - 20px);
    padding: 5px;
    margin: 0px 10px 10px 10px;
    box-shadow: var( --portalinnerelemboxshadow);
    border: var( --portalinnerelemborder);
    position: relative;
    color: white;
    background-color: #eb5d7b;
    border-radius: 5px;
    align-items: center;
}

.nonselectedloc {
    height: 45px;
    width: calc(100% - 20px);
    padding: 5px;
    margin: 0px 10px 10px 10px;
    box-shadow: var( --portalinnerelemboxshadow);
    border: var( --portalinnerelemborder);
    position: relative;
    border-radius: 5px;
    align-items: center;
}

.locationselector_loc {
    width: 37px;
    background-color: transparent;
    outline: none;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
}

.locationselector_modalroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    place-content: center;
    cursor: pointer;
    position: relative;
    font-family: Lato;
    align-items: center;
}

.locationselector_val {
    top: 0;
    left: 0;
    position: absolute;
    padding: 0px 10px;
    margin: 0px;
    font-weight: bold;
    height: 100%;
    align-items: center;
    place-content: center;
    text-align: center;
    display: flex;
}

.locationselector_locitem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 16px;
}