.Start_root{
    align-items: center;
    place-content: center;
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.Start_btn{
    display: flex;
    background-color: transparent;
    color: black;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    width: 180px;
    height: 80px;
    border: 1px solid black;
    place-content: center;
    font-size: large;
    font-family: Lato;
    position: relative;
    overflow: hidden;
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: pre-line;
    font-size: 16px;
    font-family: Lato;

}
.Startbtn_root{
  height: 100vh;
  width:100%;
  display: flex;
  place-content: center;
  align-items: center;
}
.Start_frontroot{
  margin: 50px 0px;
  width: 100%;
  display: flex;
  place-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes button_animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
    30% {
      opacity: 1;
    }
    80% {
      transform: translate(-50%, -50%) scale(0.8);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
  }

.Start_icon{
  text-align: center;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  place-content: center;
  place-self: center;
  background-color: white;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #eb5d7b;
}
.Start_browsegb{
  display: flex;
  text-align: center;
  white-space: pre-wrap;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(235, 93, 123);
  color: white;
  border:1px solid white;
  padding: 5px 20px;
  font-size: 17px;
  touch-action: manipulation;
  height: 40px;
}
.Start_backbtn{
  display: flex;
  text-align: center;
  white-space: pre-wrap;
  border: 1px solid #eb5d7b;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  color: #eb5d7b;
  padding: 5px 20px;
  font-size: 17px;
  touch-action: auto;
  position: absolute;
  width: 85px;
  height: 40px;
  top: 0;
  left:0;
  margin: 10px;
  z-index:1;
  position: fixed;
}
.Start_floatbtmbar{
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  align-items: center;
  border: 0px solid transparent;
  border-bottom: 0px;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  place-content: flex-end;
  background-color: transparent;
  top: 0;
}
.Start_indexing{
  display: flex;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  border: 1px solid rgb(112, 112, 112);
  background-color: rgb(112, 112, 112);
  margin-right: 10px;
}
.Start_heartindexing{
  height: 30px;
  width: 20px;
  margin-right: 10px;
  font-size: 20px;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.Emojisummary_root{
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Lato;
  /* background-image: url(https://i.ibb.co/VJk7f0B/main-bg-YIR-d2x-2.png); */
  /* background-image:url(https://i.ibb.co/MkkC7Vq/actual.png); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.Emojisummary_title{
  display: flex;
  place-content: center;
  color: rgb(235, 93, 123);
  font-weight: bold;
  font-size: 39px;
  padding: 50px 10px 10px;
}
.Emojisummary_content{
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  place-content: center;
  align-items: center;
  z-index: 1;
}
.Emojisummary_pagecontent{
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  align-items: center;
}
.Emojisummary_pageindicatorroot{
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-items: center;
  place-content: center;
}
.Emojisummary_nextbackroot{
  display: flex;
  flex-direction: row;
  width: 100%;
  color: white;
  font-size: 17px;
  font-weight: bold;
  place-content: center;
  padding: 10px;
}
.Emojisummary_nbbtn{
  touch-action: manipulation;
  height: 40px;
  width: 200px;
  background-color: #eb5d7b;
  border-radius: 10px;
  border: 1px solid white;
  margin: 0px 5px 0px 0px;
  user-select: none;
}
#Start_pointerbtn{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;
    width: 100px;
    border-radius: 10px;
    border: 1px solid black;
    touch-action: manipulation;
    padding: 5px 20px;
    user-select: none;
}