.p_root{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000040;
    z-index: 99999;
    place-content: center;
    display: flex;
}

.p_subroot{
    display: flex;
    flex-direction: column;
    width:550px;
    height: 100vh;
    top: 0;
    background-color:white ;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%);
    place-content: flex-start;
    align-items: center;
    position: relative;
}
.p_toptitlebar{
    display: flex;
    width:100%;
    font-size: 21px;
    border-bottom: 1px solid rgba(206, 206, 206, 0.933);
    padding: 13px;
    place-content: space-between;
}
.p_bodyroot{
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}
.p_row{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:white;
}
.p_btnleft{
    flex-direction: column;
    height: 60px;
    width: 50%;
    text-align: center;
    place-content: center;
    align-items: center;
    display: flex;
    background-color: white;
    border-radius: 5px;
    margin: 10px;
    margin-right: 5px;
    margin-bottom: 0px;
    border: 1px solid #707070;
    font-size: 16px;
    letter-spacing: 0.5px;
}
.p_btnright{
    flex-direction: column;
    height: 60px;
    width: 50%;
    text-align: center;
    place-content: center;
    align-items: center;
    display: flex;
    background-color: white;
    border-radius: 5px;
    margin: 10px;
    margin-left: 5px;
    margin-bottom: 0px;
    border: 1px solid #707070;
    font-size: 16px;
    letter-spacing: 0.5px;
}
.p_addressroot{
    display: flex;
    flex-direction: column;
    margin: 10px;
    height: max-content;
    width:calc( 100% - 20px );
}
.p_contentroot{
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    margin: 10px;
    height: 200px;
    width:calc( 100% - 20px );
    min-height: max-content;
}
.p_label{
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    width: max-content;
}
.gbedit_purchase_content{
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 0px;
}
.gbedit_purchase_paymentmethod{
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}
.p_confirm{
    flex-direction: row;
    height: 40px;
    width: 100%;
    text-align: center;
    place-content: center;
    align-items: center;
    display: flex;
    background-color: #eb5d7b;
    border-radius: 5px;
    border: 1px solid transparent;
    font-size: 16px;
    margin:10px 10px 0px 10px;
    color: white;
    font-weight: bold;
    
}
.gbedit_purchase_delibtn{
    display: flex;
    border: 1px solid transparent;
    width: 100%;
    border-radius: 5px;
    min-height: max-content;
    padding: 5px;
    height: 100px;
    margin: 0px 0px 15px 0px;
    align-items: center;
    border: 1px solid black;
    margin-left: 5px;
    background-color: white;
    place-content: center;
    flex-direction: column;
    font-size: 16px;
}
.p_delsub{
    font-size: 11px;
}
.gbedit_purchase_paymentbtn{
    display: flex;
    border: 1px solid transparent;
    width: 100%;
    border-radius: 5px;
    min-height: max-content;
    padding: 5px;
    height: 40px;
    margin: 0px 0px 15px 0px;
    align-items: center;
    border: 1px solid black;
    margin-left: 5px;
    background-color: white;
    place-content: center;
    flex-direction: column;
    max-height: max-content;
    font-size: 16px;

}
.p_codeinput{
    height: 33px;
    margin-bottom: 10px;
    /* width: 100% ; */
    width: calc( 100% - 65px );
}
.gbedit_purchase_input{
    height: 33px;
    margin-bottom: 10px;
}
.gbedit_closeicon{
    cursor: pointer;
}
.gbedit_purchase_paymentdom{
    display: flex;
    flex-direction: column;

}
.p_close{
    cursor: pointer;
}
.p_applycode{
    height: 33px;
    width: 60px;
    background-color: white;
    border-radius: 5px;
    border:1px solid black;
    margin-left: 5px;
}
.gbedit_label{
    height: 33px;
    margin-bottom: 10px;
    text-align: center;
    align-items: center;
    place-content: center;
    border: 1px solid black;
    display: flex;
    border-radius: 2px;
    background-color: black;
    color: white;
}