.simulator_root{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    position:relative;
    background-color: #fbfbfb;
}
.simulator_credmodalroot{
    padding: 10px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    min-width: 300px;
}
.simulator_deviceroot{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    top: 0px;
    position: absolute;
    width: 100%;
    height: 60px;
    align-items: center;
    place-content: center;
}
.simulator_deviceselected{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    place-content: center;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    outline: none;
    border: 1px solid #e9e9e9;
    box-shadow: rgb(25 25 25 / 25%) 0px 2px 3px;
    z-index: 0;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.simulator_device{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    place-content: center;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid transparent;
    background-color: #efeff0;
    color: #aaaaaa;
    transition: background-color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, color 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s, -webkit-box-shadow 500ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
.simulator_devicesubroot{
    border: 1px solid rgb(218, 218, 218);
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    background-color:  #efeff0;
}
.simulator_mobileclickscroll{
    border-radius: 50%;
    display: flex;
    place-self: center;
    align-items: center;
    fill: white;
    border: none;
    background-color: transparent;
}
.simulator_noselect{
        -webkit-touch-callout: none; 
          -webkit-user-select: none; 
           -khtml-user-select: none;
             -moz-user-select: none; 
              -ms-user-select: none; 
                  user-select: none; 
}

@media (min-width:641px)  {
    .simulator_mobileclickscroll{
        padding: 0px;
        margin: 0px;
    
    }
    .simulator_clickscrollroot{
        display: flex;
        flex-direction: row;
        width: 100%;
        place-content: flex-end;
        padding-top: 10px;
    }

}
@media (min-width:1100px) { 
    .simulator_mobileclickscroll{
        margin-top: 5px;
        margin-left: 0px;
    }
    .simulator_clickscrollroot{
        display: flex;
        flex-direction: column;
        width: 40px;
        place-content: flex-end;
    }
 
}