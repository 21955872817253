.Tlookup_WEB_root {
    width: 100%;
    height: 100%;
    /* padding: 15px 25%; */
    padding: 1px;
}

.Tlookup_TABLET_root {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.Tlookup_MOBILE_root {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.Tlookup_subroot {
    min-height: 100vh;
    box-shadow: rgb(238 238 238) 0px 5px 40px -12px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

#Itinerarylookup_root {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: center;
    padding: 15px;
    min-height: 100%;
    box-shadow: 0 2px 8px #0e131812;
}
.Itinerarylookup_empty{
    display: flex;
    width: 100%;
    height: 100vh;
    place-content: center;
    align-items: center;
}

#Itinerarylookup_comproot {
    display: flex;
    /* border: 1px solid var(--maindark); */
    align-items: center;
    width: 500px;
}

.Itinerarylookup_textroot {
    height: auto;
    min-height: 100%;
    width: 100%;
    border: 1px solid var(--maindark);
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: transparent;
}
.Itinerarylookup_qrroot {
    height: auto;
    min-height: 100%;
    width: 100%;
    border: 1px solid var(--maindark);
    white-space: pre-wrap;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: transparent;
    place-content: center;
}

.Itinerarylookup_editbtn {
    background-color: transparent;
    border: none;
}
.Itinerarylookup_menu {
    display: flex;
    flex-direction: row;
    height: 30px;
    border: 1px solid var(--maindark);
    place-content: space-between;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1;
    font-size: 16px;
}

/* .Itinerarylookup_menu {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 150px;
    border: 1px solid var(--maindark);
    place-content: space-between;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 1;
} */

/* .Itinerarylookup_menu{
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 150px;
    border: 1px solid var(--maindark);
    place-content: space-between;
    padding: 5px;
}    */
.Itinerarylookup_content {
    /* width: calc( 100% - 50px ); */
    width: 100%;
    height: max-content;
    place-content: center;
    align-items: center;
    display: flex;
}

.Itinerarylookup_img {
    display: flex;
    width: 65px;
    height: 65px;
    border: 1px solid var(--subgrey);
    border-radius: 100px;
}