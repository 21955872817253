#Timelineobj_root{
    display: flex;
    flex-direction:column;
    width: 100%;
}

#Timelineobj_main{
    display: flex;
    flex-direction: row;
    height: max-content;
    width:100%;
    align-items: flex-start;
    align-self: center;
}
#Timelineobj_time{
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#Timelineobj_timeright{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: max-content;
    background-color: transparent;
    border-radius: 3px;
}
#Timelineobj_dateright{
    display: flex;
    flex-direction: row;
    height: max-content;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    min-height: max-content;
}
#Timelineobj_line{
    height: 100%;
    display: flex;
    place-content: center;
    justify-items: center;
    min-height: 20px;
    opacity: 0.6;
}
#Timelineobj_nametitle{
    display: flex;
    padding:0px 0px 10px 0px;
}