/* .VCARD_ROOT {
    display: flex;
    flex-direction: column;
    transition: box-shadow .3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 6px;
    align-items: center;
    margin: 7px;
    background-color: white;
    justify-content: flex-start;
    height: 370px;
    user-select: none;
}

.VCARD_ROOT:hover {
    box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px
} */


.Vendorcard_root {
    transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0ms;
    position: relative;
}

.Vendorcard_root:hover {
    box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px
}


.VCARD_IMGROOT {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.VCARD_IMG {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 1px;
    border-radius: 150px;
    height: 160px;
    width: 160px;
    display: flex;
    justify-content: center;
}

.VCARD_NAMEVTPRICEROOT {
    color: #2b2b2b;
    font-weight: bold;
    overflow-x: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

}

.VCARD_NAMESUBROOT {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    letter-spacing: 0.1px;
    line-height: 18px;
}

.VCARD_NAMEFIRSTROOT {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    align-items: center;
}

.VCARD_NAMESECROOT {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%
}

.VCARD_COVIDANDHEART {
    display: flex;
    width: 100%;
    padding: 3px 3px 0px 0px;
    height: 40px;
    justify-content: space-between;

}

.VCARD_LINK {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 330px;
    width: 100%;
    text-decoration: none !important;
}

.VCARD_DETAIL_ROOT {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    color: #2b2b2b;
}

.VCARD_TOP {
    width: 100%;
    height: 50%;
    display: flex;
    border-bottom: 1px solid var(--subgrey);
    border-top: 1px solid var(--subgrey);
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.VCARD_TOP_TITLE {
    font-size: 11px;
    color: var(--maindark);
    font-family: Lato;
}

.VCARD_NUM {
    color: var(--maindark);
    font-size: 13px;
}

.VCARD_LR_ROOT {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #2b2b2b;
}

.VCARD_LEFT {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 50%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.VCARD_RIGHT {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--subgrey);
    font-size: 14px;
}

.VCARD_MOBILE_ROOT {
    height: 275px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    align-items: center;
    margin: 7px 4px;
    border: 1px solid var(--subgrey);
}

.VCARD_MOBILE_COVIDANDHEART {
    position: relative;
    height: max-content;
    width: 100%;
}

.VCARD_MOBILE_COVIDANDHEART>div {
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: space-between;
    padding: 5px;
}

.VCARD_MOBILE_IMGLINK {
    text-decoration: none;
    overflow: hidden;
    height: 135px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.VCARD_VT_WEB_ROOT {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 10px;
}

.VCARD_VT_WEB_FONT {
    font-size: 14px;
    color: #767676;
    background-color: transparent;
    font-family: Lato;
    font-weight: normal;
    place-content: center;
    display: flex;
}

.VCARD_VT_MOB_ROOT {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.VCARD_VT_MOB_FONT {
    font-size: 12px;
    /* color: var(--maindark); */
    /* color:#21201f; */
    color: #767676;
    background-color: transparent;
    font-weight: bold;
    min-height: 14px;
}

.VCARD_DIAMONDROOT {
    border-radius: 100px;
    position: relative;
    display: flex;
    width: 30px;
    height: 30px;
    place-content: center;
    align-content: center;
    place-items: center;
    margin: 5px;
}

.VCARD_DIAMONDCONTENT {
    /* width: 20px;
    height: 21px;
    background-size: 21px;
    background-repeat: no-repeat;
    position: absolute; */
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    position: absolute;
}

.VCARD_DIAMONDROOT_1 {
    /* border: 1px solid var(--maindark); */
}

.VCARD_DIAMONDROOT_2 {
    /* border: 1px solid #b8b3b3; */
    background-color: #ffffff;
}

.VCARD_DIAMONDROOT_3 {
    /* border: 1px solid #dcb047; */
    background-color: #ffffff;
}