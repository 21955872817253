.longbar_root{
    display:flex;
    height:60px;
    border-radius:10px;
    padding:10px;
    flex-direction: row;
    width:100%;
    border:thin solid rgb(207, 207, 207);
    overflow-x:auto;
    overflow-y:hidden;
    align-items: center;
}
.longbar_circle{
    width:50px;
    height:50px;
    min-width: 50px;
    min-height: 50px;
    border-radius:50%;
    place-content: center;
    display:flex;
    margin:0px 5px;
    cursor:pointer;
}

