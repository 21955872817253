.ExpansionPanelSummary {
    box-shadow: none !important;
}

.ExpansionPanelDetails {
    box-shadow: none !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiPaper-elevation {
    box-shadow: none !important;
}

.Projgallery_root {
    overflow-x: hidden;
    touch-action: manipulation;
    width: 100%;
}

.ProjgallerysubrootWEB {
    position: relative;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding: 20px;
    min-height: 150px;
    max-height: 150px;
}

.ProjgallerysubrootTABLET {
    position: relative;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding: 20px;
    min-height: max-content;
    max-height: max-content;
}

.ProjgallerysubrootMOBILE {
    position: relative;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding: 0px 10px;
    min-height: 50px;
    max-height: max-content;
}

.Projectgallery_mapbtnWEB {
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 120px;
    align-items: center;
    place-self: center;
    border-radius: 5px;
    border: 1px solid var(--subgrey);
    cursor: pointer;
}

.Projectgallery_mapbtnTABLET {
    display: flex;
    flex-direction: row;
    width: 180px;
    align-items: center;
    place-self: center;
    padding: 5px;
    border-right: 1px solid var(--subgrey);
}

.Projectgallery_mapbtnMOBILE {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    place-self: center;
    padding: 5px;
    border-right: 1px solid var(--subgrey);
    height: 50px;
}

.Projectgallery_mapbtnWEB:hover {
    border: 1px solid var(--maindark);
}

.Projgalleryemptybtn {
    width: 40px;
    height: 40px;
    margin: 5px;
    padding: 5px;
}

.ProjgalleryinnersubrootWEB {
    position: relative;
    max-height: 50px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.ProjgalleryinnersubrootTABLET {
    position: relative;
    max-height: max-content;
    min-height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.ProjgalleryinnersubrootMOBILE {
    position: relative;
    max-height: 45px;
    min-height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    overflow: hidden;
}

.Projgalleryfiltericonmobile {
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    border: 1px solid var(--maindark);
    background-color: transparent;
    outline: none;
    color: var(--maindark);
    width: 40px;
    height: 40px;
    margin: 5px;
}

.ProjgallerydefaultcatWT {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
}

.ProjgallerydefaultcatM {
    display: flex;
    flex-direction: row;
    place-items: center;
}

.ProjgallerytypegalleryWT {
    height: max-content;
    outline: none;
    color: var(--maindark);
    background-color: transparent;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 10px;
    place-content: center;
    font-size: 32px;
    z-index: 2;
}

.ProjgallerytypegalleryM {
    font-size: 32px;
    height: max-content;
    outline: none;
    color: var(--maindark);
    background-color: white;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 10px;
    place-content: center;
}

.ProjgallerymapbtnWEB {
    display: flex;
    place-self: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    text-decoration: none !important;
    margin-top: 5px;
    width: 220px;
    cursor: pointer;
    color: var(--maindark);
    place-content: space-between;
}

.ProjgallerymapbtnWEB:hover {
    text-decoration: none !important;
    cursor: pointer;
    background-color: var(--hoverpink);
}

.ProjgallerymapbtnTABLET {
    display: flex;
    place-self: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    text-decoration: none !important;
    color: var(--maindark);
    width: 220px;
    cursor: pointer;
    place-content: space-between;
}

.ProjgallerymapbtnTABLET:hover {
    text-decoration: none !important;
}

.ProjgallerymapbtnMOBILE {
    display: flex;
    place-self: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--maindark);
    background-color: white;
    width: calc(100% - 10px);
    text-decoration: none !important;
    color: var(--maindark);
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    margin-top: 5px;
    place-content: center;
}

.ProjgallerymapbtnMOBILE:hover {
    text-decoration: none !important;
}

.Projgallerymaptext {
    min-width: calc(100% - 50px);
    width: 125px;
    height: 40px;
    display: flex;
    font-family: 'Lato';
    color: var(--maindark);
    place-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    white-space: pre-wrap;
}

.ProjectgalleryfeaturebtninnerMOBILE {
    user-select: none;
    font-size: 12px;
    place-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    color: var(--maindark);
    width: 100%;
    height: 100%;
    flex-direction: column;
    white-space: pre;
    border: 1px dashed;
    line-height: 14px;
    border-radius: 5px;
}


.ProjectgalleryfeaturebtninnerWEB{
    user-select: none;
    font-size: 14px;
    place-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    color: var(--maindark);
    width: 100%;
    height: 100%;
    flex-direction: column;
    white-space: pre;
    border-radius: 5px;
}


.ProjectgalleryfeaturebtninnerTABLET{
    user-select: none;
    font-size: 14px;
    place-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    color: var(--maindark);
    width: 100%;
    height: 100%;
    flex-direction: column;
    white-space: pre;
    border-radius: 5px;
}