.Gbmod_root{
    display: flex;
    flex-direction: column;
    width: 100%;
    place-content: center;
    align-items: center;
    color: rgb(112, 112, 112);
}
.Gbmod_col{
    display: flex;
    flex-direction: column;
}
.Gbmod_row{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    place-content: flex-end;
}
.Gbmod_topbtnrow{
    display: flex;
    flex-direction: row;
    padding: 20px 10px 10px 10px;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 12%), 0 1px 0px rgb(200 200 200 / 24%);
}
.Gbmod_msg{
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word ;
    position:relative;
}
.Gbmod_tick50{
    display: flex;
    height: 40px;
    width:50%;
    align-items: center;
    place-content: center;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius:0px;
    border: 1px solid #707070;
    border-right: none;
    cursor: pointer;
}
.Gbmod_del50{
    display: flex;
    height: 40px;
    width:50%;
    align-items: center;
    place-content: center;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius:0px;
    border: 1px solid #707070;
    cursor: pointer;
}
.Gbmod_del100{
    display: flex;
    height: 40px;
    width:100%;
    align-items: center;
    place-content: center;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius:0px;
    border: 1px solid #707070;
    cursor: pointer;
}
.Gbmod_topbtnL{
    display: flex;
    padding:5px;
    border:1px solid #707070;
    border-right: 1px solid transparent;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius:0px;
    flex-basis: 33.33%;
    height: 40px;
    place-content: center;
    background-color: white;
    color:#707070;
    cursor: pointer;
    align-items: center;
    font-size: 15px;

}
.Gbmod_topbtnselectedL{
    display: flex;
    padding:5px;
    border:1px solid #9c0424;
    border-right: 1px solid transparent;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius:0px;
    height: 40px;
    place-content: center;
    background-color: #d82a56cf;
    color: white;
    cursor: pointer;
    align-items: center;
    flex-basis: 33.33%;
    font-size:15px;
    font-weight: bold;
}
.Gbmod_topbtnM{
    display: flex;
    padding:5px;
    border:1px solid #707070;
    border-right: 1px solid transparent;
    flex-basis:33.33%;
    height: 40px;
    place-content: center;
    background-color: white;
    color:#707070;
    cursor: pointer;
    align-items: center;
    font-size: 15px;
}
.Gbmod_topbtnselectedM{
    display: flex;
    padding:5px;
    border:1px solid #9c0424;
    border-right: 1px solid transparent;
    flex-basis:33.33%;
    height: 40px;
    place-content: center;
    background-color: #d82a56cf;
    color: white;
    cursor: pointer;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
}
.Gbmod_topbtnR{
    display: flex;
    padding:5px;
    border:1px solid #707070;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius:0px;
    width:33.33%;
    height: 40px;
    place-content: center;
    background-color: white;
    color:#707070;
    cursor: pointer;
    align-items: center;
    font-size: 15px;

}
.Gbmod_topbtnselectedR{
    display: flex;
    padding:5px;
    border:1px solid #9c0424;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius:0px;
    flex-basis:33.33%;
    height: 40px;
    place-content: center;
    background-color: #d82a56cf;
    color: white;
    cursor: pointer;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
}


