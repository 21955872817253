#T1sim_webulti {
    display: flex;
    position: relative;
}

#T1sim_webroot {
    font-family: Lato;
    box-shadow: rgb(0 0 0 / 13%) 0px 10px 50px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    align-items: center;
    border: 1px solid #ececec;
}

.T1sim_topbar {
    display: flex;
    flex-direction: row;
    min-height: 20px;
    border-bottom: 1px solid #ececec;
    align-items: center;
    z-index: 999;
    background-color: white;
}

.T1sim_topbarcircle {
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin: 0px 2px;
}

.T1sim_1circle {
    background-color: rgb(41, 205, 66);
    margin-left: 4px;
}

.T1sim_2circle {
    background-color: rgb(255, 191, 47);
}

.T1sim_3circle {
    background-color: rgb(255, 97, 89);
}

.T1sim_btmnav {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    bottom: 0;
    z-index: 1000;
}

.T1sim_btmnavitem {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor: pointer;
    height: 35px;
    width: 35px;
}

.T1sim_btmnav_withouticon {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    bottom: 0;
    z-index: 1000;
}

.T1sim_btmnavitem_withouticon {
    font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  cursor: pointer;
  height: 35px;
  margin: 0px 2.5px;
}

.T1sim_topnav {
    display: flex;
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100%;
    right: 0;
    height: 50px;
    place-items: flex-end;
    padding-right: 15px;
}

.T1sim_topnavitem {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    cursor: pointer;
    padding: 7px;
}

.ilookup_toptxt{

}


@media (min-width:641px) {
    #T1sim_webulti {
        flex-direction: column;
    }
}
@media (min-width:761px) {
    #T1sim_webulti {
        flex-direction: column;
    }
    #T1sim_webroot {
        transform: scale(0.75);
    }
}


@media (min-width:861px) {
    #T1sim_webulti {
        flex-direction: column;
    }
    #T1sim_webroot {
        transform: scale(0.85);
    }
}


@media (min-width:961px) {
    #T1sim_webulti {
        flex-direction: column;
    }
    #T1sim_webroot {
        transform: scale(0.93);
    }
}

@media (min-width:1025px) {
    #T1sim_webulti {
        flex-direction: column;
    }
    #T1sim_webroot {
        transform: scale(1);
    }
}

@media (min-width:1100px) {
    #T1sim_webulti {
        flex-direction: row;
    }
    #T1sim_webroot {
        transform: scale(1);
    }
}

.T1sim_contentroot::-webkit-scrollbar {
    background-color: #dbd6d669;
    width:10px;
}

.T1sim_contentroot::-webkit-scrollbar-thumb {
    background-color: #0000005e;
    border-radius: 60px;
    width:100%;
} 

.topright_wt_simulator {
    width: 250px;
    height: 250px;
    left: calc( 80% - 10px); 
    /* 10 px scroll bar */
    bottom: 50%;
}

.btmleft_wt_simulator{
    width: 250px;
    height: 250px;
    right: 80%;
    top: 50%;
}

.topleft_wt_simulator{
    width: 250px;
    height: 250px;
    right: 80%;
    bottom: 50%;
}

.btmright_wt_simulator{
    width: 250px;
    height: 250px;
    left: calc( 80% - 10px); 
    /* 10 px scroll bar */
    top: 50%;
}