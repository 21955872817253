.Footer_rootTABLET {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 80%;
    color: var(--maindark);
    place-self: center;
}

.Footer_rootWEB {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 80%;
    color: var(--maindark);
    place-self: center;
}

.Footer_rootMOBILE {
    display: flex;
    flex-direction: column;
    padding: 5px;
    color: var(--maindark);
    border-top: 1px solid #dbdbdb;
}

.Footer_subrootMOBILE {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    margin: 5px 10px;
    padding: 10px;
}

.Footer_subrootWEB {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    margin: 5px 10px;
    padding: 10px;
}

.Footer_subrootTABLET {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    margin: 5px 10px;
    padding: 10px;
}

.Footer_title {
    display: flex;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    border-bottom: 1px solid var(--maindark);
    width: max-content;
}

.Footer_content {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
    font-family: lato;
}

.Footer_item {
    padding: 5px 0px;
    color: var(--maindark);
    white-space: nowrap;
}

.Footer_icon {
    margin-right: 5px;
    margin-bottom: 3px;
}

.Footer_iconrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--maindark);
}

.Footer_divider {
    display: flex;
    width: 100%;
    /* border-top: thin solid #e3e3e3; */
    border-top: thin solid transparent;
    margin: 5px 0px 10px 0px
}