.MSB_SUPERROOTM {
    display: flex;
    flex-direction: column;
    height: 65px;
    border-bottom: 1px solid var(--subgrey);
    max-width: 1920px;
    align-self: center;
}

.MSB_vtbtnsslim {
    display: flex;
    height: 55px;
    margin-right: 5px;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.MSB_subroot {
    display: flex;
    height: 75px;
    margin-top: 25px;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
}

/* BOTH FAT AND SLIM */
.MSB_emptyarrow {
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    margin: 5px;
    place-self: center;
}

.MSB_arrow {
    width: 30px;
    height: 30px;
    place-self: center;
    margin: 5px;
    display: flex;
    place-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.MSB_vticon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    place-content: center;
    color: var(--maindark);
    cursor: pointer;
}

.MSB_vticon:hover {
    /* color: var(--weakermainpink); */
}

.MSB_vticonselected {
    color: var(--mainpink)
}

.MSB_vticon_vtname {
    margin-top: 5px;
    letter-spacing: 0.5px;
    text-align: center;
    display: flex;
    height: 35px;
    white-space: pre-wrap;
}

/* FAT */
.MSB_fatroot {
    display: flex;
    justify-content: center;
    margin: 35px;
    flex-direction: column;
    width: calc(100% - 70px);
}

.MSB_fatvtroot {
    display: flex;
    flex-direction: row;
}

.MSB_vtbtnsgrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 100px);
    place-content: center;
    padding: 0px;
    margin: 0px;
}

.MSB_vtshowbtn {
    width: 120px;
    height: 58px;
    white-space: pre-wrap;
    border: none;
    border-right: 1px solid var(--maindark);
    padding: 0px 5px;
    font-size: 12px;
    background-color: var(--maindark);
    color: #ffffff;
}

.MSB_vtscroll {
    scrollbar-width: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
}

.MSB_vtscroll::-webkit-scrollbar {
    background: transparent;
    /* Chrome/Safari/Webkit */
    width: 0px;
}

.MSB_vgsegment {
    display: flex;
    flex-direction: row;
}

.MSB_fattop {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--subgrey);
    border-radius: 15px;
    place-content: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
}

.MSB_fatbtm {
    display: flex;
    flex-direction: row;
    place-content: center;
    margin-top: 15px;
    /* place-content: flex-end; */
}

.MSB_fatexpandhidebtn {
    border: none;
    background-color: var(--maindark);
    color: rgb(255, 255, 255);
    border-radius: 100px;
    padding: 3px 10px;
    place-self: center;
    display: flex;
    position: absolute;
    bottom: -13px;
    height: 26px;
    align-items: center;
    text-align: center;
}

.MSB_faname {
    color: var(--maindark);
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: white;
    font-size: 1.5em;
    display: flex;
    place-content: center;
    place-self: start;
    font-weight: bold;
}

/* SLIM */

.MSB_slimroot {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--subgrey);
    height: 100px;
    place-content: center;
    box-shadow: 0 2px 4px #00000014;
}

.MSB_slimtop {
    user-select: none;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    padding: 0px 10px;
    width: 100%;
}

.MSB_slimvtsroot {
    display: flex;
    height: 100px;
    margin-right: 5px;
    align-items: center;
    position: relative;
    width: 100%;
    flex-direction: row;
    overflow-y: hidden;
    scrollbar-width: none;
}

.MSB_slimvtsroot::-webkit-scrollbar {
    background: transparent;
    /* Chrome/Safari/Webkit */
    width: 0px;
    height: 0px;
}

.MSB_visualdata {
    min-width: 110px;
    place-self: center;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    position: relative;
    height: 58px;
    border: 1px solid var(--subgrey);
    margin-top: 15px;
    border-radius: 15px;
    margin-left: 5px;
}