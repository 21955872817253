.Checklist_equal{
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    background-color: #6dc761;
    color: white;
    place-self: flex-end;
    margin:10px;
}
.Checklist_nonequal{
    padding: 0px 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    place-self: flex-end;
    border: thin solid #707070;
    border-radius:5px;
    margin:10px;
}

.Checklist_additem{
    padding: 0px 5px;
    margin: 20px 5px 10px 0px;
    border-radius: 5px;
    width: max-content;
    height: max-content;
    text-align: center;
    border: thin solid #707070;
}
.Checklist_delitem{
    padding: 0px 5px;
    margin: 20px 0px 10px 5px;
    border-radius: 5px;
    width: max-content;
    height: max-content;
    text-align: center;
    border: thin solid rgb(112, 112, 112);
    color: rgb(226, 90, 90);
    font-weight: bold;
}

.Checklist_deledititem{
    display: flex;
    align-self: center;
    place-content: center;
    align-items: center;
    min-height: 30px;
    min-width: 30px;
    max-width: 30px;
    margin: 5px;
    border-radius: 5px;
    background-color: transparent;
    outline:none;
    border:thin solid #707070;
}

.Checklist_chkbtnroot{
    height: 100%;
    width:max-content;
    display: flex;
    align-items: center;
}
.Checklist_chkbtncheck{
    margin: 5px;
    padding: 8px 8px 8px 0px;
    color:#6dc761;
}
.Checklist_chkbtnblank{
    margin: 5px;
    padding: 8px 8px 8px 0px;
    color:#707070;
}
.Checklist_content{
    position: relative;
    width:100%;
    outline: none;
    width: 100%;
    margin-right: 5px;
    flex-wrap: wrap;
    min-height: 60px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
}
.Checklist_link{
    align-self: center;
    margin: 5px 0px 0px;
    padding: 0px 4px;
    border-radius: 5px;
    color: white;
    background-color: #f77f7f;
    text-align: center;
}