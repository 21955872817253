.Notfound_root {
    display: flex;
    place-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    font-family: Lato;
    flex-direction: column;
    text-align: center;
}
.Notfound_404{
    font-size: 48px;
    font-weight: bold;
}