/* web */
.Emojiart_leftWEB{
    overflow: auto;
    display: flex;
    width: calc( 100% - 250px );
    min-width: calc( 100% - 250px );
    max-width: calc( 100% - 250px );
    position: relative;
    justify-content: space-between;
    height: 100%;
    place-content: center;
    align-items: center;
}
.Emojiart_leftWEB > div{
    display: flex;
    margin: 10px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%);
    position: relative;
    height: max-content;
    width: max-content;
    place-content: center;
}
.Emojiart_leftWEB > div > div{
    display: flex;
    place-content: center;
}

.Emojiart_leftWEB_{
    overflow: auto;
    display: flex;
    width: calc( 100% - 250px );
    min-width: calc( 100% - 250px );
    max-width: calc( 100% - 250px );
    position: relative;
    justify-content: space-between;
    height: 100%;
    place-content: center;
    align-items: center;
}
.Emojiart_leftWEB_ > div{
    display: flex;
    margin: 10px;
    box-shadow: none;
    position: relative;
    height: max-content;
    width: max-content;
    place-content: center;
}
.Emojiart_leftWEB_ > div > div{
    display: flex;
    place-content: center;
}

/* WTINBTW */
.Emojiart_leftWTINBTW{
    overflow: auto;
    display: flex;
    width: calc( 100% - 250px );
    min-width: calc( 100% - 250px );
    max-width: calc( 100% - 250px );
    position: relative;
    justify-content: space-between;
    height: 100%;
    place-content: center;
    align-items: center;
}
.Emojiart_leftWTINBTW > div{
    display: flex;
    margin: 10px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%);
    position: relative;
    height: max-content;
    width: max-content;
    place-content: center;
}
.Emojiart_leftWTINBTW > div > div{
    display: flex;
    place-content: center;
}

.Emojiart_leftWTINBTW_{
    overflow: auto;
    display: flex;
    width: calc( 100% - 250px );
    min-width: calc( 100% - 250px );
    max-width: calc( 100% - 250px );
    position: relative;
    justify-content: space-between;
    height: 100%;
    place-content: center;
    align-items: center;
}
.Emojiart_leftWTINBTW_ > div{
    display: flex;
    margin: 10px;
    box-shadow: none;
    position: relative;
    height: max-content;
    width: max-content;
    place-content: center;
}
.Emojiart_leftWTINBTW_ > div > div{
    display: flex;
    place-content: center;
}

/* tablet */
.Emojiart_leftTABLET {
    overflow: auto;
    display: flex;
    width: calc( 100% - 195px );
    min-width: calc( 100% - 195px );
    max-width: calc( 100% - 195px );
    position: relative;
    justify-content: space-between;
    height: 100%;
    place-content: center;
    align-items: center;
}
.Emojiart_leftTABLET > div{
    display: flex;
    margin: 10px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%);
    position: relative;
    height: max-content;
    width: max-content;
    place-content: center;
}
.Emojiart_leftTABLET > div > div{
    display: flex;
    place-content: center;
}

.Emojiart_leftTABLET_{
    overflow: auto;
    display: flex;
    width: calc( 100% - 165px );
    min-width: calc( 100% - 165px );
    max-width: calc( 100% - 165px );
    position: relative;
    justify-content: space-between;
    height: 100%;
    place-content: center;
    align-items: center;
}
.Emojiart_leftTABLET_ > div{
    display: flex;
    margin: 10px;
    box-shadow: none;
    position: relative;
    height: max-content;
    width: max-content;
    place-content: center;
}
.Emojiart_leftTABLET_ > div > div{
    display: flex;
    place-content: center;
}




.Emojiart_toplandscape{
    overflow: auto;
    place-content: center;
    display: flex;
    width: 100%;
    margin: 20px 0px;
    min-height: 380px;
}
.Emojiart_topportrait{
    overflow: auto;
    place-content: center;
    display: flex;
    width: 100%;
    margin: 20px 0px;
    min-height: 550px;
}
.Emojiart_topportrait > div{
    display: flex;
    margin: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: max-content;
}
.Emojiart_toplandscape > div{
    display: flex;
    margin: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: max-content;
}
.Emojiart_btm{
    width:100%;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    display: flex;
}

.Emojiart_purchase{
    display: flex;
    background-color: #eb5d7b;
    color: white;
    width: 100%;
    height: 40px;
    border: 1px solid #eb5d7b;
    text-align: center;
    align-items: center;
    place-content: center;
    position: absolute;
    top: calc( 100% - 40px);
    font-size: 16px;
    cursor: pointer;
}
.Emojiart_row{
    display: flex;
    flex-direction: row;
    position: absolute;
    top: calc( 100% - 40px);
    width: 100%;
}
