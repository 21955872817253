/* .RSVP_root{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    max-height: max-content;
    font-family: Lato;
    overflow-x: hidden;
}
.RSVP_subroot{
    background-color: white;
    color: #707070;
    z-index: 1;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 20px 15px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 8%) 0px 5px 17px 0px;
    border: 1px solid #ebebeb;
    overflow-x:hidden;
}
.RSVP_qnselect{
    height: 32px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    padding: 1px 2px;
    width: 100%;
}
.RSVP_input{
    height: 32px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    padding: 1px 2px;
    width: 100%;
}
.RSVP_captcha{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: max-content;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100px;
}
.RSVP_titletxt{
    display: flex;
    justify-self: start;
}
.RSVP_largetitle{
    display: flex;
    flex-direction: row;
    color: #707070;
    padding: 30px 0px;
    place-content: center;
    text-align: center;
}
.RSVP_rsvpdiv{
    display: flex;
    flex-direction: column;
    margin: 10px 10px 20px 10px;
}
.RSVP_submitbtn{
    padding: 5px 10px ;
    border-radius: 5px ; 
    font-weight: bold;
    border: thin solid rgb(112, 112, 112);
    background-color: white;
    outline: none;
    margin: 20px 10px ;
    height: max-content;
} */

.RSVP_root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    max-height: max-content;
    font-family: Lato;
    overflow-x: hidden;
    /* position: fixed;
    top: 0;
    left: 0; */
}

.RSVP_subroot {
    /* background-color: white; */
    color: #707070;
    z-index: 1;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 20px 15px;
    border-radius: 10px;
    /* box-shadow: rgb(0 0 0 / 8%) 0px 5px 17px 0px; */
    /* border: 1px solid #ebebeb; */
    overflow-x: hidden;
}

.RSVP_loading {
    display: flex;
    height: 100%;
    place-content: center;
    align-items: center;
    font-size: 16px;
    flex-direction: column;
}

.RSVP_main {
    width: 100%;
    display: flex;
    align-items: center;
    place-content: flex-start;
    flex-direction: column;
    margin: 60px 0px;
}

.RSVP_titleroot {
    display: flex;
    font-size: 48px;
    place-content: center;
    align-items: center;
}

.RSVP_detailroot {
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
}

.Rsvp_detailcomp {
    display: flex;
    padding: 5px 20px 0px 20px;
    text-align: center;
    align-items: center;
    width: 100%;
    place-content: center;
    font-size: 16px;
}

.RSVP_nameroot {
    display: flex;
    align-items: center;
    text-align: center;
    place-content: center;
    flex-direction: column;
}

.RSVP_contentroot {
    padding: 40px 0px;
    font-size: 16px;
    overflow-x: hidden;
}

.RSVP_grpleadercomp {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    font-size: 14px;
    overflow-x: hidden;
    align-items: center;
}

.RSVP_grpleadercomprow {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.RSVP_grpleadercompunderline {
    width: 100%;
    height: 1px;
    margin-top: 15px;
    opacity: 0.15;
}

.RSVP_grpleadercompname {
    padding: 0px 10px 0px 10px;
    margin: 0px 10px 0px 0px;
    width: calc(100% - 130px);
    flex-wrap: wrap;
    white-space: pre;
    overflow-x: auto;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.RSVP_grpleadercomprespond {
    min-width: 110px;
    background-color: white;
    border-radius: 5px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    place-content: center;
    align-items: center;
    height: 55px;
}

.RSVP_grpleadercomprespondinner {
    pointer-events: none;
}


.RSVP_grpleaderself {
    width: 100%;
    margin-bottom: 40px;
}

.RSVP_grpleadertitle {
    font-size: 16px;
    width: max-content;
    margin: 0px 10px;
}

.RSVP_menubtn {
    border: 0px solid transparent;
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: flex;
    place-content: center;
    align-items: center;
    height: 35px;
    width: max-content;
    cursor: pointer;
}

.RSVP_arrow {
    display: flex;
    place-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    pointer-events: none;
}

.RSVP_menutxt {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    margin: 0px 5px;
    pointer-events: none;
}


/* Qnlist */
.RSVP_qnlistroot {
    display: flex;
    flex-direction: column;
}

.RSVP_nextroot{
    display: flex;
    width: 100%;
    place-content: flex-end;    
    padding: 0px 10px;
}

.RSVP_next {
    border-radius: 5px;
    width: 110px;
    height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    font-size: 16px;
}
.RSVP_finalreturnmenu {
    border-radius: 5px;
    height: 40px;
    display: flex;
    place-content: center;
    align-items: center;
    margin: 5px 0px;
    font-size: 16px;

}

.RSVP_followupqn {
    margin-left: 10px;
}

.RSVP_recaptcha{
    display: flex;
    align-items: flex-end;
    padding: 0px 10px 15px 10px;
}

@media (min-width:320px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 100%;
    }

    .RSVP_qnlistroot {
        width: 100%;
    }
}

@media (min-width:481px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 420px;
    }

    .RSVP_qnlistroot {
        width: 420px;
    }
}

@media (min-width:641px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 550px;
    }

    .RSVP_qnlistroot {
        width: 550px;
    }
}

@media (min-width:767px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 550px;
    }

    .RSVP_qnlistroot {
        width: 550px;
    }
}

@media (min-width:961px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 550px;
    }

    .RSVP_qnlistroot {
        width: 550px;
    }
}

@media (min-width:1025px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 550px;
    }

    .RSVP_qnlistroot {
        width: 550px;
    }
}

@media (min-width:1281px) {
    .RSVP_main {
        width: 100%;
    }

    .RSVP_contentroot {
        width: 550px;
    }

    .RSVP_qnlistroot {
        width: 550px;
    }
}