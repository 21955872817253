.Gbmsgshort_root{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
.Gbmsgshort_col{
    display: flex;
    flex-direction: column;
    margin: 10px;
    border:thin dashed #707070;
    border-radius: 5px;
    padding: 5px;
}
.Gbmsgshort_closetxt{
    font-size: 14px;
    padding:3px;
}
.Gbmsgshort_expandedtxt{
    font-size: 14px;
    padding:3px;
}
.Gbmsgshort_name{
    font-size: 12px;
    padding:3px;
    font-weight: bold;
}   
