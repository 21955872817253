#Mta_rightcontainer{
    border: 1px solid black;
    margin: 0px 5px 5px 5px;
    padding: 5px;
    border-top: 0;
    flex-direction: column;
    place-content: center;
    align-items: center;
    display: flex;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
#Mta_colorrow{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px 5px 5px 5px;
    place-content: center;
}
#Mta_txt{
    height: 30px;
    min-height: 30px;
    display: flex;
    place-content: center;
    align-items: center;
}
#Mta_colorselected{
    border: 3px solid white;
}
#Mta_colornonselect{
    border: 1px solid transparent;
}
#Mta_txtroot{
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    place-content: space-between;
}
#Mta_firstpart{
    display:flex;
    flex-direction: row;
}
#Mta_index{
    width:30px;
}
#Mta_w{

}
#Mta_freq{
    width:40px;
}
#Mta_colorcircle{
    /* width: 10px;
    height: 10px; */
    width: 100px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid black;
    place-self: center;
    cursor: pointer;
}
.Mta_startbtn{
    display: flex;
    place-content: center;
    margin: 0px 0px 0px 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: 100%;
}
.Mta_midbtn{
    display: flex;
    place-content: center;
    margin: 0px 0px 0px 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: 100%;
}
.Mta_endbtn{
    display: flex;
    place-content: center;
    margin: 0px 5px 0px 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    text-align: center;
    align-items: center;
    width: 100%;
}
.Mta_selectedtrue{
    background-color:#eb5d7b !important;
    color: white !important;
    border:1px solid #eb5d7b;
}