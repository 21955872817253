.Quiz_root {
    display: flex;
    color: var(--maindark);
    flex-direction: column;
    align-self: center;
    padding: 40px 0px;
    margin: 0px 5px;
    font-family: Lato;
}

.Quiz_title {
    display: flex;
    place-content: flex-start;
    white-space: pre;
}

.Quiz_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f0f0;
    border-radius: 10px;
}