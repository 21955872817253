.Scedit_designroot {
    position: relative;
    padding: 61px 0px 100px 0px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    overflow: hidden;
}

.Scdesign_topeditbar {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(57, 76, 96, 0.15);
    flex-direction: row;
    display: flex;
    place-content: flex-start;
    position: fixed;
    z-index: 1;
}

.Scdesign_topeditdd {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 0px;
    top: 0px;
    z-index: 2;
    border: 1px solid rgba(57, 76, 96, 0.15);
    overflow: auto;
    margin-top: 120px;
    background-color: white;
    width: 250px;
    height: calc(100% - 120px);
}

.Scdesign_choosetemplateroot {
    position: fixed;
    flex-direction: column;
    left: 0px;
    top: 0px;
    z-index: 2;
    border: 1px solid rgba(57, 76, 96, 0.15);
    overflow: auto;
    margin-top: 120px;
    background-color: white;
    place-content: flex-start;
    width: 250px;
    height: calc(100% - (61px + 70px + 22px));
    display: flex;
}

.Scdesign_templatetypebtn {
    display: flex;
    width: 100%;
    height: 32px;
    font-family: Lato;
    background-color: white;
    text-align: center;
    place-content: center;
    align-items: center;
    border: none;
    border-bottom: 1px solid rgba(57, 76, 96, 0.15);
}

.Scdesign_templategrid {
    background-color: white;
    display: grid;
    grid-template-columns: auto auto;
    place-content: flex-start;
    gap: 5px;
    margin: 5px;
}

.Scdesign_templateselection {
    display: flex;
    width: calc(125px - 9px);
    height: 150px;
    border: 0.5px solid #00000033;
    background-color: white;
    border-radius: 5px;
    padding: 0px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    box-shadow: #00000033 1px 1px 1px 0px;
}

.Scdesign_templateselected {
    border: 2px solid #eb5d7b !important;
}

.Scdesign_editbtn {
    background-color: white;
    border: 1px solid black;
    margin: 0px 0px 0px 5px;
    border-radius: 5px;
    height: 40px;
    padding: 0px 10px;
    font-family: Lato;
    line-height: 16px;
    place-content: center;
    align-items: center;
}

.Scdesign_editbtn_selected {
    background-color: #eb5d7b;
    color: white;
    border: 1px solid #eb5d7b;
    margin: 0px 0px 0px 5px;
    border-radius: 5px;
    height: 40px;
    padding: 0px 10px;
}

.Scdesign_shorttext {
    width: 95px;
}

.Scdesign_ffdropdown {
    width: 170px;
    display: flex;
    align-items: center;
    height: 40px;
    min-height: 40px;
    border-radius: 5px;
    border: thin solid black;
    background-color: white;
    padding: 0px 10px;
    font-family: Lato;

}

.Scdesign_topbartitle {
    font-size: 10px;
    min-height: 15px;
    font-weight: bold;
}

.Scdesign_topbarrow {
    display: flex;
    flex-direction: row;
    min-height: 25px;
    max-height: 25px;
    align-items: center;
}

.Scdesign_fs {
    display: flex;
    flex-direction: column;
    height: 40px;
    padding-left: 5px;
}

.Scdesign_fsnum {
    width: 50px;
    height: 100%;
    border: 1px solid black;
    align-items: center;
    display: flex;
    place-content: center;
}

.Scdesign_fssub {
    background-color: white;
    border: 1px solid black;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0px 10px;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    width: 30px;
}

.Scdesign_fsadd {
    background-color: white;
    border: 1px solid black;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0px 10px;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    width: 30px;
}

.Scdesign_download {
    right: 0;
    position: absolute;
    margin: 10px;
    height: 40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    place-content: center;
    align-items: center;
    padding: 0px 10px;
}

.Scdesign_template {
    left: 0;
    position: absolute;
    margin: 10px;
    height: 40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    place-content: center;
    align-items: center;
    padding: 0px 10px;
}

.Scdesign_done {
    text-align: center;
    background-color: var(--donegreen);
    color: white;
    height: 40px;
    width: 250px;
    border: 1px solid var(--donegreen);
    font-size: 18px;
    display: flex;
    bottom: 0;
    left: 0;
    padding: 0px;
    align-items: center;
    place-content: center;
    position: fixed;
}

.Scdesign_ffbtn {
    background-color: white;
    border: 0.1px solid #c1c1c1;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px 0px;
}

.Scdesign_ffbtn_selected {
    background-color: #eb5d7b;
    color: white;
    border: 0.1px solid #c1c1c1;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px 0px;
}

.Scdesign_topeditimgdd {
    flex-direction: column;
    left: 0px;
    top: 0px;
    z-index: 2;
    padding: 5px;
    border: 1px solid rgba(57, 76, 96, 0.15);
    height: calc(100% - 160px);
    overflow: auto;
    margin-top: 120px;
    background-color: white;
    width: 250px;
    place-content: flex-start;
    position: fixed;
}

.Scdesign_empty {
    margin-top: 50px;
}

.Scdesign_icon {
    width: 40px;
    height: 40px;
    place-content: center;
    align-items: center;
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 5px;
    background-color: white;
}

.Scdesign_templateback {
    width: 100%;
    height: 100vh;
    margin-top: 61px;
    position: fixed;
}

.Scdesign_imgrow {
    display: flex;
    flex-direction: row;
}

.Scdesign_imgbanner {
    width: calc(240px - 110px);
    margin: 5px 5px 0px 5px;
    height: 40px;
    border-radius: 5px;
    object-fit: contain;
}

.Scdesign_imgsq {
    max-height: 40px !important;
    max-width: 40px !important;
    min-height: 40px !important;
    min-width: 40px !important;
    margin-top: 5px;
    border-radius: 5px;
    object-fit: contain;
}

.Scdesign_btmpagebar {
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    border-top: 1px solid rgba(57, 76, 96, 0.15);
    flex-direction: row;
    display: flex;
    place-content: space-between;
    position: fixed;
    z-index: 0;
    bottom: 0;
}

.Scdesign_pageroot {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    width: 30px;
    height: 40px;
    margin: 10px 0px 10px 10px;
    flex-direction: column;
    font-size: 14px;
    place-content: center;
    align-items: center;
    cursor: pointer;
}

.Scdesign_pageselected {
    border: 1px solid #eb5d7b;
    background-color: #eb5d7b;
    color: white;
}

.Scdesign_btmbarroot {
    display: flex;
    flex-direction: row;
}

.Scdesign_addbtn {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    width: 30px;
    height: 40px;
    background-color: black;
    color: white;
    place-content: center;
    align-items: center;
    margin: 10px 0px 10px 10px;
    cursor: pointer;
}

.Scdesign_delete {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    width: 30px;
    height: 40px;
    background-color: white;
    color: black;
    place-content: center;
    align-items: center;
    margin: 10px;
    cursor: pointer;
}

.Scdesign_emptystart {
    margin-top: 60px;
    height: calc(100vh - 122px - 100px);
    place-content: center;
    align-items: center;
    display: flex;
}

.Scdesign_templatebtn {
    padding: 10px;
    border: 1px dashed black;
    background-color: white;
    border-radius: 5px;
}

.Scdesign_seatmodal {
    width: 350px;
    height: 550px;
    border-radius: 10px;
    background-color: white;
    z-index: 2;
    place-content: space-between;
    align-items: center;
    place-self: center;
    top: calc(50vh - 275px);
    position: absolute;
    font-family: Lato;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0 3px 15px rgb(0 0 0 / 23%);
    display: flex;
    flex-direction: column;
}

.Scdesign_seatmodalcol {
    display: flex;
    flex-direction: column;
}

.Scdesign_seatmodalrow {
    display: flex;
    flex-direction: row;
    place-content: center;
    padding: 5px 0px 15px 0px;
    width: 350px
}

.Scdesign_seatminus {
    min-width: 40px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.Scdesign_seatplus {
    min-width: 40px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.Scdesign_searmodalnum {
    width: 40px;
    height: 40px;
    place-content: center;
    display: flex;
    align-items: center;
    border: 1px solid black;
}

.Scdesign_title {
    display: flex;
    place-content: center;
    padding-top: 15px;
}

.Scdesign_topbarbtnitems {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 10px;
}

.Scdesign_topbarstaticitems {
    display: flex;
    place-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.Scdesign_cancel {
    height: 40px;
    width: calc(100% - 10px);
    margin: 10px;
    padding: 5px;
    border: 1px solid black;
    background-color: white;
    border-radius: 5px;
}

.Scdesign_seatmodalcontent {
    min-height: 30px;
    margin-bottom: 4px;
    height: 30px;
    border: 1px solid black;
    text-align: center;
    align-items: center;
    place-content: center;
    display: flex;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
}

.Scdesign_seatmodalcontent:hover {
    background-color: #eb5d7b;
    color: white;
}

.Scdesign_bggrid {
    display: grid;
    /* grid-template-columns: 16.6666667% 16.6666667% 16.6666667% 16.6666667% 16.6666667% 16.6666667%;
    grid-template-rows: calc(250px / 6); */
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: calc(250px / 5);
}


.Scdesign_bgcoloritem {
    display: flex;
    padding: 3px;
    border-radius: 50px;
    margin: 2px;
    cursor: pointer;
    width: 44.66px;
    height: 44.66px;
}

.Scdesign_bgcoloriteminner {
    border-radius: 100%;
    min-height: 36.66px;
    min-width: 36.66px;
    border: 1px solid #8b8b8b;
}


/* .Scdesign_bgcoloritem_selected {
    width: calc(220px / 6);
    height: calc(220px / 6);
    border: 2px solid #cbaf8a;
    display: flex;
    border-radius: 100%;
    margin: auto;
    cursor: pointer;
}
.Scdesign_bgcoloritem {
    width: calc(220px / 6);
    height: calc(220px / 6);
    border: 1px solid #8b8b8b;
    display: flex;
    border-radius: 100%;
    margin: auto;
    cursor: pointer;
} */




.Scdesign_pagedom {
    height: calc(100vh - 61px - 60px - 60px);
    top: 60px;
    width: 100%;
    color: black;
    position: relative;
    overflow-y: scroll;
    padding: 60px;
    background-color: #fbfbfb;
}

.Scdesign_sep {
    border-left: 1px solid #707070;
    height: 100%;
    width: 1px;
    margin-left: 10px;
    opacity: 0.5;
}

.Scdesign_hidden {
    opacity: 0;
}

/* template_sample_dom START */
.Scdesign_templatesampletop {
    display: flex;
    width: 100%;
    height: 45px;
    flex-direction: row;
    position: relative;
    user-select: none;
    pointer-events: none;
}

.Scdesign_templatesamplemid {
    display: flex;
    flex-direction: row;
    height: calc((100% - 50px) / 2);
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.Scdesign_templatesamplebtm {
    display: flex;
    flex-direction: row;
    height: calc((100% - 50px) / 2);
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.Scdesign_templatesampleleftimg {
    width: 30%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scaleX(-1);
}

.Scdesign_templatesamplerightimg {
    width: 30%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scaleX(1);
    right: 0px;
    position: absolute;
}

.Scdesign_templatesampletext {
    position: absolute;
    height: 45px;
    width: 100%;
    display: flex;
    place-content: center;
    font-size: 5px;
    flex-direction: column;
}

.Scdesign_templatesampletexttop {
    position: relative;
    height: 15%;
    display: flex;
    place-content: center;
    align-items: flex-end;
    font-size: 3px;
}

.Scdesign_templatesampletextmaintitle {
    position: relative;
    height: 40%;
    display: flex;
    place-content: center;
    align-items: flex-start;
    font-size: 10px;
}

.Scdesign_templatebanner {
    width: 100%;
    height: 20%;
    object-fit: contain;
}

.Scdesign_templaterow {
    width: 50%;
    height: 100%;
    margin: auto;
    flex-direction: column;
    display: flex;
    place-content: center;
    font-size: 5px;
}

.Scdesign_templatetabletxt {
    font-weight: bold;
}

.Scdesign_templateseattxt {
    font-weight: bold;
}

/* template_sample_dom END */

.Scdesign_portalrow {
    display: flex;
    flex-direction: row;
    height: 40px;
}

.Scdesign_portalleftbtn {
    padding: 0px 10px;
    height: 40px;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid var(--maindark);
    border-right: none;
    background-color: #ffffff;
    color: var(--maindark);
}

.Scdesign_portalrighttbtn {
    padding: 0px 10px;
    height: 40px;
    border-radius: 0px 5px 5px 0px;
    border: 1px solid var(--maindark);
    border-left:none;
    background-color: #ffffff;
    color: var(--maindark);
}
.Scdesign_btnselectd{
    color: #ffffff;
    background-color: var(--mainpink);
}

.Scdesign_colorpickerroot{

}

.scdesign_colorpicker{
    height: 200px !important;
    width: 100% !important;
    padding: 10px !important;
}