.react-datepicker {
    font-size: 1.3rem !important;
    touch-action:manipulation;
}
  
.react-datepicker__current-month {
    font-size: 1.5rem !important;
    touch-action:manipulation;

}
  
.react-datepicker__navigation {
    top: 13px !important;
    touch-action:manipulation;

}
  
.react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem !important;
    touch-action:manipulation;
}



.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
       width: 100%;
       height: 100%;
    }
    .react-datepicker__input-container  {
        height: inherit;
        align-items: center;
        display:flex;
        width: 100%;

      }
      .react-datepicker__input-container input {
        width: 450px;
        height: 100%;
        outline: 'none';
        border:thin solid rgb(217, 216, 216);

      }

      .react-datepicker-wrapper {
        width: 100%;

      }


    