#Sconline_root{
   width: 100vw;
   height: calc( 100vh - 61px );
   margin-top: 61px;
   place-content: center;
   display: flex;
   align-items: center;
   font-family: Lato;
   background-color: #fbfbfb;
}
#Sconline_centerbox{
    display: flex;
    width: 400px;
    height: 600px;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 20px 0px;
    border-radius: 32px;
    align-items: flex-start;
    place-content: flex-start;
    flex-direction: column;
    background-color: white;
    padding: 40px;
    position: relative;
}
#Sconline_row{
    display: flex;
    white-space: pre-wrap;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}
.Sconline_pageitem{
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    width: 30px;
    height: 40px;
    margin: 3px 10px 10px 0px;
    flex-direction: column;
    font-size: 14px;
    place-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
}
.Scoline_selected {
    border: 1px solid #eb5d7b !important;
    background-color: #eb5d7b !important;
    color: white;
}
#Scoline_savepreview{
    bottom: 40px;
    position: absolute;
    width: calc( 100% - 80px );
    height: 40px;
    border-radius: 5px;
    border: 1px solid black;
    background-color: white;
    font-size: 15px;
}
#Scoline_savepreview:hover{
    border: 1px solid #eb5d7b;
    background-color: #eb5d7b;
    color: white;
    fill: white;
}
#Sconline_layers{
    border: 1px solid var(--maindark);
    padding: 1px 6px;
    background-color: white;
    border-radius: 5px;
    height: 40px;
    width: 90px;
    margin: 3px 0px 10px 0px;
    margin-right: 10px;
}